import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { format, parseISO, isBefore } from 'date-fns'
import { OutOfSubscriptionCell } from 'components/DataTableSlim/Custom/OutOfSubscriptionCell'
import { Column, DataTableInstance, Direction, Categorizable } from 'components/DataTableSlim/DataTableSlim'
import { SolPodName, SolPodVersion, SolStatus } from 'SolComponents'
import {
  ManagementStatus,
  UpdateablePod,
  UpdateablePodsStatus,
  UpdateablePodsQuery,
  UpdateablePodsQueryResult,
} from 'graphql/__generated__/types'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { download } from 'shared/core/csv-download'
import { VersionUtils } from 'shared/hooks/versions/useVersionUtils'
import { SolsticeElementEnum } from '../../shared/enums'

const renderStatus = (pod: UpdateablePod, toCsv: boolean) => {
  const isGen2 = pod?.hardware?.includes('Gen2') ?? false
  if (toCsv) {
    if (pod?.licenseShortName === SolsticeElementEnum.licenseShortName) {
      return 'ELEMENT'
    }

    switch (pod?.status) {
      case UpdateablePodsStatus.Ready: {
        return 'Ready to Update'
      }

      case UpdateablePodsStatus.SolsticeSubscriptionLapsed: {
        return 'EXPIRED'
      }

      case UpdateablePodsStatus.PodOffline: {
        return 'Offline'
      }

      case UpdateablePodsStatus.UnsupportedFirmware: {
        return 'Unsupported Firmware'
      }

      default:
        return 'Ready to Update'
    }
  }

  switch (pod?.status) {
    case UpdateablePodsStatus.Ready: {
      return <SolStatus status={ManagementStatus.Online} tooltipText="Ready to Update" />
    }

    case UpdateablePodsStatus.SolsticeSubscriptionLapsed: {
      return <OutOfSubscriptionCell isGen2={isGen2} />
    }

    case UpdateablePodsStatus.PodOffline: {
      return <SolStatus status={ManagementStatus.Offline} />
    }

    case UpdateablePodsStatus.UnsupportedFirmware: {
      return <Icon name="exclamation circle" color="red" />
    }

    default:
      return UpdateablePodsStatus.Ready
  }
}

const defaultSort = {
  field: 'name',
  direction: 'ascending' as Direction,
}

const isSelectable = (pod: UpdateablePod) => !!pod.updateable

export function UpdatesTable({
  joyride,
  updateButton,
  totalItemsSelected,
  data,
  loading,
  fetchCsvData,
  versionUtils,
}: {
  joyride: JSX.Element | null
  updateButton: JSX.Element
  data?: UpdateablePodsQuery
  loading: boolean
  totalItemsSelected: number
  fetchCsvData: () => Promise<UpdateablePodsQueryResult>
  versionUtils: VersionUtils
}) {
  const pods = data?.updateablePods?.pods ?? []
  const totalItems = data?.updateablePods?.totalPodsCount ?? 0
  const totalItemsSelectable = data?.updateablePods?.updateablePodsCount ?? 0

  const getStatus = (row:  UpdateablePod) => {
    if (!row.subscriptionEndDate) {
      return 'Unknown'
    }

    const subscriptionDate = parseISO(row.subscriptionEndDate)
    const currentDate = new Date()
    if (row.status === UpdateablePodsStatus.SolsticeSubscriptionLapsed || isBefore(subscriptionDate, currentDate)) {
      return 'OutOfMx'
    }
    if (row.status === UpdateablePodsStatus.UnsupportedFirmware) {
      return 'Unsupported'
    }
    return row.status || 'Offline'
  }

  const getSubscriptionRow = (row: Categorizable<UpdateablePod>) => {
    if (row.licenseShortName === SolsticeElementEnum.licenseShortName) {
      return 'N/A'
    }
  
    const dateFormat = 'yyyy/MM/dd'
    const maintenanceEnd = row.subscriptionEndDate
      ? format(new Date(row.subscriptionEndDate), dateFormat)
      : null
  
    if (maintenanceEnd) {
      return maintenanceEnd
    }
    return '--'
  }
  const columns: Column<Categorizable<UpdateablePod>>[] = [
    {
      name: 'status',
      centered: true,
      className: 'joyride-update-pods-table-status',
      render: renderStatus,
      addable: false,
      collapsing: true,
    },
    {
      name: 'name',
      render: (p, toCsv) => {
        if (toCsv) {
          return p.name
        }
        return (
          <SolPodName
            id={p.displayId ?? ''}
            name={p.name ?? ''}
          />
        )
      },
      addable: false,
    },
    {
      name: 'version',
      addable: false,
      render: (p, toCsv) => (
        toCsv ? versionUtils.getSoftwareVersion(p?.version?.join('.') || '') : <SolPodVersion
          version={p?.version?.join('.')}
          catchingUp={p.catchingUp}
          isElementPod={p?.licenseShortName === SolsticeElementEnum.licenseShortName}
        />
      ),
    },
    {
      name: 'hardware',
      displayName: 'Hardware',
      addable: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : row?.hardware
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : row.hardware || '--')
      },
    },
    {
      name: 'subscriptionEndDate',
      displayName: 'Subscription',
      addable: true,
      defaultAdded: true,
      render: (row, toCsv) => {
        const status = getStatus(row)
        if (toCsv) {
          return status === 'OutOfMx'
            ? 'RENEW' : getSubscriptionRow(row)
        }
        return (status === 'Unknown' ? <span style={{ color: 'grey' }}>--</span>
          : getSubscriptionRow(row))
      },
    },
  ]

  const [loadingExport, setLoadingExport] = useState(false)
  const onExport = async () => {
    setLoadingExport(true)
    download(((await fetchCsvData()).data?.updateablePods?.pods ?? []).map(row => {
      return {
        'status': columns[0].render?.(row, true) as string,
        'name': columns[1].render?.(row, true) as string,
        'version': columns[2].render?.(row, true) as string,
        'hardware': columns[3].render?.(row, true) as string,
        'subscription': columns[4].render?.(row, true) as string,
      }
    }), 'Pods To Update')
    setLoadingExport(false)
  }

  return (
    <div>
      {joyride}
      {ServerDataTable<Categorizable<UpdateablePod>>({
        id: DataTableInstance.Updates,
        idSelector: row => row.displayId,
        title: 'Pods To Update',
        data: pods,
        columns,
        selectable: isSelectable,
        defaultSort,
        defaultNoDataMessage: 'All Pods up to date.',
        columnManager: () => updateButton,
        totalItems,
        totalItemsSelected,
        totalItemsSelectable,
        allowExportToCsv: true,
        exportProcessing: loadingExport,
        addableColumns: false,
        onExport,
        loading,
        padded: false,
      })}
    </div>
  )
}
