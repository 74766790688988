import React, { useState } from 'react'
import { SolTooltipWithLink } from 'SolComponents'
import MaintenanceModal from 'components/Maintenance/MaintenanceModal'
import styles from './index.module.scss'
import SolAlertIcon from 'SolComponents/Icons/SolAlertIcon'

export function OutOfSubscriptionCell({ isGen2 = false }: {isGen2?: boolean}) {
  const [showModal, setShowModal] = useState(false)

  const text = isGen2 ? [
    'Certain features are not available for this Gen2i Pod because its Solstice',
    'Subscription period has lapsed and it cannot be renewed.'] : [
    'Certain features are not available for this Pod because its Solstice',
    'Subscription period has lapsed.',
  ]
  const linkUrl = isGen2 ? 'https://www.mersive.com/gen2-eol/' : undefined
  return (
    <React.Fragment>
      <MaintenanceModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        slideKeys={['availability', 'proVsBase']}
      />
      <SolTooltipWithLink
        trigger={<SolAlertIcon className={styles.exclamationTriangle} isGen2={isGen2} />}
        text={text}
        buttonText="LEARN MORE"
        position="bottom right"
        onClick={() => {
          setShowModal(true)
        }}
        {...(isGen2 && {
          linkUrl,
          isExternalLink: true,
        })}
      />
    </React.Fragment>
  )
}
