import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import {
  Display,
  DisplayCompatibilityAggregate,
  DisplayOrderableField,
  DisplaySearchOptions,
  SettingsGroup,
  SettingsGroupsDocument,
  SettingsGroupsQuery,
  SettingsGroupType,
  Template,
  TemplateCompatibilityDocument,
  TemplateCompatibilityQuery,
  TemplateCompatibilityQueryVariables,
  TotalDisplaysDocument,
  TotalDisplaysQuery,
  TotalDisplaysQueryVariables,
} from 'graphql/__generated__/types'
import { StatusOptions } from 'SolComponents/SolStatus/SolStatus'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { Column, DataTableInstance, OrderBy } from 'components/DataTableSlim/DataTableSlim'
import {
  SelectedItems,
  ServerSelectedItems,
} from 'components/DataTableSlim/types'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import {
  SolConfirmationModal,
  SolButtonGroup,
  SolButtonGroupProps,
  SolStatus,
  SolPodName,
} from 'SolComponents'
import { handleOnSelect, handleSelectItems } from 'components/DataTableSlim/Utils'
import { useAlerts } from 'shared/hooks/useAlerts'
import {
  useAssignTemplates,
  useUnassignTemplates,
  updateAllDisplaysQueryOnUnassign,
} from 'shared/hooks/management/useTemplates'
import styles from './AllPodsTable.module.scss'
import UniqueSettings, { Props as UniqueSettingsProps } from 'pages/Templates/UniqueSettings/UniqueSettings'
import { FormValidation } from 'shared/context/FormValidation'
import sortBy from 'lodash/sortBy'
import omit from 'lodash/omit'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import TemplateDropDown from './TemplateDropDown'
import { useSelector, useDispatch } from 'react-redux'
import { setShowUnussigTemplateCheckbox } from '../../../shared/store/slices/core'

enum TemplateCategory {
  Features = 'features',
  Network = 'network',
  Settings = 'settings',
}

const settingsGroupsByCategory = {
  [TemplateCategory.Features]: [
    SettingsGroupType.WelcomeScreen,
    SettingsGroupType.Calendar,
    SettingsGroupType.Features,
    SettingsGroupType.DigitalSignage,
    SettingsGroupType.RoomIntelligence,
  ],
  [TemplateCategory.Network]: [
    SettingsGroupType.Ethernet,
    SettingsGroupType.Proxy,
    SettingsGroupType.Wifi,
    SettingsGroupType.Discovery,
  ],
  [TemplateCategory.Settings]: [
    SettingsGroupType.Security,
    SettingsGroupType.TimeLocale,
    SettingsGroupType.Advanced,
    SettingsGroupType.PowerManagement,
    SettingsGroupType.MessageCenter,
  ],
}
const settingsGroupsOrder: SettingsGroupType[] = Object.values(settingsGroupsByCategory).flat()

const TemplateManager = () => {
  const { setAddedColumns } = useDataTable<any>(DataTableInstance.AllPods)
  const [templateCategory, setTemplateCategory] = useState(TemplateCategory.Features)
  useEffect(() => {
    setAddedColumns([
      DisplayOrderableField.IsManageable,
      'name',
      ...settingsGroupsByCategory[templateCategory],
    ])
  }, [templateCategory])
  const templateCategoryButtons: SolButtonGroupProps = [
    {
      primary: templateCategory === TemplateCategory.Features,
      value: TemplateCategory.Features,
      content: 'Features',
      onClick: setTemplateCategory,
    },
    {
      primary: templateCategory === TemplateCategory.Network,
      value: TemplateCategory.Network,
      content: 'Network',
      onClick: setTemplateCategory,
    },
    {
      primary: templateCategory === TemplateCategory.Settings,
      value: TemplateCategory.Settings,
      content: 'Settings',
      onClick: setTemplateCategory,
    },
  ]
  return (
    <div className={styles.allPodsButtons}>
      <SolButtonGroup buttons={templateCategoryButtons} />
    </div>
  )
}

interface AllPodsTableProps {
  totalItems: number
  totalItemsSelectable: number
  displaySearchOptions: DisplaySearchOptions
  totalItemsSelected: number
  onSelect: (selectedItems: ServerSelectedItems) => void
  refetchDisplays: () => void
  selectedItems: ServerSelectedItems
  data: Display[]
  defaultSort: OrderBy
  loadingTotalSelectedItems: boolean
  loading: boolean
  displayCompatibilityAggregate?: DisplayCompatibilityAggregate
  selectedItemsIds: string[]
  selectedFailedOnboardingItemsIds: string[]
}

type Confirmation = {
  modalOpen: boolean
  meta?: {
    displayId: string
    settingsGroupType: SettingsGroupType
    template: {
      id: string
      name: string
    }
    action: 'assign' | 'unassign'
    showClassicWarning: boolean
  }
}

type UniqueSettingsWorkflow = {
  drawerIsOpen: boolean
} & Omit<UniqueSettingsProps, 'onClose' | 'assignTemplates'>

export function AllPodsTable({
  data,
  totalItems,
  totalItemsSelectable,
  displaySearchOptions,
  onSelect,
  refetchDisplays,
  defaultSort,
  loading,
  totalItemsSelected,
  selectedItems,
  loadingTotalSelectedItems,
  selectedItemsIds,
  selectedFailedOnboardingItemsIds,
}: AllPodsTableProps) {
  const client = useApolloClient()
  const { showSuccess } = useAlerts()
  const {
    data: settingsGroupsResult,
    refetch: refetchSettingsGroupTemplates,
  } = useQuery<SettingsGroupsQuery>(SettingsGroupsDocument, {
    fetchPolicy: 'cache-and-network',
  })
  
  const [fetchTemplateCompatibility, { data: templateCompatibilityResult, loading: templateCompatibilityLoading }]
    = useLazyQuery<TemplateCompatibilityQuery, TemplateCompatibilityQueryVariables>(TemplateCompatibilityDocument, {
      fetchPolicy: 'cache-and-network',
    })

  const { setSelectedItems, tableData } = useDataTable<Display>(DataTableInstance.AllPods)

  const [confirmation, setConfirmation] = useState<Confirmation>({
    modalOpen: false,
  })
  const [uniqueSettingsWorkflow, setUniqueSettingsWorkflow] = useState<UniqueSettingsWorkflow>({
    drawerIsOpen: false,
  })

  const showUnussignTemplateCheckboxState
    = useSelector((coreState: any) => coreState?.core?.showUnussigTemplateCheckbox)

  const [displayToAdd, setDisplayToAdd] = useState<string>('') // part of displayToAdd hack
  const [welcomeTemplateId, setWelcomeTemplateId] = useState('') // similar hack as above

  const [currentSelectedItems, setCurrentSelectedItems] = useState<SelectedItems>(
    tableData?.selectedItems ?? { ids: [], items: [] },
  )

  const dispatch = useDispatch()

  useEffect(() => {
    fetchTemplateCompatibility()
  }, [])

  useEffect(() => {
    setCurrentSelectedItems(tableData?.selectedItems ?? { ids: [], items: [] })
  }, [tableData?.selectedItems])

  // This useEffect for 'displayToAdd' is a hack to get around some scope issues.
  // We want to update the currentSelected items, but don't have an up to date
  // list in the options/onChange because it is memoized(for performance)
  // This lets us get around that. It's not my favorite but it is the best I could come up with
  useEffect(() => {
    if (currentSelectedItems.includedIds?.includes(displayToAdd)) {
      setDisplayToAdd('')
    }
    // all this garbage is just to add 1 selected item
    const newlySelectedItems = handleSelectItems(currentSelectedItems, item => item.id, [{ id: displayToAdd }])
    setSelectedItems(newlySelectedItems)
    onSelect({
      includedIds: newlySelectedItems.includedIds,
      excludedIds: newlySelectedItems.excludedIds,
    })
  }, [displayToAdd, confirmation])

  // same hack as above
  useEffect(() => {
    if (welcomeTemplateId) {
      // most of this is for the silly way we do selected items
      const ids = new Set([welcomeTemplateId, ...(tableData?.selectedItems?.includedIds  || [])])
      const idsArray = Array.from(ids)
      const excluded = currentSelectedItems.excludedIds?.filter(function(id: string ) {
        return id !== welcomeTemplateId
      })
      fetchTemplateCompatibility({
        variables: {
          options: {
            settingsGroupType: SettingsGroupType.WelcomeScreen,
            displaySearchOptions: {
              displayIds: idsArray,
              excludeDisplayIds: excluded,
            },
          },
        },
      })
      setWelcomeTemplateId('')
    }
  }, [welcomeTemplateId])

  const state = confirmation.meta
  const displayFilterSearchOptions = omit(displaySearchOptions, ['records', 'page', 'orderBy'])
  const { assignTemplates, assigning } = useAssignTemplates(
    displayFilterSearchOptions,
    () => refetchSettingsGroupTemplates(),
  )
  const { unassignTemplates, unassigning } = useUnassignTemplates(
    displayFilterSearchOptions,
    updateAllDisplaysQueryOnUnassign(displaySearchOptions, selectedItems, state?.settingsGroupType),
  )

  const columns = [
    {
      displayName: 'Status',
      centered: true,
      name: DisplayOrderableField.IsManageable,
      collapsing: true,
      render: (r, toCsv) => {
        let tooltip
        let status = r.managementStatus as StatusOptions
        if (r.managementStatus === 'Online' &&  (r.syncState === 'mismatched' || r.syncState === 'rejected')) {
          status = 'TemplateSyncError'
          tooltip = `Online, but some templates could not be applied to this Pod.
           Please review Pod's template settings and try again.`
        }
        return toCsv ? (
          status
        ) : (
          <SolStatus
            status={status as StatusOptions}
            isInline={false}
            className={styles.statusIcon}
            tooltipText={tooltip}
          />
        )
      },
    },
    {
      name: 'name',
      render: (r, toCsv) => {
        const hardware = r.versions?.hardware?.replace('Pod ', '') ?? ''
        const ip = r.ipAddressesPrimary ?? ''
        if (toCsv) {
          return `${r.name} ${ip || ''}`
        }
        return <SolPodName id={r.id} name={r.name} ip={`${hardware} ${hardware && ip ? '|' : ''} ${ip}`} />
      },
    },
  ] as Column<Display>[]

  const settingsGroups = useMemo(() => sortBy(settingsGroupsResult?.settingsGroups ?? [], settingsGroup =>
    settingsGroupsOrder.indexOf(settingsGroup?.type!),
  ) as SettingsGroup[], [settingsGroupsResult],
  )

  const selectedItemsRef = useRef(selectedItems)

  useEffect(() => {
    selectedItemsRef.current = selectedItems
  }, [selectedItems])

  const chooseTemplate = useCallback(async ({
    row,
    value,
    settingsGroupType,
    name,
  }: {
    row: any
    value: string
    settingsGroupType: SettingsGroupType
    name: string
  }) => {
    if (value === 'unassign' && localStorage.getItem('showUnussigTemplateCheckbox') === 'false') {
      let selectedItemsResult
      if (
        selectedItemsRef.current.includedIds?.length === 0
        || (selectedItemsRef.current.includedIds?.length === 1 && selectedItemsRef.current.includedIds[0] === '')
      ) {
        selectedItemsResult = {
          ...selectedItemsRef.current,
          includedIds: [...selectedItemsRef.current.includedIds, row.id],
        }
        unassignTemplates(selectedItemsResult, settingsGroupType).then(() => {
          refetchDisplays()
          showSuccess('Templates were unassigned')
        })
        return
      }
      if (selectedItemsRef.current.includedIds?.includes('*')) {
        selectedItemsResult = {
          ...selectedItemsRef.current,
          includedIds: data
            ? selectedItemsIds.filter(id =>
              (!selectedItemsRef.current.excludedIds?.includes(id) || id === row.id),
            )
            : selectedItemsRef.current.includedIds,
        }
        unassignTemplates(selectedItemsResult, settingsGroupType).then(() => {
          refetchDisplays()
          showSuccess('Templates were unassigned')
        })
        return
      }
      if (!selectedItemsRef.current.includedIds?.includes(row.id)) {
        selectedItemsResult = {
          ...selectedItemsRef.current,
          includedIds: data.filter(d => selectedItemsRef.current.includedIds?.includes(d.id) || d.id === row.id)
            .map(d => d.id),
        }
        unassignTemplates(selectedItemsResult, settingsGroupType).then(() => {
          refetchDisplays()
          showSuccess('Templates were unassigned')
        })
        return
      }
      selectedItemsResult = {
        ...selectedItems,
        includedIds: selectedItemsRef.current.includedIds?.includes('*')
          ? selectedItemsIds
          : data.filter(d => selectedItemsRef.current.includedIds?.includes(d.id)
            || d.id === confirmation.meta?.displayId)
            .map(d => d.id),
      }
      unassignTemplates(selectedItemsResult, settingsGroupType).then(() => {
        refetchDisplays()
        showSuccess('Templates were unassigned')
      })
      return
    }
    let showClassicWarning = false
    if (settingsGroupType === SettingsGroupType.WelcomeScreen && value !== 'unassign') {
      const { data: totalDisplaysResult } = await client.query<
        TotalDisplaysQuery,
        TotalDisplaysQueryVariables
      >({
        query: TotalDisplaysDocument,
        variables: {
          options: {
            displayIds: selectedItems.includedIds,
            excludeDisplayIds: selectedItems.excludedIds,
            inClassicSplash: true,
          },
        },
      })

      const totalDisplaysInClassicSplash = totalDisplaysResult?.displays?.totalRecords ?? 0
      if (totalDisplaysInClassicSplash > 0) {
        showClassicWarning = true
      }
    }
    setConfirmation({
      modalOpen: true,
      meta: {
        displayId: row.id,
        settingsGroupType: settingsGroupType,
        action: value === 'unassign' ? 'unassign' : 'assign',
        template: {
          id: value,
          name,
        },
        showClassicWarning,
      },
    })
  }, [currentSelectedItems, data])

  const settingsGroupColumns = useMemo(() => {
    const sgColumns = settingsGroups.map((sg: SettingsGroup) => ({
      displayName: sg.type === SettingsGroupType.PowerManagement ? 'Power Mgmt' : sg.name,
      name: sg.type,
      addable: true,
      render: row => {
        if (!row.isManageable) {
          return
        }

        const columnTemplate = row?.assignedTemplates
          .find(t => t?.template?.settingsGroup.type === sg.type)

        // filters out unassigned templates to match previous functionality
        const currentTemplate  = columnTemplate?.template?.isCustom ? undefined :  columnTemplate?.template
        const hasPendingChanges  = columnTemplate?.hasPendingChanges
        const syncState  = columnTemplate?.syncState

        const options = [
          {
            key: 'unassign',
            text: 'Unassign',
            value: 'unassign',
            active: !currentTemplate,
            disabled: !currentTemplate,
          },
        ].concat(
          sg.templates?.map((t: Template) => {
            const templateCompatibility = templateCompatibilityResult?.templateCompatibility?.find(
              tc => tc?.templateId === t.id && sg.type === SettingsGroupType.WelcomeScreen,
            ) ?? {
              isCompatible: true,
            }

            return {
              key: t.id,
              text: t.name,
              value: t.id,
              active: t.id === currentTemplate?.id,
              disabled: t.id === currentTemplate?.id || !templateCompatibility.isCompatible,
              description: !templateCompatibility.isCompatible ? templateCompatibility.incompatibleReason : undefined,
            }
          }) ?? [],
        )
        return (
          <TemplateDropDown
            row={row}
            settingsGroupType={sg.type}
            options={options}
            hasPendingChanges={hasPendingChanges  ||  false}
            syncState={syncState || ''}
            currentTemplate={currentTemplate}
            onChange={chooseTemplate}
            getTemplateCompatibility={id => {
              setWelcomeTemplateId(id)
            }}
            loadingTotalSelectedItems={loadingTotalSelectedItems}
            templateCompatibilityLoading={templateCompatibilityLoading}
          />
        )
      },
      defaultAdded: true,
    })) as Column<Display>[]
    return [...columns, ...sgColumns]
  }, [
    settingsGroups,
    templateCompatibilityResult,
    templateCompatibilityLoading,
    templateCompatibilityResult?.templateCompatibility,
  ],
  )

  const classicText = (
    <span>
      <strong>{
        totalItemsSelected === 1 ? 'This Pod' : 'Some Pods'
      } will be converted to Modern Welcome Screen.
      </strong>{' '}
      Modern Welcome Screen is the only Welcome Screen in Solstice Cloud Management. You need to use the Dashboard in
      order to undo this.
    </span>
  )

  const getPodIdentifier = (): string => {
    let selectedItemsResult: string[] = []
    if (totalItemsSelected > 500) {
      return `${totalItemsSelected} Pods`
    }
    if (selectedItems.includedIds?.includes('*')) {
      selectedItemsResult = selectedFailedOnboardingItemsIds.filter(id =>
        (!selectedItems.excludedIds?.includes(id) || id === confirmation.meta?.displayId))
      if (confirmation.meta?.displayId && selectedItems.excludedIds?.includes(confirmation.meta.displayId)) {
        selectedItemsResult.push(confirmation.meta.displayId)
      }
    } else {
      const allPagesResult
        = selectedFailedOnboardingItemsIds?.filter(
          id => id !== '' && !selectedItems.excludedIds?.includes(id),
        ) || []
      const currentPageResult = data
        .filter(
          d => d.id === confirmation.meta?.displayId)
        .map(d => d.id)

      selectedItemsResult = [
        ...allPagesResult,
        ...currentPageResult.filter(id => !allPagesResult.includes(id)),
      ]
    }
    if (selectedItemsResult.length > 1) {
      return `${selectedItemsResult.length} Pods`
    }

    const selectedPod = data.find(d => d.id === confirmation.meta?.displayId)

    const truncatedName = selectedPod?.name && selectedPod?.name?.length > 46
      ? `${selectedPod.name.slice(0, 46)}...`
      : selectedPod?.name

    return truncatedName || 'Pod'
  }

  const modernScreenText = state?.action === 'unassign' && showUnussignTemplateCheckboxState
    ? 'Unassigning a template will not change any existing Pod settings.'
    : `You're changing a template on ${getPodIdentifier()}`

  const modalTextWarningClassic = state?.showClassicWarning === true
    ? classicText
    : modernScreenText

  let assignedText
  if (state?.action === 'assign') {
    assignedText = `${getPodIdentifier()} will now be on the ${state.template.name} template.`
  } else {
    assignedText = `Unassign template from ${getPodIdentifier()}?`
  }

  const handleOnSelectTable = (selectedItemsResult: ServerSelectedItems) => {
    onSelect(selectedItemsResult)
  }

  return (
    <div className={styles.wrapper}>
      <SolConfirmationModal
        modalText={assignedText}
        subHeaderText={modalTextWarningClassic}
        isOpen={confirmation.modalOpen}
        dontShowAgainCheckbox={state?.action === 'unassign' ? showUnussignTemplateCheckboxState : false}
        onDontShowCheckboxChange={value => {
          dispatch(setShowUnussigTemplateCheckbox(value))
        }}
        onCloseModal={() => {
          const result = handleOnSelect({
            selectType: 'deselect',
            selectQuantity: 'some',
            items: [{ id: displayToAdd }],
            currentSelectedItems,
            idSelector: d => d.id,
          })

          setSelectedItems(result)
          if (onSelect) {
            onSelect({
              includedIds: result.includedIds,
              excludedIds: result.excludedIds,
            })
          }
          setDisplayToAdd('')
          setConfirmation({ modalOpen: false })
        }}
        onAcceptingAction={async () => {
          if (state?.action === 'unassign') {
            const selectedItemsResult = {
              ...selectedItems,
              includedIds: selectedItems.includedIds?.includes('*')
                ? selectedItemsIds.filter(id =>
                  (!selectedItems.excludedIds?.includes(id) || id === confirmation.meta?.displayId),
                )
                : data.filter(d => selectedItems.includedIds?.includes(d.id) || d.id === confirmation.meta?.displayId)
                  .map(d => d.id),
            }
            unassignTemplates(selectedItemsResult, state?.settingsGroupType).then(() => {
              refetchDisplays()
              showSuccess('Templates were unassigned')
            })
          } else {
            let selectedItemsResult
            selectedItemsResult = {
              ...selectedItems,
              includedIds: !selectedItems.includedIds?.includes(confirmation.meta?.displayId as string)
                ? data.filter(d => selectedItems.includedIds?.includes(d.id) || d.id === confirmation.meta?.displayId)
                  .map(d => d.id)
                : selectedItems.includedIds,
              excludedIds: [],
            }

            if (selectedItems.includedIds?.includes('*')) {
              selectedItemsResult = {
                ...selectedItems,
                includedIds: selectedItems.excludedIds?.length === selectedItemsIds.length
                  ? selectedItemsIds.filter(id => id === confirmation.meta?.displayId)
                  : selectedItemsIds,
                excludedIds: !selectedItems.excludedIds?.includes(confirmation.meta?.displayId as string)
                  ? selectedItems.excludedIds
                  : selectedItems.excludedIds?.filter(id => id !== confirmation.meta?.displayId as string),
              }
            }

            const response = await assignTemplates(selectedItemsResult, state?.template.id ?? '')
            refetchDisplays()
            if (response.data?.assignTemplate.validation.incompatibleDisplays?.length === 0) {
              showSuccess('Template was applied')
            } else {
              setUniqueSettingsWorkflow({
                drawerIsOpen: true,
                ...response.data?.assignTemplate,
              } as any)
            }
          }
          setConfirmation({ modalOpen: false })
        }}
        acceptingDisabled={assigning || unassigning}
        cancelDisabled={assigning || unassigning}
        acceptingText="Confirm"
        cancelText="Cancel"
        isLoading={loading}
      />
      <FormValidation>
        <UniqueSettings
          onClose={() => {
            setUniqueSettingsWorkflow({ drawerIsOpen: false })
          }}
          assignTemplates={assignTemplates}
          {...uniqueSettingsWorkflow}
        />
      </FormValidation>
      {ServerDataTable<Display>({
        id: DataTableInstance.AllPods,
        columns: settingsGroupColumns,
        onSelect: handleOnSelectTable,
        data,
        title: 'Pod Template Assignments',
        totalItems,
        totalItemsSelectable, // search for usages of this
        totalItemsSelected,
        defaultSort,
        loading,
        tableHeaderColumnAutoWidth: true,
        columnManager: TemplateManager,
        scrollable: false,
        persistAddedColumns: false,
        selectable: true,
        wideHeader: true,
        addableColumns: true,
        padded: false,
      })}
    </div>
  )
}
