import { useAlerts } from '../useAlerts'
import { useMutation } from '@apollo/client'
import {
  Address,
  DeleteAddressDocument,
  DeleteAddressResult,
  MutationDeleteAddressArgs, useLocationCategoriesQuery,
} from '../../../graphql/__generated__/types'
import { useDeleteAlertFilters } from '../../../pages/Alerts/Settings/hooks/useDeleteAlertFilters'
import { AddressToDelete } from '../../../components/Location/AddressSearchSelectDropdown/BuildingsDropdown'
import { Filter, FilterType } from '../../../pages/Alerts/Settings/types'
import { toLower } from 'lodash'
import { DataTableInstance } from '../../../components/DataTableSlim/DataTableSlim'
import { useDataTable } from '../../../components/DataTableSlim/Hooks/useDataTable'
import {
  LocationsDisplaysInfoDocument,
  LocationsPageOverviewCardCountDocument,
} from 'graphql/__generated__/hasura-types'
import { LocationPageFilters } from 'pages/Location/Utils/get-hasura-params'

type RequiredAddress = {
  id: string
  city: string
  state_province: string
  country: string
  building: string
  address_full: string
}

const createFilters = (address: RequiredAddress, orgAddresses: Address[]): Filter[] => {
  const toKebabCase = (str: string) => toLower(str).replace(/\s+/g, '_')
  const filters: Filter[] = []

  if (orgAddresses.filter(addr => addr.building === address.building).length === 1) {
    filters.push({
      id: `building-address_id_${address.id}`,
      categoryInternalName: 'building',
      optionInternalName: `address_id_${address.id}`,
    })
  }

  const uniqueCity = orgAddresses.filter(addr =>
    addr.city === address.city && addr.country === address.country).length === 1
  if (uniqueCity) {
    filters.push({
      id: `city-${toKebabCase(address.city)}_${toKebabCase(address.state_province)}`,
      categoryInternalName: 'city',
      optionInternalName: `${toKebabCase(address.city)}_${toKebabCase(address.state_province)}`,
    })
  }

  const countryCount = orgAddresses.filter(addr => toLower(addr.country || '') === toLower(address.country)).length

  const isCountryUnique = countryCount === 1
  if (isCountryUnique) {
    filters.push({
      id: `country-${toKebabCase(address.country)}`,
      categoryInternalName: 'country',
      optionInternalName: `${toKebabCase(address.country)}`,
    })
  }

  return filters
}

export const useDeleteAddress = (searchParams?: LocationPageFilters['tableFilters']) => {
  const { showSuccess, showError } = useAlerts()
  const tableId = DataTableInstance.AlertHistory
  const { setCategoryFilters, tableData } = useDataTable<any>(tableId)
  const { categoryFilters } = tableData || { categoryFilters: {} }

  const deleteAlertFilters = useDeleteAlertFilters()

  const [deleteAddressMut, { loading: deletingAddress }] = useMutation<
    DeleteAddressResult,
    MutationDeleteAddressArgs
  >(DeleteAddressDocument, {
    onCompleted: () => {
      refetchLocationData().then(() => showSuccess('Address was deleted'))
    },
    onError: error => {
      showError('There was a problem deleting the address. Try again later.')
      throw error
    },
    refetchQueries: [
      {
        query: LocationsDisplaysInfoDocument,
      },
      ...(searchParams?.where
        ? [
          {
            query: LocationsPageOverviewCardCountDocument,
            variables: { displaywhere: searchParams.where },
          },
        ]
        : []),
    ],
    awaitRefetchQueries: true,
  })

  const { data: locationData, refetch: refetchLocationData } = useLocationCategoriesQuery({
    variables: {
      options: {
        hasPods: true,
      },
    },
    fetchPolicy: 'network-only',
  })

  return {
    deleteAddress: async (addressToDelete: AddressToDelete) => {
      const locationFilters = locationData?.addresses as Address[]
      const foundAddress = locationFilters.find(lf => lf.id === addressToDelete.key)

      if (foundAddress) {
        const requiredAddress: RequiredAddress = {
          id: foundAddress.id.toString(),
          city: foundAddress.city || '',
          state_province: foundAddress.state_province || '',
          country: foundAddress.country || '',
          building: foundAddress.building || '',
          address_full: foundAddress.address_full || '',
        }

        let filters = createFilters(requiredAddress, locationFilters)

        Object.values(FilterType).forEach(filterType => {
          filters.forEach(filter => {
            deleteAlertFilters(filterType, filter)

            if (filter.categoryInternalName && ['building', 'city', 'country'].includes(filter.categoryInternalName)) {
              const {
                [filter.categoryInternalName]: _currentCategory,
                ...newCategories
              } = categoryFilters

              const updatedCategories = Object.keys(newCategories).reduce((acc, key) => {
                if (!['building', 'city', 'country'].includes(key)) {
                  acc[key] = newCategories[key]
                }
                return acc
              }, {} as typeof newCategories)

              setCategoryFilters(updatedCategories)
            } else {
              setCategoryFilters({})
            }
          })
        })
      }

      return deleteAddressMut({
        variables: {
          options: { addressId: addressToDelete.key },
        },
      })
    },
    deletingAddress,
  }
}
