import React, { useEffect } from 'react'
import Filter from './Filter'
import {
  AppliedCategoryFilter,
  AppliedCategoryFilters,
  useCustomFilters,
} from './Hooks/useAppliedFilters'
import styles from './DataTableSlim.module.scss'
import {
  ActiveLearningStatus,
  CategoriesListQuery,
  useCategoriesListQuery,
} from 'graphql/__generated__/types'
import { useDataTable } from './Hooks/useDataTable'
import { ellipsesStringOverflow } from 'shared/core/utils'
import { useLocationsDisplaysInfoQuery } from 'graphql/__generated__/hasura-types'

interface Props {
  tableId: string
  clearSelectedItemsOnFilter?: boolean
  topFilter?: boolean
  totalPods?: number
  setSearchValue?: (searchValue: string) => void
  searchValue?: string
}

interface CategoryOptionsMap {
  [categoryId: string]: string[]
}

export function AppliedFilters({
  tableId,
  clearSelectedItemsOnFilter,
  topFilter,
  ...props
}: Props) {
  const { setCategoryFilters, categoryFilters, ...dataTable } = useDataTable<any>(
    tableId,
    { clearSelectedItemsOnFilter },
  )

  const setSearchValue = props.setSearchValue ?? dataTable.setSearchValue
  const searchValue = props.searchValue ?? dataTable.searchValue
  const { customFilters, removeCustomFilter } = useCustomFilters<ActiveLearningStatus>(tableId)

  const handleRemoveFilter = (categoryId: string | number) => {
    const { [categoryId]: _existing, ...rest } = categoryFilters
    setCategoryFilters(rest)
  }

  const {
    data: categoriesData,
    loading: categoriesLoading,
  } = useCategoriesListQuery({
    variables: {
      options: {},
    },
  })

  const { data: locationData, loading: locationsLoading } = useLocationsDisplaysInfoQuery()

  const getCurrentAppliedCategoryFilters = () => {
    const locationKeys = ['country', 'city', 'building']

    const newCategoryFilters: AppliedCategoryFilters = {}

    const locationPageFilters: { [key: string]: AppliedCategoryFilter } = Object.keys(categoryFilters)
      .filter(f => locationKeys.includes(f))
      .reduce<{ [key: string]: AppliedCategoryFilter }>((acc, key) => {
        acc[key] = categoryFilters[key]
        return acc
      }, {})
      
    const customPageFilters: { [key: string]: AppliedCategoryFilter } = Object.keys(categoryFilters)
      .filter(f => !locationKeys.includes(f))
      .reduce<{ [key: string]: AppliedCategoryFilter }>((acc, key) => {
        acc[key] = categoryFilters[key]
        return acc
      }, {})

    if (Object.keys(locationPageFilters).length !== 0) {
      const locationIds = Array.from(
        new Set(
          locationData?._displays?.flatMap(display => display.location?.address?.id || []) || [],
        ))

      const locationIdsWithNicknames = Array.from(
        new Set(
          locationData?._displays?.flatMap(display => {
            const { id, nickname } = display.location?.address || {}
            return id && nickname ? [`${id}-${nickname}`, id] : id ? [id] : []
          }) || [],
        ),
      )
  
      const updatedLocationPageFilters = Object.entries(locationPageFilters).reduce(
        (acc, [key, value]) => {
          const filteredOptionMatches = Object.entries(value.optionMatches).reduce(
            (optionAcc, [optionKey, optionValue]) => {
              if (optionValue.addressIds) {

                const isBuildingFilter = key === 'building'
                const filteredAddressIds = optionValue.addressIds.filter(id => {
                  if (isBuildingFilter) {
                    const displayName = optionValue.displayName
                    return locationIdsWithNicknames.includes(`${id}-${displayName}`)
                  }
                  return locationIds.includes(id)
                            
                })

                if (filteredAddressIds.length > 0) {
                  optionAcc[optionKey] = { ...optionValue, addressIds: filteredAddressIds }
                }
              }

              return optionAcc
            },
                {} as Record<string, any>,
          )

          if (Object.keys(filteredOptionMatches).length > 0) {
            acc[key] = { ...value, optionMatches: filteredOptionMatches }
          }

          return acc
        },
        {} as Record<string, AppliedCategoryFilter>,
      )

      Object.assign(newCategoryFilters, updatedLocationPageFilters)
    } else {
      Object.assign(newCategoryFilters, locationPageFilters)
    }

    if (Object.keys(customPageFilters).length !== 0) {
      const getUniqueCustomOptions = (data: CategoriesListQuery): CategoryOptionsMap => {
        const categoriesMap: { [categoryId: string]: Set<string> } = {}
      
        data.categoryManagerPods.forEach(pod => {
          pod.categories.forEach(category => {
            const { categoryId, option } = category
      
            if (!categoriesMap[categoryId]) {
              categoriesMap[categoryId] = new Set()
            }
      
            categoriesMap[categoryId].add(option.id)
          })
        })
      
        const result: CategoryOptionsMap = Object.keys(categoriesMap)
          .filter(categoryId => categoriesMap[categoryId].size > 0)
          .reduce((acc, categoryId) => {
            acc[categoryId] = Array.from(categoriesMap[categoryId])
            return acc
          }, {} as CategoryOptionsMap)
      
        return result
      }

      const filterCustomPageFilters = (
        pageCategoryFilters: Record<string, AppliedCategoryFilter>,
        categoriesOptions: CategoryOptionsMap,
      ): Record<string, AppliedCategoryFilter> => {
        const filteredCustomFilters: Record<string, AppliedCategoryFilter> = {}
      
        Object.entries(pageCategoryFilters).forEach(([categoryId, filter]) => {
          if (categoriesOptions[categoryId]) {
            const validOptions = categoriesOptions[categoryId]
      
            const filteredOptionMatches = Object.entries(filter.optionMatches).reduce(
              (acc, [optionId, option]) => {
                if (validOptions.includes(optionId)) {
                  acc[optionId] = option
                }
                return acc
              },
              {} as Record<string, any>,
            )
      
            if (Object.keys(filteredOptionMatches).length > 0) {
              filteredCustomFilters[categoryId] = {
                ...filter,
                optionMatches: filteredOptionMatches,
              }
            }
          }
        })
      
        return filteredCustomFilters
      }
      const newCategoriesOptions = categoriesData ? getUniqueCustomOptions(categoriesData) : {}
      const updatedCustomFilters = filterCustomPageFilters(customPageFilters, newCategoriesOptions)
      Object.assign(newCategoryFilters, updatedCustomFilters)
    } else {
      Object.assign(newCategoryFilters, customPageFilters)
    }
    setCategoryFilters(newCategoryFilters)
  }

  useEffect(() => {
    if (!locationsLoading && !categoriesLoading) {
      getCurrentAppliedCategoryFilters()
    }
  }, [tableId, locationData, categoriesData])

  return (
    <div>
      {topFilter && searchValue && searchValue.length > 0 ? (
        <Filter
          key="searchOpt"
          className={styles.pill}
          optionDisplayName={ellipsesStringOverflow(searchValue, 150)}
          onRemoveFilter={() => setSearchValue('')}
        />
      ) : (
        ''
      )}
      {Object.values(categoryFilters).map((categoryFilter, index) => {
        return (
          <Filter
            key={index}
            className={styles.pill}
            optionDisplayName={Object.values(categoryFilter.optionMatches)
              .map(option => option.displayName)
              .join(' OR ')}
            onRemoveFilter={() => handleRemoveFilter(categoryFilter.categoryInternalName)}
          />
        )
      })}
      {Object.entries(customFilters).map(([filterId, customFilter]) => (
        <Filter
          key={filterId}
          className={styles.pill}
          optionDisplayName={Object.values(customFilter.values)
            .map(v => v.displayName)
            .join(' OR ')}
          color={customFilter.color}
          onRemoveFilter={() => removeCustomFilter(filterId)}
        />
      ))}
    </div>
  )
}
