import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { SolDropdown } from 'SolComponents'
import styles from './CategoriesTable.module.scss'
import { useSelectDisplays } from './useSelectDisplays'
import { Confirm } from 'semantic-ui-react'
import { Option, Pod } from './../CategoryTypes'
import { Category } from 'graphql/__generated__/types'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'

const ConfirmBulkApplyModal = (props: {
  categoryName: string
  optionName: string
  podsCount: number
  onCancel: () => void
  onConfirm: () => void
}) => {
  const message = `Set ${props.categoryName} to '${props.optionName}'` + ` for ${props.podsCount} selected screens?`
  return (
    <Confirm
      open
      content={message}
      cancelButton="Cancel"
      confirmButton="Save"
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
    />
  )
}

interface Props {
  disabled?: boolean
  pod: Pod
  category: Category
  options: Option[]
  value?: { id?: string | null; name?: string | null }
  totalSelectedItems: number
  applyCategory: (categoryId: string, optionId?: string, displayIdToInclude?: string) => Promise<number>
}

export const CategoryDropdown = ({
  disabled,
  pod,
  category,
  options,
  value,
  totalSelectedItems,
  applyCategory,
}: Props) => {
  const [applyingOptionId, setApplyingOptionId] = useState<string | undefined>(undefined)
  const [pendingParams, setPendingParams] = useState<{
    optionId?: string
    isSelected?: boolean
    displayId?: string
  } | null>(null)
  const { selectDisplays, unselectDisplays } = useSelectDisplays()
  const { selectedItems: currentSelectedItems } = useDataTable<Pod>(DataTableInstance.Categories)

  const dropdownOptions = [
    {
      text: 'Not set',
      value: 'unassign',
      disabled: !value,
    },
    ...options.map(option => ({
      value: option.id ?? '',
      text: option.name,
    })),
  ]

  /*
    HEY!!!!!!!! The verb for this element is really weird. The component lives on a row that represents a pod, and
    it looks like it should use the component props to determine which pod is being updated,
    but it's actually applying the category option to every pod in the table that is selected with the selection
    widget (which lives in Redux). It only has a pod prop so it can auto-select that pod when you click the dropdown! :)
    It functions completely outside its context and is global to the entire table--it could even be completely moved
    outside the table and, except for the auto-select, still work fine!
  */
  const confirmApplyCategoryToSelectedPods = async (optionId?: string, isSelected?: boolean, displayId?: string) => {
    await applyCategory(
      category.id,
      optionId === 'unassign' ? undefined : optionId,
      isSelected ? undefined : displayId,
    )
    setApplyingOptionId(undefined)
    setPendingParams(null)
  }
  const onOptionSelect = async (optionId?: string) => {
    const isSelected = currentSelectedItems.includedIds?.includes('*')
      ? true : !!currentSelectedItems.includedIds?.includes(pod.displayId)
    selectDisplays([pod], isSelected)
    setApplyingOptionId(optionId)
    setPendingParams({ optionId, isSelected, displayId: pod.displayId })
  }

  const [modalParams, setModalParams] = useState(pendingParams)

  useEffect(() => {
    if (pendingParams) {
      setModalParams(pendingParams)
    }
  }, [pendingParams])

  return (
    <>
      <SolDropdown
        type="bulk"
        itemsLabel="Pods"
        className={classNames(styles.categoryDropdown, {
          [styles.unassigned]: !value,
        })}
        light
        options={dropdownOptions}
        text={value?.name ?? 'Not set'}
        value={value?.id ?? 'unassign'}
        size="fluid"
        menuSize="medium"
        direction="left"
        onChange={onOptionSelect}
        onCancel={unselectDisplays}
        disabled={disabled}
      />
      {applyingOptionId && modalParams && (
        <ConfirmBulkApplyModal
          categoryName={category.name ?? ''}
          optionName={dropdownOptions.find(option => option.value === applyingOptionId)?.text ?? ''}
          podsCount={modalParams?.isSelected ? totalSelectedItems : totalSelectedItems + 1}
          onCancel={() => {
            setApplyingOptionId(undefined)
            setPendingParams(null)
          }}
          onConfirm={() =>
            confirmApplyCategoryToSelectedPods(
              modalParams?.optionId,
              modalParams?.isSelected,
              modalParams?.displayId,
            )
          }
        />
      )}
    </>
  )
}
