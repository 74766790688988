import React from 'react'
import styles from './Cards.module.scss'
import { ViewDisplaysBoolExp, useLocationsPageOverviewCardCountQuery } from 'graphql/__generated__/hasura-types'
import { SolCard, SolTooltipWithLink } from 'SolComponents'
import { Header } from 'semantic-ui-react'
import { AppliedCategoryFilters } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import EarthIcon from 'mdi-react/EarthIcon'
import OfficeBuildingIcon from 'mdi-react/OfficeBuildingIcon'
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon'
import SolAlertIcon from 'SolComponents/Icons/SolAlertIcon'
import SolQuestionCircle from 'SolComponents/Icons/SolQuestionCircle'

type Props = {
  searchParamsWhere?: ViewDisplaysBoolExp | null | undefined
  setFilterbyUnknownBuilding: () => void
  currentFilters: AppliedCategoryFilters
}

const Plural = (props: { count: number; label: string }) => (
  <span className={styles.label}>
    <span className={styles.count}>{props.count}</span> {props.label}
  </span>
)

const OverviewCard = (props: Props) => {
  const { data } = useLocationsPageOverviewCardCountQuery({
    variables: {
      displaywhere: props.searchParamsWhere,
    },
    fetchPolicy: 'network-only',
  })

  return (
    <SolCard className={styles.aggregateCard}>
      <Header as="h3" textAlign="left" className={styles.header}>
        Location Overview{' '}
        <SolTooltipWithLink
          trigger={<SolQuestionCircle size="default" className={styles.questionIcon} />}
          text={
            [
              'Solstice Cloud uses data from Solstice Location Services',
              'to automatically categorize Pods by their country, city, and building',
              'which are available throughout the site.',
              'You can manually edit the locations for better accuracy,',
              'which will also improve the experience of Solstice end users.',
            ].join(' ')
          }
          buttonText="LEARN MORE"
          position="top left"
          isExternalLink
          linkUrl="https://documentation.mersive.com/en/pod-locations.html"
        />
      </Header>

      <div className={styles.description}>
        <div className={styles.leftSide}>
          <div className={styles.count}>{data?.pods?.aggregate?.count ?? 0}</div>
          <div className={styles.label}>{data?.pods?.aggregate?.count !== 1 ? 'Pods' : 'Pod'}</div>
        </div>

        <div className={styles.rightSide}>
          <div>
            <EarthIcon color="#C1C1C1" />
            <Plural
              count={data?.country?.aggregate?.count ?? 0}
              label={data?.country?.aggregate?.count !== 1 ? 'countries' : 'country'}
            />
          </div>

          <div>
            <HomeCityOutlineIcon color="#C1C1C1" />
            <Plural
              count={data?.city?.aggregate?.count ?? 0}
              label={data?.city?.aggregate?.count !== 1 ? 'cities' : 'city'}
            />
          </div>

          <div>
            <OfficeBuildingIcon color="#C1C1C1" />
            <Plural
              count={data?.building?.aggregate?.count ?? 0}
              label={data?.building?.aggregate?.count !== 1 ? 'buildings' : 'building'}
            />
          </div>
        </div>
      </div>

      <div className={styles.unassigned}>
        <SolTooltipWithLink
          trigger={<SolAlertIcon className={styles.alert} />}
          text={
            [
              'If a Pod\'s location is currently unknown,',
              'you can manually assign it to a building.',
              'Doing so will make it visible to Solstice end users',
              'when they enter that building and look for nearby Pods',
              '(they must have Location Services enabled).',
              'It will also add the Pod to the automatic',
              'Categories so you can filter by country / city / building in Solstice Cloud.',
            ].join(' ')
          }
          buttonText="LEARN MORE"
          isExternalLink
          linkUrl="https://documentation.mersive.com/en/pod-locations.html"
          position="bottom left"
        />
        <Plural
          count={data?.unassignedBuilding?.aggregate?.count ?? 0}
          label={
            (data?.unassignedBuilding?.aggregate?.count ?? 0) !== 1
              ? ' Pods with unassigned location.'
              : ' Pod with unassigned location.'
          }
        />
        <span className={styles.filter} onClick={() => props.setFilterbyUnknownBuilding()}>
          {' '}
          FILTER
        </span>
      </div>
    </SolCard>
  )
}

export default OverviewCard
