import React from 'react'
import {
  Display,
  MetricType,
  DisplaySearchOptions,
  DisplaysDocument,
  DisplaysQuery,
  SubscriptionStatus,
  DisplayOrderableField,
} from 'graphql/__generated__/types'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { OutOfSubscriptionCell } from 'components/DataTableSlim/Custom/OutOfSubscriptionCell'
import {
  OrderBy,
  Categorizable,
  GraphQLCategorizable,
  DataTableInstance,
  ColumnsOrder,
} from 'components/DataTableSlim/DataTableSlim'
import { transformAppliedCategories } from 'components/DataTableSlim/Utils'
import { Column } from 'components/DataTableSlim/DataTableSlim'
import classNames from 'classnames'
import { ComputeMetrics, metricLabels } from 'shared/domain/metric'
import { ServerSelectedItems } from 'components/DataTableSlim/types'
import { roundNumber } from 'shared/core/utils'
import { useTableExport } from 'components/DataTableSlim/Hooks/useTableExport'
import { SolStatus } from 'SolComponents'
import { SolsticeElementEnum } from '../../shared/enums'
import { SolsticeElementCell } from '../../components/DataTableSlim/Custom/SolsticeElementCell'

export interface MetricTableProps {
  id: DataTableInstance
  totalItemsSelectable?: number
  totalItemsSelected?: number
  columnsOrder: ColumnsOrder[]
  totalItems: number
  onSort: (orderBy: OrderBy) => void
  onSelect?: (selectedItems: ServerSelectedItems) => void
  data: Display[]
  displaySearchOptions: DisplaySearchOptions
  loading: boolean
  columns?: Column<Categorizable<Display>>[]
  selectable?: boolean
}

const columns: Column<Categorizable<Display>>[] = [
  {
    name: DisplayOrderableField.Subscription,
    displayName: 'Status',
    centered: true,
    addable: false,
    render: (row: Display, toCsv: boolean) => {
      const isGen2 = row?.versions?.hardware?.includes('Gen2') ?? false
      if (toCsv) {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName)  {
          return 'ELEMENT'
        }

        if ( !row?.subscription?.expirationDate ) {
          return 'UNKNOWN'
        }
        return row?.subscription?.status
      }

      if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
        return (
          <SolsticeElementCell
            message="Certain features are not available for Solstice Element"
            position="bottom left"
          />
        )
      }

      if (!row?.subscription?.expirationDate) {
        return <SolStatus status="Unknown" />
      }
      if (row?.subscription?.status === SubscriptionStatus.Expired) {
        return <OutOfSubscriptionCell isGen2={isGen2} />
      }
      return null
    },
  },
  {
    name: 'name',
    addable: false,
    render: (row: Display, toCsv: boolean) => {
      if (toCsv) {
        return row?.name
      }
      return (
        <span
          className={classNames({
            greyData: row?.subscription?.status === SubscriptionStatus.Expired,
          })}
        >
          {row?.name}
        </span>
      )
    },
  },
  {
    name: ComputeMetrics.Hour,
    displayName: metricLabels[ComputeMetrics.Hour],
    render: (row: Display, toCsv: boolean) => {
      if (toCsv) {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return 'Not available'
        }

        return row?.subscription?.status === SubscriptionStatus.Expired
          ? 'RENEW' : roundNumber((row?.metrics?.totalMinutesInUse || 0) / 60, 2).toString()
      }
      return (!row?.subscription?.expirationDate ? <span style={{ color: 'grey' }}>--</span>
        : roundNumber((row?.metrics?.totalMinutesInUse || 0) / 60, 2))
    },
  },
  {
    name: MetricType.Meetings,
    displayName: metricLabels.MEETINGS,
    render: (row: Display, toCsv: boolean) => {
      if (toCsv) {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return 'Not available'
        }

        return row?.subscription?.status === SubscriptionStatus.Expired
          ? 'RENEW' : (row?.metrics?.totalMeetings || 0).toString()
      }
      return (!row?.subscription?.expirationDate ? <span style={{ color: 'grey' }}>--</span>
        : row?.metrics?.totalMeetings || 0)
    },
  },
  {
    name: MetricType.Posts,
    displayName: metricLabels.POSTS,
    render: (row: Display, toCsv: boolean) => {
      if (toCsv) {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return 'Not available'
        }

        return row?.subscription?.status === SubscriptionStatus.Expired
          ? 'RENEW' : (row?.metrics?.totalPosts || 0).toString()
      }
      return (!row?.subscription?.expirationDate ? <span style={{ color: 'grey' }}>--</span>
        : row?.metrics?.totalPosts || 0)
    },
  },
  {
    name: MetricType.Users,
    displayName: metricLabels.USERS,
    render: (row: Display, toCsv: boolean) => {
      if (toCsv) {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return 'Not available'
        }

        return row?.subscription?.status === SubscriptionStatus.Expired
          ? 'RENEW' : (row?.metrics?.totalUsers || 0).toString()
      }
      return (!row?.subscription?.expirationDate ? <span style={{ color: 'grey' }}>--</span>
        : row?.metrics?.totalUsers || 0)
    },
  },
  {
    name: MetricType.AverageMeetingDuration,
    displayName: metricLabels.AVERAGE_MEETING_DURATION,
    render: (row: Display, toCsv: boolean) => {
      if (toCsv) {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return 'Not available'
        }

        return row?.subscription?.status === SubscriptionStatus.Expired
          ? 'RENEW' : roundNumber(row?.metrics?.totalAverageMeetingDuration || 0, 2).toString()
      }
      return (!row?.subscription?.expirationDate ? <span style={{ color: 'grey' }}>--</span>
        : roundNumber(row?.metrics?.totalAverageMeetingDuration || 0, 2))
    },
  },
  {
    name: MetricType.ContentItemsPerMeeting,
    displayName: metricLabels.CONTENT_ITEMS_PER_MEETING,
    render: (row: Display, toCsv: boolean) => {
      if (toCsv) {
        if (row?.license?.shortName === SolsticeElementEnum.licenseShortName) {
          return 'Not available'
        }

        return row?.subscription?.status === SubscriptionStatus.Expired
          ? 'RENEW' : roundNumber(row?.metrics?.totalContentPerMeeting || 0, 2).toString()
      }
      return (!row?.subscription?.expirationDate ? <span style={{ color: 'grey' }}>--</span>
        : roundNumber(row?.metrics?.totalContentPerMeeting || 0, 2))
    },
  },
]

const columnsToBlur = [...Object.values(MetricType), ComputeMetrics.Hour]

const blur = (column: ComputeMetrics | MetricType, item: Display) => (
  (item?.license?.shortName === SolsticeElementEnum.licenseShortName && columnsToBlur.includes(column))
  || (item?.subscription?.status === SubscriptionStatus.Expired && columnsToBlur.includes(column))
)

const selectableFunction = (row: Categorizable<Display>) => {
  return row?.license?.shortName !== SolsticeElementEnum.licenseShortName
}

const resultMapper = (data: DisplaysQuery): Display[] => data.displays?.items?.map(d => d as Display) || []

export default function MetricTable({
  id,
  columnsOrder,
  data,
  totalItems,
  onSort,
  displaySearchOptions,
  loading,
  totalItemsSelectable,
  totalItemsSelected,
  selectable,
  onSelect,
}: MetricTableProps) {
  const NUM_RECORDS = 500
  const queryOptions = { ...displaySearchOptions, records: NUM_RECORDS }

  const { onExport, isLoading: loadingExport } = useTableExport<DisplaysQuery, Display>({
    title: 'Usage History',
    query: DisplaysDocument,
    searchOptions: {
      options: {
        ...queryOptions,
      },
    },
    totalItems,
    resultMapper,
    addableColumns: true,
    useProper: true,
  })

  return ServerDataTable<Categorizable<Display>>({
    id,
    columns,
    onSort,
    data: transformAppliedCategories<Display>(data as GraphQLCategorizable<Display>[]),
    addableColumns: true,
    addCategoryCols: true,
    includeGeneratedCategories: true,
    title: 'Pods',
    allowExportToCsv: true,
    exportProcessing: loadingExport,
    onExport,
    columnsOrder,
    totalItems,
    loading,
    blur,
    selectable: selectable ? selectableFunction : selectable,
    defaultSelectAll: true,
    totalItemsSelected,
    totalItemsSelectable,
    onSelect,
    padded: false,
  })
}
