import React, { useState, useEffect, createRef } from 'react'
import { LatLng } from 'leaflet'
import styles from './Map.module.scss'
import 'leaflet/dist/leaflet.css'
import { SolButton, SolInput, SolCard } from 'SolComponents'
import InteractiveMap from './InteractiveMap'
import {
  useUpsertDisplayOneLocationMutation,
  UpsertDisplayOneLocationMutationVariables,
  useCurrentFloorInformationQuery,
  LocationsDisplaysInfoDocument,
} from 'graphql/__generated__/hasura-types'
import { BaseAddress } from './MapsTypes'
import { BuildingsDropdown } from 'components/Location/AddressSearchSelectDropdown/BuildingsDropdown'
import { SetInitialLocationModal } from './SetInitialLocationModal'
import { FloorsDropdown } from 'components/Location/FloorsDropdown/FloorListDropdown'
import { LocationCategoriesDocument } from 'graphql/__generated__/types'

interface Props {
  podName: string
  podId: string
  podUuid: string | null
  currentPodLocation: LatLng | undefined
  address?: BaseAddress
}

export function PodsMap(props: Props) {
  const { address, currentPodLocation, podId, podName } = props
  // State
  const [isInitialLocationModalOpen, setIsInitialLocationModalOpen] = useState(false)
  const [save, setSave] = useState(false)
  const [toast, setToast] = useState(false)
  const [search, setSearch] = useState(currentPodLocation)
  const [upsertDisplayLocation, { loading: upsertDisplayLocationLoading }] = useUpsertDisplayOneLocationMutation()

  const { data: currentFloorData, refetch: refetchCurrentFloor } = useCurrentFloorInformationQuery({
    variables: {
      addressId: props?.address?.id,
      displayId: props?.podId,
    },
  })

  useEffect(() => {
    if (!address) {
      setIsInitialLocationModalOpen(true)
    } else {
      setIsInitialLocationModalOpen(false)
    }
  }, [address])

  const updateNewPosition = async (
    data: UpsertDisplayOneLocationMutationVariables = {
      address_id: address?.id,
      user_lat: search?.lat,
      user_long: search?.lng,
      user_accuracy_h: accuracyH,
      display: podId,
      display_uuid: props.podUuid,
    },
  ) => {
    setToast(true) // TODO: only set toast to true when call is successful.

    if (!upsertDisplayLocationLoading) {
      upsertDisplayLocation({
        variables: {
          address_id: data.address_id,
          user_lat: data.user_lat,
          user_long: data.user_long,
          user_accuracy_h: accuracyH,
          display: data.display,
          display_uuid: props.podUuid,
        },
        refetchQueries: [
          {
            query: LocationCategoriesDocument,
            variables: {
              options: {
                hasPods: true,
              },
            },
          },
          {
            query: LocationsDisplaysInfoDocument,
          },
        ],
      })

      window.setTimeout(() => {
        setToast(false)
      }, 6000)
    }
  }

  useEffect(() => {
    setSearch(currentPodLocation)
  }, [currentPodLocation])

  const upsertLocation = (val: BaseAddress) => {
    const distance = currentPodLocation?.distanceTo(new LatLng(val?.lat as number, val?.long as number)) as number
    updateNewPosition({
      address_id: val.id,
      user_lat: distance < 500 ? currentPodLocation?.lat : val?.lat,
      user_long: distance < 500 ? currentPodLocation?.lng : val?.long,
      user_accuracy_h: distance < 500 ? accuracyH : 25.0,
      display: podId,
      display_uuid: props.podUuid,
    })
  }

  const truncatedName = props.podName && props.podName.length > 48
    ? `${props.podName.slice(0, 48)}...`
    : props.podName

  const header
    = address?.address_full === undefined ? (
      <h2>{truncatedName} location not set</h2>
    ) : (
      <>
        <h2>Set location for {props.podName}</h2>
        <p className={styles.address}>
          {address?.nickname ? `${address?.nickname} | ` : ''}
          {address?.address_full}
        </p>
      </>
    )

  const Ref = createRef<HTMLDivElement>()
  const [mapBounds, setMapBounds] = useState<number[][] | undefined>(undefined)
  const [accuracyH, setAccuracyH] = useState<number | undefined>(undefined)

  return (
    <SolCard
      className={styles.mapCard}
      header={
        <>
          <div className={styles.header} ref={Ref}>
            {header}
          </div>
          <div>
            {!save && (
              <SolButton
                primary
                className={styles.button}
                containerClassName={styles.mapButtons}
                handleClick={() => {
                  setSave(!save)
                }}
                text="Fine-tune Location"
              />
            )}
            {save && (
              <div>
                <SolButton
                  primary={false}
                  className={styles.button}
                  containerClassName={styles.mapButtons}
                  handleClick={() => {
                    setSave(!save)
                    setSearch(currentPodLocation)
                  }}
                  text="Cancel"
                />
                <SolButton
                  primary
                  className={styles.button}
                  containerClassName={styles.mapButtons}
                  handleClick={() => {
                    updateNewPosition()
                    setSave(!save)
                  }}
                  text="Save"
                />
              </div>
            )}
          </div>
        </>
      }
    >
      <InteractiveMap
        podId={podId}
        podName={props.podName}
        save={save}
        toast={toast}
        setSearch={pod => setSearch(pod)}
        search={search}
        currentPodLocation={currentPodLocation}
        setMapBounds={setMapBounds}
        setAccuracyH={setAccuracyH}
      />
      <div className={styles.location}>
        <BuildingsDropdown
          label="Building"
          address={address}
          size="fixed"
          variant="single-view"
          onSelectAddress={upsertLocation}
          mapBounds={mapBounds}
          tooltipText={`
            This is the building that we identified for your Pod. If we got it wrong, select a different building or
            rename it. Note: changing a Pod's location may affect the Alerts Settings filters.
          `}
        />
        <FloorsDropdown
          addressId={address?.id}
          displayId={podId}
          variant="single"
          refetchCurrentFloor={refetchCurrentFloor}
          currentFloor={currentFloorData?._display_locations[0]?.floor_count?.floor ?? null}
          className={styles.floorsDropdown}
        />
        <SolInput
          label="City"
          disabled
          containerClassName={styles.input}
          size="fluid"
          clickable={false}
          value={address?.city}
        />
        <SolInput
          label="Country"
          disabled
          containerClassName={styles.input}
          size="fluid"
          clickable={false}
          value={address?.country}
        />
      </div>
      <SetInitialLocationModal
        isOpen={isInitialLocationModalOpen}
        displayName={podName}
        onSelectAddress={a => {
          upsertLocation(a)
          setIsInitialLocationModalOpen(false)
        }}
      />
    </SolCard>
  )
}
export default PodsMap
