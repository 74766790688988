import React from 'react'
import { Grid, List, Icon } from 'semantic-ui-react'

const greenCheck = require('shared/images/PieCheck.png')
import { SolCard, SolDonutChart } from 'SolComponents'
import styles from './UpdatesPage.module.scss'
import { useLatestVersion, useGetSoftwareVersion } from 'shared/hooks/versions'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { pluralize } from 'shared/core/utils'
import { usePieData } from './hooks/usePieData'
import { UpdateablePods } from 'graphql/__generated__/types'
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper'
import Carousel, { ResponsiveType } from 'react-multi-carousel'
import { SolArrowButton } from 'SolComponents'

export interface Props {
  taskTab: any
  data?: UpdateablePods
  scheduledTaskCount?: number
  aspectRatio?: number
}

const responsiveType: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3000, min: 1390 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1390, min: 970 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 970, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
}

export const UpdatesHeaderCard = (props: Props) => {
  const { pieData, loading } = usePieData()
  const { data, scheduledTaskCount = 0 } = props
  const totalPods = data?.totalPodsCount ?? 0
  const getSoftwareVersion = useGetSoftwareVersion()
  const { full } = useLatestVersion()
  const latestVersion = getSoftwareVersion(full)
  const {
    whatsNewVersion: WHATS_NEW_VERSION = '4.3',
    // whatsNewOne: WHATS_NEW_ONE = 'off',
    // whatsNewTwo: WHATS_NEW_TWO = 'off',
    // whatsNewThree: WHATS_NEW_THREE = 'off',
    // whatsNewFour: WHATS_NEW_FOUR = 'off',
    // whatsNewSubOne: WHATS_NEW_SUB_ONE = 'off',
    // whatsNewSubTwo: WHATS_NEW_SUB_TWO = 'off',
  } = useFlags()

  return (
    <div>
      <Carousel
        draggable={false}
        responsive={responsiveType}
        ssr={!!props.aspectRatio}
        deviceType={props.aspectRatio ? 'desktop' : ''}
        containerClass={styles.topRow}
        partialVisible
        customLeftArrow={<SolArrowButton direction="left" />}
        customRightArrow={<SolArrowButton direction="right" />}
      >
        <SolCard className={styles.card}>
          <LoadingWrapper isLoading={loading}>
            {pieData.length > 0 && (
              <>
                <SolDonutChart
                  pieData={pieData}
                  paging
                  minWidth={350}
                  xPosition="58%"
                  yPosition="40%"
                  legendWidth={220}
                  verticalAlign="top"
                  legendAlignment="left"
                  chartHeight={200}
                  innerRadius="65%"
                  outerRadius="80%"
                  legendTitle={`${pluralize(pieData
                    ?.reduce((acc: number, item: any) => (item.count + acc), 0), 'pod')} ready to update`}
                />
                { scheduledTaskCount > 0
                  && <div >
                    <span className={styles.legendTasks}>
                      {`*NOTE: ${scheduledTaskCount} ${scheduledTaskCount === 1 ? 'Pod is ' : 'Pods are '}
                      currently queued for updating. `}
                    </span>
                    <span onClick={props.taskTab} className={styles.viewTasks}>
                      VIEW TASKS
                    </span>
                  </div>
                }
              </>
            )}
            {pieData.length === 0 && (
              <div className={styles.allUpdated}>
                <Grid columns={3}>
                  <Grid.Row>
                    <h1>All Pods Up to Date!</h1>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      <Grid.Row>
                        <Grid.Column width={5} className={styles.versionWrapper}>
                          <Icon name="circle" size="small" color="green" />
                          <span className={styles.version}>Version {latestVersion}</span>
                        </Grid.Column>
                        <Grid.Column width={3} className={styles.pods} floated="right">
                          {totalPods} (100%)
                        </Grid.Column>
                        {scheduledTaskCount > 0 && (
                          <Grid.Row>
                            <Grid.Column mobile={16} tablet={16} computer={16} className={styles.queued}>
                              *NOTE: {scheduledTaskCount} {scheduledTaskCount === 1 ? 'Pod is ' : 'Pods are '}
                              currently queued for updating.
                            </Grid.Column>
                            <Grid.Row className={styles.tasks}>
                              <p className={styles.tasks} onClick={props.taskTab}>
                                VIEW TASKS
                              </p>
                            </Grid.Row>
                          </Grid.Row>
                        )}
                      </Grid.Row>
                    </Grid.Column>
                    <Grid.Column only="computer" width={8} className={styles.checkWrapper}>
                      <img className={styles.greenCheck} src={greenCheck} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            )}
          </LoadingWrapper>
        </SolCard>
        <SolCard className={styles.card}>
          <LoadingWrapper isLoading={loading}>
            <div className={styles.rightColumn}>
              <h2>Solstice {WHATS_NEW_VERSION} has arrived!</h2>
              <h1>What's new in Solstice {WHATS_NEW_VERSION}? (
                <a href="https://documentation.mersive.com/en/solstice-release-notes.html" target="_blank"
                  rel="noreferrer">  Learn More
                </a>)
              </h1>
              <List bulleted className={styles.bulletedList}>
                <List.Item className={styles.bulletInfo}>
                  <List.Header>Corrected auto volume adjustment during web shares on Windows</List.Header>
                </List.Item>
                <List.Item className={styles.bulletInfo}>
                  <List.Header>Scheduled daily reboot logic improved</List.Header>
                </List.Item>
                <List.Item className={styles.bulletInfo}>
                  <List.Header>Fixed issue which affected ability to control display power state
                    using RS232</List.Header>
                </List.Item>
                <List.Item className={styles.bulletInfo}>
                  <List.Header>Improved stability for Active Learning connections</List.Header>
                </List.Item>
              </List>
              <p className={styles.bulletInfoText}>
                And more! Read the full release notes
                <a href="https://documentation.mersive.com/en/solstice-release-notes.html" target="_blank"
                  rel="noreferrer"> here
                </a>
              </p>
            </div>
          </LoadingWrapper>
        </SolCard>
      </Carousel>
    </div>
  )
}
