import React, { useState, useEffect } from 'react'
import { LatLng, divIcon } from 'leaflet'
import { Marker } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import { useLocationsPageMapQuery, ViewDisplaysBoolExp } from 'graphql/__generated__/hasura-types'
import MarkerCluster from 'components/Location/Clustering/MarkerCluster'
import { renderToString } from 'react-dom/server'
import MapDot from 'SolComponents/Icons/SolGpsIcon/MapDot'
import styles from './Map.module.scss'
import { MarkerObject } from 'components/Location/Clustering/MarkerObjectTypes'
import { PodsClusterGroup } from 'components/Location/Clustering/ClusterTypes'

type Props = {
  searchParamsWhere?: ViewDisplaysBoolExp | null | undefined
  zoomToBounds: boolean
  showCluster: boolean
  zoom: number
  buildingFloorMap: { [key: number]: string }
}

export function PodsOnMap(props: Props) {
  const [orgPods, setOrgPods] = useState<MarkerObject[]>([])
  const { data } = useLocationsPageMapQuery({
    variables: {
      where: props.searchParamsWhere,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  })

  useEffect(() => {
    if (data) {
      const locationData = data?._displays
      const locations: MarkerObject[] = locationData.map(
        display =>
          ({
            id: display.id,
            name: display.name ?? '',
            latlng: new LatLng(display.location?.precise_lat, display.location?.precise_long),
          } as MarkerObject),
      )

      setOrgPods(locations)
    } else {
      setOrgPods([])
    }
  }, [data])

  // Clearing map from the last marker
  useEffect(() => {
    if (orgPods.length === 0) {
      PodsClusterGroup.clearLayers()
    }
  }, [orgPods])

  return (
    <div>
      {orgPods && orgPods.length > 0 ? (
        <MarkerCluster
          mcg={PodsClusterGroup}
          markersList={orgPods}
          buttonPopUp
          type="Pod"
          fitBounds={props.zoomToBounds}
          showCluster={props.showCluster}
        />
      ) : (
        <MarkerClusterGroup>
          <Marker
            icon={divIcon({
              html: renderToString(<MapDot color="#00000000" className={styles.displayNone} />),
              className: `${styles.displayNone}`,
            })}
            position={new LatLng(0, 0)}
          />
        </MarkerClusterGroup>
      )}
    </div>
  )
}
export default PodsOnMap
