import sortBy from 'lodash/sortBy'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { Dropdown, Icon, DropdownItemProps, Progress } from 'semantic-ui-react'

import styles from './SolDropdown.module.scss'
import { SolTooltip } from 'SolComponents'

type Direction = 'left' | 'right'
type DropdownType = 'ellipsis' | 'bulk' | 'caret'

export type BulkProps = {
  onCancel: () => void
  itemsSelected: number
  itemsLabel: string
}

export type Props = {
  type?: DropdownType
  text?: string
  direction?: Direction
  children?: any
  className?: string
  containerClassName?: string
  options?: (DropdownItemProps)[]
  onChange?: (val: string) => void
  defaultValue?: any
  value?: any
  disabled?: boolean
  openItemsUpward?: (e: MouseEvent) => boolean
  onClick?: () => void
  onFocus?: () => void
  loading?: boolean
  size?: 'large' | 'medium' | 'small' | 'tiny' | 'fluid' | 'medium-large'
  label?: string
  sorted?: boolean
  light?: boolean
  trigger?: React.ReactNode
  menu?: boolean
  menuSize?: 'medium'
  mismatched?: boolean
  notification?: JSX.Element | JSX.Element[]
  loadingTotalSelectedItems?: boolean
  templateCompatibilityLoading?: boolean
} & Partial<BulkProps>

// TODO decide what to do for single select (loading)
export const SolDropdown = (props: Props) => {
  const {
    options,
    type: iconType,
    value: dropdownValue,
    disabled: isDisabled,
    menu: menuSelection,
    itemsSelected = 0,
    containerClassName,
    label: dropdownLabel,
    openItemsUpward,
    defaultValue,
    itemsLabel,
    className,
    children,
    direction,
    onCancel,
    onClick,
    onChange,
    onFocus,
    trigger,
    sorted,
    loading,
    light,
    text,
    menuSize,
    size,
    mismatched,
    notification,
    loadingTotalSelectedItems,
    templateCompatibilityLoading,
  } = props

  const [open, setIsOpen] = useState(false)
  const [upward, setUpwardDropdownDirection] = useState<boolean>(false)
  const [sortedOptions, setDropdownOptions] = useState<DropdownItemProps[]>(options || [])
  const classes: string = classNames(
    {
      [styles.dropdownInput]: true,
      [styles.mediumMenu]: menuSize === 'medium',
      [styles.lightVariant]: light,
      [styles.bulkDropDown]: iconType === 'bulk',
      [styles.bulkHeader]: itemsSelected > 1,
      [styles.large]: size === 'large',
      [styles.medium]: size === 'medium',
      [styles.small]: size === 'small',
      [styles.tiny]: size === 'tiny',
      [styles.mediumLarge]: size === 'medium-large',
      [styles.fluid]: size === 'fluid',
    },
    className,
  )
  const isLoading = loading || (loadingTotalSelectedItems || templateCompatibilityLoading)

  useEffect(() => {
    const unsortedOptions = [
      iconType === 'bulk'
        && itemsSelected > 1 && {
        key: 'bulkheader',
        disabled: true,
        text: (
          <div>
            {isLoading && <Progress size="tiny" active percent="100" />}
            {!isLoading && (
              <>
                <div className={styles.label}>
                  {itemsSelected} {itemsLabel ?? 'items'} selected
                </div>
                <Icon
                  className="times circle"
                  onClick={() => {
                    setIsOpen(false)
                    onCancel?.()
                  }}
                />
              </>
            )}
          </div>
        ),
      },
      ...(options ?? []).map(opt => ({ ...opt, disabled: isLoading || opt.disabled })),
    ].filter(Boolean) as DropdownItemProps[]

    setDropdownOptions(sorted ? sortBy(unsortedOptions, ['text']) : unsortedOptions)
  }, [options, iconType, itemsSelected, itemsLabel, sorted, isLoading, onCancel])

  return (
    <div className={classNames(styles.wrapper, { [styles.dropdown]: !light }, containerClassName)}>
      {dropdownLabel
        && <label className={classNames(styles.label, { [styles.disabled]: isDisabled })}>
          {dropdownLabel}
          { mismatched
          && <span className={styles.syncStateIcon}>
            <SolTooltip
              isInline
              trigger={<Icon  name="exclamation circle" color="red" />}
              text={`This setting does not match between Solstice Cloud and the 
                Pod. Please review this setting and try again.`}
              position="top left"
            />
          </span>
          }
        </label>
      }
      <Dropdown
        selection={!menuSelection}
        trigger={trigger}
        icon={iconType === 'ellipsis' ? 'ellipsis vertical' : 'caret down'}
        className={classes}
        direction={direction}
        options={sortedOptions}
        disabled={isDisabled}
        text={text}
        upward={upward}
        selectOnBlur={false}
        onBlur={() => setIsOpen(false)}
        onChange={(e, value) => {
          onChange?.(value.value?.toString?.() ?? '')
          setIsOpen(false)
        }}
        value={dropdownValue}
        defaultValue={defaultValue}
        open={open}
        onClick={e => {
          if (openItemsUpward) {
            // @ts-ignore This looks like a mistake with semantic UI to define
            // as a keyboard event (inspected the event in-action and it has the
            // clientX and clientY needed to work here)
            setUpwardDropdownDirection(openItemsUpward(e as MouseEvent))
          }
          setIsOpen(!open)
          onClick?.()
        }}
        onFocus={() => {
          onFocus?.()
        }}
        fluid={size === 'fluid'}
      >
        {children}
      </Dropdown>
      {notification}
    </div>
  )
}

export default SolDropdown
