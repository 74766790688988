import React from 'react'
import { Column, DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import {
  OrgAddresses,
  useDisplayAddressesCountQuery,
  LocationsPageTableQuery,
} from 'graphql/__generated__/hasura-types'
import styles from './LocationsTable.module.scss'
import { SolPodName, SolStatus } from 'SolComponents'
import { BuildingsDropdown } from 'components/Location/AddressSearchSelectDropdown/BuildingsDropdown'
import { handleSelectItems } from 'components/DataTableSlim/Utils'
import { LocationPageFilters } from '../Utils/get-hasura-params'
import { FloorsDropdown } from 'components/Location/FloorsDropdown/FloorListDropdown'
import { getSearchIdArgs } from '../../../components/DataTableSlim/Utils/hasuraUtils'
import SolTooltip from '../../../SolComponents/SolTooltip/SolTooltip'
import { AreYouSureFloorModalContext } from './ChangeFloorModal'
import { AreYouSureModalContext } from './ChangeLocationModal'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { DisplayLocation } from '../Location'

export type Pod = LocationsPageTableQuery['_displays'][0]

export const useColumns = ({
  selectedPodCount,
  setAreYouSureModalContext,
  setAreYouSureFloorModalContext,
  searchParams,
  mapBounds,
}: {
  selectedPodCount: number
  setAreYouSureModalContext: (c: AreYouSureModalContext) => void
  setAreYouSureFloorModalContext: (c: AreYouSureFloorModalContext) => void
  searchParams: LocationPageFilters['tableFilters']
  mapBounds?: number[][]
}) => {
  const { selectedItems, setSelectedItems, tableData } = useDataTable<DisplayLocation>(DataTableInstance.Locations)
  const currentSelectedItems = tableData?.selectedItems ?? { ids: [], items: [] }

  const getUnknownBuilding = (item: Pod) => {
    return item?.location?.address?.address_full === null || !item?.location?.address?.address_full
  }

  const selectedDisplayArgs = getSearchIdArgs(currentSelectedItems)
  const whereSelected = {
    _display: {
      _and: [...(searchParams.where?._and ?? []), selectedDisplayArgs],
    },
  }

  const { data: addressesCount, loading: addressCountLoading } = useDisplayAddressesCountQuery({
    variables: {
      where: whereSelected,
    },
  })

  const selectPod = (pod: Pod) => {
    const newlySelectedItems = handleSelectItems(currentSelectedItems, item => item.id ?? '', [pod])
    setSelectedItems(newlySelectedItems)
  }

  const deselectAllPods = () => {
    setSelectedItems({
      items: [],
      ids: [],
    })
  }

  const bulkProps = {
    onCancel: deselectAllPods,
    itemsSelected: selectedPodCount,
    itemsLabel: 'Pods',
  }

  const disableFloors = (row: Pod) => {
    const theseSelectedItems = selectedItems ?? { ids: [], items: [] }
    const allSelected = selectedItems.includedIds?.[0] === '*' ?? false

    if (theseSelectedItems.items.length && addressesCount?.noAddresses[0]) {
      return true
    }

    if (allSelected) {
      return (addressesCount?.total?.aggregate?.count ?? 0) > 1
    }
    if (!theseSelectedItems.items.length || (!addressesCount?.address?.[0]?.id ?? true)) {
      return false
    }

    if (row.location?.address?.id !== addressesCount?.address?.[0]?.id) {
      return true
    }
    return (addressesCount?.total?.aggregate?.count ?? 0) > 1 && !addressCountLoading

  }

  const tableColumns: Column<Pod>[] = [
    {
      name: 'status',
      centered: true,
      render: row => <SolStatus status={row.availability?.is_online === true ? 'Online' : 'Offline'} />,
      collapsing: true,
    },
    {
      name: 'name',
      className: styles.widthSize,
      render: p => {
        const hardware = p.hardware?.replace('Pod ', '') ?? ''
        const ip = p.ip_addresses_primary ?? ''
        return <SolPodName name={p.name ?? ''} id={p.id ?? ''} ip={`${hardware} ${hardware && ip ? '|' : ''} ${ip}`} />
      },
      displayName: 'Pod Name',
      collapsing: true,
    },
    {
      name: 'building',
      displayName: 'Building',
      className: styles.fixedWidth,
      tooltipText: `
        Use the dropdown to edit the Building Name or assign Pods to a different building. Note: changing a Pod’s
        location may affect the Alerts Settings filters.
      `,
      render: row => (
        <BuildingsDropdown
          onClick={() => selectPod(row)}
          hasUnknownBuilding={getUnknownBuilding(row)}
          size="fixed"
          variant="table-view"
          address={row?.location?.address as OrgAddresses}
          onSelectAddress={address => {
            setAreYouSureModalContext({
              isOpen: true,
              addressId: address.id,
              addressLat: address.lat,
              addressLong: address.long,
            })
          }}
          mapBounds={mapBounds}
          bulk={bulkProps}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: 'floor',
      displayName: 'Floor',
      className: styles.fixedWidthFloors,
      tooltipText:
        'If a building has multiple floors, you can assign each Pod to its appropriate floor.'
        + ' Unfortunately, Solstice Location Services can\'t '
        + 'automatically determine this information, it must be entered manually.',
      render: row => {
        if (!row?.location?.address?.id) {
          return
        }
        const floorDropdown = (
          <FloorsDropdown
            onFocus={() => selectPod(row)}
            bulk={bulkProps}
            onSelect={(displayId, newFloor: number, addressId) => {
              setAreYouSureFloorModalContext({
                isFloorOpen: true,
                displayId,
                newFloor,
                addressId,
              })
            }}
            selectedDisplays={whereSelected}
            disabled={disableFloors(row)}
            displayId={row?.id ?? ''}
            addressId={row.location?.address?.id}
            currentFloor={row?.location?.floor_count?.floor}
          />
        )

        if (disableFloors(row)) {
          return (
            <SolTooltip
              text="Floors cannot be changed when multiple buildings are selected."
              trigger={floorDropdown}
            />
          )
        }
        {
          return floorDropdown
        }
      },
    },
    {
      name: 'city',
      displayName: 'City',
      render: row => row.location?.address?.city,
    },
    {
      name: 'country',
      displayName: 'Country',
      render: row => row.location?.address?.country,
    },
  ]

  return tableColumns
}
