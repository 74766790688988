import React from 'react'
import { DisplayVersions, Template } from 'graphql/__generated__/types'
import styles from './SettingsCards.module.scss'
import { StatusOptions } from 'SolComponents/SolStatus/SolStatus'
import { useFlags } from 'launchdarkly-react-client-sdk'
import omit from 'lodash/omit'
import { UseDisplay } from 'shared/hooks/management/useDisplay'
import pick from 'lodash/pick'
import { PodsMap } from './Map/PodsMap'
import { BaseAddress } from './Map/MapsTypes'
import { LatLng } from 'leaflet'
import { PodNameCard } from './PodNameCard/PodNameCard'
import { CategoriesCard } from './CategoriesCard/CategoriesCard'

export type Props = {
  podId: string
  status: StatusOptions
  podName: string
  podUuid: string | null
  isManageable: boolean
  uniqueSettingsTemplate?: Template
  versions: Partial<DisplayVersions>
  catchingUp: boolean
  handleSave: (template: Template) => Promise<Template | null>
  currentPodLocation: LatLng | undefined
  currentAddress: BaseAddress
  ipAddressesPrimary: string | undefined
  currentFloorInformation?:
    | {
        floor?: number | undefined | null
        count_per_floor?: number | undefined | null
      }
    | null
    | undefined
} & Pick<UseDisplay, 'categories' | 'appliedCategories' | 'macAddresses' | 'applyCategory' | 'serialId'>

export const SettingsCards = (props: Props) => {
  const { podConfigurationCategories: showCategories } = useFlags()
  return (
    <div className={styles.settingsCards}>
      <PodNameCard {...omit(props, 'categories')} />
      <PodsMap
        podName={props.podName}
        podId={props.podId}
        podUuid={props.podUuid}
        currentPodLocation={props.currentPodLocation}
        address={props.currentAddress}
      />
      {showCategories && (
        <CategoriesCard
          {...pick(props, [
            'categories',
            'appliedCategories',
            'applyCategory',
          ])}
        />
      )}
    </div>
  )
}
