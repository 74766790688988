import React, { useState } from 'react'
import ServerDataTable from 'components/DataTableSlim/ServerDataTable'
import { SolPodVersion, SolStatus } from 'SolComponents'
import formatDistance from 'date-fns/formatDistance'
import {
  Categorizable,
  Column,
  DataTableInstance,
} from 'components/DataTableSlim/DataTableSlim'
import { OutOfSubscriptionCell } from 'components/DataTableSlim/Custom/OutOfSubscriptionCell'
import classNames from 'classnames'
import {
  StatusOptions,
  OutOfSubscriptionStatus,
  SolsticeElement,
} from 'SolComponents/SolStatus/SolStatus'
import { useGetSoftwareVersion } from 'shared/hooks/versions'
import {
  AvailabilityByDisplay,
  AvailabilityQueryResult,
} from '../../graphql/__generated__/types'
import { download } from 'shared/core/csv-download'
import { parseISO } from 'date-fns'
import styles from './availability.module.scss'
import { SolsticeElementCell } from '../../components/DataTableSlim/Custom/SolsticeElementCell'
import { SolsticeElementEnum } from '../../shared/enums'

interface AvailabilityTableProps {
  totalItems: number
  data: AvailabilityByDisplay[]
  loading: boolean
  fetchCsvData: () => Promise<AvailabilityQueryResult>
}

const getAvailabilityStatus = (item: AvailabilityByDisplay) => {
  const availabilityStatus = item.status
  let podStatus = 'Unknown'
  if (!availabilityStatus) {
    podStatus = 'NeverConnected'
  } else {
    switch (availabilityStatus) {
      case 'SOLSTICE_SUBSCRIPTION_LAPSED':
        podStatus = 'Unsupported'
        break
      case 'READY':
        podStatus = 'Online'
        break
      case 'POD_OFFLINE':
        podStatus = 'Offline'
        break
      default:
        podStatus = 'Unknown'
    }
  }
  return podStatus
}

const AvailabilityTable = ({ totalItems, data, loading, fetchCsvData }: AvailabilityTableProps) => {

  const getSoftwareVersion = useGetSoftwareVersion()

  // This columns object is some DataTableSlim nonsense
  const columns: Column<Categorizable<AvailabilityByDisplay>>[] = [
    {
      name: 'status',
      displayName: 'Status',
      centered: true,
      collapsing: true,
      addable: false,
      render: (r, toCsv) => {
        const podStatus = getAvailabilityStatus(r)
        const isGen2 = r?.hardware?.includes('Gen2') ?? false
        if (r.licenseShortName === SolsticeElementEnum.licenseShortName) {
          return toCsv ? SolsticeElement : <SolsticeElementCell />
        }

        if (r.status === 'SOLSTICE_SUBSCRIPTION_LAPSED') {
          return toCsv ? OutOfSubscriptionStatus : <OutOfSubscriptionCell isGen2={isGen2} />
        }
        return toCsv ? podStatus : <SolStatus status={podStatus as StatusOptions} />
      },
    },
    {
      name: 'name',
      addable: false,
      render: (row, toCsv) => {
        if (toCsv) {
          return row.name
        }
        const podStatus = getAvailabilityStatus(row)
        if (podStatus === 'Unknown' || podStatus === 'NeverConnected') {
          return <span style={{ color: 'grey' }}>{row.name}</span>
        }
        return (
          <span
            className={classNames({
              greyData: row.status === 'SOLSTICE_SUBSCRIPTION_LAPSED',
            })}
          >
            {row.name}
          </span>
        )
      },
    },
    {
      name: 'lastOnline',
      displayName: 'Uptime / Downtime',
      className: styles.fixedWidth,
      tooltipText: `How long the Pod has been online or offline in the Cloud.`,
      render: (r, toCsv) => {
        const now = (new Date()).getTime()
        let lastOnlineTime = now
        if (r.lastOnline) {
          lastOnlineTime = (new Date(r.lastOnline)).getTime()
        }
        let clockError = false
        const podStatus = getAvailabilityStatus(r)

        if (podStatus === 'Online' && lastOnlineTime > now) {
          clockError = true
        }
        if (podStatus === 'Offline' && lastOnlineTime > now) {
          clockError = true
        }
        if (clockError) {
          return toCsv ? (
            'Clock Error'
          ) : (
            <em>Clock Error</em>
          )
        }

        if (r.licenseShortName === SolsticeElementEnum.licenseShortName) {
          return toCsv ? SolsticeElement : (
            <div className="greyBlur">{SolsticeElement}</div>
          )
        }

        if (r.status === 'SOLSTICE_SUBSCRIPTION_LAPSED') {
          return toCsv ? OutOfSubscriptionStatus : (
            <div className="greyBlur">{OutOfSubscriptionStatus}</div>
          )
        }

        const lastOnline = parseISO(r?.lastOnline || '')
        const timeSince = r?.lastOnline && lastOnline
          ? formatDistance(new Date(lastOnline), new Date()) : 'unknown'

        if (podStatus === 'Online') {
          return `Up for ${timeSince}`
        }

        if (podStatus === 'Offline') {
          return `Down for ${timeSince}`
        }

        if (podStatus === 'NeverConnected') {
          return <span style={{ color: 'grey' }}>Pod has not yet connected to cloud</span>
        }

        if ( podStatus === 'Unknown') {
          return <span style={{ color: 'grey' }}>Unknown Status</span>
        }

        return 'N/A'
      },
    },
    {
      name: 'version',
      displayName: 'Version',
      render: (r, toCsv) => (
        toCsv ? getSoftwareVersion(r.version?.join('.') ?? '')
          : <SolPodVersion version={r.version?.join('.')} catchingUp={r.catchingUp}/>
      ),
    },
  ]

  const [loadingExport, setLoadingExport] = useState(false)
  const onExport = async () => {
    setLoadingExport(true)
    download(((await fetchCsvData()).data?.availability?.pods ?? []).map(row => {
      return {
        'Status': columns[0].render?.(row, true) as string,
        'Name': columns[1].render?.(row, true) as string,
        'Uptime / Downtime': columns[2].render?.(row, true) as string,
        'Version': columns[3].render?.(row, true) as string,
      }
    }), 'Availability')
    setLoadingExport(false)
  }

  return (
    <div>
      {ServerDataTable<Categorizable<AvailabilityByDisplay>>({
        id: DataTableInstance.Availability,
        columns,
        data: data,
        title: 'Availability',
        totalItems,
        loading,
        exportProcessing: loadingExport,
        addableColumns: false,
        selectable: false,
        allowExportToCsv: true,
        onExport,
      })}
    </div>
  )
}

export default AvailabilityTable
