import React from 'react'
import {
  Address,
  CategoryManagerPodOptions,
  useCategoryManagerPodsQuery,
  useCustomCategoriesQuery,
  useLocationCategoriesQuery,
  useTotalDisplaysNoViewQuery,
  useCategoryManagerPodsLazyQuery,
  useApplyCategoryMutation,
  useCreateCategoryOptionMutation,
  useUpdateCategoryOptionMutation,
  useDeleteCategoryOptionMutation,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  CustomCategoriesDocument,
  CategoryManagerPodsDocument,
} from 'graphql/__generated__/types'
import { CategoryPage } from './CategoryPage'
import { useDataTable } from 'components/DataTableSlim/Hooks/useDataTable'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useAppliedFilterOptions } from 'components/DataTableSlim/Hooks/useAppliedFilters'
import { useSelectedPodsOptions } from 'components/DataTableSlim/Hooks/useSelectedItems'
import { useDeleteAlertFilters } from '../Alerts/Settings/hooks/useDeleteAlertFilters'
import { Filter, FilterType } from '../Alerts/Settings/types'

const CategoryController = () => {
  // QUERIES
  const {
    data: locationsData,
    loading: locationsLoading,
  } = useLocationCategoriesQuery({
    variables: {
      options: {
        hasPods: true,
      },
    },
  })

  const locationCategories = constructLocationCategories(locationsData?.addresses ?? [])

  const {
    data: categoriesData,
    loading: categoriesLoading,
    refetch: refetchCategories,
  } = useCustomCategoriesQuery({ fetchPolicy: 'cache-first' })

  const { data: countData, loading: countLoading } = useTotalDisplaysNoViewQuery()

  const {
    searchValue,
    page,
    orderBy,
    setCategoryFilters,
    categoryFilters: { building: _, city: __, country: ___, ...customCategories },
  } = useDataTable<any>(DataTableInstance.Categories)

  const { setCategoryFilters: setCategoriesFiltersForAlertPage, tableData: tableDataAlertPage }
    = useDataTable<any>(DataTableInstance.AlertHistory)
  const { categoryFilters: categoryFiltersAlertPage } = tableDataAlertPage || { categoryFilters: {} }

  const queryOptions: CategoryManagerPodOptions = {
    ...useAppliedFilterOptions(DataTableInstance.Categories),
    ...(searchValue ? { nameLike: searchValue } : {}),
    orderBy: { [orderBy?.field ?? 'name']: (orderBy?.direction !== 'ascending' ? 'DESC' : 'ASC') },
  }

  const {
    data: podsData,
    loading: podsLoading,
    refetch: refetchPods,
  } = useCategoryManagerPodsQuery({
    variables: {
      options: {
        ...queryOptions,
        offset: (page.number - 1) * page.size,
        limit: page.size,
      },
    },
    fetchPolicy: 'network-only',
  })

  const [fetchCsvData] = useCategoryManagerPodsLazyQuery({
    variables: {
      options: {
        ...queryOptions,
        offset: 0,
        limit: 999999,
      },
    },
  })
  const selectedPodsOptions = useSelectedPodsOptions(DataTableInstance.Categories)
  const [fetchSelectedPods] = useCategoryManagerPodsLazyQuery({
    variables: {
      options: {
        ...queryOptions,
        ...selectedPodsOptions,
      },
    },
  })


  // MUTATIONS

  // pod mutation
  const [applyCategoryMutation] = useApplyCategoryMutation()
  const applyCategory = async (categoryId: string, optionId?: string, displayIdToInclude?: string) => {
    const baseOptions = {
      ...queryOptions,
      ...selectedPodsOptions,
    }

    const updatedOptions = displayIdToInclude
      ? {
        ...baseOptions,
        idIn: [...(baseOptions.idIn || []), displayIdToInclude],
      }
      : baseOptions
    const selectedPods = (await fetchSelectedPods({
      variables: {
        options: updatedOptions,
      },
    })).data?.categoryManagerPods.map(pod => pod.displayId) ?? []
    const podsUpdated = (await applyCategoryMutation({
      variables: {
        options: {
          displayIds: selectedPods,
          categoryId,
          optionId,
        },
      },
      refetchQueries: [
        {
          query: CustomCategoriesDocument,
          variables: {},
        },
        {
          query: CategoryManagerPodsDocument,
          variables: {
            options: {},
          },
        },
      ],
      awaitRefetchQueries: true,
    })).data?.applyCategory.recordsUpdated ?? 0
    refetchCategories()
    refetchPods()
    return podsUpdated
  }

  // option mutations
  const [createCategoryOptionMutation, { loading: createCategoryOptionLoading }] = useCreateCategoryOptionMutation()
  const createCategoryOption = async (categoryId: string, optionName: string) => {
    const newCategoryOption = (await createCategoryOptionMutation({
      variables: {
        options: {
          categoryId,
          optionName,
        },
      },
    })).data!.createCategoryOption
    refetchCategories()
    return newCategoryOption
  }

  const [updateCategoryOptionMutation, { loading: updateCategoryOptionLoading }] = useUpdateCategoryOptionMutation()
  const updateCategoryOption = async (categoryId: string, optionId: string, optionName: string) => {
    const updatedCategoryOption = (await updateCategoryOptionMutation({
      variables: {
        options: {
          categoryId,
          optionId,
          optionName,
        },
      },
    })).data!.updateCategoryOption
    refetchCategories()
    refetchPods()
    return updatedCategoryOption
  }

  const [deleteCategoryOptionMutation, { loading: deleteCategoryOptionLoading }] = useDeleteCategoryOptionMutation()
  const deleteAlertFilters = useDeleteAlertFilters()
  const deleteCategoryOption = async (categoryId: string, optionId: string) => {
    const filter: Filter = {
      categoryInternalName: categoryId,
      id: `${categoryId}-${optionId}`,
      optionInternalName: optionId,
    }

    Object.values(FilterType).forEach(filterType => {
      deleteAlertFilters(filterType, filter)


      if (filter.categoryInternalName) {
        const {
          [filter.categoryInternalName]: _currentCategory,
          ...newCategories
        } = categoryFiltersAlertPage

        const updatedCategories = Object.keys(newCategories).reduce((acc, key) => {
          if (['building', 'city', 'country'].includes(key)) {
            acc[key] = newCategories[key]
          }
          return acc
        }, {} as typeof newCategories)
        setCategoriesFiltersForAlertPage(updatedCategories)
      }
    })

    const recordsUpdated = (await deleteCategoryOptionMutation({
      variables: {
        options: {
          categoryId,
          optionId,
        },
      },
    })).data!.deleteCategoryOption.recordsUpdated
    const { [categoryId]: _existing, ...rest } = customCategories
    setCategoryFilters(rest)
    refetchCategories()
    refetchPods()
    return recordsUpdated
  }

  // category mutations
  const [createCategoryMutation, { loading: createCategoryLoading }] = useCreateCategoryMutation()
  const createCategory = async (categoryName: string) => {
    const newCategory = (await createCategoryMutation({
      variables: {
        options: {
          categoryName,
        },
      },
    })).data!.createCategory
    refetchCategories()
    return newCategory
  }

  const [updateCategoryMutation, { loading: updateCategoryLoading }] = useUpdateCategoryMutation()
  const updateCategory = async (categoryId: string, categoryName: string) => {
    const updatedCategory = (await updateCategoryMutation({
      variables: {
        options: {
          categoryId,
          categoryName,
        },
      },
    })).data!.updateCategory
    refetchCategories()
    refetchPods()
    return updatedCategory
  }

  const [deleteCategoryMutation, { loading: deleteCategoryLoading }] = useDeleteCategoryMutation()
  const deleteCategory = async (categoryId: string) => {
    const categoryFilters = categoriesData?.categories.find(c => c.id === categoryId)
    const filters: Filter[] | undefined = categoryFilters?.options.map(o => {
      return {
        categoryInternalName: categoryId,
        id: `${categoryId}-${o.id}`,
        optionInternalName: o.id,
      }
    })

    Object.values(FilterType).forEach(filterType => {
      filters?.forEach(filter => {
        deleteAlertFilters(filterType, filter)

        if (filter.categoryInternalName) {
          const {
            [filter.categoryInternalName]: _currentCategory,
            ...newCategories
          } = categoryFiltersAlertPage

          const updatedCategories = Object.keys(newCategories).reduce((acc, key) => {
            if (['building', 'city', 'country'].includes(key)) {
              acc[key] = newCategories[key]
            }
            return acc
          }, {} as typeof newCategories)
          setCategoriesFiltersForAlertPage(updatedCategories)
        }
      })
    })
    const recordsUpdated = (await deleteCategoryMutation({
      variables: {
        options: {
          categoryId,
        },
      },
    })).data!.deleteCategory.recordsUpdated
    const { [categoryId]: _existing, ...rest } = customCategories
    setCategoryFilters(rest)
    refetchCategories()
    refetchPods()
    return recordsUpdated
  }

  const loading = locationsLoading
    || categoriesLoading
    || podsLoading
    || countLoading
    || createCategoryOptionLoading
    || updateCategoryOptionLoading
    || deleteCategoryOptionLoading
    || createCategoryLoading
    || updateCategoryLoading
    || deleteCategoryLoading

  return (<CategoryPage
    pods={podsData?.categoryManagerPods ?? []}
    customCategories={(categoriesData?.categories ?? [])}
    locationCategories={locationCategories ?? []}
    totalPods={countData?.totalDisplays ?? 0}
    loading={loading}
    fetchCsvData={fetchCsvData}
    applyCategory={applyCategory}
    createCategoryOption={createCategoryOption}
    updateCategoryOption={updateCategoryOption}
    deleteCategoryOption={deleteCategoryOption}
    createCategory={createCategory}
    updateCategory={updateCategory}
    deleteCategory={deleteCategory}
  />)
}

const constructLocationCategories = (addresses: Address[]) => {
  return addresses.reduce((accumulator, current) => {
    // The Apollo query returns a list of buildings/addresses. This collapses that list into hashmaps of countries, cities, and buildings.
    if (current.country) {
      const country = accumulator[0].options[current.country] ?? { appliedDisplayCount: 0 }
      accumulator[0].options[current.country] = {
        appliedDisplayCount: country.appliedDisplayCount + current.num_displays,
        name: current.country,
        id: current.country.toLowerCase().replace(/\s/g, '_'),
      }
    }
    if (current.city) {
      const city = accumulator[1].options[current.city] ?? { appliedDisplayCount: 0 }
      accumulator[1].options[current.city] = {
        appliedDisplayCount: city.appliedDisplayCount + current.num_displays,
        name: current.state_province ? `${current.city}, ${current.state_province}` : current.city,
        id: (current.state_province
          ? `${current.city}_${current.state_province}`
          : current.city
        ).toLowerCase().replace(/\s/g, '_'),
      }
    }
    accumulator[2].options[current.id] = {
      appliedDisplayCount: current.num_displays,
      name: current.nickname ?? current.building,
      id: `address_id_${current.id}`,
    }
    return accumulator
  }, [
    { id: 'country', name: 'Country', options: {}, location: true },
    { id: 'city', name: 'City', options: {}, location: true },
    { id: 'building', name: 'Building', options: {}, location: true },
  ] as Array<{
    id: string
    name: string
    location: boolean
    options: Record<string, {
      id: string
      name: string
      appliedDisplayCount: number
    }>
  }>).map(category => ({ // change options from hashmap to an array, discarding the keys used by the reducer
    ...category,
    options: Object.values(category.options),
  })) ?? []
}

export default CategoryController
