import { useMutation, useQuery } from '@apollo/client'
import { NetworkStatus, MutationUpdaterFn } from '@apollo/client'
import {
  AssignedTemplate,
  CancelPendingDocument,
  CancelPendingMutation,
  CancelPendingMutationVariables,
  DisplayDocument,
  DisplayQuery,
  DisplayQueryVariables,
  SettingsGroupType,
  Template,
  UpdateDisplayDocument,
  UpdateDisplayInput,
  UpdateDisplayMutation,
  UpdateDisplayMutationVariables,
  TemplateListDocument,
  TemplateListQuery,
  TemplateCompatibility,
  Category,
  AppliedCategory,
  DisplayVersions,
  CustomCategoriesDocument,
  CategoryManagerPodsDocument,
} from 'graphql/__generated__/types'
import { PodTemplates } from 'pages/Templates/TemplateTypes'
import { ExecutionResult } from 'graphql'
import { useAlerts } from '../useAlerts'
import { UniqueSettingsConfiguration } from './../../../graphql/__generated__/types'
import {
  useDeleteDisplayCategoriesMutation,
  useUpsertDisplayCategoriesMutation,
} from './../../../graphql/__generated__/hasura-types'
import { DataProxy } from '@apollo/client/cache'
import { StatusOptions } from 'SolComponents/SolStatus/SolStatus'

interface MacAddresses {
  primary?: string
  wifi?: string
}

export interface UseDisplay {
  loading: boolean
  networkStatus: NetworkStatus
  status: StatusOptions
  podName: string
  updateDisplay: (
    options: UpdateDisplayInput,
    update?: MutationUpdaterFn<UpdateDisplayMutation>
  ) => Promise<ExecutionResult<UpdateDisplayMutation>>
  updatingDisplay: boolean
  cancelPending: (options?: any) => Promise<ExecutionResult<CancelPendingMutation>>
  applyCategory: (categoryId: string, optionId: string) => void
  templates: PodTemplates
  refetchDisplay: () => void
  templateCompatibility?: (Partial<TemplateCompatibility> | null)[]
  categories?: (Partial<Category> | null)[]
  appliedCategories?: (Partial<AppliedCategory> | null)[]
  versions: Partial<DisplayVersions>
  catchingUp: boolean
  macAddresses: MacAddresses
  isOnline: boolean
  displayUuid: string | null
  syncState?: string | null
  serialId?: string | null
  ipAddressesPrimary: string | undefined
}

export function useDisplay(podId: string): UseDisplay {
  const { showSuccess, showError } = useAlerts()
  const { data, refetch, loading, networkStatus } = useQuery<DisplayQuery, DisplayQueryVariables>(DisplayDocument, {
    variables: { id: podId },
    pollInterval: 10000,
  })
  const display = data?.display
  const displayNameSetting = (display?.assignedTemplates.find(
    temp => temp?.settingsGroup?.type === SettingsGroupType.UniqueSettings,
  )?.template?.revision?.configuration as UniqueSettingsConfiguration)?.WELCOME_SCREEN?.displayName
  const podName = displayNameSetting ?? display?.name ?? ''

  const [updateDisplayMut, { loading: updatingDisplay }] = useMutation<
    UpdateDisplayMutation,
    UpdateDisplayMutationVariables
  >(UpdateDisplayDocument, {
    onError: error => {
      if (error.graphQLErrors?.[0]?.extensions?.code === 'BAD_USER_INPUT') {
        throw new Error(error.graphQLErrors[0].message)
      } else {
        throw new Error('Something went wrong updating a pod')
      }
    },
  })

  const [cancelPending] = useMutation<CancelPendingMutation, CancelPendingMutationVariables>(CancelPendingDocument, {
    onError: err => {
      /* eslint-disable-next-line no-console */
      console.error(err.message)
      showError('There was an issue canceling the pending changes')
    },
    onCompleted: () => {
      showSuccess('Pending Changes Cancelled')
      refetch()
    },
  })

  const options = {
    onError: (err: Error) => {
      /* eslint-disable-next-line no-console */
      console.error(err.message)
      showError('Something went wrong updating a pod')
    },
    onCompleted: () => {
      showSuccess('Pod configuration updated')
      refetch()
    },
  }
  const [unassignCategoryMut] = useDeleteDisplayCategoriesMutation(options)
  const [applyCategoryMut] = useUpsertDisplayCategoriesMutation(options)
  const applyCategory = (categoryId: string, optionId: string) =>
    (optionId === 'unassign'
      ? unassignCategoryMut({
        variables: {
          displayIds: [podId],
          categoryId,
        },
        refetchQueries: [
          {
            query: CategoryManagerPodsDocument,
            variables: {
              options: {},
            },
          },
          {
            query: CustomCategoriesDocument,
            variables: {},
          },
        ],
        awaitRefetchQueries: true,
      })
      : applyCategoryMut({
        variables: {
          items: [
            {
              display: podId,
              category: categoryId,
              config_applied_categories: optionId,
            },
          ],
        },
        refetchQueries: [
          {
            query: CategoryManagerPodsDocument,
            variables: {
              options: {},
            },
          },
          {
            query: CustomCategoriesDocument,
            variables: {},
          },
        ],
        awaitRefetchQueries: true,
      }))

  let welcomeScreenTemplate: AssignedTemplate | undefined,
    ethernetTemplate: AssignedTemplate | undefined,
    proxyTemplate: AssignedTemplate | undefined,
    wifiTemplate: AssignedTemplate | undefined,
    uniqueSettingsTemplate: AssignedTemplate | undefined,
    calendarTemplate: AssignedTemplate | undefined,
    discoveryTemplate: AssignedTemplate | undefined,
    timeLocaleTemplate: AssignedTemplate | undefined,
    advancedTemplate: AssignedTemplate | undefined,
    powerManagementTemplate: AssignedTemplate | undefined,
    digitalSignageTemplate: AssignedTemplate | undefined,
    securityTemplate: AssignedTemplate | undefined,
    messageCenterTemplate: AssignedTemplate | undefined,
    featuresTemplate: AssignedTemplate | undefined,
    roomIntelligenceTemplate: AssignedTemplate | undefined

  if (display?.assignedTemplates) {
    const assignedTemplates = display?.assignedTemplates

    uniqueSettingsTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.UniqueSettings,
    ) as AssignedTemplate
    welcomeScreenTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.WelcomeScreen,
    ) as AssignedTemplate
    ethernetTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.Ethernet,
    ) as AssignedTemplate
    proxyTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.Proxy,
    ) as AssignedTemplate
    wifiTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.Wifi,
    ) as AssignedTemplate
    uniqueSettingsTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.UniqueSettings,
    ) as AssignedTemplate
    calendarTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.Calendar,
    ) as AssignedTemplate
    discoveryTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.Discovery,
    ) as AssignedTemplate
    timeLocaleTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.TimeLocale,
    ) as AssignedTemplate
    advancedTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.Advanced,
    ) as AssignedTemplate
    powerManagementTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.PowerManagement,
    ) as AssignedTemplate
    digitalSignageTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.DigitalSignage,
    ) as AssignedTemplate
    securityTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.Security,
    ) as AssignedTemplate
    messageCenterTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.MessageCenter,
    ) as AssignedTemplate
    featuresTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.Features,
    ) as AssignedTemplate
    roomIntelligenceTemplate = assignedTemplates.find(
      temp => temp?.settingsGroup?.type === SettingsGroupType.RoomIntelligence,
    ) as AssignedTemplate
  }

  const hasPendingChanges = display?.hasPendingChanges ?? false
  let status
    = hasPendingChanges && display?.managementStatus === 'Online'
      ? 'OnlinePending'
      : (display?.managementStatus as StatusOptions)

  return {
    loading,
    networkStatus,
    podName,
    status,
    updateDisplay: async (displayOptions, update) =>
      updateDisplayMut({
        variables: { options: displayOptions },
        update: (proxy, response) => {
          if (!response.data?.updateDisplay?.validation?.incompatibleDisplays?.length) {
            showSuccess('Pod configuration updated')
          }
          refetch()
          update?.(proxy, response)
        },
      }),
    updatingDisplay,
    cancelPending,
    applyCategory,
    refetchDisplay: refetch,
    templateCompatibility: data?.templateCompatibility ?? undefined,
    templates: {
      welcomeScreen: {
        pending: welcomeScreenTemplate?.hasPendingChanges === true,
        syncState: welcomeScreenTemplate?.syncState || undefined,
        template: welcomeScreenTemplate?.template as Template,
      },
      ethernet: {
        pending: ethernetTemplate?.hasPendingChanges === true,
        syncState: ethernetTemplate?.syncState || undefined,
        template: ethernetTemplate?.template as Template,
      },
      proxy: {
        pending: proxyTemplate?.hasPendingChanges === true,
        syncState: proxyTemplate?.syncState || undefined,
        template: proxyTemplate?.template as Template,
      },
      wifi: {
        pending: wifiTemplate?.hasPendingChanges === true,
        syncState: wifiTemplate?.syncState || undefined,
        template: wifiTemplate?.template as Template,
      },
      uniqueSettings: {
        pending: uniqueSettingsTemplate?.hasPendingChanges === true,
        syncState: uniqueSettingsTemplate?.syncState || undefined,
        template: uniqueSettingsTemplate?.template as Template,
      },
      calendar: {
        pending: calendarTemplate?.hasPendingChanges === true,
        syncState: calendarTemplate?.syncState || undefined,
        template: calendarTemplate?.template as Template,
      },
      discovery: {
        pending: discoveryTemplate?.hasPendingChanges === true,
        syncState: discoveryTemplate?.syncState || undefined,
        template: discoveryTemplate?.template as Template,
      },
      timeLocale: {
        pending: timeLocaleTemplate?.hasPendingChanges === true,
        syncState: timeLocaleTemplate?.syncState || undefined,
        template: timeLocaleTemplate?.template as Template,
      },
      advanced: {
        pending: advancedTemplate?.hasPendingChanges === true,
        syncState: advancedTemplate?.syncState || undefined,
        template: advancedTemplate?.template as Template,
      },
      powerManagement: {
        pending: powerManagementTemplate?.hasPendingChanges === true,
        syncState: powerManagementTemplate?.syncState || undefined,
        template: powerManagementTemplate?.template as Template,
      },
      digitalSignage: {
        pending: digitalSignageTemplate?.hasPendingChanges === true,
        syncState: digitalSignageTemplate?.syncState || undefined,
        template: digitalSignageTemplate?.template as Template,
      },
      security: {
        pending: securityTemplate?.hasPendingChanges === true,
        syncState: securityTemplate?.syncState || undefined,
        template: securityTemplate?.template as Template,
      },
      messageCenter: {
        pending: messageCenterTemplate?.hasPendingChanges === true,
        syncState: messageCenterTemplate?.syncState || undefined,
        template: messageCenterTemplate?.template as Template,
      },
      features: {
        pending: featuresTemplate?.hasPendingChanges === true,
        syncState: featuresTemplate?.syncState || undefined,
        template: featuresTemplate?.template as Template,
      },
      roomIntelligence: {
        pending: roomIntelligenceTemplate?.hasPendingChanges === true,
        syncState: roomIntelligenceTemplate?.syncState || undefined,
        template: roomIntelligenceTemplate?.template as Template,
      },
    },
    categories: (data?.categories as Partial<Category>[]) ?? undefined,
    serialId: display?.serialId ?? null,
    appliedCategories: display?.categories ?? undefined,
    versions: display?.versions ?? {},
    catchingUp: display?.catchingUp ?? false,
    macAddresses: {
      primary: display?.macAddressesPrimary ?? undefined,
      wifi: display?.macAddressesWifi ?? undefined,
    },
    isOnline: display?.isOnline ?? false,
    displayUuid: display?.uuid ?? null,
    syncState: display?.syncState,
    ipAddressesPrimary: data?.display?.ipAddressesPrimary || undefined,
  }
}

export const updateTemplateListQuery = (settingsGroupType: SettingsGroupType) => (
  proxy: DataProxy,
  { data }: ExecutionResult<UpdateDisplayMutation>,
) => {
  const cacheData = proxy.readQuery({
    query: TemplateListDocument,
    variables: {
      options: { settingsGroupType },
    },
  }) as TemplateListQuery

  const templates = cacheData?.templates ?? []
  const newTemplate = data?.updateDisplay.display?.assignedTemplates.find(
    t => t?.settingsGroup?.type === settingsGroupType,
  )?.template

  proxy.writeQuery({
    query: TemplateListDocument,
    variables: {
      options: { settingsGroupType },
    },
    data: {
      ...templates,
      templates: [...templates, newTemplate],
    },
  })
}
