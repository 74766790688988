import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int4: any;
  bigint: any;
  date: any;
  date_interval: any;
  float8: any;
  geography: any;
  geometry: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Float. All fields are combined with logical 'AND'. */
export type FloatComparisonExp = {
  _eq?: Maybe<Scalars['Float']>;
  _gt?: Maybe<Scalars['Float']>;
  _gte?: Maybe<Scalars['Float']>;
  _in?: Maybe<Array<Scalars['Float']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Float']>;
  _lte?: Maybe<Scalars['Float']>;
  _neq?: Maybe<Scalars['Float']>;
  _nin?: Maybe<Array<Scalars['Float']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type AlertsChartArgs = {
  categories?: Maybe<Scalars['json']>;
  di?: Maybe<Scalars['date_interval']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['timestamp']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['timestamp']>;
};

export type AvgHoursSpentPerUserArgs = {
  categories?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  to_date?: Maybe<Scalars['date']>;
};

export type AvgRoomsVisitedArgs = {
  categories?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  to_date?: Maybe<Scalars['date']>;
};

export type BusiestHourArgs = {
  from_date?: Maybe<Scalars['date']>;
  to_date?: Maybe<Scalars['date']>;
};

export type ConferenceVendorGraphArgs = {
  categories?: Maybe<Scalars['json']>;
  di?: Maybe<Scalars['date_interval']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['timestamp']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['timestamp']>;
};

export type DailyActiveUsersGraphArgs = {
  categories?: Maybe<Scalars['json']>;
  di?: Maybe<Scalars['date_interval']>;
  from_date?: Maybe<Scalars['timestamp']>;
  to_date?: Maybe<Scalars['timestamp']>;
};

export type DisplaysByGeoArgs = {
  center_latitude?: Maybe<Scalars['float8']>;
  center_longitude?: Maybe<Scalars['float8']>;
  radius_km?: Maybe<Scalars['float8']>;
  take?: Maybe<Scalars['Int']>;
};

export type HomePageMapByDisplayArgs = {
  from_date?: Maybe<Scalars['date']>;
  to_date?: Maybe<Scalars['date']>;
};

export type HybridMeetingsGraphArgs = {
  categories?: Maybe<Scalars['json']>;
  di?: Maybe<Scalars['date_interval']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['timestamp']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['timestamp']>;
};


/** expression to compare columns of type _int4. All fields are combined with logical 'AND'. */
export type Int4ComparisonExp = {
  _eq?: Maybe<Scalars['_int4']>;
  _gt?: Maybe<Scalars['_int4']>;
  _gte?: Maybe<Scalars['_int4']>;
  _in?: Maybe<Array<Scalars['_int4']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_int4']>;
  _lte?: Maybe<Scalars['_int4']>;
  _neq?: Maybe<Scalars['_int4']>;
  _nin?: Maybe<Array<Scalars['_int4']>>;
};

export type MedianMeetingDurationArgs = {
  from_date?: Maybe<Scalars['date']>;
  to_date?: Maybe<Scalars['date']>;
};

export type SearchActivityUserMobilityArgs = {
  categories?: Maybe<Scalars['json']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['date']>;
};

export type SearchClientScreenResolutionsArgs = {
  categories?: Maybe<Scalars['json']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['date']>;
};

export type SearchClientVersionsArgs = {
  categories?: Maybe<Scalars['json']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['date']>;
};

export type SearchContentSharingAppGroupsArgs = {
  categories?: Maybe<Scalars['json']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['date']>;
};

export type SearchContentSharingAppNamesArgs = {
  app_group?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['json']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['date']>;
};

export type SearchContentSharingTypesArgs = {
  categories?: Maybe<Scalars['json']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['date']>;
};

export type SearchMostCollaborativeSpacesArgs = {
  categories?: Maybe<Scalars['json']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['date']>;
};

export type SearchUserDevicesArgs = {
  categories?: Maybe<Scalars['json']>;
  exclude_ids?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  include_ids?: Maybe<Scalars['json']>;
  search?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['date']>;
};

export type SessionsPerUserArgs = {
  categories?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  to_date?: Maybe<Scalars['date']>;
};

export type UniqueUsersCountArgs = {
  categories?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  to_date?: Maybe<Scalars['date']>;
};

export type UsageByRoomTypeArgs = {
  from_date?: Maybe<Scalars['date']>;
  to_date?: Maybe<Scalars['date']>;
};

export type UsbProductHistoryArgs = {
  days?: Maybe<Scalars['Int']>;
};

export type UserCollaborationArgs = {
  categories?: Maybe<Scalars['json']>;
  from_date?: Maybe<Scalars['date']>;
  to_date?: Maybe<Scalars['date']>;
};

/** columns and relationships of "active_learning_flags" */
export type ActiveLearningFlags = {
  __typename?: 'active_learning_flags';
  org_id: Scalars['String'];
  screen_key_enabled: Scalars['Boolean'];
};

/** aggregated selection of "active_learning_flags" */
export type ActiveLearningFlagsAggregate = {
  __typename?: 'active_learning_flags_aggregate';
  aggregate?: Maybe<ActiveLearningFlagsAggregateFields>;
  nodes: Array<ActiveLearningFlags>;
};

/** aggregate fields of "active_learning_flags" */
export type ActiveLearningFlagsAggregateFields = {
  __typename?: 'active_learning_flags_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ActiveLearningFlagsMaxFields>;
  min?: Maybe<ActiveLearningFlagsMinFields>;
};


/** aggregate fields of "active_learning_flags" */
export type ActiveLearningFlagsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ActiveLearningFlagsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "active_learning_flags" */
export type ActiveLearningFlagsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ActiveLearningFlagsMaxOrderBy>;
  min?: Maybe<ActiveLearningFlagsMinOrderBy>;
};

/** input type for inserting array relation for remote table "active_learning_flags" */
export type ActiveLearningFlagsArrRelInsertInput = {
  data: Array<ActiveLearningFlagsInsertInput>;
  on_conflict?: Maybe<ActiveLearningFlagsOnConflict>;
};

/** Boolean expression to filter rows from the table "active_learning_flags". All fields are combined with a logical 'AND'. */
export type ActiveLearningFlagsBoolExp = {
  _and?: Maybe<Array<Maybe<ActiveLearningFlagsBoolExp>>>;
  _not?: Maybe<ActiveLearningFlagsBoolExp>;
  _or?: Maybe<Array<Maybe<ActiveLearningFlagsBoolExp>>>;
  org_id?: Maybe<StringComparisonExp>;
  screen_key_enabled?: Maybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "active_learning_flags" */
export enum ActiveLearningFlagsConstraint {
  /** unique or primary key constraint */
  ActiveLearningFlagsPkey = 'active_learning_flags_pkey'
}

/** input type for inserting data into table "active_learning_flags" */
export type ActiveLearningFlagsInsertInput = {
  screen_key_enabled?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type ActiveLearningFlagsMaxFields = {
  __typename?: 'active_learning_flags_max_fields';
  org_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "active_learning_flags" */
export type ActiveLearningFlagsMaxOrderBy = {
  org_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ActiveLearningFlagsMinFields = {
  __typename?: 'active_learning_flags_min_fields';
  org_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "active_learning_flags" */
export type ActiveLearningFlagsMinOrderBy = {
  org_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "active_learning_flags" */
export type ActiveLearningFlagsMutationResponse = {
  __typename?: 'active_learning_flags_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ActiveLearningFlags>;
};

/** input type for inserting object relation for remote table "active_learning_flags" */
export type ActiveLearningFlagsObjRelInsertInput = {
  data: ActiveLearningFlagsInsertInput;
  on_conflict?: Maybe<ActiveLearningFlagsOnConflict>;
};

/** on conflict condition type for table "active_learning_flags" */
export type ActiveLearningFlagsOnConflict = {
  constraint: ActiveLearningFlagsConstraint;
  update_columns: Array<ActiveLearningFlagsUpdateColumn>;
  where?: Maybe<ActiveLearningFlagsBoolExp>;
};

/** ordering options when selecting data from "active_learning_flags" */
export type ActiveLearningFlagsOrderBy = {
  org_id?: Maybe<OrderBy>;
  screen_key_enabled?: Maybe<OrderBy>;
};

/** primary key columns input for table: "active_learning_flags" */
export type ActiveLearningFlagsPkColumnsInput = {
  org_id: Scalars['String'];
};

/** select columns of table "active_learning_flags" */
export enum ActiveLearningFlagsSelectColumn {
  /** column name */
  OrgId = 'org_id',
  /** column name */
  ScreenKeyEnabled = 'screen_key_enabled'
}

/** input type for updating data in table "active_learning_flags" */
export type ActiveLearningFlagsSetInput = {
  screen_key_enabled?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "active_learning_flags" */
export enum ActiveLearningFlagsUpdateColumn {
  /** column name */
  ScreenKeyEnabled = 'screen_key_enabled'
}


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};




/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};


/** Expression to compare the result of casting a column of type geography. Multiple cast targets are combined with logical 'AND'. */
export type GeographyCastExp = {
  geometry?: Maybe<GeometryComparisonExp>;
};

/** expression to compare columns of type geography. All fields are combined with logical 'AND'. */
export type GeographyComparisonExp = {
  _cast?: Maybe<GeographyCastExp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a distance from a geography value */
  _st_d_within?: Maybe<StDWithinGeographyInput>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>;
};


/** Expression to compare the result of casting a column of type geometry. Multiple cast targets are combined with logical 'AND'. */
export type GeometryCastExp = {
  geography?: Maybe<GeographyComparisonExp>;
};

/** expression to compare columns of type geometry. All fields are combined with logical 'AND'. */
export type GeometryComparisonExp = {
  _cast?: Maybe<GeometryCastExp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>;
  /** does the column crosses the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>;
  /** is the column within a distance from a geometry value */
  _st_d_within?: Maybe<StDWithinInput>;
  /** is the column equal to given geometry value. Directionality is ignored */
  _st_equals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>;
};



/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** delete data from the table: "orgs_categories" */
  delete_orgs_categories?: Maybe<OrgsCategoriesMutationResponse>;
  /** delete single row from the table: "orgs_categories" */
  delete_orgs_categories_by_pk?: Maybe<OrgsCategories>;
  /** delete data from the table: "orgs_categories_options" */
  delete_orgs_categories_options?: Maybe<OrgsCategoriesOptionsMutationResponse>;
  /** delete single row from the table: "orgs_categories_options" */
  delete_orgs_categories_options_by_pk?: Maybe<OrgsCategoriesOptions>;
  /** delete data from the table: "orgs_displays_categories" */
  delete_orgs_displays_categories?: Maybe<OrgsDisplaysCategoriesMutationResponse>;
  /** delete single row from the table: "orgs_displays_categories" */
  delete_orgs_displays_categories_by_pk?: Maybe<OrgsDisplaysCategories>;
  /** delete data from the table: "orgs_users_joyrides" */
  delete_orgs_users_joyrides?: Maybe<OrgsUsersJoyridesMutationResponse>;
  /** delete single row from the table: "orgs_users_joyrides" */
  delete_orgs_users_joyrides_by_pk?: Maybe<OrgsUsersJoyrides>;
  /** delete data from the table: "orgs_users_rebooted_filters" */
  delete_orgs_users_rebooted_filters?: Maybe<OrgsUsersRebootedFiltersMutationResponse>;
  /** delete single row from the table: "orgs_users_rebooted_filters" */
  delete_orgs_users_rebooted_filters_by_pk?: Maybe<OrgsUsersRebootedFilters>;
  /** delete data from the table: "orgs_users_template_filters" */
  delete_orgs_users_template_filters?: Maybe<OrgsUsersTemplateFiltersMutationResponse>;
  /** delete single row from the table: "orgs_users_template_filters" */
  delete_orgs_users_template_filters_by_pk?: Maybe<OrgsUsersTemplateFilters>;
  /** delete data from the table: "orgs_users_unreachable_filters" */
  delete_orgs_users_unreachable_filters?: Maybe<OrgsUsersUnreachableFiltersMutationResponse>;
  /** delete single row from the table: "orgs_users_unreachable_filters" */
  delete_orgs_users_unreachable_filters_by_pk?: Maybe<OrgsUsersUnreachableFilters>;
  /** delete data from the table: "orgs_users_usb_plug_filters" */
  delete_orgs_users_usb_plug_filters?: Maybe<OrgsUsersUsbPlugFiltersMutationResponse>;
  /** delete single row from the table: "orgs_users_usb_plug_filters" */
  delete_orgs_users_usb_plug_filters_by_pk?: Maybe<OrgsUsersUsbPlugFilters>;
  /** insert data into the table: "active_learning_flags" */
  insert_active_learning_flags?: Maybe<ActiveLearningFlagsMutationResponse>;
  /** insert a single row into the table: "active_learning_flags" */
  insert_active_learning_flags_one?: Maybe<ActiveLearningFlags>;
  /** insert data into the table: "org_addresses" */
  insert_org_addresses?: Maybe<OrgAddressesMutationResponse>;
  /** insert a single row into the table: "org_addresses" */
  insert_org_addresses_one?: Maybe<OrgAddresses>;
  /** insert data into the table: "orgs_categories" */
  insert_orgs_categories?: Maybe<OrgsCategoriesMutationResponse>;
  /** insert a single row into the table: "orgs_categories" */
  insert_orgs_categories_one?: Maybe<OrgsCategories>;
  /** insert data into the table: "orgs_categories_options" */
  insert_orgs_categories_options?: Maybe<OrgsCategoriesOptionsMutationResponse>;
  /** insert a single row into the table: "orgs_categories_options" */
  insert_orgs_categories_options_one?: Maybe<OrgsCategoriesOptions>;
  /** insert data into the table: "orgs_displays_categories" */
  insert_orgs_displays_categories?: Maybe<OrgsDisplaysCategoriesMutationResponse>;
  /** insert a single row into the table: "orgs_displays_categories" */
  insert_orgs_displays_categories_one?: Maybe<OrgsDisplaysCategories>;
  /** insert data into the table: "orgs_displays_locations" */
  insert_orgs_displays_locations?: Maybe<OrgsDisplaysLocationsMutationResponse>;
  /** insert a single row into the table: "orgs_displays_locations" */
  insert_orgs_displays_locations_one?: Maybe<OrgsDisplaysLocations>;
  /** insert data into the table: "orgs_flags" */
  insert_orgs_flags?: Maybe<OrgsFlagsMutationResponse>;
  /** insert a single row into the table: "orgs_flags" */
  insert_orgs_flags_one?: Maybe<OrgsFlags>;
  /** insert data into the table: "orgs_users_joyrides" */
  insert_orgs_users_joyrides?: Maybe<OrgsUsersJoyridesMutationResponse>;
  /** insert a single row into the table: "orgs_users_joyrides" */
  insert_orgs_users_joyrides_one?: Maybe<OrgsUsersJoyrides>;
  /** insert data into the table: "orgs_users_rebooted_filters" */
  insert_orgs_users_rebooted_filters?: Maybe<OrgsUsersRebootedFiltersMutationResponse>;
  /** insert a single row into the table: "orgs_users_rebooted_filters" */
  insert_orgs_users_rebooted_filters_one?: Maybe<OrgsUsersRebootedFilters>;
  /** insert data into the table: "orgs_users_template_filters" */
  insert_orgs_users_template_filters?: Maybe<OrgsUsersTemplateFiltersMutationResponse>;
  /** insert a single row into the table: "orgs_users_template_filters" */
  insert_orgs_users_template_filters_one?: Maybe<OrgsUsersTemplateFilters>;
  /** insert data into the table: "orgs_users_unreachable_filters" */
  insert_orgs_users_unreachable_filters?: Maybe<OrgsUsersUnreachableFiltersMutationResponse>;
  /** insert a single row into the table: "orgs_users_unreachable_filters" */
  insert_orgs_users_unreachable_filters_one?: Maybe<OrgsUsersUnreachableFilters>;
  /** insert data into the table: "orgs_users_usb_plug_filters" */
  insert_orgs_users_usb_plug_filters?: Maybe<OrgsUsersUsbPlugFiltersMutationResponse>;
  /** insert a single row into the table: "orgs_users_usb_plug_filters" */
  insert_orgs_users_usb_plug_filters_one?: Maybe<OrgsUsersUsbPlugFilters>;
  /** update data of the table: "active_learning_flags" */
  update_active_learning_flags?: Maybe<ActiveLearningFlagsMutationResponse>;
  /** update single row of the table: "active_learning_flags" */
  update_active_learning_flags_by_pk?: Maybe<ActiveLearningFlags>;
  /** update data of the table: "org_addresses" */
  update_org_addresses?: Maybe<OrgAddressesMutationResponse>;
  /** update single row of the table: "org_addresses" */
  update_org_addresses_by_pk?: Maybe<OrgAddresses>;
  /** update data of the table: "orgs" */
  update_orgs?: Maybe<OrgsMutationResponse>;
  /** update single row of the table: "orgs" */
  update_orgs_by_pk?: Maybe<Orgs>;
  /** update data of the table: "orgs_categories" */
  update_orgs_categories?: Maybe<OrgsCategoriesMutationResponse>;
  /** update single row of the table: "orgs_categories" */
  update_orgs_categories_by_pk?: Maybe<OrgsCategories>;
  /** update data of the table: "orgs_categories_options" */
  update_orgs_categories_options?: Maybe<OrgsCategoriesOptionsMutationResponse>;
  /** update single row of the table: "orgs_categories_options" */
  update_orgs_categories_options_by_pk?: Maybe<OrgsCategoriesOptions>;
  /** update data of the table: "orgs_displays_categories" */
  update_orgs_displays_categories?: Maybe<OrgsDisplaysCategoriesMutationResponse>;
  /** update single row of the table: "orgs_displays_categories" */
  update_orgs_displays_categories_by_pk?: Maybe<OrgsDisplaysCategories>;
  /** update data of the table: "orgs_displays_locations" */
  update_orgs_displays_locations?: Maybe<OrgsDisplaysLocationsMutationResponse>;
  /** update single row of the table: "orgs_displays_locations" */
  update_orgs_displays_locations_by_pk?: Maybe<OrgsDisplaysLocations>;
  /** update data of the table: "orgs_flags" */
  update_orgs_flags?: Maybe<OrgsFlagsMutationResponse>;
  /** update data of the table: "orgs_users" */
  update_orgs_users?: Maybe<OrgsUsersMutationResponse>;
  /** update single row of the table: "orgs_users" */
  update_orgs_users_by_pk?: Maybe<OrgsUsers>;
  /** update data of the table: "orgs_users_rebooted_filters" */
  update_orgs_users_rebooted_filters?: Maybe<OrgsUsersRebootedFiltersMutationResponse>;
  /** update single row of the table: "orgs_users_rebooted_filters" */
  update_orgs_users_rebooted_filters_by_pk?: Maybe<OrgsUsersRebootedFilters>;
  /** update data of the table: "orgs_users_template_filters" */
  update_orgs_users_template_filters?: Maybe<OrgsUsersTemplateFiltersMutationResponse>;
  /** update single row of the table: "orgs_users_template_filters" */
  update_orgs_users_template_filters_by_pk?: Maybe<OrgsUsersTemplateFilters>;
  /** update data of the table: "orgs_users_unreachable_filters" */
  update_orgs_users_unreachable_filters?: Maybe<OrgsUsersUnreachableFiltersMutationResponse>;
  /** update single row of the table: "orgs_users_unreachable_filters" */
  update_orgs_users_unreachable_filters_by_pk?: Maybe<OrgsUsersUnreachableFilters>;
  /** update data of the table: "orgs_users_usb_plug_filters" */
  update_orgs_users_usb_plug_filters?: Maybe<OrgsUsersUsbPlugFiltersMutationResponse>;
  /** update single row of the table: "orgs_users_usb_plug_filters" */
  update_orgs_users_usb_plug_filters_by_pk?: Maybe<OrgsUsersUsbPlugFilters>;
};


/** mutation root */
export type MutationRootDeleteOrgsCategoriesArgs = {
  where: OrgsCategoriesBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrgsCategoriesByPkArgs = {
  category: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteOrgsCategoriesOptionsArgs = {
  where: OrgsCategoriesOptionsBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrgsCategoriesOptionsByPkArgs = {
  category: Scalars['String'];
  option: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteOrgsDisplaysCategoriesArgs = {
  where: OrgsDisplaysCategoriesBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrgsDisplaysCategoriesByPkArgs = {
  category: Scalars['String'];
  display: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteOrgsUsersJoyridesArgs = {
  where: OrgsUsersJoyridesBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrgsUsersJoyridesByPkArgs = {
  joyride: Scalars['String'];
  org: Scalars['String'];
  user: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteOrgsUsersRebootedFiltersArgs = {
  where: OrgsUsersRebootedFiltersBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrgsUsersRebootedFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  rebooted_filter: Scalars['String'];
  user: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteOrgsUsersTemplateFiltersArgs = {
  where: OrgsUsersTemplateFiltersBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrgsUsersTemplateFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  template_filter: Scalars['String'];
  user: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteOrgsUsersUnreachableFiltersArgs = {
  where: OrgsUsersUnreachableFiltersBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrgsUsersUnreachableFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  unreachable_filter: Scalars['String'];
  user: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteOrgsUsersUsbPlugFiltersArgs = {
  where: OrgsUsersUsbPlugFiltersBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrgsUsersUsbPlugFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  usb_plug_filter: Scalars['String'];
  user: Scalars['String'];
};


/** mutation root */
export type MutationRootInsertActiveLearningFlagsArgs = {
  objects: Array<ActiveLearningFlagsInsertInput>;
  on_conflict?: Maybe<ActiveLearningFlagsOnConflict>;
};


/** mutation root */
export type MutationRootInsertActiveLearningFlagsOneArgs = {
  object: ActiveLearningFlagsInsertInput;
  on_conflict?: Maybe<ActiveLearningFlagsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgAddressesArgs = {
  objects: Array<OrgAddressesInsertInput>;
  on_conflict?: Maybe<OrgAddressesOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgAddressesOneArgs = {
  object: OrgAddressesInsertInput;
  on_conflict?: Maybe<OrgAddressesOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsCategoriesArgs = {
  objects: Array<OrgsCategoriesInsertInput>;
  on_conflict?: Maybe<OrgsCategoriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsCategoriesOneArgs = {
  object: OrgsCategoriesInsertInput;
  on_conflict?: Maybe<OrgsCategoriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsCategoriesOptionsArgs = {
  objects: Array<OrgsCategoriesOptionsInsertInput>;
  on_conflict?: Maybe<OrgsCategoriesOptionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsCategoriesOptionsOneArgs = {
  object: OrgsCategoriesOptionsInsertInput;
  on_conflict?: Maybe<OrgsCategoriesOptionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsDisplaysCategoriesArgs = {
  objects: Array<OrgsDisplaysCategoriesInsertInput>;
  on_conflict?: Maybe<OrgsDisplaysCategoriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsDisplaysCategoriesOneArgs = {
  object: OrgsDisplaysCategoriesInsertInput;
  on_conflict?: Maybe<OrgsDisplaysCategoriesOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsDisplaysLocationsArgs = {
  objects: Array<OrgsDisplaysLocationsInsertInput>;
  on_conflict?: Maybe<OrgsDisplaysLocationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsDisplaysLocationsOneArgs = {
  object: OrgsDisplaysLocationsInsertInput;
  on_conflict?: Maybe<OrgsDisplaysLocationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsFlagsArgs = {
  objects: Array<OrgsFlagsInsertInput>;
  on_conflict?: Maybe<OrgsFlagsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsFlagsOneArgs = {
  object: OrgsFlagsInsertInput;
  on_conflict?: Maybe<OrgsFlagsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsUsersJoyridesArgs = {
  objects: Array<OrgsUsersJoyridesInsertInput>;
};


/** mutation root */
export type MutationRootInsertOrgsUsersJoyridesOneArgs = {
  object: OrgsUsersJoyridesInsertInput;
};


/** mutation root */
export type MutationRootInsertOrgsUsersRebootedFiltersArgs = {
  objects: Array<OrgsUsersRebootedFiltersInsertInput>;
  on_conflict?: Maybe<OrgsUsersRebootedFiltersOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsUsersRebootedFiltersOneArgs = {
  object: OrgsUsersRebootedFiltersInsertInput;
  on_conflict?: Maybe<OrgsUsersRebootedFiltersOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsUsersTemplateFiltersArgs = {
  objects: Array<OrgsUsersTemplateFiltersInsertInput>;
  on_conflict?: Maybe<OrgsUsersTemplateFiltersOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsUsersTemplateFiltersOneArgs = {
  object: OrgsUsersTemplateFiltersInsertInput;
  on_conflict?: Maybe<OrgsUsersTemplateFiltersOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsUsersUnreachableFiltersArgs = {
  objects: Array<OrgsUsersUnreachableFiltersInsertInput>;
  on_conflict?: Maybe<OrgsUsersUnreachableFiltersOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsUsersUnreachableFiltersOneArgs = {
  object: OrgsUsersUnreachableFiltersInsertInput;
  on_conflict?: Maybe<OrgsUsersUnreachableFiltersOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsUsersUsbPlugFiltersArgs = {
  objects: Array<OrgsUsersUsbPlugFiltersInsertInput>;
  on_conflict?: Maybe<OrgsUsersUsbPlugFiltersOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrgsUsersUsbPlugFiltersOneArgs = {
  object: OrgsUsersUsbPlugFiltersInsertInput;
  on_conflict?: Maybe<OrgsUsersUsbPlugFiltersOnConflict>;
};


/** mutation root */
export type MutationRootUpdateActiveLearningFlagsArgs = {
  _set?: Maybe<ActiveLearningFlagsSetInput>;
  where: ActiveLearningFlagsBoolExp;
};


/** mutation root */
export type MutationRootUpdateActiveLearningFlagsByPkArgs = {
  _set?: Maybe<ActiveLearningFlagsSetInput>;
  pk_columns: ActiveLearningFlagsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgAddressesArgs = {
  _set?: Maybe<OrgAddressesSetInput>;
  where: OrgAddressesBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgAddressesByPkArgs = {
  _set?: Maybe<OrgAddressesSetInput>;
  pk_columns: OrgAddressesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsArgs = {
  _set?: Maybe<OrgsSetInput>;
  where: OrgsBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsByPkArgs = {
  _set?: Maybe<OrgsSetInput>;
  pk_columns: OrgsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsCategoriesArgs = {
  _set?: Maybe<OrgsCategoriesSetInput>;
  where: OrgsCategoriesBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsCategoriesByPkArgs = {
  _set?: Maybe<OrgsCategoriesSetInput>;
  pk_columns: OrgsCategoriesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsCategoriesOptionsArgs = {
  _set?: Maybe<OrgsCategoriesOptionsSetInput>;
  where: OrgsCategoriesOptionsBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsCategoriesOptionsByPkArgs = {
  _set?: Maybe<OrgsCategoriesOptionsSetInput>;
  pk_columns: OrgsCategoriesOptionsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsDisplaysCategoriesArgs = {
  _set?: Maybe<OrgsDisplaysCategoriesSetInput>;
  where: OrgsDisplaysCategoriesBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsDisplaysCategoriesByPkArgs = {
  _set?: Maybe<OrgsDisplaysCategoriesSetInput>;
  pk_columns: OrgsDisplaysCategoriesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsDisplaysLocationsArgs = {
  _inc?: Maybe<OrgsDisplaysLocationsIncInput>;
  _set?: Maybe<OrgsDisplaysLocationsSetInput>;
  where: OrgsDisplaysLocationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsDisplaysLocationsByPkArgs = {
  _inc?: Maybe<OrgsDisplaysLocationsIncInput>;
  _set?: Maybe<OrgsDisplaysLocationsSetInput>;
  pk_columns: OrgsDisplaysLocationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsFlagsArgs = {
  _set?: Maybe<OrgsFlagsSetInput>;
  where: OrgsFlagsBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersArgs = {
  _inc?: Maybe<OrgsUsersIncInput>;
  _set?: Maybe<OrgsUsersSetInput>;
  where: OrgsUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersByPkArgs = {
  _inc?: Maybe<OrgsUsersIncInput>;
  _set?: Maybe<OrgsUsersSetInput>;
  pk_columns: OrgsUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersRebootedFiltersArgs = {
  _set?: Maybe<OrgsUsersRebootedFiltersSetInput>;
  where: OrgsUsersRebootedFiltersBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersRebootedFiltersByPkArgs = {
  _set?: Maybe<OrgsUsersRebootedFiltersSetInput>;
  pk_columns: OrgsUsersRebootedFiltersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersTemplateFiltersArgs = {
  _set?: Maybe<OrgsUsersTemplateFiltersSetInput>;
  where: OrgsUsersTemplateFiltersBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersTemplateFiltersByPkArgs = {
  _set?: Maybe<OrgsUsersTemplateFiltersSetInput>;
  pk_columns: OrgsUsersTemplateFiltersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersUnreachableFiltersArgs = {
  _set?: Maybe<OrgsUsersUnreachableFiltersSetInput>;
  where: OrgsUsersUnreachableFiltersBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersUnreachableFiltersByPkArgs = {
  _set?: Maybe<OrgsUsersUnreachableFiltersSetInput>;
  pk_columns: OrgsUsersUnreachableFiltersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersUsbPlugFiltersArgs = {
  _set?: Maybe<OrgsUsersUsbPlugFiltersSetInput>;
  where: OrgsUsersUsbPlugFiltersBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrgsUsersUsbPlugFiltersByPkArgs = {
  _set?: Maybe<OrgsUsersUsbPlugFiltersSetInput>;
  pk_columns: OrgsUsersUsbPlugFiltersPkColumnsInput;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "org_addresses" */
export type OrgAddresses = {
  __typename?: 'org_addresses';
  address_full: Scalars['String'];
  /** An array relationship */
  assignedDisplays: Array<ViewDisplaysLocations>;
  /** An aggregated array relationship */
  assignedDisplays_aggregate: ViewDisplaysLocationsAggregate;
  building: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  floors: Array<ViewFloorCount>;
  id: Scalars['Int'];
  lat?: Maybe<Scalars['float8']>;
  long?: Maybe<Scalars['float8']>;
  nickname?: Maybe<Scalars['String']>;
  org: Scalars['String'];
  precise_location?: Maybe<Scalars['geography']>;
  provider?: Maybe<Scalars['String']>;
  state_province?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};


/** columns and relationships of "org_addresses" */
export type OrgAddressesAssignedDisplaysArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysLocationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysLocationsOrderBy>>;
  where?: Maybe<ViewDisplaysLocationsBoolExp>;
};


/** columns and relationships of "org_addresses" */
export type OrgAddressesAssignedDisplaysAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysLocationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysLocationsOrderBy>>;
  where?: Maybe<ViewDisplaysLocationsBoolExp>;
};


/** columns and relationships of "org_addresses" */
export type OrgAddressesFloorsArgs = {
  distinct_on?: Maybe<Array<ViewFloorCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewFloorCountOrderBy>>;
  where?: Maybe<ViewFloorCountBoolExp>;
};

/** aggregated selection of "org_addresses" */
export type OrgAddressesAggregate = {
  __typename?: 'org_addresses_aggregate';
  aggregate?: Maybe<OrgAddressesAggregateFields>;
  nodes: Array<OrgAddresses>;
};

/** aggregate fields of "org_addresses" */
export type OrgAddressesAggregateFields = {
  __typename?: 'org_addresses_aggregate_fields';
  avg?: Maybe<OrgAddressesAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<OrgAddressesMaxFields>;
  min?: Maybe<OrgAddressesMinFields>;
  stddev?: Maybe<OrgAddressesStddevFields>;
  stddev_pop?: Maybe<OrgAddressesStddevPopFields>;
  stddev_samp?: Maybe<OrgAddressesStddevSampFields>;
  sum?: Maybe<OrgAddressesSumFields>;
  var_pop?: Maybe<OrgAddressesVarPopFields>;
  var_samp?: Maybe<OrgAddressesVarSampFields>;
  variance?: Maybe<OrgAddressesVarianceFields>;
};


/** aggregate fields of "org_addresses" */
export type OrgAddressesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrgAddressesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "org_addresses" */
export type OrgAddressesAggregateOrderBy = {
  avg?: Maybe<OrgAddressesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<OrgAddressesMaxOrderBy>;
  min?: Maybe<OrgAddressesMinOrderBy>;
  stddev?: Maybe<OrgAddressesStddevOrderBy>;
  stddev_pop?: Maybe<OrgAddressesStddevPopOrderBy>;
  stddev_samp?: Maybe<OrgAddressesStddevSampOrderBy>;
  sum?: Maybe<OrgAddressesSumOrderBy>;
  var_pop?: Maybe<OrgAddressesVarPopOrderBy>;
  var_samp?: Maybe<OrgAddressesVarSampOrderBy>;
  variance?: Maybe<OrgAddressesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "org_addresses" */
export type OrgAddressesArrRelInsertInput = {
  data: Array<OrgAddressesInsertInput>;
  on_conflict?: Maybe<OrgAddressesOnConflict>;
};

/** aggregate avg on columns */
export type OrgAddressesAvgFields = {
  __typename?: 'org_addresses_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "org_addresses" */
export type OrgAddressesAvgOrderBy = {
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "org_addresses". All fields are combined with a logical 'AND'. */
export type OrgAddressesBoolExp = {
  _and?: Maybe<Array<Maybe<OrgAddressesBoolExp>>>;
  _not?: Maybe<OrgAddressesBoolExp>;
  _or?: Maybe<Array<Maybe<OrgAddressesBoolExp>>>;
  address_full?: Maybe<StringComparisonExp>;
  assignedDisplays?: Maybe<ViewDisplaysLocationsBoolExp>;
  building?: Maybe<StringComparisonExp>;
  city?: Maybe<StringComparisonExp>;
  country?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  external_id?: Maybe<StringComparisonExp>;
  floors?: Maybe<ViewFloorCountBoolExp>;
  id?: Maybe<IntComparisonExp>;
  lat?: Maybe<Float8ComparisonExp>;
  long?: Maybe<Float8ComparisonExp>;
  nickname?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  precise_location?: Maybe<GeographyComparisonExp>;
  provider?: Maybe<StringComparisonExp>;
  state_province?: Maybe<StringComparisonExp>;
  zip_code?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "org_addresses" */
export enum OrgAddressesConstraint {
  /** unique or primary key constraint */
  OaOrgExternalId = 'oa_org_external_id',
  /** unique or primary key constraint */
  OrgAddressesOrgNicknameUnique = 'org_addresses_org_nickname_unique',
  /** unique or primary key constraint */
  OrgAddressesPkey = 'org_addresses_pkey'
}

/** input type for inserting data into table "org_addresses" */
export type OrgAddressesInsertInput = {
  address_full?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  long?: Maybe<Scalars['float8']>;
  nickname?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  state_province?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrgAddressesMaxFields = {
  __typename?: 'org_addresses_max_fields';
  address_full?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['float8']>;
  long?: Maybe<Scalars['float8']>;
  nickname?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  state_province?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "org_addresses" */
export type OrgAddressesMaxOrderBy = {
  address_full?: Maybe<OrderBy>;
  building?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  country?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  external_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
  nickname?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  provider?: Maybe<OrderBy>;
  state_province?: Maybe<OrderBy>;
  zip_code?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OrgAddressesMinFields = {
  __typename?: 'org_addresses_min_fields';
  address_full?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['float8']>;
  long?: Maybe<Scalars['float8']>;
  nickname?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  state_province?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "org_addresses" */
export type OrgAddressesMinOrderBy = {
  address_full?: Maybe<OrderBy>;
  building?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  country?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  external_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
  nickname?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  provider?: Maybe<OrderBy>;
  state_province?: Maybe<OrderBy>;
  zip_code?: Maybe<OrderBy>;
};

/** response of any mutation on the table "org_addresses" */
export type OrgAddressesMutationResponse = {
  __typename?: 'org_addresses_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgAddresses>;
};

/** input type for inserting object relation for remote table "org_addresses" */
export type OrgAddressesObjRelInsertInput = {
  data: OrgAddressesInsertInput;
  on_conflict?: Maybe<OrgAddressesOnConflict>;
};

/** on conflict condition type for table "org_addresses" */
export type OrgAddressesOnConflict = {
  constraint: OrgAddressesConstraint;
  update_columns: Array<OrgAddressesUpdateColumn>;
  where?: Maybe<OrgAddressesBoolExp>;
};

/** ordering options when selecting data from "org_addresses" */
export type OrgAddressesOrderBy = {
  address_full?: Maybe<OrderBy>;
  assignedDisplays_aggregate?: Maybe<ViewDisplaysLocationsAggregateOrderBy>;
  building?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  country?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  external_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
  nickname?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  precise_location?: Maybe<OrderBy>;
  provider?: Maybe<OrderBy>;
  state_province?: Maybe<OrderBy>;
  zip_code?: Maybe<OrderBy>;
};

/** primary key columns input for table: "org_addresses" */
export type OrgAddressesPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "org_addresses" */
export enum OrgAddressesSelectColumn {
  /** column name */
  AddressFull = 'address_full',
  /** column name */
  Building = 'building',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Long = 'long',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Org = 'org',
  /** column name */
  PreciseLocation = 'precise_location',
  /** column name */
  Provider = 'provider',
  /** column name */
  StateProvince = 'state_province',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "org_addresses" */
export type OrgAddressesSetInput = {
  nickname?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OrgAddressesStddevFields = {
  __typename?: 'org_addresses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "org_addresses" */
export type OrgAddressesStddevOrderBy = {
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrgAddressesStddevPopFields = {
  __typename?: 'org_addresses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "org_addresses" */
export type OrgAddressesStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrgAddressesStddevSampFields = {
  __typename?: 'org_addresses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "org_addresses" */
export type OrgAddressesStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrgAddressesSumFields = {
  __typename?: 'org_addresses_sum_fields';
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['float8']>;
  long?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "org_addresses" */
export type OrgAddressesSumOrderBy = {
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
};

/** update columns of table "org_addresses" */
export enum OrgAddressesUpdateColumn {
  /** column name */
  Nickname = 'nickname'
}

/** aggregate var_pop on columns */
export type OrgAddressesVarPopFields = {
  __typename?: 'org_addresses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "org_addresses" */
export type OrgAddressesVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrgAddressesVarSampFields = {
  __typename?: 'org_addresses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "org_addresses" */
export type OrgAddressesVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrgAddressesVarianceFields = {
  __typename?: 'org_addresses_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "org_addresses" */
export type OrgAddressesVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  lat?: Maybe<OrderBy>;
  long?: Maybe<OrderBy>;
};

/** columns and relationships of "orgs" */
export type Orgs = {
  __typename?: 'orgs';
  org: Scalars['String'];
  partition: Scalars['bigint'];
  workplace?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "orgs". All fields are combined with a logical 'AND'. */
export type OrgsBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsBoolExp>>>;
  _not?: Maybe<OrgsBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsBoolExp>>>;
  org?: Maybe<StringComparisonExp>;
  partition?: Maybe<BigintComparisonExp>;
  workplace?: Maybe<BooleanComparisonExp>;
};

/** columns and relationships of "orgs_categories" */
export type OrgsCategories = {
  __typename?: 'orgs_categories';
  active?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  display_name?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  is_auto_generated?: Maybe<Scalars['Boolean']>;
};

/** input type for inserting array relation for remote table "orgs_categories" */
export type OrgsCategoriesArrRelInsertInput = {
  data: Array<OrgsCategoriesInsertInput>;
  on_conflict?: Maybe<OrgsCategoriesOnConflict>;
};

/** Boolean expression to filter rows from the table "orgs_categories". All fields are combined with a logical 'AND'. */
export type OrgsCategoriesBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsCategoriesBoolExp>>>;
  _not?: Maybe<OrgsCategoriesBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsCategoriesBoolExp>>>;
  active?: Maybe<BooleanComparisonExp>;
  caption?: Maybe<StringComparisonExp>;
  category?: Maybe<StringComparisonExp>;
  default?: Maybe<BooleanComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  internal_name?: Maybe<StringComparisonExp>;
  is_auto_generated?: Maybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "orgs_categories" */
export enum OrgsCategoriesConstraint {
  /** unique or primary key constraint */
  OrgsCategoriesPkey = 'orgs_categories_pkey'
}

/** input type for inserting data into table "orgs_categories" */
export type OrgsCategoriesInsertInput = {
  active?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  display_name?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "orgs_categories" */
export type OrgsCategoriesMutationResponse = {
  __typename?: 'orgs_categories_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsCategories>;
};

/** input type for inserting object relation for remote table "orgs_categories" */
export type OrgsCategoriesObjRelInsertInput = {
  data: OrgsCategoriesInsertInput;
  on_conflict?: Maybe<OrgsCategoriesOnConflict>;
};

/** on conflict condition type for table "orgs_categories" */
export type OrgsCategoriesOnConflict = {
  constraint: OrgsCategoriesConstraint;
  update_columns: Array<OrgsCategoriesUpdateColumn>;
  where?: Maybe<OrgsCategoriesBoolExp>;
};

/** columns and relationships of "orgs_categories_options" */
export type OrgsCategoriesOptions = {
  __typename?: 'orgs_categories_options';
  category: Scalars['String'];
  display_name?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  option: Scalars['String'];
};

/** input type for inserting array relation for remote table "orgs_categories_options" */
export type OrgsCategoriesOptionsArrRelInsertInput = {
  data: Array<OrgsCategoriesOptionsInsertInput>;
  on_conflict?: Maybe<OrgsCategoriesOptionsOnConflict>;
};

/** Boolean expression to filter rows from the table "orgs_categories_options". All fields are combined with a logical 'AND'. */
export type OrgsCategoriesOptionsBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsCategoriesOptionsBoolExp>>>;
  _not?: Maybe<OrgsCategoriesOptionsBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsCategoriesOptionsBoolExp>>>;
  category?: Maybe<StringComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  internal_name?: Maybe<StringComparisonExp>;
  option?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "orgs_categories_options" */
export enum OrgsCategoriesOptionsConstraint {
  /** unique or primary key constraint */
  OrgsCategoriesOptionsPkey = 'orgs_categories_options_pkey'
}

/** input type for inserting data into table "orgs_categories_options" */
export type OrgsCategoriesOptionsInsertInput = {
  category?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  option?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "orgs_categories_options" */
export type OrgsCategoriesOptionsMutationResponse = {
  __typename?: 'orgs_categories_options_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsCategoriesOptions>;
};

/** input type for inserting object relation for remote table "orgs_categories_options" */
export type OrgsCategoriesOptionsObjRelInsertInput = {
  data: OrgsCategoriesOptionsInsertInput;
  on_conflict?: Maybe<OrgsCategoriesOptionsOnConflict>;
};

/** on conflict condition type for table "orgs_categories_options" */
export type OrgsCategoriesOptionsOnConflict = {
  constraint: OrgsCategoriesOptionsConstraint;
  update_columns: Array<OrgsCategoriesOptionsUpdateColumn>;
  where?: Maybe<OrgsCategoriesOptionsBoolExp>;
};

/** ordering options when selecting data from "orgs_categories_options" */
export type OrgsCategoriesOptionsOrderBy = {
  category?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  internal_name?: Maybe<OrderBy>;
  option?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_categories_options" */
export type OrgsCategoriesOptionsPkColumnsInput = {
  category: Scalars['String'];
  option: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};

/** select columns of table "orgs_categories_options" */
export enum OrgsCategoriesOptionsSelectColumn {
  /** column name */
  Category = 'category',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  InternalName = 'internal_name',
  /** column name */
  Option = 'option'
}

/** input type for updating data in table "orgs_categories_options" */
export type OrgsCategoriesOptionsSetInput = {
  category?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  option?: Maybe<Scalars['String']>;
};

/** update columns of table "orgs_categories_options" */
export enum OrgsCategoriesOptionsUpdateColumn {
  /** column name */
  Category = 'category',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  InternalName = 'internal_name',
  /** column name */
  Option = 'option'
}

/** ordering options when selecting data from "orgs_categories" */
export type OrgsCategoriesOrderBy = {
  active?: Maybe<OrderBy>;
  caption?: Maybe<OrderBy>;
  category?: Maybe<OrderBy>;
  default?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  internal_name?: Maybe<OrderBy>;
  is_auto_generated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_categories" */
export type OrgsCategoriesPkColumnsInput = {
  category: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};

/** select columns of table "orgs_categories" */
export enum OrgsCategoriesSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Caption = 'caption',
  /** column name */
  Category = 'category',
  /** column name */
  Default = 'default',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  InternalName = 'internal_name',
  /** column name */
  IsAutoGenerated = 'is_auto_generated'
}

/** input type for updating data in table "orgs_categories" */
export type OrgsCategoriesSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  display_name?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
};

/** update columns of table "orgs_categories" */
export enum OrgsCategoriesUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Caption = 'caption',
  /** column name */
  Category = 'category',
  /** column name */
  Default = 'default',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  InternalName = 'internal_name'
}

/** unique or primary key constraints on table "orgs" */
export enum OrgsConstraint {
  /** unique or primary key constraint */
  OrgsPkey = 'orgs_pkey'
}

/** columns and relationships of "orgs_displays_categories" */
export type OrgsDisplaysCategories = {
  __typename?: 'orgs_displays_categories';
  category: Scalars['String'];
  config_applied_categories?: Maybe<Scalars['String']>;
  display: Scalars['String'];
};

/** input type for inserting array relation for remote table "orgs_displays_categories" */
export type OrgsDisplaysCategoriesArrRelInsertInput = {
  data: Array<OrgsDisplaysCategoriesInsertInput>;
  on_conflict?: Maybe<OrgsDisplaysCategoriesOnConflict>;
};

/** Boolean expression to filter rows from the table "orgs_displays_categories". All fields are combined with a logical 'AND'. */
export type OrgsDisplaysCategoriesBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsDisplaysCategoriesBoolExp>>>;
  _not?: Maybe<OrgsDisplaysCategoriesBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsDisplaysCategoriesBoolExp>>>;
  category?: Maybe<StringComparisonExp>;
  config_applied_categories?: Maybe<StringComparisonExp>;
  display?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "orgs_displays_categories" */
export enum OrgsDisplaysCategoriesConstraint {
  /** unique or primary key constraint */
  OrgsDisplaysCategoriesPkey = 'orgs_displays_categories_pkey'
}

/** input type for inserting data into table "orgs_displays_categories" */
export type OrgsDisplaysCategoriesInsertInput = {
  category?: Maybe<Scalars['String']>;
  config_applied_categories?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "orgs_displays_categories" */
export type OrgsDisplaysCategoriesMutationResponse = {
  __typename?: 'orgs_displays_categories_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsDisplaysCategories>;
};

/** input type for inserting object relation for remote table "orgs_displays_categories" */
export type OrgsDisplaysCategoriesObjRelInsertInput = {
  data: OrgsDisplaysCategoriesInsertInput;
  on_conflict?: Maybe<OrgsDisplaysCategoriesOnConflict>;
};

/** on conflict condition type for table "orgs_displays_categories" */
export type OrgsDisplaysCategoriesOnConflict = {
  constraint: OrgsDisplaysCategoriesConstraint;
  update_columns: Array<OrgsDisplaysCategoriesUpdateColumn>;
  where?: Maybe<OrgsDisplaysCategoriesBoolExp>;
};

/** ordering options when selecting data from "orgs_displays_categories" */
export type OrgsDisplaysCategoriesOrderBy = {
  category?: Maybe<OrderBy>;
  config_applied_categories?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_displays_categories" */
export type OrgsDisplaysCategoriesPkColumnsInput = {
  category: Scalars['String'];
  display: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};

/** select columns of table "orgs_displays_categories" */
export enum OrgsDisplaysCategoriesSelectColumn {
  /** column name */
  Category = 'category',
  /** column name */
  ConfigAppliedCategories = 'config_applied_categories',
  /** column name */
  Display = 'display'
}

/** input type for updating data in table "orgs_displays_categories" */
export type OrgsDisplaysCategoriesSetInput = {
  category?: Maybe<Scalars['String']>;
  config_applied_categories?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

/** update columns of table "orgs_displays_categories" */
export enum OrgsDisplaysCategoriesUpdateColumn {
  /** column name */
  Category = 'category',
  /** column name */
  ConfigAppliedCategories = 'config_applied_categories',
  /** column name */
  Display = 'display'
}

/** columns and relationships of "orgs_displays_locations" */
export type OrgsDisplaysLocations = {
  __typename?: 'orgs_displays_locations';
  /** An object relationship */
  _display?: Maybe<ViewDisplays>;
  /** An object relationship */
  address?: Maybe<OrgAddresses>;
  address_id?: Maybe<Scalars['Int']>;
  display: Scalars['String'];
  org: Scalars['String'];
  sls_alt?: Maybe<Scalars['Float']>;
  sls_lat?: Maybe<Scalars['float8']>;
  sls_long?: Maybe<Scalars['float8']>;
  user_alt?: Maybe<Scalars['Float']>;
  user_lat?: Maybe<Scalars['float8']>;
  user_long?: Maybe<Scalars['float8']>;
};

/** input type for inserting array relation for remote table "orgs_displays_locations" */
export type OrgsDisplaysLocationsArrRelInsertInput = {
  data: Array<OrgsDisplaysLocationsInsertInput>;
  on_conflict?: Maybe<OrgsDisplaysLocationsOnConflict>;
};

/** Boolean expression to filter rows from the table "orgs_displays_locations". All fields are combined with a logical 'AND'. */
export type OrgsDisplaysLocationsBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsDisplaysLocationsBoolExp>>>;
  _display?: Maybe<ViewDisplaysBoolExp>;
  _not?: Maybe<OrgsDisplaysLocationsBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsDisplaysLocationsBoolExp>>>;
  address?: Maybe<OrgAddressesBoolExp>;
  address_id?: Maybe<IntComparisonExp>;
  display?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  sls_alt?: Maybe<FloatComparisonExp>;
  sls_lat?: Maybe<Float8ComparisonExp>;
  sls_long?: Maybe<Float8ComparisonExp>;
  user_alt?: Maybe<FloatComparisonExp>;
  user_lat?: Maybe<Float8ComparisonExp>;
  user_long?: Maybe<Float8ComparisonExp>;
};

/** unique or primary key constraints on table "orgs_displays_locations" */
export enum OrgsDisplaysLocationsConstraint {
  /** unique or primary key constraint */
  OrgsDisplaysLocationsPkey = 'orgs_displays_locations_pkey'
}

/** input type for incrementing integer column in table "orgs_displays_locations" */
export type OrgsDisplaysLocationsIncInput = {
  address_id?: Maybe<Scalars['Int']>;
  floor?: Maybe<Scalars['Int']>;
  user_accuracy_h?: Maybe<Scalars['float8']>;
  user_lat?: Maybe<Scalars['float8']>;
  user_long?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "orgs_displays_locations" */
export type OrgsDisplaysLocationsInsertInput = {
  address?: Maybe<OrgAddressesObjRelInsertInput>;
  address_id?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['String']>;
  display_uuid?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  user_accuracy_h?: Maybe<Scalars['float8']>;
  user_alt?: Maybe<Scalars['Float']>;
  user_lat?: Maybe<Scalars['float8']>;
  user_long?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "orgs_displays_locations" */
export type OrgsDisplaysLocationsMutationResponse = {
  __typename?: 'orgs_displays_locations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsDisplaysLocations>;
};

/** input type for inserting object relation for remote table "orgs_displays_locations" */
export type OrgsDisplaysLocationsObjRelInsertInput = {
  data: OrgsDisplaysLocationsInsertInput;
  on_conflict?: Maybe<OrgsDisplaysLocationsOnConflict>;
};

/** on conflict condition type for table "orgs_displays_locations" */
export type OrgsDisplaysLocationsOnConflict = {
  constraint: OrgsDisplaysLocationsConstraint;
  update_columns: Array<OrgsDisplaysLocationsUpdateColumn>;
  where?: Maybe<OrgsDisplaysLocationsBoolExp>;
};

/** ordering options when selecting data from "orgs_displays_locations" */
export type OrgsDisplaysLocationsOrderBy = {
  _display?: Maybe<ViewDisplaysOrderBy>;
  address?: Maybe<OrgAddressesOrderBy>;
  address_id?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  sls_alt?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_alt?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_displays_locations" */
export type OrgsDisplaysLocationsPkColumnsInput = {
  display: Scalars['String'];
  org: Scalars['String'];
};

/** select columns of table "orgs_displays_locations" */
export enum OrgsDisplaysLocationsSelectColumn {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Display = 'display',
  /** column name */
  Org = 'org',
  /** column name */
  SlsAlt = 'sls_alt',
  /** column name */
  SlsLat = 'sls_lat',
  /** column name */
  SlsLong = 'sls_long',
  /** column name */
  UserAlt = 'user_alt',
  /** column name */
  UserLat = 'user_lat',
  /** column name */
  UserLong = 'user_long'
}

/** input type for updating data in table "orgs_displays_locations" */
export type OrgsDisplaysLocationsSetInput = {
  address_id?: Maybe<Scalars['Int']>;
  display_uuid?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  user_accuracy_h?: Maybe<Scalars['float8']>;
  user_lat?: Maybe<Scalars['float8']>;
  user_long?: Maybe<Scalars['float8']>;
};

/** update columns of table "orgs_displays_locations" */
export enum OrgsDisplaysLocationsUpdateColumn {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  DisplayUuid = 'display_uuid',
  /** column name */
  Floor = 'floor',
  /** column name */
  UserAccuracyH = 'user_accuracy_h',
  /** column name */
  UserLat = 'user_lat',
  /** column name */
  UserLong = 'user_long'
}

/** columns and relationships of "orgs_displays_tags" */
export type OrgsDisplaysTags = {
  __typename?: 'orgs_displays_tags';
  color?: Maybe<Scalars['String']>;
  display: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
  tag: Scalars['String'];
};

/** aggregated selection of "orgs_displays_tags" */
export type OrgsDisplaysTagsAggregate = {
  __typename?: 'orgs_displays_tags_aggregate';
  aggregate?: Maybe<OrgsDisplaysTagsAggregateFields>;
  nodes: Array<OrgsDisplaysTags>;
};

/** aggregate fields of "orgs_displays_tags" */
export type OrgsDisplaysTagsAggregateFields = {
  __typename?: 'orgs_displays_tags_aggregate_fields';
  avg?: Maybe<OrgsDisplaysTagsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<OrgsDisplaysTagsMaxFields>;
  min?: Maybe<OrgsDisplaysTagsMinFields>;
  stddev?: Maybe<OrgsDisplaysTagsStddevFields>;
  stddev_pop?: Maybe<OrgsDisplaysTagsStddevPopFields>;
  stddev_samp?: Maybe<OrgsDisplaysTagsStddevSampFields>;
  sum?: Maybe<OrgsDisplaysTagsSumFields>;
  var_pop?: Maybe<OrgsDisplaysTagsVarPopFields>;
  var_samp?: Maybe<OrgsDisplaysTagsVarSampFields>;
  variance?: Maybe<OrgsDisplaysTagsVarianceFields>;
};


/** aggregate fields of "orgs_displays_tags" */
export type OrgsDisplaysTagsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrgsDisplaysTagsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orgs_displays_tags" */
export type OrgsDisplaysTagsAggregateOrderBy = {
  avg?: Maybe<OrgsDisplaysTagsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<OrgsDisplaysTagsMaxOrderBy>;
  min?: Maybe<OrgsDisplaysTagsMinOrderBy>;
  stddev?: Maybe<OrgsDisplaysTagsStddevOrderBy>;
  stddev_pop?: Maybe<OrgsDisplaysTagsStddevPopOrderBy>;
  stddev_samp?: Maybe<OrgsDisplaysTagsStddevSampOrderBy>;
  sum?: Maybe<OrgsDisplaysTagsSumOrderBy>;
  var_pop?: Maybe<OrgsDisplaysTagsVarPopOrderBy>;
  var_samp?: Maybe<OrgsDisplaysTagsVarSampOrderBy>;
  variance?: Maybe<OrgsDisplaysTagsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type OrgsDisplaysTagsAvgFields = {
  __typename?: 'orgs_displays_tags_avg_fields';
  partition?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsAvgOrderBy = {
  partition?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "orgs_displays_tags". All fields are combined with a logical 'AND'. */
export type OrgsDisplaysTagsBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsDisplaysTagsBoolExp>>>;
  _not?: Maybe<OrgsDisplaysTagsBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsDisplaysTagsBoolExp>>>;
  color?: Maybe<StringComparisonExp>;
  display?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  partition?: Maybe<BigintComparisonExp>;
  tag?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type OrgsDisplaysTagsMaxFields = {
  __typename?: 'orgs_displays_tags_max_fields';
  color?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['bigint']>;
  tag?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsMaxOrderBy = {
  color?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  tag?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OrgsDisplaysTagsMinFields = {
  __typename?: 'orgs_displays_tags_min_fields';
  color?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['bigint']>;
  tag?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsMinOrderBy = {
  color?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  tag?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "orgs_displays_tags" */
export type OrgsDisplaysTagsOrderBy = {
  color?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  tag?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_displays_tags" */
export type OrgsDisplaysTagsPkColumnsInput = {
  display: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
  tag: Scalars['String'];
};

/** select columns of table "orgs_displays_tags" */
export enum OrgsDisplaysTagsSelectColumn {
  /** column name */
  Color = 'color',
  /** column name */
  Display = 'display',
  /** column name */
  Org = 'org',
  /** column name */
  Partition = 'partition',
  /** column name */
  Tag = 'tag'
}

/** aggregate stddev on columns */
export type OrgsDisplaysTagsStddevFields = {
  __typename?: 'orgs_displays_tags_stddev_fields';
  partition?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsStddevOrderBy = {
  partition?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrgsDisplaysTagsStddevPopFields = {
  __typename?: 'orgs_displays_tags_stddev_pop_fields';
  partition?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsStddevPopOrderBy = {
  partition?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrgsDisplaysTagsStddevSampFields = {
  __typename?: 'orgs_displays_tags_stddev_samp_fields';
  partition?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsStddevSampOrderBy = {
  partition?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrgsDisplaysTagsSumFields = {
  __typename?: 'orgs_displays_tags_sum_fields';
  partition?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsSumOrderBy = {
  partition?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type OrgsDisplaysTagsVarPopFields = {
  __typename?: 'orgs_displays_tags_var_pop_fields';
  partition?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsVarPopOrderBy = {
  partition?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrgsDisplaysTagsVarSampFields = {
  __typename?: 'orgs_displays_tags_var_samp_fields';
  partition?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsVarSampOrderBy = {
  partition?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrgsDisplaysTagsVarianceFields = {
  __typename?: 'orgs_displays_tags_variance_fields';
  partition?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "orgs_displays_tags" */
export type OrgsDisplaysTagsVarianceOrderBy = {
  partition?: Maybe<OrderBy>;
};

/** columns and relationships of "orgs_flags" */
export type OrgsFlags = {
  __typename?: 'orgs_flags';
  org_id: Scalars['String'];
  wellness_enabled: Scalars['Boolean'];
};

/** input type for inserting array relation for remote table "orgs_flags" */
export type OrgsFlagsArrRelInsertInput = {
  data: Array<OrgsFlagsInsertInput>;
  on_conflict?: Maybe<OrgsFlagsOnConflict>;
};

/** Boolean expression to filter rows from the table "orgs_flags". All fields are combined with a logical 'AND'. */
export type OrgsFlagsBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsFlagsBoolExp>>>;
  _not?: Maybe<OrgsFlagsBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsFlagsBoolExp>>>;
  org_id?: Maybe<StringComparisonExp>;
  wellness_enabled?: Maybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "orgs_flags" */
export enum OrgsFlagsConstraint {
  /** unique or primary key constraint */
  OrgsFlagsOrgIdKey = 'orgs_flags_org_id_key'
}

/** input type for inserting data into table "orgs_flags" */
export type OrgsFlagsInsertInput = {
  wellness_enabled?: Maybe<Scalars['Boolean']>;
};

/** response of any mutation on the table "orgs_flags" */
export type OrgsFlagsMutationResponse = {
  __typename?: 'orgs_flags_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsFlags>;
};

/** input type for inserting object relation for remote table "orgs_flags" */
export type OrgsFlagsObjRelInsertInput = {
  data: OrgsFlagsInsertInput;
  on_conflict?: Maybe<OrgsFlagsOnConflict>;
};

/** on conflict condition type for table "orgs_flags" */
export type OrgsFlagsOnConflict = {
  constraint: OrgsFlagsConstraint;
  update_columns: Array<OrgsFlagsUpdateColumn>;
  where?: Maybe<OrgsFlagsBoolExp>;
};

/** ordering options when selecting data from "orgs_flags" */
export type OrgsFlagsOrderBy = {
  org_id?: Maybe<OrderBy>;
  wellness_enabled?: Maybe<OrderBy>;
};

/** select columns of table "orgs_flags" */
export enum OrgsFlagsSelectColumn {
  /** column name */
  OrgId = 'org_id',
  /** column name */
  WellnessEnabled = 'wellness_enabled'
}

/** input type for updating data in table "orgs_flags" */
export type OrgsFlagsSetInput = {
  wellness_enabled?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "orgs_flags" */
export enum OrgsFlagsUpdateColumn {
  /** column name */
  WellnessEnabled = 'wellness_enabled'
}

/** response of any mutation on the table "orgs" */
export type OrgsMutationResponse = {
  __typename?: 'orgs_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Orgs>;
};

/** on conflict condition type for table "orgs" */
export type OrgsOnConflict = {
  constraint: OrgsConstraint;
  update_columns: Array<OrgsUpdateColumn>;
  where?: Maybe<OrgsBoolExp>;
};

/** ordering options when selecting data from "orgs" */
export type OrgsOrderBy = {
  org?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  workplace?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs" */
export type OrgsPkColumnsInput = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
};

/** select columns of table "orgs" */
export enum OrgsSelectColumn {
  /** column name */
  Org = 'org',
  /** column name */
  Partition = 'partition',
  /** column name */
  Workplace = 'workplace'
}

/** input type for updating data in table "orgs" */
export type OrgsSetInput = {
  workplace?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "orgs" */
export enum OrgsUpdateColumn {
  /** column name */
  Workplace = 'workplace'
}

/** columns and relationships of "orgs_users" */
export type OrgsUsers = {
  __typename?: 'orgs_users';
  config_alerts_display_out_of_template?: Maybe<Scalars['Boolean']>;
  config_alerts_display_rebooted_enabled?: Maybe<Scalars['Boolean']>;
  config_alerts_display_unreachable_delay?: Maybe<Scalars['bigint']>;
  config_alerts_display_unreachable_enabled?: Maybe<Scalars['Boolean']>;
  config_alerts_usb_plug_event_enabled?: Maybe<Scalars['Boolean']>;
  config_alerts_usb_plug_event_type?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "orgs_users". All fields are combined with a logical 'AND'. */
export type OrgsUsersBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsUsersBoolExp>>>;
  _not?: Maybe<OrgsUsersBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsUsersBoolExp>>>;
  config_alerts_display_out_of_template?: Maybe<BooleanComparisonExp>;
  config_alerts_display_rebooted_enabled?: Maybe<BooleanComparisonExp>;
  config_alerts_display_unreachable_delay?: Maybe<BigintComparisonExp>;
  config_alerts_display_unreachable_enabled?: Maybe<BooleanComparisonExp>;
  config_alerts_usb_plug_event_enabled?: Maybe<BooleanComparisonExp>;
  config_alerts_usb_plug_event_type?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "orgs_users" */
export enum OrgsUsersConstraint {
  /** unique or primary key constraint */
  OrgsUsersPkey = 'orgs_users_pkey'
}

/** input type for incrementing integer column in table "orgs_users" */
export type OrgsUsersIncInput = {
  config_alerts_display_unreachable_delay?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "orgs_users_joyrides" */
export type OrgsUsersJoyrides = {
  __typename?: 'orgs_users_joyrides';
  finished_on?: Maybe<Scalars['timestamptz']>;
  joyride: Scalars['String'];
};

/** input type for inserting array relation for remote table "orgs_users_joyrides" */
export type OrgsUsersJoyridesArrRelInsertInput = {
  data: Array<OrgsUsersJoyridesInsertInput>;
};

/** Boolean expression to filter rows from the table "orgs_users_joyrides". All fields are combined with a logical 'AND'. */
export type OrgsUsersJoyridesBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsUsersJoyridesBoolExp>>>;
  _not?: Maybe<OrgsUsersJoyridesBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsUsersJoyridesBoolExp>>>;
  finished_on?: Maybe<TimestamptzComparisonExp>;
  joyride?: Maybe<StringComparisonExp>;
};

/** input type for inserting data into table "orgs_users_joyrides" */
export type OrgsUsersJoyridesInsertInput = {
  finished_on?: Maybe<Scalars['timestamptz']>;
  joyride?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "orgs_users_joyrides" */
export type OrgsUsersJoyridesMutationResponse = {
  __typename?: 'orgs_users_joyrides_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsUsersJoyrides>;
};

/** input type for inserting object relation for remote table "orgs_users_joyrides" */
export type OrgsUsersJoyridesObjRelInsertInput = {
  data: OrgsUsersJoyridesInsertInput;
};

/** ordering options when selecting data from "orgs_users_joyrides" */
export type OrgsUsersJoyridesOrderBy = {
  finished_on?: Maybe<OrderBy>;
  joyride?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_users_joyrides" */
export type OrgsUsersJoyridesPkColumnsInput = {
  joyride: Scalars['String'];
  org: Scalars['String'];
  user: Scalars['String'];
};

/** select columns of table "orgs_users_joyrides" */
export enum OrgsUsersJoyridesSelectColumn {
  /** column name */
  FinishedOn = 'finished_on',
  /** column name */
  Joyride = 'joyride'
}

/** response of any mutation on the table "orgs_users" */
export type OrgsUsersMutationResponse = {
  __typename?: 'orgs_users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsUsers>;
};

/** on conflict condition type for table "orgs_users" */
export type OrgsUsersOnConflict = {
  constraint: OrgsUsersConstraint;
  update_columns: Array<OrgsUsersUpdateColumn>;
  where?: Maybe<OrgsUsersBoolExp>;
};

/** ordering options when selecting data from "orgs_users" */
export type OrgsUsersOrderBy = {
  config_alerts_display_out_of_template?: Maybe<OrderBy>;
  config_alerts_display_rebooted_enabled?: Maybe<OrderBy>;
  config_alerts_display_unreachable_delay?: Maybe<OrderBy>;
  config_alerts_display_unreachable_enabled?: Maybe<OrderBy>;
  config_alerts_usb_plug_event_enabled?: Maybe<OrderBy>;
  config_alerts_usb_plug_event_type?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_users" */
export type OrgsUsersPkColumnsInput = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  user: Scalars['String'];
};

/** columns and relationships of "orgs_users_rebooted_filters" */
export type OrgsUsersRebootedFilters = {
  __typename?: 'orgs_users_rebooted_filters';
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  org: Scalars['String'];
  partition: Scalars['bigint'];
  rebooted_filter: Scalars['String'];
  user: Scalars['String'];
};

/** input type for inserting array relation for remote table "orgs_users_rebooted_filters" */
export type OrgsUsersRebootedFiltersArrRelInsertInput = {
  data: Array<OrgsUsersRebootedFiltersInsertInput>;
  on_conflict?: Maybe<OrgsUsersRebootedFiltersOnConflict>;
};

/** Boolean expression to filter rows from the table "orgs_users_rebooted_filters". All fields are combined with a logical 'AND'. */
export type OrgsUsersRebootedFiltersBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsUsersRebootedFiltersBoolExp>>>;
  _not?: Maybe<OrgsUsersRebootedFiltersBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsUsersRebootedFiltersBoolExp>>>;
  category_internal_name?: Maybe<StringComparisonExp>;
  option_internal_name?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  partition?: Maybe<BigintComparisonExp>;
  rebooted_filter?: Maybe<StringComparisonExp>;
  user?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "orgs_users_rebooted_filters" */
export enum OrgsUsersRebootedFiltersConstraint {
  /** unique or primary key constraint */
  OrgsUsersRebootedFiltersPkey = 'orgs_users_rebooted_filters_pkey'
}

/** input type for inserting data into table "orgs_users_rebooted_filters" */
export type OrgsUsersRebootedFiltersInsertInput = {
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  rebooted_filter?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "orgs_users_rebooted_filters" */
export type OrgsUsersRebootedFiltersMutationResponse = {
  __typename?: 'orgs_users_rebooted_filters_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsUsersRebootedFilters>;
};

/** input type for inserting object relation for remote table "orgs_users_rebooted_filters" */
export type OrgsUsersRebootedFiltersObjRelInsertInput = {
  data: OrgsUsersRebootedFiltersInsertInput;
  on_conflict?: Maybe<OrgsUsersRebootedFiltersOnConflict>;
};

/** on conflict condition type for table "orgs_users_rebooted_filters" */
export type OrgsUsersRebootedFiltersOnConflict = {
  constraint: OrgsUsersRebootedFiltersConstraint;
  update_columns: Array<OrgsUsersRebootedFiltersUpdateColumn>;
  where?: Maybe<OrgsUsersRebootedFiltersBoolExp>;
};

/** ordering options when selecting data from "orgs_users_rebooted_filters" */
export type OrgsUsersRebootedFiltersOrderBy = {
  category_internal_name?: Maybe<OrderBy>;
  option_internal_name?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  rebooted_filter?: Maybe<OrderBy>;
  user?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_users_rebooted_filters" */
export type OrgsUsersRebootedFiltersPkColumnsInput = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  rebooted_filter: Scalars['String'];
  user: Scalars['String'];
};

/** select columns of table "orgs_users_rebooted_filters" */
export enum OrgsUsersRebootedFiltersSelectColumn {
  /** column name */
  CategoryInternalName = 'category_internal_name',
  /** column name */
  OptionInternalName = 'option_internal_name',
  /** column name */
  Org = 'org',
  /** column name */
  Partition = 'partition',
  /** column name */
  RebootedFilter = 'rebooted_filter',
  /** column name */
  User = 'user'
}

/** input type for updating data in table "orgs_users_rebooted_filters" */
export type OrgsUsersRebootedFiltersSetInput = {
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  rebooted_filter?: Maybe<Scalars['String']>;
};

/** update columns of table "orgs_users_rebooted_filters" */
export enum OrgsUsersRebootedFiltersUpdateColumn {
  /** column name */
  CategoryInternalName = 'category_internal_name',
  /** column name */
  OptionInternalName = 'option_internal_name',
  /** column name */
  RebootedFilter = 'rebooted_filter'
}

/** select columns of table "orgs_users" */
export enum OrgsUsersSelectColumn {
  /** column name */
  ConfigAlertsDisplayOutOfTemplate = 'config_alerts_display_out_of_template',
  /** column name */
  ConfigAlertsDisplayRebootedEnabled = 'config_alerts_display_rebooted_enabled',
  /** column name */
  ConfigAlertsDisplayUnreachableDelay = 'config_alerts_display_unreachable_delay',
  /** column name */
  ConfigAlertsDisplayUnreachableEnabled = 'config_alerts_display_unreachable_enabled',
  /** column name */
  ConfigAlertsUsbPlugEventEnabled = 'config_alerts_usb_plug_event_enabled',
  /** column name */
  ConfigAlertsUsbPlugEventType = 'config_alerts_usb_plug_event_type'
}

/** input type for updating data in table "orgs_users" */
export type OrgsUsersSetInput = {
  config_alerts_display_out_of_template?: Maybe<Scalars['Boolean']>;
  config_alerts_display_rebooted_enabled?: Maybe<Scalars['Boolean']>;
  config_alerts_display_unreachable_delay?: Maybe<Scalars['bigint']>;
  config_alerts_display_unreachable_enabled?: Maybe<Scalars['Boolean']>;
  config_alerts_usb_plug_event_enabled?: Maybe<Scalars['Boolean']>;
  config_alerts_usb_plug_event_type?: Maybe<Scalars['String']>;
};

/** columns and relationships of "orgs_users_template_filters" */
export type OrgsUsersTemplateFilters = {
  __typename?: 'orgs_users_template_filters';
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  org: Scalars['String'];
  partition: Scalars['bigint'];
  template_filter: Scalars['String'];
  user: Scalars['String'];
};

/** input type for inserting array relation for remote table "orgs_users_template_filters" */
export type OrgsUsersTemplateFiltersArrRelInsertInput = {
  data: Array<OrgsUsersTemplateFiltersInsertInput>;
  on_conflict?: Maybe<OrgsUsersTemplateFiltersOnConflict>;
};

/** Boolean expression to filter rows from the table "orgs_users_template_filters". All fields are combined with a logical 'AND'. */
export type OrgsUsersTemplateFiltersBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsUsersTemplateFiltersBoolExp>>>;
  _not?: Maybe<OrgsUsersTemplateFiltersBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsUsersTemplateFiltersBoolExp>>>;
  category_internal_name?: Maybe<StringComparisonExp>;
  option_internal_name?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  partition?: Maybe<BigintComparisonExp>;
  template_filter?: Maybe<StringComparisonExp>;
  user?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "orgs_users_template_filters" */
export enum OrgsUsersTemplateFiltersConstraint {
  /** unique or primary key constraint */
  OrgsUsersTemplateFiltersPkey = 'orgs_users_template_filters_pkey'
}

/** input type for inserting data into table "orgs_users_template_filters" */
export type OrgsUsersTemplateFiltersInsertInput = {
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  template_filter?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "orgs_users_template_filters" */
export type OrgsUsersTemplateFiltersMutationResponse = {
  __typename?: 'orgs_users_template_filters_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsUsersTemplateFilters>;
};

/** input type for inserting object relation for remote table "orgs_users_template_filters" */
export type OrgsUsersTemplateFiltersObjRelInsertInput = {
  data: OrgsUsersTemplateFiltersInsertInput;
  on_conflict?: Maybe<OrgsUsersTemplateFiltersOnConflict>;
};

/** on conflict condition type for table "orgs_users_template_filters" */
export type OrgsUsersTemplateFiltersOnConflict = {
  constraint: OrgsUsersTemplateFiltersConstraint;
  update_columns: Array<OrgsUsersTemplateFiltersUpdateColumn>;
  where?: Maybe<OrgsUsersTemplateFiltersBoolExp>;
};

/** ordering options when selecting data from "orgs_users_template_filters" */
export type OrgsUsersTemplateFiltersOrderBy = {
  category_internal_name?: Maybe<OrderBy>;
  option_internal_name?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  template_filter?: Maybe<OrderBy>;
  user?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_users_template_filters" */
export type OrgsUsersTemplateFiltersPkColumnsInput = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  template_filter: Scalars['String'];
  user: Scalars['String'];
};

/** select columns of table "orgs_users_template_filters" */
export enum OrgsUsersTemplateFiltersSelectColumn {
  /** column name */
  CategoryInternalName = 'category_internal_name',
  /** column name */
  OptionInternalName = 'option_internal_name',
  /** column name */
  Org = 'org',
  /** column name */
  Partition = 'partition',
  /** column name */
  TemplateFilter = 'template_filter',
  /** column name */
  User = 'user'
}

/** input type for updating data in table "orgs_users_template_filters" */
export type OrgsUsersTemplateFiltersSetInput = {
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  template_filter?: Maybe<Scalars['String']>;
};

/** update columns of table "orgs_users_template_filters" */
export enum OrgsUsersTemplateFiltersUpdateColumn {
  /** column name */
  CategoryInternalName = 'category_internal_name',
  /** column name */
  OptionInternalName = 'option_internal_name',
  /** column name */
  TemplateFilter = 'template_filter'
}

/** columns and relationships of "orgs_users_unreachable_filters" */
export type OrgsUsersUnreachableFilters = {
  __typename?: 'orgs_users_unreachable_filters';
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  org: Scalars['String'];
  partition: Scalars['bigint'];
  unreachable_filter: Scalars['String'];
  user: Scalars['String'];
};

/** input type for inserting array relation for remote table "orgs_users_unreachable_filters" */
export type OrgsUsersUnreachableFiltersArrRelInsertInput = {
  data: Array<OrgsUsersUnreachableFiltersInsertInput>;
  on_conflict?: Maybe<OrgsUsersUnreachableFiltersOnConflict>;
};

/** Boolean expression to filter rows from the table "orgs_users_unreachable_filters". All fields are combined with a logical 'AND'. */
export type OrgsUsersUnreachableFiltersBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsUsersUnreachableFiltersBoolExp>>>;
  _not?: Maybe<OrgsUsersUnreachableFiltersBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsUsersUnreachableFiltersBoolExp>>>;
  category_internal_name?: Maybe<StringComparisonExp>;
  option_internal_name?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  partition?: Maybe<BigintComparisonExp>;
  unreachable_filter?: Maybe<StringComparisonExp>;
  user?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "orgs_users_unreachable_filters" */
export enum OrgsUsersUnreachableFiltersConstraint {
  /** unique or primary key constraint */
  OrgsUsersUnreachableFiltersPkey = 'orgs_users_unreachable_filters_pkey'
}

/** input type for inserting data into table "orgs_users_unreachable_filters" */
export type OrgsUsersUnreachableFiltersInsertInput = {
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  unreachable_filter?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "orgs_users_unreachable_filters" */
export type OrgsUsersUnreachableFiltersMutationResponse = {
  __typename?: 'orgs_users_unreachable_filters_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsUsersUnreachableFilters>;
};

/** input type for inserting object relation for remote table "orgs_users_unreachable_filters" */
export type OrgsUsersUnreachableFiltersObjRelInsertInput = {
  data: OrgsUsersUnreachableFiltersInsertInput;
  on_conflict?: Maybe<OrgsUsersUnreachableFiltersOnConflict>;
};

/** on conflict condition type for table "orgs_users_unreachable_filters" */
export type OrgsUsersUnreachableFiltersOnConflict = {
  constraint: OrgsUsersUnreachableFiltersConstraint;
  update_columns: Array<OrgsUsersUnreachableFiltersUpdateColumn>;
  where?: Maybe<OrgsUsersUnreachableFiltersBoolExp>;
};

/** ordering options when selecting data from "orgs_users_unreachable_filters" */
export type OrgsUsersUnreachableFiltersOrderBy = {
  category_internal_name?: Maybe<OrderBy>;
  option_internal_name?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  unreachable_filter?: Maybe<OrderBy>;
  user?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_users_unreachable_filters" */
export type OrgsUsersUnreachableFiltersPkColumnsInput = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  unreachable_filter: Scalars['String'];
  user: Scalars['String'];
};

/** select columns of table "orgs_users_unreachable_filters" */
export enum OrgsUsersUnreachableFiltersSelectColumn {
  /** column name */
  CategoryInternalName = 'category_internal_name',
  /** column name */
  OptionInternalName = 'option_internal_name',
  /** column name */
  Org = 'org',
  /** column name */
  Partition = 'partition',
  /** column name */
  UnreachableFilter = 'unreachable_filter',
  /** column name */
  User = 'user'
}

/** input type for updating data in table "orgs_users_unreachable_filters" */
export type OrgsUsersUnreachableFiltersSetInput = {
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  unreachable_filter?: Maybe<Scalars['String']>;
};

/** update columns of table "orgs_users_unreachable_filters" */
export enum OrgsUsersUnreachableFiltersUpdateColumn {
  /** column name */
  CategoryInternalName = 'category_internal_name',
  /** column name */
  OptionInternalName = 'option_internal_name',
  /** column name */
  UnreachableFilter = 'unreachable_filter'
}

/** update columns of table "orgs_users" */
export enum OrgsUsersUpdateColumn {
  /** column name */
  ConfigAlertsDisplayOutOfTemplate = 'config_alerts_display_out_of_template',
  /** column name */
  ConfigAlertsDisplayRebootedEnabled = 'config_alerts_display_rebooted_enabled',
  /** column name */
  ConfigAlertsDisplayUnreachableDelay = 'config_alerts_display_unreachable_delay',
  /** column name */
  ConfigAlertsDisplayUnreachableEnabled = 'config_alerts_display_unreachable_enabled',
  /** column name */
  ConfigAlertsUsbPlugEventEnabled = 'config_alerts_usb_plug_event_enabled',
  /** column name */
  ConfigAlertsUsbPlugEventType = 'config_alerts_usb_plug_event_type'
}

/** columns and relationships of "orgs_users_usb_plug_filters" */
export type OrgsUsersUsbPlugFilters = {
  __typename?: 'orgs_users_usb_plug_filters';
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  org: Scalars['String'];
  partition: Scalars['bigint'];
  usb_plug_filter: Scalars['String'];
  user: Scalars['String'];
};

/** input type for inserting array relation for remote table "orgs_users_usb_plug_filters" */
export type OrgsUsersUsbPlugFiltersArrRelInsertInput = {
  data: Array<OrgsUsersUsbPlugFiltersInsertInput>;
  on_conflict?: Maybe<OrgsUsersUsbPlugFiltersOnConflict>;
};

/** Boolean expression to filter rows from the table "orgs_users_usb_plug_filters". All fields are combined with a logical 'AND'. */
export type OrgsUsersUsbPlugFiltersBoolExp = {
  _and?: Maybe<Array<Maybe<OrgsUsersUsbPlugFiltersBoolExp>>>;
  _not?: Maybe<OrgsUsersUsbPlugFiltersBoolExp>;
  _or?: Maybe<Array<Maybe<OrgsUsersUsbPlugFiltersBoolExp>>>;
  category_internal_name?: Maybe<StringComparisonExp>;
  option_internal_name?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  partition?: Maybe<BigintComparisonExp>;
  usb_plug_filter?: Maybe<StringComparisonExp>;
  user?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "orgs_users_usb_plug_filters" */
export enum OrgsUsersUsbPlugFiltersConstraint {
  /** unique or primary key constraint */
  OrgsUsersUsbPlugFiltersPkey = 'orgs_users_usb_plug_filters_pkey'
}

/** input type for inserting data into table "orgs_users_usb_plug_filters" */
export type OrgsUsersUsbPlugFiltersInsertInput = {
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  usb_plug_filter?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "orgs_users_usb_plug_filters" */
export type OrgsUsersUsbPlugFiltersMutationResponse = {
  __typename?: 'orgs_users_usb_plug_filters_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrgsUsersUsbPlugFilters>;
};

/** input type for inserting object relation for remote table "orgs_users_usb_plug_filters" */
export type OrgsUsersUsbPlugFiltersObjRelInsertInput = {
  data: OrgsUsersUsbPlugFiltersInsertInput;
  on_conflict?: Maybe<OrgsUsersUsbPlugFiltersOnConflict>;
};

/** on conflict condition type for table "orgs_users_usb_plug_filters" */
export type OrgsUsersUsbPlugFiltersOnConflict = {
  constraint: OrgsUsersUsbPlugFiltersConstraint;
  update_columns: Array<OrgsUsersUsbPlugFiltersUpdateColumn>;
  where?: Maybe<OrgsUsersUsbPlugFiltersBoolExp>;
};

/** ordering options when selecting data from "orgs_users_usb_plug_filters" */
export type OrgsUsersUsbPlugFiltersOrderBy = {
  category_internal_name?: Maybe<OrderBy>;
  option_internal_name?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  usb_plug_filter?: Maybe<OrderBy>;
  user?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orgs_users_usb_plug_filters" */
export type OrgsUsersUsbPlugFiltersPkColumnsInput = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  usb_plug_filter: Scalars['String'];
  user: Scalars['String'];
};

/** select columns of table "orgs_users_usb_plug_filters" */
export enum OrgsUsersUsbPlugFiltersSelectColumn {
  /** column name */
  CategoryInternalName = 'category_internal_name',
  /** column name */
  OptionInternalName = 'option_internal_name',
  /** column name */
  Org = 'org',
  /** column name */
  Partition = 'partition',
  /** column name */
  UsbPlugFilter = 'usb_plug_filter',
  /** column name */
  User = 'user'
}

/** input type for updating data in table "orgs_users_usb_plug_filters" */
export type OrgsUsersUsbPlugFiltersSetInput = {
  category_internal_name?: Maybe<Scalars['String']>;
  option_internal_name?: Maybe<Scalars['String']>;
  usb_plug_filter?: Maybe<Scalars['String']>;
};

/** update columns of table "orgs_users_usb_plug_filters" */
export enum OrgsUsersUsbPlugFiltersUpdateColumn {
  /** column name */
  CategoryInternalName = 'category_internal_name',
  /** column name */
  OptionInternalName = 'option_internal_name',
  /** column name */
  UsbPlugFilter = 'usb_plug_filter'
}

/** query root */
export type QueryRoot = {
  __typename?: 'query_root';
  /** execute function "_active_meeting_count" which returns "view_single_result" */
  _active_meeting_count: Array<ViewSingleResult>;
  /** fetch data from the table: "org_addresses" using primary key columns */
  _address?: Maybe<OrgAddresses>;
  /** fetch data from the table: "org_addresses" */
  _addresses: Array<OrgAddresses>;
  /** fetch data from the table: "view_display_alerts" */
  _alerts: Array<ViewDisplayAlerts>;
  /** execute function "_alerts_chart" which returns "view_alerts_chart_result" */
  _alerts_chart: Array<ViewAlertsChartResult>;
  /** execute function "_alerts_chart" and query aggregates on result of table type "view_alerts_chart_result" */
  _alerts_chart_aggregate: ViewAlertsChartResultAggregate;
  /** fetch data from the table: "view_display_assigned_categories" */
  _assignedCategories: Array<ViewDisplayAssignedCategories>;
  /** execute function "_avg_hours_spent_per_user" which returns "view_single_result" */
  _avg_hours_spent_per_user: Array<ViewSingleResult>;
  /** execute function "_avg_rooms_visited" which returns "view_single_result" */
  _avg_rooms_visited: Array<ViewSingleResult>;
  /** execute function "_busiest_hour" which returns "view_single_result" */
  _busiest_hour: Array<ViewSingleResult>;
  /** fetch data from the table: "view_categories" */
  _categories: Array<ViewCategories>;
  /** fetch aggregated fields from the table: "view_categories" */
  _categoriesAggregate: ViewCategoriesAggregate;
  /** execute function "_conference_vendor_graph" which returns "view_conference_vendor_result" */
  _conference_vendor_graph: Array<ViewConferenceVendorResult>;
  /** execute function "_daily_active_users_graph" which returns "view_date_aggregation_result" */
  _daily_active_users_graph: Array<ViewDateAggregationResult>;
  /** fetch data from the table: "view_displays_assigned_options" */
  _displayAssignedOptions: Array<ViewDisplaysAssignedOptions>;
  /** fetch aggregated fields from the table: "view_displays_assigned_options" */
  _displayAssignedOptionsAggregate: ViewDisplaysAssignedOptionsAggregate;
  /** fetch data from the table: "view_displays_locations" */
  _display_locations: Array<ViewDisplaysLocations>;
  /** fetch aggregated fields from the table: "view_displays_locations" */
  _display_locations_aggregate: ViewDisplaysLocationsAggregate;
  /** fetch data from the table: "view_displays" */
  _displays: Array<ViewDisplays>;
  /** fetch aggregated fields from the table: "view_displays" */
  _displaysAggregate: ViewDisplaysAggregate;
  /** fetch data from the table: "view_displays_availability" */
  _displaysAvailability: Array<ViewDisplaysAvailability>;
  /** fetch aggregated fields from the table: "view_displays_availability" */
  _displaysAvailabilityAggregate: ViewDisplaysAvailabilityAggregate;
  /** execute function "_displays_by_geo" which returns "view_displays" */
  _displays_by_geo: Array<ViewDisplays>;
  /** execute function "_displays_by_geo" and query aggregates on result of table type "view_displays" */
  _displays_by_geo_aggregate: ViewDisplaysAggregate;
  /** execute function "_home_page_map_by_display" which returns "view_home_page_map" */
  _home_page_map_by_display: Array<ViewHomePageMap>;
  /** execute function "_hybrid_meetings_graph" which returns "view_hybrid_meeting_aggregation_result" */
  _hybrid_meetings_graph: Array<ViewHybridMeetingAggregationResult>;
  /** execute function "_median_meeting_duration" which returns "view_single_result" */
  _median_meeting_duration: Array<ViewSingleResult>;
  /** fetch data from the table: "view_options" */
  _options: Array<ViewOptions>;
  /** fetch aggregated fields from the table: "view_options" */
  _optionsAggregate: ViewOptionsAggregate;
  /** execute function "_search_activity_user_mobility" which returns "view_activity_user_mobility_result" */
  _search_activity_user_mobility: Array<ViewActivityUserMobilityResult>;
  /** execute function "_search_client_screen_resolutions" which returns "view_client_screen_resolutions" */
  _search_client_screen_resolutions: Array<ViewClientScreenResolutions>;
  /** execute function "_search_client_screen_resolutions" and query aggregates on result of table type "view_client_screen_resolutions" */
  _search_client_screen_resolutions_aggregate: ViewClientScreenResolutionsAggregate;
  /** execute function "_search_client_versions" which returns "view_client_versions" */
  _search_client_versions: Array<ViewClientVersions>;
  /** execute function "_search_client_versions" and query aggregates on result of table type "view_client_versions" */
  _search_client_versions_aggregate: ViewClientVersionsAggregate;
  /** execute function "_search_content_sharing_app_groups" which returns "view_content_sharing_app_groups" */
  _search_content_sharing_app_groups: Array<ViewContentSharingAppGroups>;
  /** execute function "_search_content_sharing_app_groups" and query aggregates on result of table type "view_content_sharing_app_groups" */
  _search_content_sharing_app_groups_aggregate: ViewContentSharingAppGroupsAggregate;
  /** execute function "_search_content_sharing_app_names" which returns "view_content_sharing_apps_by_name" */
  _search_content_sharing_app_names: Array<ViewContentSharingAppsByName>;
  /** execute function "_search_content_sharing_app_names" and query aggregates on result of table type "view_content_sharing_apps_by_name" */
  _search_content_sharing_app_names_aggregate: ViewContentSharingAppsByNameAggregate;
  /** execute function "_search_content_sharing_types" which returns "view_content_sharing_types" */
  _search_content_sharing_types: Array<ViewContentSharingTypes>;
  /** execute function "_search_content_sharing_types" and query aggregates on result of table type "view_content_sharing_types" */
  _search_content_sharing_types_aggregate: ViewContentSharingTypesAggregate;
  /** execute function "_search_most_collaborative_spaces" which returns "view_most_collaborative_spaces_result" */
  _search_most_collaborative_spaces: Array<ViewMostCollaborativeSpacesResult>;
  /** execute function "_search_user_devices" which returns "view_user_devices" */
  _search_user_devices: Array<ViewUserDevices>;
  /** execute function "_sessions_per_user" which returns "view_single_result" */
  _sessions_per_user: Array<ViewSingleResult>;
  /** fetch data from the table: "view_solstice_versions" */
  _solstice_versions: Array<ViewSolsticeVersions>;
  /** execute function "_unique_users_count" which returns "view_single_result" */
  _unique_users_count: Array<ViewSingleResult>;
  /** execute function "_usage_by_room_type" which returns "view_usage_by_room_type" */
  _usage_by_room_type: Array<ViewUsageByRoomType>;
  /** execute function "_usage_by_room_type" and query aggregates on result of table type "view_usage_by_room_type" */
  _usage_by_room_type_aggregate: ViewUsageByRoomTypeAggregate;
  /** execute function "_usb_product_history" which returns "view_usb_product_history" */
  _usb_product_history: Array<ViewUsbProductHistory>;
  /** execute function "_usb_product_history" and query aggregates on result of table type "view_usb_product_history" */
  _usb_product_history_aggregate: ViewUsbProductHistoryAggregate;
  /** execute function "_user_collaboration" which returns "view_user_collaboration_results" */
  _user_collaboration: Array<ViewUserCollaborationResults>;
  /** fetch data from the table: "active_learning_flags" */
  active_learning_flags: Array<ActiveLearningFlags>;
  /** fetch aggregated fields from the table: "active_learning_flags" */
  active_learning_flags_aggregate: ActiveLearningFlagsAggregate;
  /** fetch data from the table: "active_learning_flags" using primary key columns */
  active_learning_flags_by_pk?: Maybe<ActiveLearningFlags>;
  /** fetch aggregated fields from the table: "org_addresses" */
  org_addresses_aggregate: OrgAddressesAggregate;
  /** fetch data from the table: "orgs" */
  orgs: Array<Orgs>;
  /** fetch data from the table: "orgs" using primary key columns */
  orgs_by_pk?: Maybe<Orgs>;
  /** fetch data from the table: "orgs_categories" */
  orgs_categories: Array<OrgsCategories>;
  /** fetch data from the table: "orgs_categories" using primary key columns */
  orgs_categories_by_pk?: Maybe<OrgsCategories>;
  /** fetch data from the table: "orgs_categories_options" */
  orgs_categories_options: Array<OrgsCategoriesOptions>;
  /** fetch data from the table: "orgs_categories_options" using primary key columns */
  orgs_categories_options_by_pk?: Maybe<OrgsCategoriesOptions>;
  /** fetch data from the table: "orgs_displays_categories" */
  orgs_displays_categories: Array<OrgsDisplaysCategories>;
  /** fetch data from the table: "orgs_displays_categories" using primary key columns */
  orgs_displays_categories_by_pk?: Maybe<OrgsDisplaysCategories>;
  /** fetch data from the table: "orgs_displays_locations" */
  orgs_displays_locations: Array<OrgsDisplaysLocations>;
  /** fetch data from the table: "orgs_displays_locations" using primary key columns */
  orgs_displays_locations_by_pk?: Maybe<OrgsDisplaysLocations>;
  /** fetch data from the table: "orgs_displays_tags" */
  orgs_displays_tags: Array<OrgsDisplaysTags>;
  /** fetch aggregated fields from the table: "orgs_displays_tags" */
  orgs_displays_tags_aggregate: OrgsDisplaysTagsAggregate;
  /** fetch data from the table: "orgs_displays_tags" using primary key columns */
  orgs_displays_tags_by_pk?: Maybe<OrgsDisplaysTags>;
  /** fetch data from the table: "orgs_flags" */
  orgs_flags: Array<OrgsFlags>;
  /** fetch data from the table: "orgs_users" */
  orgs_users: Array<OrgsUsers>;
  /** fetch data from the table: "orgs_users" using primary key columns */
  orgs_users_by_pk?: Maybe<OrgsUsers>;
  /** fetch data from the table: "orgs_users_joyrides" */
  orgs_users_joyrides: Array<OrgsUsersJoyrides>;
  /** fetch data from the table: "orgs_users_joyrides" using primary key columns */
  orgs_users_joyrides_by_pk?: Maybe<OrgsUsersJoyrides>;
  /** fetch data from the table: "orgs_users_rebooted_filters" */
  orgs_users_rebooted_filters: Array<OrgsUsersRebootedFilters>;
  /** fetch data from the table: "orgs_users_rebooted_filters" using primary key columns */
  orgs_users_rebooted_filters_by_pk?: Maybe<OrgsUsersRebootedFilters>;
  /** fetch data from the table: "orgs_users_template_filters" */
  orgs_users_template_filters: Array<OrgsUsersTemplateFilters>;
  /** fetch data from the table: "orgs_users_template_filters" using primary key columns */
  orgs_users_template_filters_by_pk?: Maybe<OrgsUsersTemplateFilters>;
  /** fetch data from the table: "orgs_users_unreachable_filters" */
  orgs_users_unreachable_filters: Array<OrgsUsersUnreachableFilters>;
  /** fetch data from the table: "orgs_users_unreachable_filters" using primary key columns */
  orgs_users_unreachable_filters_by_pk?: Maybe<OrgsUsersUnreachableFilters>;
  /** fetch data from the table: "orgs_users_usb_plug_filters" */
  orgs_users_usb_plug_filters: Array<OrgsUsersUsbPlugFilters>;
  /** fetch data from the table: "orgs_users_usb_plug_filters" using primary key columns */
  orgs_users_usb_plug_filters_by_pk?: Maybe<OrgsUsersUsbPlugFilters>;
  /** fetch data from the table: "view_activity_user_mobility_result" */
  view_activity_user_mobility_result: Array<ViewActivityUserMobilityResult>;
  /** fetch data from the table: "view_alerts_chart_result" */
  view_alerts_chart_result: Array<ViewAlertsChartResult>;
  /** fetch aggregated fields from the table: "view_alerts_chart_result" */
  view_alerts_chart_result_aggregate: ViewAlertsChartResultAggregate;
  /** fetch data from the table: "view_client_screen_resolutions" */
  view_client_screen_resolutions: Array<ViewClientScreenResolutions>;
  /** fetch aggregated fields from the table: "view_client_screen_resolutions" */
  view_client_screen_resolutions_aggregate: ViewClientScreenResolutionsAggregate;
  /** fetch data from the table: "view_client_versions" */
  view_client_versions: Array<ViewClientVersions>;
  /** fetch aggregated fields from the table: "view_client_versions" */
  view_client_versions_aggregate: ViewClientVersionsAggregate;
  /** fetch data from the table: "view_conference_vendor_result" */
  view_conference_vendor_result: Array<ViewConferenceVendorResult>;
  /** fetch data from the table: "view_content_sharing_app_groups" */
  view_content_sharing_app_groups: Array<ViewContentSharingAppGroups>;
  /** fetch aggregated fields from the table: "view_content_sharing_app_groups" */
  view_content_sharing_app_groups_aggregate: ViewContentSharingAppGroupsAggregate;
  /** fetch data from the table: "view_content_sharing_apps_by_name" */
  view_content_sharing_apps_by_name: Array<ViewContentSharingAppsByName>;
  /** fetch aggregated fields from the table: "view_content_sharing_apps_by_name" */
  view_content_sharing_apps_by_name_aggregate: ViewContentSharingAppsByNameAggregate;
  /** fetch data from the table: "view_content_sharing_types" */
  view_content_sharing_types: Array<ViewContentSharingTypes>;
  /** fetch aggregated fields from the table: "view_content_sharing_types" */
  view_content_sharing_types_aggregate: ViewContentSharingTypesAggregate;
  /** fetch data from the table: "view_current_usb_products" */
  view_current_usb_products: Array<ViewCurrentUsbProducts>;
  /** fetch aggregated fields from the table: "view_current_usb_products" */
  view_current_usb_products_aggregate: ViewCurrentUsbProductsAggregate;
  /** fetch data from the table: "view_date_aggregation_result" */
  view_date_aggregation_result: Array<ViewDateAggregationResult>;
  /** fetch aggregated fields from the table: "view_display_alerts" */
  view_display_alerts_aggregate: ViewDisplayAlertsAggregate;
  /** fetch data from the table: "view_displays_current_usb_connections" */
  view_displays_current_usb_connections: Array<ViewDisplaysCurrentUsbConnections>;
  /** fetch aggregated fields from the table: "view_displays_current_usb_connections" */
  view_displays_current_usb_connections_aggregate: ViewDisplaysCurrentUsbConnectionsAggregate;
  /** fetch data from the table: "view_displays_deployment" */
  view_displays_deployment: Array<ViewDisplaysDeployment>;
  /** fetch aggregated fields from the table: "view_displays_deployment" */
  view_displays_deployment_aggregate: ViewDisplaysDeploymentAggregate;
  /** fetch data from the table: "view_displays_discover_realtime" */
  view_displays_discover_realtime: Array<ViewDisplaysDiscoverRealtime>;
  /** fetch aggregated fields from the table: "view_displays_discover_realtime" */
  view_displays_discover_realtime_aggregate: ViewDisplaysDiscoverRealtimeAggregate;
  /** fetch data from the table: "view_displays_hosts" */
  view_displays_hosts: Array<ViewDisplaysHosts>;
  /** fetch aggregated fields from the table: "view_displays_hosts" */
  view_displays_hosts_aggregate: ViewDisplaysHostsAggregate;
  /** fetch data from the table: "view_displays_management_status" */
  view_displays_management_status: Array<ViewDisplaysManagementStatus>;
  /** fetch data from the table: "view_displays_realtime" */
  view_displays_realtime: Array<ViewDisplaysRealtime>;
  /** fetch aggregated fields from the table: "view_displays_realtime" */
  view_displays_realtime_aggregate: ViewDisplaysRealtimeAggregate;
  /** fetch data from the table: "view_displays_subscriptions" */
  view_displays_subscriptions: Array<ViewDisplaysSubscriptions>;
  /** fetch data from the table: "view_displays_updateability" */
  view_displays_updateability: Array<ViewDisplaysUpdateability>;
  /** fetch aggregated fields from the table: "view_displays_updateability" */
  view_displays_updateability_aggregate: ViewDisplaysUpdateabilityAggregate;
  /** fetch data from the table: "view_displays_wellness_config" */
  view_displays_wellness_config: Array<ViewDisplaysWellnessConfig>;
  /** fetch aggregated fields from the table: "view_displays_wellness_config" */
  view_displays_wellness_config_aggregate: ViewDisplaysWellnessConfigAggregate;
  /** fetch data from the table: "view_floor_count" */
  view_floor_count: Array<ViewFloorCount>;
  /** fetch data from the table: "view_home_page_map" */
  view_home_page_map: Array<ViewHomePageMap>;
  /** fetch data from the table: "view_hybrid_meeting_aggregation_result" */
  view_hybrid_meeting_aggregation_result: Array<ViewHybridMeetingAggregationResult>;
  /** fetch data from the table: "view_meetings" */
  view_meetings: Array<ViewMeetings>;
  /** fetch aggregated fields from the table: "view_meetings" */
  view_meetings_aggregate: ViewMeetingsAggregate;
  /** fetch data from the table: "view_most_collaborative_spaces_result" */
  view_most_collaborative_spaces_result: Array<ViewMostCollaborativeSpacesResult>;
  /** fetch data from the table: "view_sds" */
  view_sds: Array<ViewSds>;
  /** fetch aggregated fields from the table: "view_sds" */
  view_sds_aggregate: ViewSdsAggregate;
  /** fetch data from the table: "view_single_result" */
  view_single_result: Array<ViewSingleResult>;
  /** fetch aggregated fields from the table: "view_solstice_versions" */
  view_solstice_versions_aggregate: ViewSolsticeVersionsAggregate;
  /** fetch data from the table: "view_usage_by_room_type" */
  view_usage_by_room_type: Array<ViewUsageByRoomType>;
  /** fetch aggregated fields from the table: "view_usage_by_room_type" */
  view_usage_by_room_type_aggregate: ViewUsageByRoomTypeAggregate;
  /** fetch data from the table: "view_usb_product_history" */
  view_usb_product_history: Array<ViewUsbProductHistory>;
  /** fetch aggregated fields from the table: "view_usb_product_history" */
  view_usb_product_history_aggregate: ViewUsbProductHistoryAggregate;
  /** fetch data from the table: "view_user_collaboration_results" */
  view_user_collaboration_results: Array<ViewUserCollaborationResults>;
  /** fetch data from the table: "view_user_devices" */
  view_user_devices: Array<ViewUserDevices>;
  /** fetch data from the table: "view_users" */
  view_users: Array<ViewUsers>;
  /** fetch aggregated fields from the table: "view_users" */
  view_users_aggregate: ViewUsersAggregate;
  /** fetch data from the table: "view_users_permissions" */
  view_users_permissions: Array<ViewUsersPermissions>;
};


/** query root */
export type QueryRootActiveMeetingCountArgs = {
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** query root */
export type QueryRootAddressArgs = {
  id: Scalars['Int'];
};


/** query root */
export type QueryRootAddressesArgs = {
  distinct_on?: Maybe<Array<OrgAddressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgAddressesOrderBy>>;
  where?: Maybe<OrgAddressesBoolExp>;
};


/** query root */
export type QueryRootAlertsArgs = {
  distinct_on?: Maybe<Array<ViewDisplayAlertsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplayAlertsOrderBy>>;
  where?: Maybe<ViewDisplayAlertsBoolExp>;
};


/** query root */
export type QueryRootAlertsChartArgs = {
  args: AlertsChartArgs;
  distinct_on?: Maybe<Array<ViewAlertsChartResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewAlertsChartResultOrderBy>>;
  where?: Maybe<ViewAlertsChartResultBoolExp>;
};


/** query root */
export type QueryRootAlertsChartAggregateArgs = {
  args: AlertsChartArgs;
  distinct_on?: Maybe<Array<ViewAlertsChartResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewAlertsChartResultOrderBy>>;
  where?: Maybe<ViewAlertsChartResultBoolExp>;
};


/** query root */
export type QueryRootAssignedCategoriesArgs = {
  distinct_on?: Maybe<Array<ViewDisplayAssignedCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplayAssignedCategoriesOrderBy>>;
  where?: Maybe<ViewDisplayAssignedCategoriesBoolExp>;
};


/** query root */
export type QueryRootAvgHoursSpentPerUserArgs = {
  args: AvgHoursSpentPerUserArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** query root */
export type QueryRootAvgRoomsVisitedArgs = {
  args: AvgRoomsVisitedArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** query root */
export type QueryRootBusiestHourArgs = {
  args: BusiestHourArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** query root */
export type QueryRootCategoriesArgs = {
  distinct_on?: Maybe<Array<ViewCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewCategoriesOrderBy>>;
  where?: Maybe<ViewCategoriesBoolExp>;
};


/** query root */
export type QueryRootCategoriesAggregateArgs = {
  distinct_on?: Maybe<Array<ViewCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewCategoriesOrderBy>>;
  where?: Maybe<ViewCategoriesBoolExp>;
};


/** query root */
export type QueryRootConferenceVendorGraphArgs = {
  args: ConferenceVendorGraphArgs;
  distinct_on?: Maybe<Array<ViewConferenceVendorResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewConferenceVendorResultOrderBy>>;
  where?: Maybe<ViewConferenceVendorResultBoolExp>;
};


/** query root */
export type QueryRootDailyActiveUsersGraphArgs = {
  args: DailyActiveUsersGraphArgs;
  distinct_on?: Maybe<Array<ViewDateAggregationResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDateAggregationResultOrderBy>>;
  where?: Maybe<ViewDateAggregationResultBoolExp>;
};


/** query root */
export type QueryRootDisplayAssignedOptionsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAssignedOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAssignedOptionsOrderBy>>;
  where?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
};


/** query root */
export type QueryRootDisplayAssignedOptionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAssignedOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAssignedOptionsOrderBy>>;
  where?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
};


/** query root */
export type QueryRootDisplayLocationsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysLocationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysLocationsOrderBy>>;
  where?: Maybe<ViewDisplaysLocationsBoolExp>;
};


/** query root */
export type QueryRootDisplayLocationsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysLocationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysLocationsOrderBy>>;
  where?: Maybe<ViewDisplaysLocationsBoolExp>;
};


/** query root */
export type QueryRootDisplaysArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};


/** query root */
export type QueryRootDisplaysAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};


/** query root */
export type QueryRootDisplaysAvailabilityArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAvailabilityOrderBy>>;
  where?: Maybe<ViewDisplaysAvailabilityBoolExp>;
};


/** query root */
export type QueryRootDisplaysAvailabilityAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAvailabilityOrderBy>>;
  where?: Maybe<ViewDisplaysAvailabilityBoolExp>;
};


/** query root */
export type QueryRootDisplaysByGeoArgs = {
  args: DisplaysByGeoArgs;
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};


/** query root */
export type QueryRootDisplaysByGeoAggregateArgs = {
  args: DisplaysByGeoArgs;
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};


/** query root */
export type QueryRootHomePageMapByDisplayArgs = {
  args: HomePageMapByDisplayArgs;
  distinct_on?: Maybe<Array<ViewHomePageMapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewHomePageMapOrderBy>>;
  where?: Maybe<ViewHomePageMapBoolExp>;
};


/** query root */
export type QueryRootHybridMeetingsGraphArgs = {
  args: HybridMeetingsGraphArgs;
  distinct_on?: Maybe<Array<ViewHybridMeetingAggregationResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewHybridMeetingAggregationResultOrderBy>>;
  where?: Maybe<ViewHybridMeetingAggregationResultBoolExp>;
};


/** query root */
export type QueryRootMedianMeetingDurationArgs = {
  args: MedianMeetingDurationArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** query root */
export type QueryRootOptionsArgs = {
  distinct_on?: Maybe<Array<ViewOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewOptionsOrderBy>>;
  where?: Maybe<ViewOptionsBoolExp>;
};


/** query root */
export type QueryRootOptionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewOptionsOrderBy>>;
  where?: Maybe<ViewOptionsBoolExp>;
};


/** query root */
export type QueryRootSearchActivityUserMobilityArgs = {
  args: SearchActivityUserMobilityArgs;
  distinct_on?: Maybe<Array<ViewActivityUserMobilityResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewActivityUserMobilityResultOrderBy>>;
  where?: Maybe<ViewActivityUserMobilityResultBoolExp>;
};


/** query root */
export type QueryRootSearchClientScreenResolutionsArgs = {
  args: SearchClientScreenResolutionsArgs;
  distinct_on?: Maybe<Array<ViewClientScreenResolutionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientScreenResolutionsOrderBy>>;
  where?: Maybe<ViewClientScreenResolutionsBoolExp>;
};


/** query root */
export type QueryRootSearchClientScreenResolutionsAggregateArgs = {
  args: SearchClientScreenResolutionsArgs;
  distinct_on?: Maybe<Array<ViewClientScreenResolutionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientScreenResolutionsOrderBy>>;
  where?: Maybe<ViewClientScreenResolutionsBoolExp>;
};


/** query root */
export type QueryRootSearchClientVersionsArgs = {
  args: SearchClientVersionsArgs;
  distinct_on?: Maybe<Array<ViewClientVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientVersionsOrderBy>>;
  where?: Maybe<ViewClientVersionsBoolExp>;
};


/** query root */
export type QueryRootSearchClientVersionsAggregateArgs = {
  args: SearchClientVersionsArgs;
  distinct_on?: Maybe<Array<ViewClientVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientVersionsOrderBy>>;
  where?: Maybe<ViewClientVersionsBoolExp>;
};


/** query root */
export type QueryRootSearchContentSharingAppGroupsArgs = {
  args: SearchContentSharingAppGroupsArgs;
  distinct_on?: Maybe<Array<ViewContentSharingAppGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppGroupsOrderBy>>;
  where?: Maybe<ViewContentSharingAppGroupsBoolExp>;
};


/** query root */
export type QueryRootSearchContentSharingAppGroupsAggregateArgs = {
  args: SearchContentSharingAppGroupsArgs;
  distinct_on?: Maybe<Array<ViewContentSharingAppGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppGroupsOrderBy>>;
  where?: Maybe<ViewContentSharingAppGroupsBoolExp>;
};


/** query root */
export type QueryRootSearchContentSharingAppNamesArgs = {
  args: SearchContentSharingAppNamesArgs;
  distinct_on?: Maybe<Array<ViewContentSharingAppsByNameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppsByNameOrderBy>>;
  where?: Maybe<ViewContentSharingAppsByNameBoolExp>;
};


/** query root */
export type QueryRootSearchContentSharingAppNamesAggregateArgs = {
  args: SearchContentSharingAppNamesArgs;
  distinct_on?: Maybe<Array<ViewContentSharingAppsByNameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppsByNameOrderBy>>;
  where?: Maybe<ViewContentSharingAppsByNameBoolExp>;
};


/** query root */
export type QueryRootSearchContentSharingTypesArgs = {
  args: SearchContentSharingTypesArgs;
  distinct_on?: Maybe<Array<ViewContentSharingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingTypesOrderBy>>;
  where?: Maybe<ViewContentSharingTypesBoolExp>;
};


/** query root */
export type QueryRootSearchContentSharingTypesAggregateArgs = {
  args: SearchContentSharingTypesArgs;
  distinct_on?: Maybe<Array<ViewContentSharingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingTypesOrderBy>>;
  where?: Maybe<ViewContentSharingTypesBoolExp>;
};


/** query root */
export type QueryRootSearchMostCollaborativeSpacesArgs = {
  args: SearchMostCollaborativeSpacesArgs;
  distinct_on?: Maybe<Array<ViewMostCollaborativeSpacesResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMostCollaborativeSpacesResultOrderBy>>;
  where?: Maybe<ViewMostCollaborativeSpacesResultBoolExp>;
};


/** query root */
export type QueryRootSearchUserDevicesArgs = {
  args: SearchUserDevicesArgs;
  distinct_on?: Maybe<Array<ViewUserDevicesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUserDevicesOrderBy>>;
  where?: Maybe<ViewUserDevicesBoolExp>;
};


/** query root */
export type QueryRootSessionsPerUserArgs = {
  args: SessionsPerUserArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** query root */
export type QueryRootSolsticeVersionsArgs = {
  distinct_on?: Maybe<Array<ViewSolsticeVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSolsticeVersionsOrderBy>>;
  where?: Maybe<ViewSolsticeVersionsBoolExp>;
};


/** query root */
export type QueryRootUniqueUsersCountArgs = {
  args: UniqueUsersCountArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** query root */
export type QueryRootUsageByRoomTypeArgs = {
  args: UsageByRoomTypeArgs;
  distinct_on?: Maybe<Array<ViewUsageByRoomTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsageByRoomTypeOrderBy>>;
  where?: Maybe<ViewUsageByRoomTypeBoolExp>;
};


/** query root */
export type QueryRootUsageByRoomTypeAggregateArgs = {
  args: UsageByRoomTypeArgs;
  distinct_on?: Maybe<Array<ViewUsageByRoomTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsageByRoomTypeOrderBy>>;
  where?: Maybe<ViewUsageByRoomTypeBoolExp>;
};


/** query root */
export type QueryRootUsbProductHistoryArgs = {
  args: UsbProductHistoryArgs;
  distinct_on?: Maybe<Array<ViewUsbProductHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsbProductHistoryOrderBy>>;
  where?: Maybe<ViewUsbProductHistoryBoolExp>;
};


/** query root */
export type QueryRootUsbProductHistoryAggregateArgs = {
  args: UsbProductHistoryArgs;
  distinct_on?: Maybe<Array<ViewUsbProductHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsbProductHistoryOrderBy>>;
  where?: Maybe<ViewUsbProductHistoryBoolExp>;
};


/** query root */
export type QueryRootUserCollaborationArgs = {
  args: UserCollaborationArgs;
  distinct_on?: Maybe<Array<ViewUserCollaborationResultsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUserCollaborationResultsOrderBy>>;
  where?: Maybe<ViewUserCollaborationResultsBoolExp>;
};


/** query root */
export type QueryRootActiveLearningFlagsArgs = {
  distinct_on?: Maybe<Array<ActiveLearningFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ActiveLearningFlagsOrderBy>>;
  where?: Maybe<ActiveLearningFlagsBoolExp>;
};


/** query root */
export type QueryRootActiveLearningFlagsAggregateArgs = {
  distinct_on?: Maybe<Array<ActiveLearningFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ActiveLearningFlagsOrderBy>>;
  where?: Maybe<ActiveLearningFlagsBoolExp>;
};


/** query root */
export type QueryRootActiveLearningFlagsByPkArgs = {
  org_id: Scalars['String'];
};


/** query root */
export type QueryRootOrgAddressesAggregateArgs = {
  distinct_on?: Maybe<Array<OrgAddressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgAddressesOrderBy>>;
  where?: Maybe<OrgAddressesBoolExp>;
};


/** query root */
export type QueryRootOrgsArgs = {
  distinct_on?: Maybe<Array<OrgsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsOrderBy>>;
  where?: Maybe<OrgsBoolExp>;
};


/** query root */
export type QueryRootOrgsByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** query root */
export type QueryRootOrgsCategoriesArgs = {
  distinct_on?: Maybe<Array<OrgsCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsCategoriesOrderBy>>;
  where?: Maybe<OrgsCategoriesBoolExp>;
};


/** query root */
export type QueryRootOrgsCategoriesByPkArgs = {
  category: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** query root */
export type QueryRootOrgsCategoriesOptionsArgs = {
  distinct_on?: Maybe<Array<OrgsCategoriesOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsCategoriesOptionsOrderBy>>;
  where?: Maybe<OrgsCategoriesOptionsBoolExp>;
};


/** query root */
export type QueryRootOrgsCategoriesOptionsByPkArgs = {
  category: Scalars['String'];
  option: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** query root */
export type QueryRootOrgsDisplaysCategoriesArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysCategoriesOrderBy>>;
  where?: Maybe<OrgsDisplaysCategoriesBoolExp>;
};


/** query root */
export type QueryRootOrgsDisplaysCategoriesByPkArgs = {
  category: Scalars['String'];
  display: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** query root */
export type QueryRootOrgsDisplaysLocationsArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysLocationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysLocationsOrderBy>>;
  where?: Maybe<OrgsDisplaysLocationsBoolExp>;
};


/** query root */
export type QueryRootOrgsDisplaysLocationsByPkArgs = {
  display: Scalars['String'];
  org: Scalars['String'];
};


/** query root */
export type QueryRootOrgsDisplaysTagsArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysTagsOrderBy>>;
  where?: Maybe<OrgsDisplaysTagsBoolExp>;
};


/** query root */
export type QueryRootOrgsDisplaysTagsAggregateArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysTagsOrderBy>>;
  where?: Maybe<OrgsDisplaysTagsBoolExp>;
};


/** query root */
export type QueryRootOrgsDisplaysTagsByPkArgs = {
  display: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
  tag: Scalars['String'];
};


/** query root */
export type QueryRootOrgsFlagsArgs = {
  distinct_on?: Maybe<Array<OrgsFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsFlagsOrderBy>>;
  where?: Maybe<OrgsFlagsBoolExp>;
};


/** query root */
export type QueryRootOrgsUsersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersOrderBy>>;
  where?: Maybe<OrgsUsersBoolExp>;
};


/** query root */
export type QueryRootOrgsUsersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  user: Scalars['String'];
};


/** query root */
export type QueryRootOrgsUsersJoyridesArgs = {
  distinct_on?: Maybe<Array<OrgsUsersJoyridesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersJoyridesOrderBy>>;
  where?: Maybe<OrgsUsersJoyridesBoolExp>;
};


/** query root */
export type QueryRootOrgsUsersJoyridesByPkArgs = {
  joyride: Scalars['String'];
  org: Scalars['String'];
  user: Scalars['String'];
};


/** query root */
export type QueryRootOrgsUsersRebootedFiltersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersRebootedFiltersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersRebootedFiltersOrderBy>>;
  where?: Maybe<OrgsUsersRebootedFiltersBoolExp>;
};


/** query root */
export type QueryRootOrgsUsersRebootedFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  rebooted_filter: Scalars['String'];
  user: Scalars['String'];
};


/** query root */
export type QueryRootOrgsUsersTemplateFiltersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersTemplateFiltersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersTemplateFiltersOrderBy>>;
  where?: Maybe<OrgsUsersTemplateFiltersBoolExp>;
};


/** query root */
export type QueryRootOrgsUsersTemplateFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  template_filter: Scalars['String'];
  user: Scalars['String'];
};


/** query root */
export type QueryRootOrgsUsersUnreachableFiltersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersUnreachableFiltersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersUnreachableFiltersOrderBy>>;
  where?: Maybe<OrgsUsersUnreachableFiltersBoolExp>;
};


/** query root */
export type QueryRootOrgsUsersUnreachableFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  unreachable_filter: Scalars['String'];
  user: Scalars['String'];
};


/** query root */
export type QueryRootOrgsUsersUsbPlugFiltersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersUsbPlugFiltersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersUsbPlugFiltersOrderBy>>;
  where?: Maybe<OrgsUsersUsbPlugFiltersBoolExp>;
};


/** query root */
export type QueryRootOrgsUsersUsbPlugFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  usb_plug_filter: Scalars['String'];
  user: Scalars['String'];
};


/** query root */
export type QueryRootViewActivityUserMobilityResultArgs = {
  distinct_on?: Maybe<Array<ViewActivityUserMobilityResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewActivityUserMobilityResultOrderBy>>;
  where?: Maybe<ViewActivityUserMobilityResultBoolExp>;
};


/** query root */
export type QueryRootViewAlertsChartResultArgs = {
  distinct_on?: Maybe<Array<ViewAlertsChartResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewAlertsChartResultOrderBy>>;
  where?: Maybe<ViewAlertsChartResultBoolExp>;
};


/** query root */
export type QueryRootViewAlertsChartResultAggregateArgs = {
  distinct_on?: Maybe<Array<ViewAlertsChartResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewAlertsChartResultOrderBy>>;
  where?: Maybe<ViewAlertsChartResultBoolExp>;
};


/** query root */
export type QueryRootViewClientScreenResolutionsArgs = {
  distinct_on?: Maybe<Array<ViewClientScreenResolutionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientScreenResolutionsOrderBy>>;
  where?: Maybe<ViewClientScreenResolutionsBoolExp>;
};


/** query root */
export type QueryRootViewClientScreenResolutionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewClientScreenResolutionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientScreenResolutionsOrderBy>>;
  where?: Maybe<ViewClientScreenResolutionsBoolExp>;
};


/** query root */
export type QueryRootViewClientVersionsArgs = {
  distinct_on?: Maybe<Array<ViewClientVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientVersionsOrderBy>>;
  where?: Maybe<ViewClientVersionsBoolExp>;
};


/** query root */
export type QueryRootViewClientVersionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewClientVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientVersionsOrderBy>>;
  where?: Maybe<ViewClientVersionsBoolExp>;
};


/** query root */
export type QueryRootViewConferenceVendorResultArgs = {
  distinct_on?: Maybe<Array<ViewConferenceVendorResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewConferenceVendorResultOrderBy>>;
  where?: Maybe<ViewConferenceVendorResultBoolExp>;
};


/** query root */
export type QueryRootViewContentSharingAppGroupsArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingAppGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppGroupsOrderBy>>;
  where?: Maybe<ViewContentSharingAppGroupsBoolExp>;
};


/** query root */
export type QueryRootViewContentSharingAppGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingAppGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppGroupsOrderBy>>;
  where?: Maybe<ViewContentSharingAppGroupsBoolExp>;
};


/** query root */
export type QueryRootViewContentSharingAppsByNameArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingAppsByNameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppsByNameOrderBy>>;
  where?: Maybe<ViewContentSharingAppsByNameBoolExp>;
};


/** query root */
export type QueryRootViewContentSharingAppsByNameAggregateArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingAppsByNameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppsByNameOrderBy>>;
  where?: Maybe<ViewContentSharingAppsByNameBoolExp>;
};


/** query root */
export type QueryRootViewContentSharingTypesArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingTypesOrderBy>>;
  where?: Maybe<ViewContentSharingTypesBoolExp>;
};


/** query root */
export type QueryRootViewContentSharingTypesAggregateArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingTypesOrderBy>>;
  where?: Maybe<ViewContentSharingTypesBoolExp>;
};


/** query root */
export type QueryRootViewCurrentUsbProductsArgs = {
  distinct_on?: Maybe<Array<ViewCurrentUsbProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewCurrentUsbProductsOrderBy>>;
  where?: Maybe<ViewCurrentUsbProductsBoolExp>;
};


/** query root */
export type QueryRootViewCurrentUsbProductsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewCurrentUsbProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewCurrentUsbProductsOrderBy>>;
  where?: Maybe<ViewCurrentUsbProductsBoolExp>;
};


/** query root */
export type QueryRootViewDateAggregationResultArgs = {
  distinct_on?: Maybe<Array<ViewDateAggregationResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDateAggregationResultOrderBy>>;
  where?: Maybe<ViewDateAggregationResultBoolExp>;
};


/** query root */
export type QueryRootViewDisplayAlertsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplayAlertsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplayAlertsOrderBy>>;
  where?: Maybe<ViewDisplayAlertsBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysCurrentUsbConnectionsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsOrderBy>>;
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysCurrentUsbConnectionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsOrderBy>>;
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysDeploymentArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysDeploymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysDeploymentOrderBy>>;
  where?: Maybe<ViewDisplaysDeploymentBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysDeploymentAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysDeploymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysDeploymentOrderBy>>;
  where?: Maybe<ViewDisplaysDeploymentBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysDiscoverRealtimeArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysDiscoverRealtimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysDiscoverRealtimeOrderBy>>;
  where?: Maybe<ViewDisplaysDiscoverRealtimeBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysDiscoverRealtimeAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysDiscoverRealtimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysDiscoverRealtimeOrderBy>>;
  where?: Maybe<ViewDisplaysDiscoverRealtimeBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysHostsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysHostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysHostsOrderBy>>;
  where?: Maybe<ViewDisplaysHostsBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysHostsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysHostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysHostsOrderBy>>;
  where?: Maybe<ViewDisplaysHostsBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysManagementStatusArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysManagementStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysManagementStatusOrderBy>>;
  where?: Maybe<ViewDisplaysManagementStatusBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysRealtimeArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysRealtimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysRealtimeOrderBy>>;
  where?: Maybe<ViewDisplaysRealtimeBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysRealtimeAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysRealtimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysRealtimeOrderBy>>;
  where?: Maybe<ViewDisplaysRealtimeBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysSubscriptionsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysSubscriptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysSubscriptionsOrderBy>>;
  where?: Maybe<ViewDisplaysSubscriptionsBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysUpdateabilityArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysUpdateabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysUpdateabilityOrderBy>>;
  where?: Maybe<ViewDisplaysUpdateabilityBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysUpdateabilityAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysUpdateabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysUpdateabilityOrderBy>>;
  where?: Maybe<ViewDisplaysUpdateabilityBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysWellnessConfigArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysWellnessConfigSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysWellnessConfigOrderBy>>;
  where?: Maybe<ViewDisplaysWellnessConfigBoolExp>;
};


/** query root */
export type QueryRootViewDisplaysWellnessConfigAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysWellnessConfigSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysWellnessConfigOrderBy>>;
  where?: Maybe<ViewDisplaysWellnessConfigBoolExp>;
};


/** query root */
export type QueryRootViewFloorCountArgs = {
  distinct_on?: Maybe<Array<ViewFloorCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewFloorCountOrderBy>>;
  where?: Maybe<ViewFloorCountBoolExp>;
};


/** query root */
export type QueryRootViewHomePageMapArgs = {
  distinct_on?: Maybe<Array<ViewHomePageMapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewHomePageMapOrderBy>>;
  where?: Maybe<ViewHomePageMapBoolExp>;
};


/** query root */
export type QueryRootViewHybridMeetingAggregationResultArgs = {
  distinct_on?: Maybe<Array<ViewHybridMeetingAggregationResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewHybridMeetingAggregationResultOrderBy>>;
  where?: Maybe<ViewHybridMeetingAggregationResultBoolExp>;
};


/** query root */
export type QueryRootViewMeetingsArgs = {
  distinct_on?: Maybe<Array<ViewMeetingsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMeetingsOrderBy>>;
  where?: Maybe<ViewMeetingsBoolExp>;
};


/** query root */
export type QueryRootViewMeetingsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewMeetingsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMeetingsOrderBy>>;
  where?: Maybe<ViewMeetingsBoolExp>;
};


/** query root */
export type QueryRootViewMostCollaborativeSpacesResultArgs = {
  distinct_on?: Maybe<Array<ViewMostCollaborativeSpacesResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMostCollaborativeSpacesResultOrderBy>>;
  where?: Maybe<ViewMostCollaborativeSpacesResultBoolExp>;
};


/** query root */
export type QueryRootViewSdsArgs = {
  distinct_on?: Maybe<Array<ViewSdsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSdsOrderBy>>;
  where?: Maybe<ViewSdsBoolExp>;
};


/** query root */
export type QueryRootViewSdsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewSdsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSdsOrderBy>>;
  where?: Maybe<ViewSdsBoolExp>;
};


/** query root */
export type QueryRootViewSingleResultArgs = {
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** query root */
export type QueryRootViewSolsticeVersionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewSolsticeVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSolsticeVersionsOrderBy>>;
  where?: Maybe<ViewSolsticeVersionsBoolExp>;
};


/** query root */
export type QueryRootViewUsageByRoomTypeArgs = {
  distinct_on?: Maybe<Array<ViewUsageByRoomTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsageByRoomTypeOrderBy>>;
  where?: Maybe<ViewUsageByRoomTypeBoolExp>;
};


/** query root */
export type QueryRootViewUsageByRoomTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ViewUsageByRoomTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsageByRoomTypeOrderBy>>;
  where?: Maybe<ViewUsageByRoomTypeBoolExp>;
};


/** query root */
export type QueryRootViewUsbProductHistoryArgs = {
  distinct_on?: Maybe<Array<ViewUsbProductHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsbProductHistoryOrderBy>>;
  where?: Maybe<ViewUsbProductHistoryBoolExp>;
};


/** query root */
export type QueryRootViewUsbProductHistoryAggregateArgs = {
  distinct_on?: Maybe<Array<ViewUsbProductHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsbProductHistoryOrderBy>>;
  where?: Maybe<ViewUsbProductHistoryBoolExp>;
};


/** query root */
export type QueryRootViewUserCollaborationResultsArgs = {
  distinct_on?: Maybe<Array<ViewUserCollaborationResultsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUserCollaborationResultsOrderBy>>;
  where?: Maybe<ViewUserCollaborationResultsBoolExp>;
};


/** query root */
export type QueryRootViewUserDevicesArgs = {
  distinct_on?: Maybe<Array<ViewUserDevicesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUserDevicesOrderBy>>;
  where?: Maybe<ViewUserDevicesBoolExp>;
};


/** query root */
export type QueryRootViewUsersArgs = {
  distinct_on?: Maybe<Array<ViewUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsersOrderBy>>;
  where?: Maybe<ViewUsersBoolExp>;
};


/** query root */
export type QueryRootViewUsersAggregateArgs = {
  distinct_on?: Maybe<Array<ViewUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsersOrderBy>>;
  where?: Maybe<ViewUsersBoolExp>;
};


/** query root */
export type QueryRootViewUsersPermissionsArgs = {
  distinct_on?: Maybe<Array<ViewUsersPermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsersPermissionsOrderBy>>;
  where?: Maybe<ViewUsersPermissionsBoolExp>;
};

export type StDWithinGeographyInput = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type StDWithinInput = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

/** subscription root */
export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** execute function "_active_meeting_count" which returns "view_single_result" */
  _active_meeting_count: Array<ViewSingleResult>;
  /** fetch data from the table: "org_addresses" using primary key columns */
  _address?: Maybe<OrgAddresses>;
  /** fetch data from the table: "org_addresses" */
  _addresses: Array<OrgAddresses>;
  /** fetch data from the table: "view_display_alerts" */
  _alerts: Array<ViewDisplayAlerts>;
  /** execute function "_alerts_chart" which returns "view_alerts_chart_result" */
  _alerts_chart: Array<ViewAlertsChartResult>;
  /** execute function "_alerts_chart" and query aggregates on result of table type "view_alerts_chart_result" */
  _alerts_chart_aggregate: ViewAlertsChartResultAggregate;
  /** fetch data from the table: "view_display_assigned_categories" */
  _assignedCategories: Array<ViewDisplayAssignedCategories>;
  /** execute function "_avg_hours_spent_per_user" which returns "view_single_result" */
  _avg_hours_spent_per_user: Array<ViewSingleResult>;
  /** execute function "_avg_rooms_visited" which returns "view_single_result" */
  _avg_rooms_visited: Array<ViewSingleResult>;
  /** execute function "_busiest_hour" which returns "view_single_result" */
  _busiest_hour: Array<ViewSingleResult>;
  /** fetch data from the table: "view_categories" */
  _categories: Array<ViewCategories>;
  /** fetch aggregated fields from the table: "view_categories" */
  _categoriesAggregate: ViewCategoriesAggregate;
  /** execute function "_conference_vendor_graph" which returns "view_conference_vendor_result" */
  _conference_vendor_graph: Array<ViewConferenceVendorResult>;
  /** execute function "_daily_active_users_graph" which returns "view_date_aggregation_result" */
  _daily_active_users_graph: Array<ViewDateAggregationResult>;
  /** fetch data from the table: "view_displays_assigned_options" */
  _displayAssignedOptions: Array<ViewDisplaysAssignedOptions>;
  /** fetch aggregated fields from the table: "view_displays_assigned_options" */
  _displayAssignedOptionsAggregate: ViewDisplaysAssignedOptionsAggregate;
  /** fetch data from the table: "view_displays_locations" */
  _display_locations: Array<ViewDisplaysLocations>;
  /** fetch aggregated fields from the table: "view_displays_locations" */
  _display_locations_aggregate: ViewDisplaysLocationsAggregate;
  /** fetch data from the table: "view_displays" */
  _displays: Array<ViewDisplays>;
  /** fetch aggregated fields from the table: "view_displays" */
  _displaysAggregate: ViewDisplaysAggregate;
  /** fetch data from the table: "view_displays_availability" */
  _displaysAvailability: Array<ViewDisplaysAvailability>;
  /** fetch aggregated fields from the table: "view_displays_availability" */
  _displaysAvailabilityAggregate: ViewDisplaysAvailabilityAggregate;
  /** execute function "_displays_by_geo" which returns "view_displays" */
  _displays_by_geo: Array<ViewDisplays>;
  /** execute function "_displays_by_geo" and query aggregates on result of table type "view_displays" */
  _displays_by_geo_aggregate: ViewDisplaysAggregate;
  /** execute function "_home_page_map_by_display" which returns "view_home_page_map" */
  _home_page_map_by_display: Array<ViewHomePageMap>;
  /** execute function "_hybrid_meetings_graph" which returns "view_hybrid_meeting_aggregation_result" */
  _hybrid_meetings_graph: Array<ViewHybridMeetingAggregationResult>;
  /** execute function "_median_meeting_duration" which returns "view_single_result" */
  _median_meeting_duration: Array<ViewSingleResult>;
  /** fetch data from the table: "view_options" */
  _options: Array<ViewOptions>;
  /** fetch aggregated fields from the table: "view_options" */
  _optionsAggregate: ViewOptionsAggregate;
  /** execute function "_search_activity_user_mobility" which returns "view_activity_user_mobility_result" */
  _search_activity_user_mobility: Array<ViewActivityUserMobilityResult>;
  /** execute function "_search_client_screen_resolutions" which returns "view_client_screen_resolutions" */
  _search_client_screen_resolutions: Array<ViewClientScreenResolutions>;
  /** execute function "_search_client_screen_resolutions" and query aggregates on result of table type "view_client_screen_resolutions" */
  _search_client_screen_resolutions_aggregate: ViewClientScreenResolutionsAggregate;
  /** execute function "_search_client_versions" which returns "view_client_versions" */
  _search_client_versions: Array<ViewClientVersions>;
  /** execute function "_search_client_versions" and query aggregates on result of table type "view_client_versions" */
  _search_client_versions_aggregate: ViewClientVersionsAggregate;
  /** execute function "_search_content_sharing_app_groups" which returns "view_content_sharing_app_groups" */
  _search_content_sharing_app_groups: Array<ViewContentSharingAppGroups>;
  /** execute function "_search_content_sharing_app_groups" and query aggregates on result of table type "view_content_sharing_app_groups" */
  _search_content_sharing_app_groups_aggregate: ViewContentSharingAppGroupsAggregate;
  /** execute function "_search_content_sharing_app_names" which returns "view_content_sharing_apps_by_name" */
  _search_content_sharing_app_names: Array<ViewContentSharingAppsByName>;
  /** execute function "_search_content_sharing_app_names" and query aggregates on result of table type "view_content_sharing_apps_by_name" */
  _search_content_sharing_app_names_aggregate: ViewContentSharingAppsByNameAggregate;
  /** execute function "_search_content_sharing_types" which returns "view_content_sharing_types" */
  _search_content_sharing_types: Array<ViewContentSharingTypes>;
  /** execute function "_search_content_sharing_types" and query aggregates on result of table type "view_content_sharing_types" */
  _search_content_sharing_types_aggregate: ViewContentSharingTypesAggregate;
  /** execute function "_search_most_collaborative_spaces" which returns "view_most_collaborative_spaces_result" */
  _search_most_collaborative_spaces: Array<ViewMostCollaborativeSpacesResult>;
  /** execute function "_search_user_devices" which returns "view_user_devices" */
  _search_user_devices: Array<ViewUserDevices>;
  /** execute function "_sessions_per_user" which returns "view_single_result" */
  _sessions_per_user: Array<ViewSingleResult>;
  /** fetch data from the table: "view_solstice_versions" */
  _solstice_versions: Array<ViewSolsticeVersions>;
  /** execute function "_unique_users_count" which returns "view_single_result" */
  _unique_users_count: Array<ViewSingleResult>;
  /** execute function "_usage_by_room_type" which returns "view_usage_by_room_type" */
  _usage_by_room_type: Array<ViewUsageByRoomType>;
  /** execute function "_usage_by_room_type" and query aggregates on result of table type "view_usage_by_room_type" */
  _usage_by_room_type_aggregate: ViewUsageByRoomTypeAggregate;
  /** execute function "_usb_product_history" which returns "view_usb_product_history" */
  _usb_product_history: Array<ViewUsbProductHistory>;
  /** execute function "_usb_product_history" and query aggregates on result of table type "view_usb_product_history" */
  _usb_product_history_aggregate: ViewUsbProductHistoryAggregate;
  /** execute function "_user_collaboration" which returns "view_user_collaboration_results" */
  _user_collaboration: Array<ViewUserCollaborationResults>;
  /** fetch data from the table: "active_learning_flags" */
  active_learning_flags: Array<ActiveLearningFlags>;
  /** fetch aggregated fields from the table: "active_learning_flags" */
  active_learning_flags_aggregate: ActiveLearningFlagsAggregate;
  /** fetch data from the table: "active_learning_flags" using primary key columns */
  active_learning_flags_by_pk?: Maybe<ActiveLearningFlags>;
  /** fetch aggregated fields from the table: "org_addresses" */
  org_addresses_aggregate: OrgAddressesAggregate;
  /** fetch data from the table: "orgs" */
  orgs: Array<Orgs>;
  /** fetch data from the table: "orgs" using primary key columns */
  orgs_by_pk?: Maybe<Orgs>;
  /** fetch data from the table: "orgs_categories" */
  orgs_categories: Array<OrgsCategories>;
  /** fetch data from the table: "orgs_categories" using primary key columns */
  orgs_categories_by_pk?: Maybe<OrgsCategories>;
  /** fetch data from the table: "orgs_categories_options" */
  orgs_categories_options: Array<OrgsCategoriesOptions>;
  /** fetch data from the table: "orgs_categories_options" using primary key columns */
  orgs_categories_options_by_pk?: Maybe<OrgsCategoriesOptions>;
  /** fetch data from the table: "orgs_displays_categories" */
  orgs_displays_categories: Array<OrgsDisplaysCategories>;
  /** fetch data from the table: "orgs_displays_categories" using primary key columns */
  orgs_displays_categories_by_pk?: Maybe<OrgsDisplaysCategories>;
  /** fetch data from the table: "orgs_displays_locations" */
  orgs_displays_locations: Array<OrgsDisplaysLocations>;
  /** fetch data from the table: "orgs_displays_locations" using primary key columns */
  orgs_displays_locations_by_pk?: Maybe<OrgsDisplaysLocations>;
  /** fetch data from the table: "orgs_displays_tags" */
  orgs_displays_tags: Array<OrgsDisplaysTags>;
  /** fetch aggregated fields from the table: "orgs_displays_tags" */
  orgs_displays_tags_aggregate: OrgsDisplaysTagsAggregate;
  /** fetch data from the table: "orgs_displays_tags" using primary key columns */
  orgs_displays_tags_by_pk?: Maybe<OrgsDisplaysTags>;
  /** fetch data from the table: "orgs_flags" */
  orgs_flags: Array<OrgsFlags>;
  /** fetch data from the table: "orgs_users" */
  orgs_users: Array<OrgsUsers>;
  /** fetch data from the table: "orgs_users" using primary key columns */
  orgs_users_by_pk?: Maybe<OrgsUsers>;
  /** fetch data from the table: "orgs_users_joyrides" */
  orgs_users_joyrides: Array<OrgsUsersJoyrides>;
  /** fetch data from the table: "orgs_users_joyrides" using primary key columns */
  orgs_users_joyrides_by_pk?: Maybe<OrgsUsersJoyrides>;
  /** fetch data from the table: "orgs_users_rebooted_filters" */
  orgs_users_rebooted_filters: Array<OrgsUsersRebootedFilters>;
  /** fetch data from the table: "orgs_users_rebooted_filters" using primary key columns */
  orgs_users_rebooted_filters_by_pk?: Maybe<OrgsUsersRebootedFilters>;
  /** fetch data from the table: "orgs_users_template_filters" */
  orgs_users_template_filters: Array<OrgsUsersTemplateFilters>;
  /** fetch data from the table: "orgs_users_template_filters" using primary key columns */
  orgs_users_template_filters_by_pk?: Maybe<OrgsUsersTemplateFilters>;
  /** fetch data from the table: "orgs_users_unreachable_filters" */
  orgs_users_unreachable_filters: Array<OrgsUsersUnreachableFilters>;
  /** fetch data from the table: "orgs_users_unreachable_filters" using primary key columns */
  orgs_users_unreachable_filters_by_pk?: Maybe<OrgsUsersUnreachableFilters>;
  /** fetch data from the table: "orgs_users_usb_plug_filters" */
  orgs_users_usb_plug_filters: Array<OrgsUsersUsbPlugFilters>;
  /** fetch data from the table: "orgs_users_usb_plug_filters" using primary key columns */
  orgs_users_usb_plug_filters_by_pk?: Maybe<OrgsUsersUsbPlugFilters>;
  /** fetch data from the table: "view_activity_user_mobility_result" */
  view_activity_user_mobility_result: Array<ViewActivityUserMobilityResult>;
  /** fetch data from the table: "view_alerts_chart_result" */
  view_alerts_chart_result: Array<ViewAlertsChartResult>;
  /** fetch aggregated fields from the table: "view_alerts_chart_result" */
  view_alerts_chart_result_aggregate: ViewAlertsChartResultAggregate;
  /** fetch data from the table: "view_client_screen_resolutions" */
  view_client_screen_resolutions: Array<ViewClientScreenResolutions>;
  /** fetch aggregated fields from the table: "view_client_screen_resolutions" */
  view_client_screen_resolutions_aggregate: ViewClientScreenResolutionsAggregate;
  /** fetch data from the table: "view_client_versions" */
  view_client_versions: Array<ViewClientVersions>;
  /** fetch aggregated fields from the table: "view_client_versions" */
  view_client_versions_aggregate: ViewClientVersionsAggregate;
  /** fetch data from the table: "view_conference_vendor_result" */
  view_conference_vendor_result: Array<ViewConferenceVendorResult>;
  /** fetch data from the table: "view_content_sharing_app_groups" */
  view_content_sharing_app_groups: Array<ViewContentSharingAppGroups>;
  /** fetch aggregated fields from the table: "view_content_sharing_app_groups" */
  view_content_sharing_app_groups_aggregate: ViewContentSharingAppGroupsAggregate;
  /** fetch data from the table: "view_content_sharing_apps_by_name" */
  view_content_sharing_apps_by_name: Array<ViewContentSharingAppsByName>;
  /** fetch aggregated fields from the table: "view_content_sharing_apps_by_name" */
  view_content_sharing_apps_by_name_aggregate: ViewContentSharingAppsByNameAggregate;
  /** fetch data from the table: "view_content_sharing_types" */
  view_content_sharing_types: Array<ViewContentSharingTypes>;
  /** fetch aggregated fields from the table: "view_content_sharing_types" */
  view_content_sharing_types_aggregate: ViewContentSharingTypesAggregate;
  /** fetch data from the table: "view_current_usb_products" */
  view_current_usb_products: Array<ViewCurrentUsbProducts>;
  /** fetch aggregated fields from the table: "view_current_usb_products" */
  view_current_usb_products_aggregate: ViewCurrentUsbProductsAggregate;
  /** fetch data from the table: "view_date_aggregation_result" */
  view_date_aggregation_result: Array<ViewDateAggregationResult>;
  /** fetch aggregated fields from the table: "view_display_alerts" */
  view_display_alerts_aggregate: ViewDisplayAlertsAggregate;
  /** fetch data from the table: "view_displays_current_usb_connections" */
  view_displays_current_usb_connections: Array<ViewDisplaysCurrentUsbConnections>;
  /** fetch aggregated fields from the table: "view_displays_current_usb_connections" */
  view_displays_current_usb_connections_aggregate: ViewDisplaysCurrentUsbConnectionsAggregate;
  /** fetch data from the table: "view_displays_deployment" */
  view_displays_deployment: Array<ViewDisplaysDeployment>;
  /** fetch aggregated fields from the table: "view_displays_deployment" */
  view_displays_deployment_aggregate: ViewDisplaysDeploymentAggregate;
  /** fetch data from the table: "view_displays_discover_realtime" */
  view_displays_discover_realtime: Array<ViewDisplaysDiscoverRealtime>;
  /** fetch aggregated fields from the table: "view_displays_discover_realtime" */
  view_displays_discover_realtime_aggregate: ViewDisplaysDiscoverRealtimeAggregate;
  /** fetch data from the table: "view_displays_hosts" */
  view_displays_hosts: Array<ViewDisplaysHosts>;
  /** fetch aggregated fields from the table: "view_displays_hosts" */
  view_displays_hosts_aggregate: ViewDisplaysHostsAggregate;
  /** fetch data from the table: "view_displays_management_status" */
  view_displays_management_status: Array<ViewDisplaysManagementStatus>;
  /** fetch data from the table: "view_displays_realtime" */
  view_displays_realtime: Array<ViewDisplaysRealtime>;
  /** fetch aggregated fields from the table: "view_displays_realtime" */
  view_displays_realtime_aggregate: ViewDisplaysRealtimeAggregate;
  /** fetch data from the table: "view_displays_subscriptions" */
  view_displays_subscriptions: Array<ViewDisplaysSubscriptions>;
  /** fetch data from the table: "view_displays_updateability" */
  view_displays_updateability: Array<ViewDisplaysUpdateability>;
  /** fetch aggregated fields from the table: "view_displays_updateability" */
  view_displays_updateability_aggregate: ViewDisplaysUpdateabilityAggregate;
  /** fetch data from the table: "view_displays_wellness_config" */
  view_displays_wellness_config: Array<ViewDisplaysWellnessConfig>;
  /** fetch aggregated fields from the table: "view_displays_wellness_config" */
  view_displays_wellness_config_aggregate: ViewDisplaysWellnessConfigAggregate;
  /** fetch data from the table: "view_floor_count" */
  view_floor_count: Array<ViewFloorCount>;
  /** fetch data from the table: "view_home_page_map" */
  view_home_page_map: Array<ViewHomePageMap>;
  /** fetch data from the table: "view_hybrid_meeting_aggregation_result" */
  view_hybrid_meeting_aggregation_result: Array<ViewHybridMeetingAggregationResult>;
  /** fetch data from the table: "view_meetings" */
  view_meetings: Array<ViewMeetings>;
  /** fetch aggregated fields from the table: "view_meetings" */
  view_meetings_aggregate: ViewMeetingsAggregate;
  /** fetch data from the table: "view_most_collaborative_spaces_result" */
  view_most_collaborative_spaces_result: Array<ViewMostCollaborativeSpacesResult>;
  /** fetch data from the table: "view_sds" */
  view_sds: Array<ViewSds>;
  /** fetch aggregated fields from the table: "view_sds" */
  view_sds_aggregate: ViewSdsAggregate;
  /** fetch data from the table: "view_single_result" */
  view_single_result: Array<ViewSingleResult>;
  /** fetch aggregated fields from the table: "view_solstice_versions" */
  view_solstice_versions_aggregate: ViewSolsticeVersionsAggregate;
  /** fetch data from the table: "view_usage_by_room_type" */
  view_usage_by_room_type: Array<ViewUsageByRoomType>;
  /** fetch aggregated fields from the table: "view_usage_by_room_type" */
  view_usage_by_room_type_aggregate: ViewUsageByRoomTypeAggregate;
  /** fetch data from the table: "view_usb_product_history" */
  view_usb_product_history: Array<ViewUsbProductHistory>;
  /** fetch aggregated fields from the table: "view_usb_product_history" */
  view_usb_product_history_aggregate: ViewUsbProductHistoryAggregate;
  /** fetch data from the table: "view_user_collaboration_results" */
  view_user_collaboration_results: Array<ViewUserCollaborationResults>;
  /** fetch data from the table: "view_user_devices" */
  view_user_devices: Array<ViewUserDevices>;
  /** fetch data from the table: "view_users" */
  view_users: Array<ViewUsers>;
  /** fetch aggregated fields from the table: "view_users" */
  view_users_aggregate: ViewUsersAggregate;
  /** fetch data from the table: "view_users_permissions" */
  view_users_permissions: Array<ViewUsersPermissions>;
};


/** subscription root */
export type SubscriptionRootActiveMeetingCountArgs = {
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootAddressArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type SubscriptionRootAddressesArgs = {
  distinct_on?: Maybe<Array<OrgAddressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgAddressesOrderBy>>;
  where?: Maybe<OrgAddressesBoolExp>;
};


/** subscription root */
export type SubscriptionRootAlertsArgs = {
  distinct_on?: Maybe<Array<ViewDisplayAlertsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplayAlertsOrderBy>>;
  where?: Maybe<ViewDisplayAlertsBoolExp>;
};


/** subscription root */
export type SubscriptionRootAlertsChartArgs = {
  args: AlertsChartArgs;
  distinct_on?: Maybe<Array<ViewAlertsChartResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewAlertsChartResultOrderBy>>;
  where?: Maybe<ViewAlertsChartResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootAlertsChartAggregateArgs = {
  args: AlertsChartArgs;
  distinct_on?: Maybe<Array<ViewAlertsChartResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewAlertsChartResultOrderBy>>;
  where?: Maybe<ViewAlertsChartResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootAssignedCategoriesArgs = {
  distinct_on?: Maybe<Array<ViewDisplayAssignedCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplayAssignedCategoriesOrderBy>>;
  where?: Maybe<ViewDisplayAssignedCategoriesBoolExp>;
};


/** subscription root */
export type SubscriptionRootAvgHoursSpentPerUserArgs = {
  args: AvgHoursSpentPerUserArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootAvgRoomsVisitedArgs = {
  args: AvgRoomsVisitedArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootBusiestHourArgs = {
  args: BusiestHourArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootCategoriesArgs = {
  distinct_on?: Maybe<Array<ViewCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewCategoriesOrderBy>>;
  where?: Maybe<ViewCategoriesBoolExp>;
};


/** subscription root */
export type SubscriptionRootCategoriesAggregateArgs = {
  distinct_on?: Maybe<Array<ViewCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewCategoriesOrderBy>>;
  where?: Maybe<ViewCategoriesBoolExp>;
};


/** subscription root */
export type SubscriptionRootConferenceVendorGraphArgs = {
  args: ConferenceVendorGraphArgs;
  distinct_on?: Maybe<Array<ViewConferenceVendorResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewConferenceVendorResultOrderBy>>;
  where?: Maybe<ViewConferenceVendorResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootDailyActiveUsersGraphArgs = {
  args: DailyActiveUsersGraphArgs;
  distinct_on?: Maybe<Array<ViewDateAggregationResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDateAggregationResultOrderBy>>;
  where?: Maybe<ViewDateAggregationResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplayAssignedOptionsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAssignedOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAssignedOptionsOrderBy>>;
  where?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplayAssignedOptionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAssignedOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAssignedOptionsOrderBy>>;
  where?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplayLocationsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysLocationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysLocationsOrderBy>>;
  where?: Maybe<ViewDisplaysLocationsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplayLocationsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysLocationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysLocationsOrderBy>>;
  where?: Maybe<ViewDisplaysLocationsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplaysArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplaysAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplaysAvailabilityArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAvailabilityOrderBy>>;
  where?: Maybe<ViewDisplaysAvailabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplaysAvailabilityAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAvailabilityOrderBy>>;
  where?: Maybe<ViewDisplaysAvailabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplaysByGeoArgs = {
  args: DisplaysByGeoArgs;
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};


/** subscription root */
export type SubscriptionRootDisplaysByGeoAggregateArgs = {
  args: DisplaysByGeoArgs;
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};


/** subscription root */
export type SubscriptionRootHomePageMapByDisplayArgs = {
  args: HomePageMapByDisplayArgs;
  distinct_on?: Maybe<Array<ViewHomePageMapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewHomePageMapOrderBy>>;
  where?: Maybe<ViewHomePageMapBoolExp>;
};


/** subscription root */
export type SubscriptionRootHybridMeetingsGraphArgs = {
  args: HybridMeetingsGraphArgs;
  distinct_on?: Maybe<Array<ViewHybridMeetingAggregationResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewHybridMeetingAggregationResultOrderBy>>;
  where?: Maybe<ViewHybridMeetingAggregationResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootMedianMeetingDurationArgs = {
  args: MedianMeetingDurationArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootOptionsArgs = {
  distinct_on?: Maybe<Array<ViewOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewOptionsOrderBy>>;
  where?: Maybe<ViewOptionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootOptionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewOptionsOrderBy>>;
  where?: Maybe<ViewOptionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchActivityUserMobilityArgs = {
  args: SearchActivityUserMobilityArgs;
  distinct_on?: Maybe<Array<ViewActivityUserMobilityResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewActivityUserMobilityResultOrderBy>>;
  where?: Maybe<ViewActivityUserMobilityResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchClientScreenResolutionsArgs = {
  args: SearchClientScreenResolutionsArgs;
  distinct_on?: Maybe<Array<ViewClientScreenResolutionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientScreenResolutionsOrderBy>>;
  where?: Maybe<ViewClientScreenResolutionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchClientScreenResolutionsAggregateArgs = {
  args: SearchClientScreenResolutionsArgs;
  distinct_on?: Maybe<Array<ViewClientScreenResolutionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientScreenResolutionsOrderBy>>;
  where?: Maybe<ViewClientScreenResolutionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchClientVersionsArgs = {
  args: SearchClientVersionsArgs;
  distinct_on?: Maybe<Array<ViewClientVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientVersionsOrderBy>>;
  where?: Maybe<ViewClientVersionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchClientVersionsAggregateArgs = {
  args: SearchClientVersionsArgs;
  distinct_on?: Maybe<Array<ViewClientVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientVersionsOrderBy>>;
  where?: Maybe<ViewClientVersionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchContentSharingAppGroupsArgs = {
  args: SearchContentSharingAppGroupsArgs;
  distinct_on?: Maybe<Array<ViewContentSharingAppGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppGroupsOrderBy>>;
  where?: Maybe<ViewContentSharingAppGroupsBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchContentSharingAppGroupsAggregateArgs = {
  args: SearchContentSharingAppGroupsArgs;
  distinct_on?: Maybe<Array<ViewContentSharingAppGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppGroupsOrderBy>>;
  where?: Maybe<ViewContentSharingAppGroupsBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchContentSharingAppNamesArgs = {
  args: SearchContentSharingAppNamesArgs;
  distinct_on?: Maybe<Array<ViewContentSharingAppsByNameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppsByNameOrderBy>>;
  where?: Maybe<ViewContentSharingAppsByNameBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchContentSharingAppNamesAggregateArgs = {
  args: SearchContentSharingAppNamesArgs;
  distinct_on?: Maybe<Array<ViewContentSharingAppsByNameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppsByNameOrderBy>>;
  where?: Maybe<ViewContentSharingAppsByNameBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchContentSharingTypesArgs = {
  args: SearchContentSharingTypesArgs;
  distinct_on?: Maybe<Array<ViewContentSharingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingTypesOrderBy>>;
  where?: Maybe<ViewContentSharingTypesBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchContentSharingTypesAggregateArgs = {
  args: SearchContentSharingTypesArgs;
  distinct_on?: Maybe<Array<ViewContentSharingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingTypesOrderBy>>;
  where?: Maybe<ViewContentSharingTypesBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchMostCollaborativeSpacesArgs = {
  args: SearchMostCollaborativeSpacesArgs;
  distinct_on?: Maybe<Array<ViewMostCollaborativeSpacesResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMostCollaborativeSpacesResultOrderBy>>;
  where?: Maybe<ViewMostCollaborativeSpacesResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootSearchUserDevicesArgs = {
  args: SearchUserDevicesArgs;
  distinct_on?: Maybe<Array<ViewUserDevicesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUserDevicesOrderBy>>;
  where?: Maybe<ViewUserDevicesBoolExp>;
};


/** subscription root */
export type SubscriptionRootSessionsPerUserArgs = {
  args: SessionsPerUserArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootSolsticeVersionsArgs = {
  distinct_on?: Maybe<Array<ViewSolsticeVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSolsticeVersionsOrderBy>>;
  where?: Maybe<ViewSolsticeVersionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootUniqueUsersCountArgs = {
  args: UniqueUsersCountArgs;
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootUsageByRoomTypeArgs = {
  args: UsageByRoomTypeArgs;
  distinct_on?: Maybe<Array<ViewUsageByRoomTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsageByRoomTypeOrderBy>>;
  where?: Maybe<ViewUsageByRoomTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootUsageByRoomTypeAggregateArgs = {
  args: UsageByRoomTypeArgs;
  distinct_on?: Maybe<Array<ViewUsageByRoomTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsageByRoomTypeOrderBy>>;
  where?: Maybe<ViewUsageByRoomTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootUsbProductHistoryArgs = {
  args: UsbProductHistoryArgs;
  distinct_on?: Maybe<Array<ViewUsbProductHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsbProductHistoryOrderBy>>;
  where?: Maybe<ViewUsbProductHistoryBoolExp>;
};


/** subscription root */
export type SubscriptionRootUsbProductHistoryAggregateArgs = {
  args: UsbProductHistoryArgs;
  distinct_on?: Maybe<Array<ViewUsbProductHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsbProductHistoryOrderBy>>;
  where?: Maybe<ViewUsbProductHistoryBoolExp>;
};


/** subscription root */
export type SubscriptionRootUserCollaborationArgs = {
  args: UserCollaborationArgs;
  distinct_on?: Maybe<Array<ViewUserCollaborationResultsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUserCollaborationResultsOrderBy>>;
  where?: Maybe<ViewUserCollaborationResultsBoolExp>;
};


/** subscription root */
export type SubscriptionRootActiveLearningFlagsArgs = {
  distinct_on?: Maybe<Array<ActiveLearningFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ActiveLearningFlagsOrderBy>>;
  where?: Maybe<ActiveLearningFlagsBoolExp>;
};


/** subscription root */
export type SubscriptionRootActiveLearningFlagsAggregateArgs = {
  distinct_on?: Maybe<Array<ActiveLearningFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ActiveLearningFlagsOrderBy>>;
  where?: Maybe<ActiveLearningFlagsBoolExp>;
};


/** subscription root */
export type SubscriptionRootActiveLearningFlagsByPkArgs = {
  org_id: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootOrgAddressesAggregateArgs = {
  distinct_on?: Maybe<Array<OrgAddressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgAddressesOrderBy>>;
  where?: Maybe<OrgAddressesBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsArgs = {
  distinct_on?: Maybe<Array<OrgsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsOrderBy>>;
  where?: Maybe<OrgsBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootOrgsCategoriesArgs = {
  distinct_on?: Maybe<Array<OrgsCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsCategoriesOrderBy>>;
  where?: Maybe<OrgsCategoriesBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsCategoriesByPkArgs = {
  category: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootOrgsCategoriesOptionsArgs = {
  distinct_on?: Maybe<Array<OrgsCategoriesOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsCategoriesOptionsOrderBy>>;
  where?: Maybe<OrgsCategoriesOptionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsCategoriesOptionsByPkArgs = {
  category: Scalars['String'];
  option: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootOrgsDisplaysCategoriesArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysCategoriesOrderBy>>;
  where?: Maybe<OrgsDisplaysCategoriesBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsDisplaysCategoriesByPkArgs = {
  category: Scalars['String'];
  display: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootOrgsDisplaysLocationsArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysLocationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysLocationsOrderBy>>;
  where?: Maybe<OrgsDisplaysLocationsBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsDisplaysLocationsByPkArgs = {
  display: Scalars['String'];
  org: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootOrgsDisplaysTagsArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysTagsOrderBy>>;
  where?: Maybe<OrgsDisplaysTagsBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsDisplaysTagsAggregateArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysTagsOrderBy>>;
  where?: Maybe<OrgsDisplaysTagsBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsDisplaysTagsByPkArgs = {
  display: Scalars['String'];
  org: Scalars['String'];
  partition: Scalars['bigint'];
  tag: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootOrgsFlagsArgs = {
  distinct_on?: Maybe<Array<OrgsFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsFlagsOrderBy>>;
  where?: Maybe<OrgsFlagsBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsUsersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersOrderBy>>;
  where?: Maybe<OrgsUsersBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsUsersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  user: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootOrgsUsersJoyridesArgs = {
  distinct_on?: Maybe<Array<OrgsUsersJoyridesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersJoyridesOrderBy>>;
  where?: Maybe<OrgsUsersJoyridesBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsUsersJoyridesByPkArgs = {
  joyride: Scalars['String'];
  org: Scalars['String'];
  user: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootOrgsUsersRebootedFiltersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersRebootedFiltersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersRebootedFiltersOrderBy>>;
  where?: Maybe<OrgsUsersRebootedFiltersBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsUsersRebootedFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  rebooted_filter: Scalars['String'];
  user: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootOrgsUsersTemplateFiltersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersTemplateFiltersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersTemplateFiltersOrderBy>>;
  where?: Maybe<OrgsUsersTemplateFiltersBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsUsersTemplateFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  template_filter: Scalars['String'];
  user: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootOrgsUsersUnreachableFiltersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersUnreachableFiltersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersUnreachableFiltersOrderBy>>;
  where?: Maybe<OrgsUsersUnreachableFiltersBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsUsersUnreachableFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  unreachable_filter: Scalars['String'];
  user: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootOrgsUsersUsbPlugFiltersArgs = {
  distinct_on?: Maybe<Array<OrgsUsersUsbPlugFiltersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsUsersUsbPlugFiltersOrderBy>>;
  where?: Maybe<OrgsUsersUsbPlugFiltersBoolExp>;
};


/** subscription root */
export type SubscriptionRootOrgsUsersUsbPlugFiltersByPkArgs = {
  org: Scalars['String'];
  partition: Scalars['bigint'];
  usb_plug_filter: Scalars['String'];
  user: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootViewActivityUserMobilityResultArgs = {
  distinct_on?: Maybe<Array<ViewActivityUserMobilityResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewActivityUserMobilityResultOrderBy>>;
  where?: Maybe<ViewActivityUserMobilityResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewAlertsChartResultArgs = {
  distinct_on?: Maybe<Array<ViewAlertsChartResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewAlertsChartResultOrderBy>>;
  where?: Maybe<ViewAlertsChartResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewAlertsChartResultAggregateArgs = {
  distinct_on?: Maybe<Array<ViewAlertsChartResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewAlertsChartResultOrderBy>>;
  where?: Maybe<ViewAlertsChartResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewClientScreenResolutionsArgs = {
  distinct_on?: Maybe<Array<ViewClientScreenResolutionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientScreenResolutionsOrderBy>>;
  where?: Maybe<ViewClientScreenResolutionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewClientScreenResolutionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewClientScreenResolutionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientScreenResolutionsOrderBy>>;
  where?: Maybe<ViewClientScreenResolutionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewClientVersionsArgs = {
  distinct_on?: Maybe<Array<ViewClientVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientVersionsOrderBy>>;
  where?: Maybe<ViewClientVersionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewClientVersionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewClientVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewClientVersionsOrderBy>>;
  where?: Maybe<ViewClientVersionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewConferenceVendorResultArgs = {
  distinct_on?: Maybe<Array<ViewConferenceVendorResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewConferenceVendorResultOrderBy>>;
  where?: Maybe<ViewConferenceVendorResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewContentSharingAppGroupsArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingAppGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppGroupsOrderBy>>;
  where?: Maybe<ViewContentSharingAppGroupsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewContentSharingAppGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingAppGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppGroupsOrderBy>>;
  where?: Maybe<ViewContentSharingAppGroupsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewContentSharingAppsByNameArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingAppsByNameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppsByNameOrderBy>>;
  where?: Maybe<ViewContentSharingAppsByNameBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewContentSharingAppsByNameAggregateArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingAppsByNameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingAppsByNameOrderBy>>;
  where?: Maybe<ViewContentSharingAppsByNameBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewContentSharingTypesArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingTypesOrderBy>>;
  where?: Maybe<ViewContentSharingTypesBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewContentSharingTypesAggregateArgs = {
  distinct_on?: Maybe<Array<ViewContentSharingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewContentSharingTypesOrderBy>>;
  where?: Maybe<ViewContentSharingTypesBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewCurrentUsbProductsArgs = {
  distinct_on?: Maybe<Array<ViewCurrentUsbProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewCurrentUsbProductsOrderBy>>;
  where?: Maybe<ViewCurrentUsbProductsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewCurrentUsbProductsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewCurrentUsbProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewCurrentUsbProductsOrderBy>>;
  where?: Maybe<ViewCurrentUsbProductsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDateAggregationResultArgs = {
  distinct_on?: Maybe<Array<ViewDateAggregationResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDateAggregationResultOrderBy>>;
  where?: Maybe<ViewDateAggregationResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplayAlertsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplayAlertsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplayAlertsOrderBy>>;
  where?: Maybe<ViewDisplayAlertsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysCurrentUsbConnectionsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsOrderBy>>;
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysCurrentUsbConnectionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsOrderBy>>;
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysDeploymentArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysDeploymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysDeploymentOrderBy>>;
  where?: Maybe<ViewDisplaysDeploymentBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysDeploymentAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysDeploymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysDeploymentOrderBy>>;
  where?: Maybe<ViewDisplaysDeploymentBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysDiscoverRealtimeArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysDiscoverRealtimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysDiscoverRealtimeOrderBy>>;
  where?: Maybe<ViewDisplaysDiscoverRealtimeBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysDiscoverRealtimeAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysDiscoverRealtimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysDiscoverRealtimeOrderBy>>;
  where?: Maybe<ViewDisplaysDiscoverRealtimeBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysHostsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysHostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysHostsOrderBy>>;
  where?: Maybe<ViewDisplaysHostsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysHostsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysHostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysHostsOrderBy>>;
  where?: Maybe<ViewDisplaysHostsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysManagementStatusArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysManagementStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysManagementStatusOrderBy>>;
  where?: Maybe<ViewDisplaysManagementStatusBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysRealtimeArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysRealtimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysRealtimeOrderBy>>;
  where?: Maybe<ViewDisplaysRealtimeBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysRealtimeAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysRealtimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysRealtimeOrderBy>>;
  where?: Maybe<ViewDisplaysRealtimeBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysSubscriptionsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysSubscriptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysSubscriptionsOrderBy>>;
  where?: Maybe<ViewDisplaysSubscriptionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysUpdateabilityArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysUpdateabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysUpdateabilityOrderBy>>;
  where?: Maybe<ViewDisplaysUpdateabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysUpdateabilityAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysUpdateabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysUpdateabilityOrderBy>>;
  where?: Maybe<ViewDisplaysUpdateabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysWellnessConfigArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysWellnessConfigSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysWellnessConfigOrderBy>>;
  where?: Maybe<ViewDisplaysWellnessConfigBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewDisplaysWellnessConfigAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysWellnessConfigSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysWellnessConfigOrderBy>>;
  where?: Maybe<ViewDisplaysWellnessConfigBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewFloorCountArgs = {
  distinct_on?: Maybe<Array<ViewFloorCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewFloorCountOrderBy>>;
  where?: Maybe<ViewFloorCountBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewHomePageMapArgs = {
  distinct_on?: Maybe<Array<ViewHomePageMapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewHomePageMapOrderBy>>;
  where?: Maybe<ViewHomePageMapBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewHybridMeetingAggregationResultArgs = {
  distinct_on?: Maybe<Array<ViewHybridMeetingAggregationResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewHybridMeetingAggregationResultOrderBy>>;
  where?: Maybe<ViewHybridMeetingAggregationResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewMeetingsArgs = {
  distinct_on?: Maybe<Array<ViewMeetingsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMeetingsOrderBy>>;
  where?: Maybe<ViewMeetingsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewMeetingsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewMeetingsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMeetingsOrderBy>>;
  where?: Maybe<ViewMeetingsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewMostCollaborativeSpacesResultArgs = {
  distinct_on?: Maybe<Array<ViewMostCollaborativeSpacesResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMostCollaborativeSpacesResultOrderBy>>;
  where?: Maybe<ViewMostCollaborativeSpacesResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewSdsArgs = {
  distinct_on?: Maybe<Array<ViewSdsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSdsOrderBy>>;
  where?: Maybe<ViewSdsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewSdsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewSdsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSdsOrderBy>>;
  where?: Maybe<ViewSdsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewSingleResultArgs = {
  distinct_on?: Maybe<Array<ViewSingleResultSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSingleResultOrderBy>>;
  where?: Maybe<ViewSingleResultBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewSolsticeVersionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewSolsticeVersionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewSolsticeVersionsOrderBy>>;
  where?: Maybe<ViewSolsticeVersionsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewUsageByRoomTypeArgs = {
  distinct_on?: Maybe<Array<ViewUsageByRoomTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsageByRoomTypeOrderBy>>;
  where?: Maybe<ViewUsageByRoomTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewUsageByRoomTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ViewUsageByRoomTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsageByRoomTypeOrderBy>>;
  where?: Maybe<ViewUsageByRoomTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewUsbProductHistoryArgs = {
  distinct_on?: Maybe<Array<ViewUsbProductHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsbProductHistoryOrderBy>>;
  where?: Maybe<ViewUsbProductHistoryBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewUsbProductHistoryAggregateArgs = {
  distinct_on?: Maybe<Array<ViewUsbProductHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsbProductHistoryOrderBy>>;
  where?: Maybe<ViewUsbProductHistoryBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewUserCollaborationResultsArgs = {
  distinct_on?: Maybe<Array<ViewUserCollaborationResultsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUserCollaborationResultsOrderBy>>;
  where?: Maybe<ViewUserCollaborationResultsBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewUserDevicesArgs = {
  distinct_on?: Maybe<Array<ViewUserDevicesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUserDevicesOrderBy>>;
  where?: Maybe<ViewUserDevicesBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewUsersArgs = {
  distinct_on?: Maybe<Array<ViewUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsersOrderBy>>;
  where?: Maybe<ViewUsersBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewUsersAggregateArgs = {
  distinct_on?: Maybe<Array<ViewUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsersOrderBy>>;
  where?: Maybe<ViewUsersBoolExp>;
};


/** subscription root */
export type SubscriptionRootViewUsersPermissionsArgs = {
  distinct_on?: Maybe<Array<ViewUsersPermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsersPermissionsOrderBy>>;
  where?: Maybe<ViewUsersPermissionsBoolExp>;
};


/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "view_activity_user_mobility_result" */
export type ViewActivityUserMobilityResult = {
  __typename?: 'view_activity_user_mobility_result';
  count?: Maybe<Scalars['bigint']>;
  group?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  sessions?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "view_activity_user_mobility_result". All fields are combined with a logical 'AND'. */
export type ViewActivityUserMobilityResultBoolExp = {
  _and?: Maybe<Array<Maybe<ViewActivityUserMobilityResultBoolExp>>>;
  _not?: Maybe<ViewActivityUserMobilityResultBoolExp>;
  _or?: Maybe<Array<Maybe<ViewActivityUserMobilityResultBoolExp>>>;
  count?: Maybe<BigintComparisonExp>;
  group?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  sessions?: Maybe<BigintComparisonExp>;
};

/** ordering options when selecting data from "view_activity_user_mobility_result" */
export type ViewActivityUserMobilityResultOrderBy = {
  count?: Maybe<OrderBy>;
  group?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  sessions?: Maybe<OrderBy>;
};

/** select columns of table "view_activity_user_mobility_result" */
export enum ViewActivityUserMobilityResultSelectColumn {
  /** column name */
  Count = 'count',
  /** column name */
  Group = 'group',
  /** column name */
  Org = 'org',
  /** column name */
  Sessions = 'sessions'
}

/** columns and relationships of "view_alerts_chart_result" */
export type ViewAlertsChartResult = {
  __typename?: 'view_alerts_chart_result';
  alert_type?: Maybe<Scalars['String']>;
  alerts?: Maybe<Scalars['bigint']>;
  dt?: Maybe<Scalars['timestamp']>;
  org_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "view_alerts_chart_result" */
export type ViewAlertsChartResultAggregate = {
  __typename?: 'view_alerts_chart_result_aggregate';
  aggregate?: Maybe<ViewAlertsChartResultAggregateFields>;
  nodes: Array<ViewAlertsChartResult>;
};

/** aggregate fields of "view_alerts_chart_result" */
export type ViewAlertsChartResultAggregateFields = {
  __typename?: 'view_alerts_chart_result_aggregate_fields';
  avg?: Maybe<ViewAlertsChartResultAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewAlertsChartResultMaxFields>;
  min?: Maybe<ViewAlertsChartResultMinFields>;
  stddev?: Maybe<ViewAlertsChartResultStddevFields>;
  stddev_pop?: Maybe<ViewAlertsChartResultStddevPopFields>;
  stddev_samp?: Maybe<ViewAlertsChartResultStddevSampFields>;
  sum?: Maybe<ViewAlertsChartResultSumFields>;
  var_pop?: Maybe<ViewAlertsChartResultVarPopFields>;
  var_samp?: Maybe<ViewAlertsChartResultVarSampFields>;
  variance?: Maybe<ViewAlertsChartResultVarianceFields>;
};


/** aggregate fields of "view_alerts_chart_result" */
export type ViewAlertsChartResultAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewAlertsChartResultSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_alerts_chart_result" */
export type ViewAlertsChartResultAggregateOrderBy = {
  avg?: Maybe<ViewAlertsChartResultAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewAlertsChartResultMaxOrderBy>;
  min?: Maybe<ViewAlertsChartResultMinOrderBy>;
  stddev?: Maybe<ViewAlertsChartResultStddevOrderBy>;
  stddev_pop?: Maybe<ViewAlertsChartResultStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewAlertsChartResultStddevSampOrderBy>;
  sum?: Maybe<ViewAlertsChartResultSumOrderBy>;
  var_pop?: Maybe<ViewAlertsChartResultVarPopOrderBy>;
  var_samp?: Maybe<ViewAlertsChartResultVarSampOrderBy>;
  variance?: Maybe<ViewAlertsChartResultVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewAlertsChartResultAvgFields = {
  __typename?: 'view_alerts_chart_result_avg_fields';
  alerts?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultAvgOrderBy = {
  alerts?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_alerts_chart_result". All fields are combined with a logical 'AND'. */
export type ViewAlertsChartResultBoolExp = {
  _and?: Maybe<Array<Maybe<ViewAlertsChartResultBoolExp>>>;
  _not?: Maybe<ViewAlertsChartResultBoolExp>;
  _or?: Maybe<Array<Maybe<ViewAlertsChartResultBoolExp>>>;
  alert_type?: Maybe<StringComparisonExp>;
  alerts?: Maybe<BigintComparisonExp>;
  dt?: Maybe<TimestampComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewAlertsChartResultMaxFields = {
  __typename?: 'view_alerts_chart_result_max_fields';
  alert_type?: Maybe<Scalars['String']>;
  alerts?: Maybe<Scalars['bigint']>;
  dt?: Maybe<Scalars['timestamp']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultMaxOrderBy = {
  alert_type?: Maybe<OrderBy>;
  alerts?: Maybe<OrderBy>;
  dt?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewAlertsChartResultMinFields = {
  __typename?: 'view_alerts_chart_result_min_fields';
  alert_type?: Maybe<Scalars['String']>;
  alerts?: Maybe<Scalars['bigint']>;
  dt?: Maybe<Scalars['timestamp']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultMinOrderBy = {
  alert_type?: Maybe<OrderBy>;
  alerts?: Maybe<OrderBy>;
  dt?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_alerts_chart_result" */
export type ViewAlertsChartResultOrderBy = {
  alert_type?: Maybe<OrderBy>;
  alerts?: Maybe<OrderBy>;
  dt?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** select columns of table "view_alerts_chart_result" */
export enum ViewAlertsChartResultSelectColumn {
  /** column name */
  AlertType = 'alert_type',
  /** column name */
  Alerts = 'alerts',
  /** column name */
  Dt = 'dt',
  /** column name */
  OrgId = 'org_id'
}

/** aggregate stddev on columns */
export type ViewAlertsChartResultStddevFields = {
  __typename?: 'view_alerts_chart_result_stddev_fields';
  alerts?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultStddevOrderBy = {
  alerts?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewAlertsChartResultStddevPopFields = {
  __typename?: 'view_alerts_chart_result_stddev_pop_fields';
  alerts?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultStddevPopOrderBy = {
  alerts?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewAlertsChartResultStddevSampFields = {
  __typename?: 'view_alerts_chart_result_stddev_samp_fields';
  alerts?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultStddevSampOrderBy = {
  alerts?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewAlertsChartResultSumFields = {
  __typename?: 'view_alerts_chart_result_sum_fields';
  alerts?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultSumOrderBy = {
  alerts?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewAlertsChartResultVarPopFields = {
  __typename?: 'view_alerts_chart_result_var_pop_fields';
  alerts?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultVarPopOrderBy = {
  alerts?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewAlertsChartResultVarSampFields = {
  __typename?: 'view_alerts_chart_result_var_samp_fields';
  alerts?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultVarSampOrderBy = {
  alerts?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewAlertsChartResultVarianceFields = {
  __typename?: 'view_alerts_chart_result_variance_fields';
  alerts?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_alerts_chart_result" */
export type ViewAlertsChartResultVarianceOrderBy = {
  alerts?: Maybe<OrderBy>;
};

/** columns and relationships of "view_categories" */
export type ViewCategories = {
  __typename?: 'view_categories';
  category_type?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  is_auto_generated?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  options: Array<ViewOptions>;
  /** An aggregated array relationship */
  options_aggregate: ViewOptionsAggregate;
  org_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "view_categories" */
export type ViewCategoriesOptionsArgs = {
  distinct_on?: Maybe<Array<ViewOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewOptionsOrderBy>>;
  where?: Maybe<ViewOptionsBoolExp>;
};


/** columns and relationships of "view_categories" */
export type ViewCategoriesOptionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewOptionsOrderBy>>;
  where?: Maybe<ViewOptionsBoolExp>;
};

/** aggregated selection of "view_categories" */
export type ViewCategoriesAggregate = {
  __typename?: 'view_categories_aggregate';
  aggregate?: Maybe<ViewCategoriesAggregateFields>;
  nodes: Array<ViewCategories>;
};

/** aggregate fields of "view_categories" */
export type ViewCategoriesAggregateFields = {
  __typename?: 'view_categories_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewCategoriesMaxFields>;
  min?: Maybe<ViewCategoriesMinFields>;
};


/** aggregate fields of "view_categories" */
export type ViewCategoriesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewCategoriesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_categories" */
export type ViewCategoriesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewCategoriesMaxOrderBy>;
  min?: Maybe<ViewCategoriesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_categories". All fields are combined with a logical 'AND'. */
export type ViewCategoriesBoolExp = {
  _and?: Maybe<Array<Maybe<ViewCategoriesBoolExp>>>;
  _not?: Maybe<ViewCategoriesBoolExp>;
  _or?: Maybe<Array<Maybe<ViewCategoriesBoolExp>>>;
  category_type?: Maybe<StringComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  is_auto_generated?: Maybe<BooleanComparisonExp>;
  options?: Maybe<ViewOptionsBoolExp>;
  org_id?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewCategoriesMaxFields = {
  __typename?: 'view_categories_max_fields';
  category_type?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_categories" */
export type ViewCategoriesMaxOrderBy = {
  category_type?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewCategoriesMinFields = {
  __typename?: 'view_categories_min_fields';
  category_type?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_categories" */
export type ViewCategoriesMinOrderBy = {
  category_type?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_categories" */
export type ViewCategoriesOrderBy = {
  category_type?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_auto_generated?: Maybe<OrderBy>;
  options_aggregate?: Maybe<ViewOptionsAggregateOrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** select columns of table "view_categories" */
export enum ViewCategoriesSelectColumn {
  /** column name */
  CategoryType = 'category_type',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsAutoGenerated = 'is_auto_generated',
  /** column name */
  OrgId = 'org_id'
}

/** columns and relationships of "view_client_screen_resolutions" */
export type ViewClientScreenResolutions = {
  __typename?: 'view_client_screen_resolutions';
  org?: Maybe<Scalars['String']>;
  screen_height?: Maybe<Scalars['bigint']>;
  screen_width?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsAggregate = {
  __typename?: 'view_client_screen_resolutions_aggregate';
  aggregate?: Maybe<ViewClientScreenResolutionsAggregateFields>;
  nodes: Array<ViewClientScreenResolutions>;
};

/** aggregate fields of "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsAggregateFields = {
  __typename?: 'view_client_screen_resolutions_aggregate_fields';
  avg?: Maybe<ViewClientScreenResolutionsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewClientScreenResolutionsMaxFields>;
  min?: Maybe<ViewClientScreenResolutionsMinFields>;
  stddev?: Maybe<ViewClientScreenResolutionsStddevFields>;
  stddev_pop?: Maybe<ViewClientScreenResolutionsStddevPopFields>;
  stddev_samp?: Maybe<ViewClientScreenResolutionsStddevSampFields>;
  sum?: Maybe<ViewClientScreenResolutionsSumFields>;
  var_pop?: Maybe<ViewClientScreenResolutionsVarPopFields>;
  var_samp?: Maybe<ViewClientScreenResolutionsVarSampFields>;
  variance?: Maybe<ViewClientScreenResolutionsVarianceFields>;
};


/** aggregate fields of "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewClientScreenResolutionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsAggregateOrderBy = {
  avg?: Maybe<ViewClientScreenResolutionsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewClientScreenResolutionsMaxOrderBy>;
  min?: Maybe<ViewClientScreenResolutionsMinOrderBy>;
  stddev?: Maybe<ViewClientScreenResolutionsStddevOrderBy>;
  stddev_pop?: Maybe<ViewClientScreenResolutionsStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewClientScreenResolutionsStddevSampOrderBy>;
  sum?: Maybe<ViewClientScreenResolutionsSumOrderBy>;
  var_pop?: Maybe<ViewClientScreenResolutionsVarPopOrderBy>;
  var_samp?: Maybe<ViewClientScreenResolutionsVarSampOrderBy>;
  variance?: Maybe<ViewClientScreenResolutionsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewClientScreenResolutionsAvgFields = {
  __typename?: 'view_client_screen_resolutions_avg_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsAvgOrderBy = {
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_client_screen_resolutions". All fields are combined with a logical 'AND'. */
export type ViewClientScreenResolutionsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewClientScreenResolutionsBoolExp>>>;
  _not?: Maybe<ViewClientScreenResolutionsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewClientScreenResolutionsBoolExp>>>;
  org?: Maybe<StringComparisonExp>;
  screen_height?: Maybe<BigintComparisonExp>;
  screen_width?: Maybe<BigintComparisonExp>;
  total?: Maybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type ViewClientScreenResolutionsMaxFields = {
  __typename?: 'view_client_screen_resolutions_max_fields';
  org?: Maybe<Scalars['String']>;
  screen_height?: Maybe<Scalars['bigint']>;
  screen_width?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsMaxOrderBy = {
  org?: Maybe<OrderBy>;
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewClientScreenResolutionsMinFields = {
  __typename?: 'view_client_screen_resolutions_min_fields';
  org?: Maybe<Scalars['String']>;
  screen_height?: Maybe<Scalars['bigint']>;
  screen_width?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsMinOrderBy = {
  org?: Maybe<OrderBy>;
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsOrderBy = {
  org?: Maybe<OrderBy>;
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** select columns of table "view_client_screen_resolutions" */
export enum ViewClientScreenResolutionsSelectColumn {
  /** column name */
  Org = 'org',
  /** column name */
  ScreenHeight = 'screen_height',
  /** column name */
  ScreenWidth = 'screen_width',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type ViewClientScreenResolutionsStddevFields = {
  __typename?: 'view_client_screen_resolutions_stddev_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsStddevOrderBy = {
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewClientScreenResolutionsStddevPopFields = {
  __typename?: 'view_client_screen_resolutions_stddev_pop_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsStddevPopOrderBy = {
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewClientScreenResolutionsStddevSampFields = {
  __typename?: 'view_client_screen_resolutions_stddev_samp_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsStddevSampOrderBy = {
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewClientScreenResolutionsSumFields = {
  __typename?: 'view_client_screen_resolutions_sum_fields';
  screen_height?: Maybe<Scalars['bigint']>;
  screen_width?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsSumOrderBy = {
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewClientScreenResolutionsVarPopFields = {
  __typename?: 'view_client_screen_resolutions_var_pop_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsVarPopOrderBy = {
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewClientScreenResolutionsVarSampFields = {
  __typename?: 'view_client_screen_resolutions_var_samp_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsVarSampOrderBy = {
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewClientScreenResolutionsVarianceFields = {
  __typename?: 'view_client_screen_resolutions_variance_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_client_screen_resolutions" */
export type ViewClientScreenResolutionsVarianceOrderBy = {
  screen_height?: Maybe<OrderBy>;
  screen_width?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** columns and relationships of "view_client_versions" */
export type ViewClientVersions = {
  __typename?: 'view_client_versions';
  client_version?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "view_client_versions" */
export type ViewClientVersionsAggregate = {
  __typename?: 'view_client_versions_aggregate';
  aggregate?: Maybe<ViewClientVersionsAggregateFields>;
  nodes: Array<ViewClientVersions>;
};

/** aggregate fields of "view_client_versions" */
export type ViewClientVersionsAggregateFields = {
  __typename?: 'view_client_versions_aggregate_fields';
  avg?: Maybe<ViewClientVersionsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewClientVersionsMaxFields>;
  min?: Maybe<ViewClientVersionsMinFields>;
  stddev?: Maybe<ViewClientVersionsStddevFields>;
  stddev_pop?: Maybe<ViewClientVersionsStddevPopFields>;
  stddev_samp?: Maybe<ViewClientVersionsStddevSampFields>;
  sum?: Maybe<ViewClientVersionsSumFields>;
  var_pop?: Maybe<ViewClientVersionsVarPopFields>;
  var_samp?: Maybe<ViewClientVersionsVarSampFields>;
  variance?: Maybe<ViewClientVersionsVarianceFields>;
};


/** aggregate fields of "view_client_versions" */
export type ViewClientVersionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewClientVersionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_client_versions" */
export type ViewClientVersionsAggregateOrderBy = {
  avg?: Maybe<ViewClientVersionsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewClientVersionsMaxOrderBy>;
  min?: Maybe<ViewClientVersionsMinOrderBy>;
  stddev?: Maybe<ViewClientVersionsStddevOrderBy>;
  stddev_pop?: Maybe<ViewClientVersionsStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewClientVersionsStddevSampOrderBy>;
  sum?: Maybe<ViewClientVersionsSumOrderBy>;
  var_pop?: Maybe<ViewClientVersionsVarPopOrderBy>;
  var_samp?: Maybe<ViewClientVersionsVarSampOrderBy>;
  variance?: Maybe<ViewClientVersionsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewClientVersionsAvgFields = {
  __typename?: 'view_client_versions_avg_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_client_versions" */
export type ViewClientVersionsAvgOrderBy = {
  total?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_client_versions". All fields are combined with a logical 'AND'. */
export type ViewClientVersionsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewClientVersionsBoolExp>>>;
  _not?: Maybe<ViewClientVersionsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewClientVersionsBoolExp>>>;
  client_version?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  total?: Maybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type ViewClientVersionsMaxFields = {
  __typename?: 'view_client_versions_max_fields';
  client_version?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "view_client_versions" */
export type ViewClientVersionsMaxOrderBy = {
  client_version?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewClientVersionsMinFields = {
  __typename?: 'view_client_versions_min_fields';
  client_version?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "view_client_versions" */
export type ViewClientVersionsMinOrderBy = {
  client_version?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_client_versions" */
export type ViewClientVersionsOrderBy = {
  client_version?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** select columns of table "view_client_versions" */
export enum ViewClientVersionsSelectColumn {
  /** column name */
  ClientVersion = 'client_version',
  /** column name */
  Org = 'org',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type ViewClientVersionsStddevFields = {
  __typename?: 'view_client_versions_stddev_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_client_versions" */
export type ViewClientVersionsStddevOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewClientVersionsStddevPopFields = {
  __typename?: 'view_client_versions_stddev_pop_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_client_versions" */
export type ViewClientVersionsStddevPopOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewClientVersionsStddevSampFields = {
  __typename?: 'view_client_versions_stddev_samp_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_client_versions" */
export type ViewClientVersionsStddevSampOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewClientVersionsSumFields = {
  __typename?: 'view_client_versions_sum_fields';
  total?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_client_versions" */
export type ViewClientVersionsSumOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewClientVersionsVarPopFields = {
  __typename?: 'view_client_versions_var_pop_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_client_versions" */
export type ViewClientVersionsVarPopOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewClientVersionsVarSampFields = {
  __typename?: 'view_client_versions_var_samp_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_client_versions" */
export type ViewClientVersionsVarSampOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewClientVersionsVarianceFields = {
  __typename?: 'view_client_versions_variance_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_client_versions" */
export type ViewClientVersionsVarianceOrderBy = {
  total?: Maybe<OrderBy>;
};

/** columns and relationships of "view_conference_vendor_result" */
export type ViewConferenceVendorResult = {
  __typename?: 'view_conference_vendor_result';
  date?: Maybe<Scalars['timestamp']>;
  num_meetings?: Maybe<Scalars['bigint']>;
  num_pods?: Maybe<Scalars['bigint']>;
  org_id?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "view_conference_vendor_result". All fields are combined with a logical 'AND'. */
export type ViewConferenceVendorResultBoolExp = {
  _and?: Maybe<Array<Maybe<ViewConferenceVendorResultBoolExp>>>;
  _not?: Maybe<ViewConferenceVendorResultBoolExp>;
  _or?: Maybe<Array<Maybe<ViewConferenceVendorResultBoolExp>>>;
  date?: Maybe<TimestampComparisonExp>;
  num_meetings?: Maybe<BigintComparisonExp>;
  num_pods?: Maybe<BigintComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
  vendor?: Maybe<StringComparisonExp>;
};

/** ordering options when selecting data from "view_conference_vendor_result" */
export type ViewConferenceVendorResultOrderBy = {
  date?: Maybe<OrderBy>;
  num_meetings?: Maybe<OrderBy>;
  num_pods?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
};

/** select columns of table "view_conference_vendor_result" */
export enum ViewConferenceVendorResultSelectColumn {
  /** column name */
  Date = 'date',
  /** column name */
  NumMeetings = 'num_meetings',
  /** column name */
  NumPods = 'num_pods',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Vendor = 'vendor'
}

/** columns and relationships of "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroups = {
  __typename?: 'view_content_sharing_app_groups';
  app_group?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsAggregate = {
  __typename?: 'view_content_sharing_app_groups_aggregate';
  aggregate?: Maybe<ViewContentSharingAppGroupsAggregateFields>;
  nodes: Array<ViewContentSharingAppGroups>;
};

/** aggregate fields of "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsAggregateFields = {
  __typename?: 'view_content_sharing_app_groups_aggregate_fields';
  avg?: Maybe<ViewContentSharingAppGroupsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewContentSharingAppGroupsMaxFields>;
  min?: Maybe<ViewContentSharingAppGroupsMinFields>;
  stddev?: Maybe<ViewContentSharingAppGroupsStddevFields>;
  stddev_pop?: Maybe<ViewContentSharingAppGroupsStddevPopFields>;
  stddev_samp?: Maybe<ViewContentSharingAppGroupsStddevSampFields>;
  sum?: Maybe<ViewContentSharingAppGroupsSumFields>;
  var_pop?: Maybe<ViewContentSharingAppGroupsVarPopFields>;
  var_samp?: Maybe<ViewContentSharingAppGroupsVarSampFields>;
  variance?: Maybe<ViewContentSharingAppGroupsVarianceFields>;
};


/** aggregate fields of "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewContentSharingAppGroupsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsAggregateOrderBy = {
  avg?: Maybe<ViewContentSharingAppGroupsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewContentSharingAppGroupsMaxOrderBy>;
  min?: Maybe<ViewContentSharingAppGroupsMinOrderBy>;
  stddev?: Maybe<ViewContentSharingAppGroupsStddevOrderBy>;
  stddev_pop?: Maybe<ViewContentSharingAppGroupsStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewContentSharingAppGroupsStddevSampOrderBy>;
  sum?: Maybe<ViewContentSharingAppGroupsSumOrderBy>;
  var_pop?: Maybe<ViewContentSharingAppGroupsVarPopOrderBy>;
  var_samp?: Maybe<ViewContentSharingAppGroupsVarSampOrderBy>;
  variance?: Maybe<ViewContentSharingAppGroupsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewContentSharingAppGroupsAvgFields = {
  __typename?: 'view_content_sharing_app_groups_avg_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsAvgOrderBy = {
  total?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_content_sharing_app_groups". All fields are combined with a logical 'AND'. */
export type ViewContentSharingAppGroupsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewContentSharingAppGroupsBoolExp>>>;
  _not?: Maybe<ViewContentSharingAppGroupsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewContentSharingAppGroupsBoolExp>>>;
  app_group?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  total?: Maybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type ViewContentSharingAppGroupsMaxFields = {
  __typename?: 'view_content_sharing_app_groups_max_fields';
  app_group?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsMaxOrderBy = {
  app_group?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewContentSharingAppGroupsMinFields = {
  __typename?: 'view_content_sharing_app_groups_min_fields';
  app_group?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsMinOrderBy = {
  app_group?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsOrderBy = {
  app_group?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** select columns of table "view_content_sharing_app_groups" */
export enum ViewContentSharingAppGroupsSelectColumn {
  /** column name */
  AppGroup = 'app_group',
  /** column name */
  Org = 'org',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type ViewContentSharingAppGroupsStddevFields = {
  __typename?: 'view_content_sharing_app_groups_stddev_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsStddevOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewContentSharingAppGroupsStddevPopFields = {
  __typename?: 'view_content_sharing_app_groups_stddev_pop_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsStddevPopOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewContentSharingAppGroupsStddevSampFields = {
  __typename?: 'view_content_sharing_app_groups_stddev_samp_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsStddevSampOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewContentSharingAppGroupsSumFields = {
  __typename?: 'view_content_sharing_app_groups_sum_fields';
  total?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsSumOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewContentSharingAppGroupsVarPopFields = {
  __typename?: 'view_content_sharing_app_groups_var_pop_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsVarPopOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewContentSharingAppGroupsVarSampFields = {
  __typename?: 'view_content_sharing_app_groups_var_samp_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsVarSampOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewContentSharingAppGroupsVarianceFields = {
  __typename?: 'view_content_sharing_app_groups_variance_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_content_sharing_app_groups" */
export type ViewContentSharingAppGroupsVarianceOrderBy = {
  total?: Maybe<OrderBy>;
};

/** columns and relationships of "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByName = {
  __typename?: 'view_content_sharing_apps_by_name';
  app_name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameAggregate = {
  __typename?: 'view_content_sharing_apps_by_name_aggregate';
  aggregate?: Maybe<ViewContentSharingAppsByNameAggregateFields>;
  nodes: Array<ViewContentSharingAppsByName>;
};

/** aggregate fields of "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameAggregateFields = {
  __typename?: 'view_content_sharing_apps_by_name_aggregate_fields';
  avg?: Maybe<ViewContentSharingAppsByNameAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewContentSharingAppsByNameMaxFields>;
  min?: Maybe<ViewContentSharingAppsByNameMinFields>;
  stddev?: Maybe<ViewContentSharingAppsByNameStddevFields>;
  stddev_pop?: Maybe<ViewContentSharingAppsByNameStddevPopFields>;
  stddev_samp?: Maybe<ViewContentSharingAppsByNameStddevSampFields>;
  sum?: Maybe<ViewContentSharingAppsByNameSumFields>;
  var_pop?: Maybe<ViewContentSharingAppsByNameVarPopFields>;
  var_samp?: Maybe<ViewContentSharingAppsByNameVarSampFields>;
  variance?: Maybe<ViewContentSharingAppsByNameVarianceFields>;
};


/** aggregate fields of "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewContentSharingAppsByNameSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameAggregateOrderBy = {
  avg?: Maybe<ViewContentSharingAppsByNameAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewContentSharingAppsByNameMaxOrderBy>;
  min?: Maybe<ViewContentSharingAppsByNameMinOrderBy>;
  stddev?: Maybe<ViewContentSharingAppsByNameStddevOrderBy>;
  stddev_pop?: Maybe<ViewContentSharingAppsByNameStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewContentSharingAppsByNameStddevSampOrderBy>;
  sum?: Maybe<ViewContentSharingAppsByNameSumOrderBy>;
  var_pop?: Maybe<ViewContentSharingAppsByNameVarPopOrderBy>;
  var_samp?: Maybe<ViewContentSharingAppsByNameVarSampOrderBy>;
  variance?: Maybe<ViewContentSharingAppsByNameVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewContentSharingAppsByNameAvgFields = {
  __typename?: 'view_content_sharing_apps_by_name_avg_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameAvgOrderBy = {
  total?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_content_sharing_apps_by_name". All fields are combined with a logical 'AND'. */
export type ViewContentSharingAppsByNameBoolExp = {
  _and?: Maybe<Array<Maybe<ViewContentSharingAppsByNameBoolExp>>>;
  _not?: Maybe<ViewContentSharingAppsByNameBoolExp>;
  _or?: Maybe<Array<Maybe<ViewContentSharingAppsByNameBoolExp>>>;
  app_name?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  total?: Maybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type ViewContentSharingAppsByNameMaxFields = {
  __typename?: 'view_content_sharing_apps_by_name_max_fields';
  app_name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameMaxOrderBy = {
  app_name?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewContentSharingAppsByNameMinFields = {
  __typename?: 'view_content_sharing_apps_by_name_min_fields';
  app_name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameMinOrderBy = {
  app_name?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameOrderBy = {
  app_name?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** select columns of table "view_content_sharing_apps_by_name" */
export enum ViewContentSharingAppsByNameSelectColumn {
  /** column name */
  AppName = 'app_name',
  /** column name */
  Org = 'org',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type ViewContentSharingAppsByNameStddevFields = {
  __typename?: 'view_content_sharing_apps_by_name_stddev_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameStddevOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewContentSharingAppsByNameStddevPopFields = {
  __typename?: 'view_content_sharing_apps_by_name_stddev_pop_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameStddevPopOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewContentSharingAppsByNameStddevSampFields = {
  __typename?: 'view_content_sharing_apps_by_name_stddev_samp_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameStddevSampOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewContentSharingAppsByNameSumFields = {
  __typename?: 'view_content_sharing_apps_by_name_sum_fields';
  total?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameSumOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewContentSharingAppsByNameVarPopFields = {
  __typename?: 'view_content_sharing_apps_by_name_var_pop_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameVarPopOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewContentSharingAppsByNameVarSampFields = {
  __typename?: 'view_content_sharing_apps_by_name_var_samp_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameVarSampOrderBy = {
  total?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewContentSharingAppsByNameVarianceFields = {
  __typename?: 'view_content_sharing_apps_by_name_variance_fields';
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_content_sharing_apps_by_name" */
export type ViewContentSharingAppsByNameVarianceOrderBy = {
  total?: Maybe<OrderBy>;
};

/** columns and relationships of "view_content_sharing_types" */
export type ViewContentSharingTypes = {
  __typename?: 'view_content_sharing_types';
  duration_in_hours?: Maybe<Scalars['float8']>;
  num_posts?: Maybe<Scalars['numeric']>;
  org?: Maybe<Scalars['String']>;
  share_type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "view_content_sharing_types" */
export type ViewContentSharingTypesAggregate = {
  __typename?: 'view_content_sharing_types_aggregate';
  aggregate?: Maybe<ViewContentSharingTypesAggregateFields>;
  nodes: Array<ViewContentSharingTypes>;
};

/** aggregate fields of "view_content_sharing_types" */
export type ViewContentSharingTypesAggregateFields = {
  __typename?: 'view_content_sharing_types_aggregate_fields';
  avg?: Maybe<ViewContentSharingTypesAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewContentSharingTypesMaxFields>;
  min?: Maybe<ViewContentSharingTypesMinFields>;
  stddev?: Maybe<ViewContentSharingTypesStddevFields>;
  stddev_pop?: Maybe<ViewContentSharingTypesStddevPopFields>;
  stddev_samp?: Maybe<ViewContentSharingTypesStddevSampFields>;
  sum?: Maybe<ViewContentSharingTypesSumFields>;
  var_pop?: Maybe<ViewContentSharingTypesVarPopFields>;
  var_samp?: Maybe<ViewContentSharingTypesVarSampFields>;
  variance?: Maybe<ViewContentSharingTypesVarianceFields>;
};


/** aggregate fields of "view_content_sharing_types" */
export type ViewContentSharingTypesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewContentSharingTypesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_content_sharing_types" */
export type ViewContentSharingTypesAggregateOrderBy = {
  avg?: Maybe<ViewContentSharingTypesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewContentSharingTypesMaxOrderBy>;
  min?: Maybe<ViewContentSharingTypesMinOrderBy>;
  stddev?: Maybe<ViewContentSharingTypesStddevOrderBy>;
  stddev_pop?: Maybe<ViewContentSharingTypesStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewContentSharingTypesStddevSampOrderBy>;
  sum?: Maybe<ViewContentSharingTypesSumOrderBy>;
  var_pop?: Maybe<ViewContentSharingTypesVarPopOrderBy>;
  var_samp?: Maybe<ViewContentSharingTypesVarSampOrderBy>;
  variance?: Maybe<ViewContentSharingTypesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewContentSharingTypesAvgFields = {
  __typename?: 'view_content_sharing_types_avg_fields';
  duration_in_hours?: Maybe<Scalars['Float']>;
  num_posts?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesAvgOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_content_sharing_types". All fields are combined with a logical 'AND'. */
export type ViewContentSharingTypesBoolExp = {
  _and?: Maybe<Array<Maybe<ViewContentSharingTypesBoolExp>>>;
  _not?: Maybe<ViewContentSharingTypesBoolExp>;
  _or?: Maybe<Array<Maybe<ViewContentSharingTypesBoolExp>>>;
  duration_in_hours?: Maybe<Float8ComparisonExp>;
  num_posts?: Maybe<NumericComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  share_type?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewContentSharingTypesMaxFields = {
  __typename?: 'view_content_sharing_types_max_fields';
  duration_in_hours?: Maybe<Scalars['float8']>;
  num_posts?: Maybe<Scalars['numeric']>;
  org?: Maybe<Scalars['String']>;
  share_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesMaxOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  share_type?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewContentSharingTypesMinFields = {
  __typename?: 'view_content_sharing_types_min_fields';
  duration_in_hours?: Maybe<Scalars['float8']>;
  num_posts?: Maybe<Scalars['numeric']>;
  org?: Maybe<Scalars['String']>;
  share_type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesMinOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  share_type?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_content_sharing_types" */
export type ViewContentSharingTypesOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  share_type?: Maybe<OrderBy>;
};

/** select columns of table "view_content_sharing_types" */
export enum ViewContentSharingTypesSelectColumn {
  /** column name */
  DurationInHours = 'duration_in_hours',
  /** column name */
  NumPosts = 'num_posts',
  /** column name */
  Org = 'org',
  /** column name */
  ShareType = 'share_type'
}

/** aggregate stddev on columns */
export type ViewContentSharingTypesStddevFields = {
  __typename?: 'view_content_sharing_types_stddev_fields';
  duration_in_hours?: Maybe<Scalars['Float']>;
  num_posts?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesStddevOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewContentSharingTypesStddevPopFields = {
  __typename?: 'view_content_sharing_types_stddev_pop_fields';
  duration_in_hours?: Maybe<Scalars['Float']>;
  num_posts?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesStddevPopOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewContentSharingTypesStddevSampFields = {
  __typename?: 'view_content_sharing_types_stddev_samp_fields';
  duration_in_hours?: Maybe<Scalars['Float']>;
  num_posts?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesStddevSampOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewContentSharingTypesSumFields = {
  __typename?: 'view_content_sharing_types_sum_fields';
  duration_in_hours?: Maybe<Scalars['float8']>;
  num_posts?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesSumOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewContentSharingTypesVarPopFields = {
  __typename?: 'view_content_sharing_types_var_pop_fields';
  duration_in_hours?: Maybe<Scalars['Float']>;
  num_posts?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesVarPopOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewContentSharingTypesVarSampFields = {
  __typename?: 'view_content_sharing_types_var_samp_fields';
  duration_in_hours?: Maybe<Scalars['Float']>;
  num_posts?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesVarSampOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewContentSharingTypesVarianceFields = {
  __typename?: 'view_content_sharing_types_variance_fields';
  duration_in_hours?: Maybe<Scalars['Float']>;
  num_posts?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_content_sharing_types" */
export type ViewContentSharingTypesVarianceOrderBy = {
  duration_in_hours?: Maybe<OrderBy>;
  num_posts?: Maybe<OrderBy>;
};

/** columns and relationships of "view_current_usb_products" */
export type ViewCurrentUsbProducts = {
  __typename?: 'view_current_usb_products';
  /** An array relationship */
  connections: Array<ViewDisplaysCurrentUsbConnections>;
  /** An aggregated array relationship */
  connections_aggregate: ViewDisplaysCurrentUsbConnectionsAggregate;
  display_count?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_name?: Maybe<Scalars['String']>;
};


/** columns and relationships of "view_current_usb_products" */
export type ViewCurrentUsbProductsConnectionsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsOrderBy>>;
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
};


/** columns and relationships of "view_current_usb_products" */
export type ViewCurrentUsbProductsConnectionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsOrderBy>>;
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
};

/** aggregated selection of "view_current_usb_products" */
export type ViewCurrentUsbProductsAggregate = {
  __typename?: 'view_current_usb_products_aggregate';
  aggregate?: Maybe<ViewCurrentUsbProductsAggregateFields>;
  nodes: Array<ViewCurrentUsbProducts>;
};

/** aggregate fields of "view_current_usb_products" */
export type ViewCurrentUsbProductsAggregateFields = {
  __typename?: 'view_current_usb_products_aggregate_fields';
  avg?: Maybe<ViewCurrentUsbProductsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewCurrentUsbProductsMaxFields>;
  min?: Maybe<ViewCurrentUsbProductsMinFields>;
  stddev?: Maybe<ViewCurrentUsbProductsStddevFields>;
  stddev_pop?: Maybe<ViewCurrentUsbProductsStddevPopFields>;
  stddev_samp?: Maybe<ViewCurrentUsbProductsStddevSampFields>;
  sum?: Maybe<ViewCurrentUsbProductsSumFields>;
  var_pop?: Maybe<ViewCurrentUsbProductsVarPopFields>;
  var_samp?: Maybe<ViewCurrentUsbProductsVarSampFields>;
  variance?: Maybe<ViewCurrentUsbProductsVarianceFields>;
};


/** aggregate fields of "view_current_usb_products" */
export type ViewCurrentUsbProductsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewCurrentUsbProductsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_current_usb_products" */
export type ViewCurrentUsbProductsAggregateOrderBy = {
  avg?: Maybe<ViewCurrentUsbProductsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewCurrentUsbProductsMaxOrderBy>;
  min?: Maybe<ViewCurrentUsbProductsMinOrderBy>;
  stddev?: Maybe<ViewCurrentUsbProductsStddevOrderBy>;
  stddev_pop?: Maybe<ViewCurrentUsbProductsStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewCurrentUsbProductsStddevSampOrderBy>;
  sum?: Maybe<ViewCurrentUsbProductsSumOrderBy>;
  var_pop?: Maybe<ViewCurrentUsbProductsVarPopOrderBy>;
  var_samp?: Maybe<ViewCurrentUsbProductsVarSampOrderBy>;
  variance?: Maybe<ViewCurrentUsbProductsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewCurrentUsbProductsAvgFields = {
  __typename?: 'view_current_usb_products_avg_fields';
  display_count?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsAvgOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_current_usb_products". All fields are combined with a logical 'AND'. */
export type ViewCurrentUsbProductsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewCurrentUsbProductsBoolExp>>>;
  _not?: Maybe<ViewCurrentUsbProductsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewCurrentUsbProductsBoolExp>>>;
  connections?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
  display_count?: Maybe<BigintComparisonExp>;
  product_id?: Maybe<BigintComparisonExp>;
  product_name?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewCurrentUsbProductsMaxFields = {
  __typename?: 'view_current_usb_products_max_fields';
  display_count?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsMaxOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewCurrentUsbProductsMinFields = {
  __typename?: 'view_current_usb_products_min_fields';
  display_count?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsMinOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_current_usb_products" */
export type ViewCurrentUsbProductsOrderBy = {
  connections_aggregate?: Maybe<ViewDisplaysCurrentUsbConnectionsAggregateOrderBy>;
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
};

/** select columns of table "view_current_usb_products" */
export enum ViewCurrentUsbProductsSelectColumn {
  /** column name */
  DisplayCount = 'display_count',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name'
}

/** aggregate stddev on columns */
export type ViewCurrentUsbProductsStddevFields = {
  __typename?: 'view_current_usb_products_stddev_fields';
  display_count?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsStddevOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewCurrentUsbProductsStddevPopFields = {
  __typename?: 'view_current_usb_products_stddev_pop_fields';
  display_count?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsStddevPopOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewCurrentUsbProductsStddevSampFields = {
  __typename?: 'view_current_usb_products_stddev_samp_fields';
  display_count?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsStddevSampOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewCurrentUsbProductsSumFields = {
  __typename?: 'view_current_usb_products_sum_fields';
  display_count?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsSumOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewCurrentUsbProductsVarPopFields = {
  __typename?: 'view_current_usb_products_var_pop_fields';
  display_count?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsVarPopOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewCurrentUsbProductsVarSampFields = {
  __typename?: 'view_current_usb_products_var_samp_fields';
  display_count?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsVarSampOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewCurrentUsbProductsVarianceFields = {
  __typename?: 'view_current_usb_products_variance_fields';
  display_count?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_current_usb_products" */
export type ViewCurrentUsbProductsVarianceOrderBy = {
  display_count?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** columns and relationships of "view_date_aggregation_result" */
export type ViewDateAggregationResult = {
  __typename?: 'view_date_aggregation_result';
  date?: Maybe<Scalars['timestamp']>;
  org_id?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['float8']>;
};

/** Boolean expression to filter rows from the table "view_date_aggregation_result". All fields are combined with a logical 'AND'. */
export type ViewDateAggregationResultBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDateAggregationResultBoolExp>>>;
  _not?: Maybe<ViewDateAggregationResultBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDateAggregationResultBoolExp>>>;
  date?: Maybe<TimestampComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
  total?: Maybe<Float8ComparisonExp>;
};

/** ordering options when selecting data from "view_date_aggregation_result" */
export type ViewDateAggregationResultOrderBy = {
  date?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** select columns of table "view_date_aggregation_result" */
export enum ViewDateAggregationResultSelectColumn {
  /** column name */
  Date = 'date',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Total = 'total'
}

/** columns and relationships of "view_display_alerts" */
export type ViewDisplayAlerts = {
  __typename?: 'view_display_alerts';
  alert_type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  display?: Maybe<ViewDisplays>;
  display_id?: Maybe<Scalars['String']>;
  is_email?: Maybe<Scalars['Boolean']>;
  org?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "view_display_alerts" */
export type ViewDisplayAlertsAggregate = {
  __typename?: 'view_display_alerts_aggregate';
  aggregate?: Maybe<ViewDisplayAlertsAggregateFields>;
  nodes: Array<ViewDisplayAlerts>;
};

/** aggregate fields of "view_display_alerts" */
export type ViewDisplayAlertsAggregateFields = {
  __typename?: 'view_display_alerts_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplayAlertsMaxFields>;
  min?: Maybe<ViewDisplayAlertsMinFields>;
};


/** aggregate fields of "view_display_alerts" */
export type ViewDisplayAlertsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplayAlertsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_display_alerts" */
export type ViewDisplayAlertsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplayAlertsMaxOrderBy>;
  min?: Maybe<ViewDisplayAlertsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_display_alerts". All fields are combined with a logical 'AND'. */
export type ViewDisplayAlertsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplayAlertsBoolExp>>>;
  _not?: Maybe<ViewDisplayAlertsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplayAlertsBoolExp>>>;
  alert_type?: Maybe<StringComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  display?: Maybe<ViewDisplaysBoolExp>;
  display_id?: Maybe<StringComparisonExp>;
  is_email?: Maybe<BooleanComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  time?: Maybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplayAlertsMaxFields = {
  __typename?: 'view_display_alerts_max_fields';
  alert_type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  display_id?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "view_display_alerts" */
export type ViewDisplayAlertsMaxOrderBy = {
  alert_type?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  time?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplayAlertsMinFields = {
  __typename?: 'view_display_alerts_min_fields';
  alert_type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  display_id?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "view_display_alerts" */
export type ViewDisplayAlertsMinOrderBy = {
  alert_type?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  time?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_display_alerts" */
export type ViewDisplayAlertsOrderBy = {
  alert_type?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  display?: Maybe<ViewDisplaysOrderBy>;
  display_id?: Maybe<OrderBy>;
  is_email?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  time?: Maybe<OrderBy>;
};

/** select columns of table "view_display_alerts" */
export enum ViewDisplayAlertsSelectColumn {
  /** column name */
  AlertType = 'alert_type',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayId = 'display_id',
  /** column name */
  IsEmail = 'is_email',
  /** column name */
  Org = 'org',
  /** column name */
  Time = 'time'
}

/** columns and relationships of "view_display_assigned_categories" */
export type ViewDisplayAssignedCategories = {
  __typename?: 'view_display_assigned_categories';
  /** An object relationship */
  category?: Maybe<ViewCategories>;
  category_id?: Maybe<Scalars['String']>;
  display_id?: Maybe<Scalars['String']>;
  is_assigned?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  option?: Maybe<ViewOptions>;
  option_id?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "view_display_assigned_categories". All fields are combined with a logical 'AND'. */
export type ViewDisplayAssignedCategoriesBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplayAssignedCategoriesBoolExp>>>;
  _not?: Maybe<ViewDisplayAssignedCategoriesBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplayAssignedCategoriesBoolExp>>>;
  category?: Maybe<ViewCategoriesBoolExp>;
  category_id?: Maybe<StringComparisonExp>;
  display_id?: Maybe<StringComparisonExp>;
  is_assigned?: Maybe<BooleanComparisonExp>;
  option?: Maybe<ViewOptionsBoolExp>;
  option_id?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
};

/** ordering options when selecting data from "view_display_assigned_categories" */
export type ViewDisplayAssignedCategoriesOrderBy = {
  category?: Maybe<ViewCategoriesOrderBy>;
  category_id?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  is_assigned?: Maybe<OrderBy>;
  option?: Maybe<ViewOptionsOrderBy>;
  option_id?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
};

/** select columns of table "view_display_assigned_categories" */
export enum ViewDisplayAssignedCategoriesSelectColumn {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  DisplayId = 'display_id',
  /** column name */
  IsAssigned = 'is_assigned',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  Org = 'org'
}

/** columns and relationships of "view_displays" */
export type ViewDisplays = {
  __typename?: 'view_displays';
  /** An object relationship */
  availability?: Maybe<ViewDisplaysAvailability>;
  catching_up?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  categories: Array<ViewDisplayAssignedCategories>;
  /** An array relationship */
  current_usb_connections: Array<ViewDisplaysCurrentUsbConnections>;
  /** An aggregated array relationship */
  current_usb_connections_aggregate: ViewDisplaysCurrentUsbConnectionsAggregate;
  deploy_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  deployment?: Maybe<ViewDisplaysDeployment>;
  /** An object relationship */
  discover_realtime?: Maybe<ViewDisplaysDiscoverRealtime>;
  flexera_maintenance_end?: Maybe<Scalars['String']>;
  hardware?: Maybe<Scalars['String']>;
  has_unconfirmed_templates?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  ip_addresses_primary?: Maybe<Scalars['String']>;
  is_in_subscription?: Maybe<Scalars['Boolean']>;
  is_manageable?: Maybe<Scalars['Boolean']>;
  is_online?: Maybe<Scalars['Boolean']>;
  lms_version?: Maybe<Scalars['_int4']>;
  /** An object relationship */
  location?: Maybe<ViewDisplaysLocations>;
  maintenance_end?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  management_status?: Maybe<ViewDisplaysManagementStatus>;
  /** An array relationship */
  meetings: Array<ViewMeetings>;
  /** An aggregated array relationship */
  meetings_aggregate: ViewMeetingsAggregate;
  name?: Maybe<Scalars['String']>;
  onboard_date?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  /** An object relationship */
  realtime?: Maybe<ViewDisplaysRealtime>;
  /** An object relationship */
  subscription?: Maybe<ViewDisplaysSubscriptions>;
  timezone?: Maybe<Scalars['String']>;
  /** An object relationship */
  updateability?: Maybe<ViewDisplaysUpdateability>;
  uuid?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['_int4']>;
  /** An object relationship */
  wellness_config?: Maybe<ViewDisplaysWellnessConfig>;
  /** An object relationship */
  wellness_realtime?: Maybe<ViewDisplaysDiscoverRealtime>;
};


/** columns and relationships of "view_displays" */
export type ViewDisplaysCategoriesArgs = {
  distinct_on?: Maybe<Array<ViewDisplayAssignedCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplayAssignedCategoriesOrderBy>>;
  where?: Maybe<ViewDisplayAssignedCategoriesBoolExp>;
};


/** columns and relationships of "view_displays" */
export type ViewDisplaysCurrentUsbConnectionsArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsOrderBy>>;
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
};


/** columns and relationships of "view_displays" */
export type ViewDisplaysCurrentUsbConnectionsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsOrderBy>>;
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
};


/** columns and relationships of "view_displays" */
export type ViewDisplaysMeetingsArgs = {
  distinct_on?: Maybe<Array<ViewMeetingsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMeetingsOrderBy>>;
  where?: Maybe<ViewMeetingsBoolExp>;
};


/** columns and relationships of "view_displays" */
export type ViewDisplaysMeetingsAggregateArgs = {
  distinct_on?: Maybe<Array<ViewMeetingsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewMeetingsOrderBy>>;
  where?: Maybe<ViewMeetingsBoolExp>;
};

/** aggregated selection of "view_displays" */
export type ViewDisplaysAggregate = {
  __typename?: 'view_displays_aggregate';
  aggregate?: Maybe<ViewDisplaysAggregateFields>;
  nodes: Array<ViewDisplays>;
};

/** aggregate fields of "view_displays" */
export type ViewDisplaysAggregateFields = {
  __typename?: 'view_displays_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysMaxFields>;
  min?: Maybe<ViewDisplaysMinFields>;
};


/** aggregate fields of "view_displays" */
export type ViewDisplaysAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays" */
export type ViewDisplaysAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysMaxOrderBy>;
  min?: Maybe<ViewDisplaysMinOrderBy>;
};

/** columns and relationships of "view_displays_assigned_options" */
export type ViewDisplaysAssignedOptions = {
  __typename?: 'view_displays_assigned_options';
  category_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  display?: Maybe<ViewDisplays>;
  display_id?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "view_displays_assigned_options" */
export type ViewDisplaysAssignedOptionsAggregate = {
  __typename?: 'view_displays_assigned_options_aggregate';
  aggregate?: Maybe<ViewDisplaysAssignedOptionsAggregateFields>;
  nodes: Array<ViewDisplaysAssignedOptions>;
};

/** aggregate fields of "view_displays_assigned_options" */
export type ViewDisplaysAssignedOptionsAggregateFields = {
  __typename?: 'view_displays_assigned_options_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysAssignedOptionsMaxFields>;
  min?: Maybe<ViewDisplaysAssignedOptionsMinFields>;
};


/** aggregate fields of "view_displays_assigned_options" */
export type ViewDisplaysAssignedOptionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysAssignedOptionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_assigned_options" */
export type ViewDisplaysAssignedOptionsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysAssignedOptionsMaxOrderBy>;
  min?: Maybe<ViewDisplaysAssignedOptionsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_assigned_options". All fields are combined with a logical 'AND'. */
export type ViewDisplaysAssignedOptionsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysAssignedOptionsBoolExp>>>;
  _not?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysAssignedOptionsBoolExp>>>;
  category_id?: Maybe<StringComparisonExp>;
  display?: Maybe<ViewDisplaysBoolExp>;
  display_id?: Maybe<StringComparisonExp>;
  option_id?: Maybe<StringComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplaysAssignedOptionsMaxFields = {
  __typename?: 'view_displays_assigned_options_max_fields';
  category_id?: Maybe<Scalars['String']>;
  display_id?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_displays_assigned_options" */
export type ViewDisplaysAssignedOptionsMaxOrderBy = {
  category_id?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  option_id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysAssignedOptionsMinFields = {
  __typename?: 'view_displays_assigned_options_min_fields';
  category_id?: Maybe<Scalars['String']>;
  display_id?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_displays_assigned_options" */
export type ViewDisplaysAssignedOptionsMinOrderBy = {
  category_id?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  option_id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_assigned_options" */
export type ViewDisplaysAssignedOptionsOrderBy = {
  category_id?: Maybe<OrderBy>;
  display?: Maybe<ViewDisplaysOrderBy>;
  display_id?: Maybe<OrderBy>;
  option_id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_assigned_options" */
export enum ViewDisplaysAssignedOptionsSelectColumn {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  DisplayId = 'display_id',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  OrgId = 'org_id'
}

/** columns and relationships of "view_displays_availability" */
export type ViewDisplaysAvailability = {
  __typename?: 'view_displays_availability';
  display?: Maybe<Scalars['String']>;
  is_online?: Maybe<Scalars['Boolean']>;
  last_online?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "view_displays_availability" */
export type ViewDisplaysAvailabilityAggregate = {
  __typename?: 'view_displays_availability_aggregate';
  aggregate?: Maybe<ViewDisplaysAvailabilityAggregateFields>;
  nodes: Array<ViewDisplaysAvailability>;
};

/** aggregate fields of "view_displays_availability" */
export type ViewDisplaysAvailabilityAggregateFields = {
  __typename?: 'view_displays_availability_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysAvailabilityMaxFields>;
  min?: Maybe<ViewDisplaysAvailabilityMinFields>;
};


/** aggregate fields of "view_displays_availability" */
export type ViewDisplaysAvailabilityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysAvailabilitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_availability" */
export type ViewDisplaysAvailabilityAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysAvailabilityMaxOrderBy>;
  min?: Maybe<ViewDisplaysAvailabilityMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_availability". All fields are combined with a logical 'AND'. */
export type ViewDisplaysAvailabilityBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysAvailabilityBoolExp>>>;
  _not?: Maybe<ViewDisplaysAvailabilityBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysAvailabilityBoolExp>>>;
  display?: Maybe<StringComparisonExp>;
  is_online?: Maybe<BooleanComparisonExp>;
  last_online?: Maybe<TimestamptzComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplaysAvailabilityMaxFields = {
  __typename?: 'view_displays_availability_max_fields';
  display?: Maybe<Scalars['String']>;
  last_online?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_displays_availability" */
export type ViewDisplaysAvailabilityMaxOrderBy = {
  display?: Maybe<OrderBy>;
  last_online?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysAvailabilityMinFields = {
  __typename?: 'view_displays_availability_min_fields';
  display?: Maybe<Scalars['String']>;
  last_online?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_displays_availability" */
export type ViewDisplaysAvailabilityMinOrderBy = {
  display?: Maybe<OrderBy>;
  last_online?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_availability" */
export type ViewDisplaysAvailabilityOrderBy = {
  display?: Maybe<OrderBy>;
  is_online?: Maybe<OrderBy>;
  last_online?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_availability" */
export enum ViewDisplaysAvailabilitySelectColumn {
  /** column name */
  Display = 'display',
  /** column name */
  IsOnline = 'is_online',
  /** column name */
  LastOnline = 'last_online',
  /** column name */
  Org = 'org',
  /** column name */
  Status = 'status'
}

/** Boolean expression to filter rows from the table "view_displays". All fields are combined with a logical 'AND'. */
export type ViewDisplaysBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysBoolExp>>>;
  _not?: Maybe<ViewDisplaysBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysBoolExp>>>;
  availability?: Maybe<ViewDisplaysAvailabilityBoolExp>;
  catching_up?: Maybe<BooleanComparisonExp>;
  categories?: Maybe<ViewDisplayAssignedCategoriesBoolExp>;
  current_usb_connections?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
  deploy_date?: Maybe<TimestamptzComparisonExp>;
  deployment?: Maybe<ViewDisplaysDeploymentBoolExp>;
  discover_realtime?: Maybe<ViewDisplaysDiscoverRealtimeBoolExp>;
  flexera_maintenance_end?: Maybe<StringComparisonExp>;
  hardware?: Maybe<StringComparisonExp>;
  has_unconfirmed_templates?: Maybe<BooleanComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  ip_addresses_primary?: Maybe<StringComparisonExp>;
  is_in_subscription?: Maybe<BooleanComparisonExp>;
  is_manageable?: Maybe<BooleanComparisonExp>;
  is_online?: Maybe<BooleanComparisonExp>;
  lms_version?: Maybe<Int4ComparisonExp>;
  location?: Maybe<ViewDisplaysLocationsBoolExp>;
  maintenance_end?: Maybe<TimestamptzComparisonExp>;
  management_status?: Maybe<ViewDisplaysManagementStatusBoolExp>;
  meetings?: Maybe<ViewMeetingsBoolExp>;
  name?: Maybe<StringComparisonExp>;
  onboard_date?: Maybe<TimestamptzComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  realtime?: Maybe<ViewDisplaysRealtimeBoolExp>;
  subscription?: Maybe<ViewDisplaysSubscriptionsBoolExp>;
  timezone?: Maybe<StringComparisonExp>;
  updateability?: Maybe<ViewDisplaysUpdateabilityBoolExp>;
  uuid?: Maybe<StringComparisonExp>;
  version?: Maybe<Int4ComparisonExp>;
  wellness_config?: Maybe<ViewDisplaysWellnessConfigBoolExp>;
  wellness_realtime?: Maybe<ViewDisplaysDiscoverRealtimeBoolExp>;
};

/** columns and relationships of "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnections = {
  __typename?: 'view_displays_current_usb_connections';
  av_device?: Maybe<Scalars['Boolean']>;
  device_id?: Maybe<Scalars['bigint']>;
  device_name?: Maybe<Scalars['String']>;
  device_protocol?: Maybe<Scalars['bigint']>;
  device_subclass?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  display?: Maybe<ViewDisplays>;
  display_id?: Maybe<Scalars['String']>;
  full_product_name?: Maybe<Scalars['String']>;
  is_conference_ready?: Maybe<Scalars['Boolean']>;
  is_occupancy_ready?: Maybe<Scalars['Boolean']>;
  is_online?: Maybe<Scalars['Boolean']>;
  is_updatable?: Maybe<Scalars['Boolean']>;
  occupancy_counting_enabled?: Maybe<Scalars['Boolean']>;
  org_id?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['bigint']>;
  plug_or_unplug?: Maybe<Scalars['Boolean']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_name?: Maybe<Scalars['String']>;
  relay_enabled?: Maybe<Scalars['Boolean']>;
  usb_plug_timestamp?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['bigint']>;
  vendor_name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregated selection of "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsAggregate = {
  __typename?: 'view_displays_current_usb_connections_aggregate';
  aggregate?: Maybe<ViewDisplaysCurrentUsbConnectionsAggregateFields>;
  nodes: Array<ViewDisplaysCurrentUsbConnections>;
};

/** aggregate fields of "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsAggregateFields = {
  __typename?: 'view_displays_current_usb_connections_aggregate_fields';
  avg?: Maybe<ViewDisplaysCurrentUsbConnectionsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysCurrentUsbConnectionsMaxFields>;
  min?: Maybe<ViewDisplaysCurrentUsbConnectionsMinFields>;
  stddev?: Maybe<ViewDisplaysCurrentUsbConnectionsStddevFields>;
  stddev_pop?: Maybe<ViewDisplaysCurrentUsbConnectionsStddevPopFields>;
  stddev_samp?: Maybe<ViewDisplaysCurrentUsbConnectionsStddevSampFields>;
  sum?: Maybe<ViewDisplaysCurrentUsbConnectionsSumFields>;
  var_pop?: Maybe<ViewDisplaysCurrentUsbConnectionsVarPopFields>;
  var_samp?: Maybe<ViewDisplaysCurrentUsbConnectionsVarSampFields>;
  variance?: Maybe<ViewDisplaysCurrentUsbConnectionsVarianceFields>;
};


/** aggregate fields of "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsAggregateOrderBy = {
  avg?: Maybe<ViewDisplaysCurrentUsbConnectionsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysCurrentUsbConnectionsMaxOrderBy>;
  min?: Maybe<ViewDisplaysCurrentUsbConnectionsMinOrderBy>;
  stddev?: Maybe<ViewDisplaysCurrentUsbConnectionsStddevOrderBy>;
  stddev_pop?: Maybe<ViewDisplaysCurrentUsbConnectionsStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewDisplaysCurrentUsbConnectionsStddevSampOrderBy>;
  sum?: Maybe<ViewDisplaysCurrentUsbConnectionsSumOrderBy>;
  var_pop?: Maybe<ViewDisplaysCurrentUsbConnectionsVarPopOrderBy>;
  var_samp?: Maybe<ViewDisplaysCurrentUsbConnectionsVarSampOrderBy>;
  variance?: Maybe<ViewDisplaysCurrentUsbConnectionsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewDisplaysCurrentUsbConnectionsAvgFields = {
  __typename?: 'view_displays_current_usb_connections_avg_fields';
  device_id?: Maybe<Scalars['Float']>;
  device_protocol?: Maybe<Scalars['Float']>;
  device_subclass?: Maybe<Scalars['Float']>;
  partition?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  vendor_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsAvgOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_current_usb_connections". All fields are combined with a logical 'AND'. */
export type ViewDisplaysCurrentUsbConnectionsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>>>;
  _not?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>>>;
  av_device?: Maybe<BooleanComparisonExp>;
  device_id?: Maybe<BigintComparisonExp>;
  device_name?: Maybe<StringComparisonExp>;
  device_protocol?: Maybe<BigintComparisonExp>;
  device_subclass?: Maybe<BigintComparisonExp>;
  display?: Maybe<ViewDisplaysBoolExp>;
  display_id?: Maybe<StringComparisonExp>;
  full_product_name?: Maybe<StringComparisonExp>;
  is_conference_ready?: Maybe<BooleanComparisonExp>;
  is_occupancy_ready?: Maybe<BooleanComparisonExp>;
  is_online?: Maybe<BooleanComparisonExp>;
  is_updatable?: Maybe<BooleanComparisonExp>;
  occupancy_counting_enabled?: Maybe<BooleanComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
  partition?: Maybe<BigintComparisonExp>;
  plug_or_unplug?: Maybe<BooleanComparisonExp>;
  product_id?: Maybe<BigintComparisonExp>;
  product_name?: Maybe<StringComparisonExp>;
  relay_enabled?: Maybe<BooleanComparisonExp>;
  usb_plug_timestamp?: Maybe<TimestamptzComparisonExp>;
  vendor_id?: Maybe<BigintComparisonExp>;
  vendor_name?: Maybe<StringComparisonExp>;
  version?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplaysCurrentUsbConnectionsMaxFields = {
  __typename?: 'view_displays_current_usb_connections_max_fields';
  device_id?: Maybe<Scalars['bigint']>;
  device_name?: Maybe<Scalars['String']>;
  device_protocol?: Maybe<Scalars['bigint']>;
  device_subclass?: Maybe<Scalars['bigint']>;
  display_id?: Maybe<Scalars['String']>;
  full_product_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_name?: Maybe<Scalars['String']>;
  usb_plug_timestamp?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['bigint']>;
  vendor_name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsMaxOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_name?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  full_product_name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  usb_plug_timestamp?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
  vendor_name?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysCurrentUsbConnectionsMinFields = {
  __typename?: 'view_displays_current_usb_connections_min_fields';
  device_id?: Maybe<Scalars['bigint']>;
  device_name?: Maybe<Scalars['String']>;
  device_protocol?: Maybe<Scalars['bigint']>;
  device_subclass?: Maybe<Scalars['bigint']>;
  display_id?: Maybe<Scalars['String']>;
  full_product_name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  partition?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_name?: Maybe<Scalars['String']>;
  usb_plug_timestamp?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['bigint']>;
  vendor_name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsMinOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_name?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  full_product_name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  usb_plug_timestamp?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
  vendor_name?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsOrderBy = {
  av_device?: Maybe<OrderBy>;
  device_id?: Maybe<OrderBy>;
  device_name?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  display?: Maybe<ViewDisplaysOrderBy>;
  display_id?: Maybe<OrderBy>;
  full_product_name?: Maybe<OrderBy>;
  is_conference_ready?: Maybe<OrderBy>;
  is_occupancy_ready?: Maybe<OrderBy>;
  is_online?: Maybe<OrderBy>;
  is_updatable?: Maybe<OrderBy>;
  occupancy_counting_enabled?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  plug_or_unplug?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  product_name?: Maybe<OrderBy>;
  relay_enabled?: Maybe<OrderBy>;
  usb_plug_timestamp?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
  vendor_name?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_current_usb_connections" */
export enum ViewDisplaysCurrentUsbConnectionsSelectColumn {
  /** column name */
  AvDevice = 'av_device',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  DeviceProtocol = 'device_protocol',
  /** column name */
  DeviceSubclass = 'device_subclass',
  /** column name */
  DisplayId = 'display_id',
  /** column name */
  FullProductName = 'full_product_name',
  /** column name */
  IsConferenceReady = 'is_conference_ready',
  /** column name */
  IsOccupancyReady = 'is_occupancy_ready',
  /** column name */
  IsOnline = 'is_online',
  /** column name */
  IsUpdatable = 'is_updatable',
  /** column name */
  OccupancyCountingEnabled = 'occupancy_counting_enabled',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Partition = 'partition',
  /** column name */
  PlugOrUnplug = 'plug_or_unplug',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  RelayEnabled = 'relay_enabled',
  /** column name */
  UsbPlugTimestamp = 'usb_plug_timestamp',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorName = 'vendor_name',
  /** column name */
  Version = 'version'
}

/** aggregate stddev on columns */
export type ViewDisplaysCurrentUsbConnectionsStddevFields = {
  __typename?: 'view_displays_current_usb_connections_stddev_fields';
  device_id?: Maybe<Scalars['Float']>;
  device_protocol?: Maybe<Scalars['Float']>;
  device_subclass?: Maybe<Scalars['Float']>;
  partition?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  vendor_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsStddevOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewDisplaysCurrentUsbConnectionsStddevPopFields = {
  __typename?: 'view_displays_current_usb_connections_stddev_pop_fields';
  device_id?: Maybe<Scalars['Float']>;
  device_protocol?: Maybe<Scalars['Float']>;
  device_subclass?: Maybe<Scalars['Float']>;
  partition?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  vendor_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsStddevPopOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewDisplaysCurrentUsbConnectionsStddevSampFields = {
  __typename?: 'view_displays_current_usb_connections_stddev_samp_fields';
  device_id?: Maybe<Scalars['Float']>;
  device_protocol?: Maybe<Scalars['Float']>;
  device_subclass?: Maybe<Scalars['Float']>;
  partition?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  vendor_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsStddevSampOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewDisplaysCurrentUsbConnectionsSumFields = {
  __typename?: 'view_displays_current_usb_connections_sum_fields';
  device_id?: Maybe<Scalars['bigint']>;
  device_protocol?: Maybe<Scalars['bigint']>;
  device_subclass?: Maybe<Scalars['bigint']>;
  partition?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  vendor_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsSumOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewDisplaysCurrentUsbConnectionsVarPopFields = {
  __typename?: 'view_displays_current_usb_connections_var_pop_fields';
  device_id?: Maybe<Scalars['Float']>;
  device_protocol?: Maybe<Scalars['Float']>;
  device_subclass?: Maybe<Scalars['Float']>;
  partition?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  vendor_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsVarPopOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewDisplaysCurrentUsbConnectionsVarSampFields = {
  __typename?: 'view_displays_current_usb_connections_var_samp_fields';
  device_id?: Maybe<Scalars['Float']>;
  device_protocol?: Maybe<Scalars['Float']>;
  device_subclass?: Maybe<Scalars['Float']>;
  partition?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  vendor_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsVarSampOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewDisplaysCurrentUsbConnectionsVarianceFields = {
  __typename?: 'view_displays_current_usb_connections_variance_fields';
  device_id?: Maybe<Scalars['Float']>;
  device_protocol?: Maybe<Scalars['Float']>;
  device_subclass?: Maybe<Scalars['Float']>;
  partition?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  vendor_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_displays_current_usb_connections" */
export type ViewDisplaysCurrentUsbConnectionsVarianceOrderBy = {
  device_id?: Maybe<OrderBy>;
  device_protocol?: Maybe<OrderBy>;
  device_subclass?: Maybe<OrderBy>;
  partition?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  vendor_id?: Maybe<OrderBy>;
};

/** columns and relationships of "view_displays_deployment" */
export type ViewDisplaysDeployment = {
  __typename?: 'view_displays_deployment';
  /** An object relationship */
  display?: Maybe<ViewDisplays>;
  display_id?: Maybe<Scalars['String']>;
  edition?: Maybe<Scalars['String']>;
  ip_primary?: Maybe<Scalars['String']>;
  ip_wifi?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  mac_primary?: Maybe<Scalars['String']>;
  mac_wifi?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  sds_enabled?: Maybe<Scalars['Boolean']>;
  sds_host_1?: Maybe<Scalars['String']>;
  sds_host_2?: Maybe<Scalars['String']>;
  /** An array relationship */
  tags: Array<OrgsDisplaysTags>;
  /** An aggregated array relationship */
  tags_aggregate: OrgsDisplaysTagsAggregate;
};


/** columns and relationships of "view_displays_deployment" */
export type ViewDisplaysDeploymentTagsArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysTagsOrderBy>>;
  where?: Maybe<OrgsDisplaysTagsBoolExp>;
};


/** columns and relationships of "view_displays_deployment" */
export type ViewDisplaysDeploymentTagsAggregateArgs = {
  distinct_on?: Maybe<Array<OrgsDisplaysTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrgsDisplaysTagsOrderBy>>;
  where?: Maybe<OrgsDisplaysTagsBoolExp>;
};

/** aggregated selection of "view_displays_deployment" */
export type ViewDisplaysDeploymentAggregate = {
  __typename?: 'view_displays_deployment_aggregate';
  aggregate?: Maybe<ViewDisplaysDeploymentAggregateFields>;
  nodes: Array<ViewDisplaysDeployment>;
};

/** aggregate fields of "view_displays_deployment" */
export type ViewDisplaysDeploymentAggregateFields = {
  __typename?: 'view_displays_deployment_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysDeploymentMaxFields>;
  min?: Maybe<ViewDisplaysDeploymentMinFields>;
};


/** aggregate fields of "view_displays_deployment" */
export type ViewDisplaysDeploymentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysDeploymentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_deployment" */
export type ViewDisplaysDeploymentAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysDeploymentMaxOrderBy>;
  min?: Maybe<ViewDisplaysDeploymentMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_deployment". All fields are combined with a logical 'AND'. */
export type ViewDisplaysDeploymentBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysDeploymentBoolExp>>>;
  _not?: Maybe<ViewDisplaysDeploymentBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysDeploymentBoolExp>>>;
  display?: Maybe<ViewDisplaysBoolExp>;
  display_id?: Maybe<StringComparisonExp>;
  edition?: Maybe<StringComparisonExp>;
  ip_primary?: Maybe<StringComparisonExp>;
  ip_wifi?: Maybe<StringComparisonExp>;
  license?: Maybe<StringComparisonExp>;
  mac_primary?: Maybe<StringComparisonExp>;
  mac_wifi?: Maybe<StringComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
  sds_enabled?: Maybe<BooleanComparisonExp>;
  sds_host_1?: Maybe<StringComparisonExp>;
  sds_host_2?: Maybe<StringComparisonExp>;
  tags?: Maybe<OrgsDisplaysTagsBoolExp>;
};

/** aggregate max on columns */
export type ViewDisplaysDeploymentMaxFields = {
  __typename?: 'view_displays_deployment_max_fields';
  display_id?: Maybe<Scalars['String']>;
  edition?: Maybe<Scalars['String']>;
  ip_primary?: Maybe<Scalars['String']>;
  ip_wifi?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  mac_primary?: Maybe<Scalars['String']>;
  mac_wifi?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  sds_host_1?: Maybe<Scalars['String']>;
  sds_host_2?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_displays_deployment" */
export type ViewDisplaysDeploymentMaxOrderBy = {
  display_id?: Maybe<OrderBy>;
  edition?: Maybe<OrderBy>;
  ip_primary?: Maybe<OrderBy>;
  ip_wifi?: Maybe<OrderBy>;
  license?: Maybe<OrderBy>;
  mac_primary?: Maybe<OrderBy>;
  mac_wifi?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  sds_host_1?: Maybe<OrderBy>;
  sds_host_2?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysDeploymentMinFields = {
  __typename?: 'view_displays_deployment_min_fields';
  display_id?: Maybe<Scalars['String']>;
  edition?: Maybe<Scalars['String']>;
  ip_primary?: Maybe<Scalars['String']>;
  ip_wifi?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  mac_primary?: Maybe<Scalars['String']>;
  mac_wifi?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  sds_host_1?: Maybe<Scalars['String']>;
  sds_host_2?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_displays_deployment" */
export type ViewDisplaysDeploymentMinOrderBy = {
  display_id?: Maybe<OrderBy>;
  edition?: Maybe<OrderBy>;
  ip_primary?: Maybe<OrderBy>;
  ip_wifi?: Maybe<OrderBy>;
  license?: Maybe<OrderBy>;
  mac_primary?: Maybe<OrderBy>;
  mac_wifi?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  sds_host_1?: Maybe<OrderBy>;
  sds_host_2?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_deployment" */
export type ViewDisplaysDeploymentOrderBy = {
  display?: Maybe<ViewDisplaysOrderBy>;
  display_id?: Maybe<OrderBy>;
  edition?: Maybe<OrderBy>;
  ip_primary?: Maybe<OrderBy>;
  ip_wifi?: Maybe<OrderBy>;
  license?: Maybe<OrderBy>;
  mac_primary?: Maybe<OrderBy>;
  mac_wifi?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  sds_enabled?: Maybe<OrderBy>;
  sds_host_1?: Maybe<OrderBy>;
  sds_host_2?: Maybe<OrderBy>;
  tags_aggregate?: Maybe<OrgsDisplaysTagsAggregateOrderBy>;
};

/** select columns of table "view_displays_deployment" */
export enum ViewDisplaysDeploymentSelectColumn {
  /** column name */
  DisplayId = 'display_id',
  /** column name */
  Edition = 'edition',
  /** column name */
  IpPrimary = 'ip_primary',
  /** column name */
  IpWifi = 'ip_wifi',
  /** column name */
  License = 'license',
  /** column name */
  MacPrimary = 'mac_primary',
  /** column name */
  MacWifi = 'mac_wifi',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  SdsEnabled = 'sds_enabled',
  /** column name */
  SdsHost_1 = 'sds_host_1',
  /** column name */
  SdsHost_2 = 'sds_host_2'
}

/** columns and relationships of "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtime = {
  __typename?: 'view_displays_discover_realtime';
  cur_calendar_attendees?: Maybe<Scalars['bigint']>;
  cur_calendar_end?: Maybe<Scalars['timestamptz']>;
  cur_calendar_start?: Maybe<Scalars['timestamptz']>;
  current_occupancy_camera?: Maybe<Scalars['bigint']>;
  display?: Maybe<Scalars['String']>;
  foot_traffic?: Maybe<Scalars['numeric']>;
  myfoottraffic?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  next_calendar_attendees?: Maybe<Scalars['bigint']>;
  next_calendar_end?: Maybe<Scalars['timestamptz']>;
  next_calendar_start?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregated selection of "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeAggregate = {
  __typename?: 'view_displays_discover_realtime_aggregate';
  aggregate?: Maybe<ViewDisplaysDiscoverRealtimeAggregateFields>;
  nodes: Array<ViewDisplaysDiscoverRealtime>;
};

/** aggregate fields of "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeAggregateFields = {
  __typename?: 'view_displays_discover_realtime_aggregate_fields';
  avg?: Maybe<ViewDisplaysDiscoverRealtimeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysDiscoverRealtimeMaxFields>;
  min?: Maybe<ViewDisplaysDiscoverRealtimeMinFields>;
  stddev?: Maybe<ViewDisplaysDiscoverRealtimeStddevFields>;
  stddev_pop?: Maybe<ViewDisplaysDiscoverRealtimeStddevPopFields>;
  stddev_samp?: Maybe<ViewDisplaysDiscoverRealtimeStddevSampFields>;
  sum?: Maybe<ViewDisplaysDiscoverRealtimeSumFields>;
  var_pop?: Maybe<ViewDisplaysDiscoverRealtimeVarPopFields>;
  var_samp?: Maybe<ViewDisplaysDiscoverRealtimeVarSampFields>;
  variance?: Maybe<ViewDisplaysDiscoverRealtimeVarianceFields>;
};


/** aggregate fields of "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysDiscoverRealtimeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeAggregateOrderBy = {
  avg?: Maybe<ViewDisplaysDiscoverRealtimeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysDiscoverRealtimeMaxOrderBy>;
  min?: Maybe<ViewDisplaysDiscoverRealtimeMinOrderBy>;
  stddev?: Maybe<ViewDisplaysDiscoverRealtimeStddevOrderBy>;
  stddev_pop?: Maybe<ViewDisplaysDiscoverRealtimeStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewDisplaysDiscoverRealtimeStddevSampOrderBy>;
  sum?: Maybe<ViewDisplaysDiscoverRealtimeSumOrderBy>;
  var_pop?: Maybe<ViewDisplaysDiscoverRealtimeVarPopOrderBy>;
  var_samp?: Maybe<ViewDisplaysDiscoverRealtimeVarSampOrderBy>;
  variance?: Maybe<ViewDisplaysDiscoverRealtimeVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewDisplaysDiscoverRealtimeAvgFields = {
  __typename?: 'view_displays_discover_realtime_avg_fields';
  cur_calendar_attendees?: Maybe<Scalars['Float']>;
  current_occupancy_camera?: Maybe<Scalars['Float']>;
  foot_traffic?: Maybe<Scalars['Float']>;
  next_calendar_attendees?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeAvgOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_discover_realtime". All fields are combined with a logical 'AND'. */
export type ViewDisplaysDiscoverRealtimeBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysDiscoverRealtimeBoolExp>>>;
  _not?: Maybe<ViewDisplaysDiscoverRealtimeBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysDiscoverRealtimeBoolExp>>>;
  cur_calendar_attendees?: Maybe<BigintComparisonExp>;
  cur_calendar_end?: Maybe<TimestamptzComparisonExp>;
  cur_calendar_start?: Maybe<TimestamptzComparisonExp>;
  current_occupancy_camera?: Maybe<BigintComparisonExp>;
  display?: Maybe<StringComparisonExp>;
  foot_traffic?: Maybe<NumericComparisonExp>;
  myfoottraffic?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  next_calendar_attendees?: Maybe<BigintComparisonExp>;
  next_calendar_end?: Maybe<TimestamptzComparisonExp>;
  next_calendar_start?: Maybe<TimestamptzComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  version?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplaysDiscoverRealtimeMaxFields = {
  __typename?: 'view_displays_discover_realtime_max_fields';
  cur_calendar_attendees?: Maybe<Scalars['bigint']>;
  cur_calendar_end?: Maybe<Scalars['timestamptz']>;
  cur_calendar_start?: Maybe<Scalars['timestamptz']>;
  current_occupancy_camera?: Maybe<Scalars['bigint']>;
  display?: Maybe<Scalars['String']>;
  foot_traffic?: Maybe<Scalars['numeric']>;
  myfoottraffic?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  next_calendar_attendees?: Maybe<Scalars['bigint']>;
  next_calendar_end?: Maybe<Scalars['timestamptz']>;
  next_calendar_start?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeMaxOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  cur_calendar_end?: Maybe<OrderBy>;
  cur_calendar_start?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  myfoottraffic?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
  next_calendar_end?: Maybe<OrderBy>;
  next_calendar_start?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysDiscoverRealtimeMinFields = {
  __typename?: 'view_displays_discover_realtime_min_fields';
  cur_calendar_attendees?: Maybe<Scalars['bigint']>;
  cur_calendar_end?: Maybe<Scalars['timestamptz']>;
  cur_calendar_start?: Maybe<Scalars['timestamptz']>;
  current_occupancy_camera?: Maybe<Scalars['bigint']>;
  display?: Maybe<Scalars['String']>;
  foot_traffic?: Maybe<Scalars['numeric']>;
  myfoottraffic?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  next_calendar_attendees?: Maybe<Scalars['bigint']>;
  next_calendar_end?: Maybe<Scalars['timestamptz']>;
  next_calendar_start?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeMinOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  cur_calendar_end?: Maybe<OrderBy>;
  cur_calendar_start?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  myfoottraffic?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
  next_calendar_end?: Maybe<OrderBy>;
  next_calendar_start?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  cur_calendar_end?: Maybe<OrderBy>;
  cur_calendar_start?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  myfoottraffic?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
  next_calendar_end?: Maybe<OrderBy>;
  next_calendar_start?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_discover_realtime" */
export enum ViewDisplaysDiscoverRealtimeSelectColumn {
  /** column name */
  CurCalendarAttendees = 'cur_calendar_attendees',
  /** column name */
  CurCalendarEnd = 'cur_calendar_end',
  /** column name */
  CurCalendarStart = 'cur_calendar_start',
  /** column name */
  CurrentOccupancyCamera = 'current_occupancy_camera',
  /** column name */
  Display = 'display',
  /** column name */
  FootTraffic = 'foot_traffic',
  /** column name */
  Myfoottraffic = 'myfoottraffic',
  /** column name */
  Name = 'name',
  /** column name */
  NextCalendarAttendees = 'next_calendar_attendees',
  /** column name */
  NextCalendarEnd = 'next_calendar_end',
  /** column name */
  NextCalendarStart = 'next_calendar_start',
  /** column name */
  Org = 'org',
  /** column name */
  Version = 'version'
}

/** aggregate stddev on columns */
export type ViewDisplaysDiscoverRealtimeStddevFields = {
  __typename?: 'view_displays_discover_realtime_stddev_fields';
  cur_calendar_attendees?: Maybe<Scalars['Float']>;
  current_occupancy_camera?: Maybe<Scalars['Float']>;
  foot_traffic?: Maybe<Scalars['Float']>;
  next_calendar_attendees?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeStddevOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewDisplaysDiscoverRealtimeStddevPopFields = {
  __typename?: 'view_displays_discover_realtime_stddev_pop_fields';
  cur_calendar_attendees?: Maybe<Scalars['Float']>;
  current_occupancy_camera?: Maybe<Scalars['Float']>;
  foot_traffic?: Maybe<Scalars['Float']>;
  next_calendar_attendees?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeStddevPopOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewDisplaysDiscoverRealtimeStddevSampFields = {
  __typename?: 'view_displays_discover_realtime_stddev_samp_fields';
  cur_calendar_attendees?: Maybe<Scalars['Float']>;
  current_occupancy_camera?: Maybe<Scalars['Float']>;
  foot_traffic?: Maybe<Scalars['Float']>;
  next_calendar_attendees?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeStddevSampOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewDisplaysDiscoverRealtimeSumFields = {
  __typename?: 'view_displays_discover_realtime_sum_fields';
  cur_calendar_attendees?: Maybe<Scalars['bigint']>;
  current_occupancy_camera?: Maybe<Scalars['bigint']>;
  foot_traffic?: Maybe<Scalars['numeric']>;
  next_calendar_attendees?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeSumOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewDisplaysDiscoverRealtimeVarPopFields = {
  __typename?: 'view_displays_discover_realtime_var_pop_fields';
  cur_calendar_attendees?: Maybe<Scalars['Float']>;
  current_occupancy_camera?: Maybe<Scalars['Float']>;
  foot_traffic?: Maybe<Scalars['Float']>;
  next_calendar_attendees?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeVarPopOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewDisplaysDiscoverRealtimeVarSampFields = {
  __typename?: 'view_displays_discover_realtime_var_samp_fields';
  cur_calendar_attendees?: Maybe<Scalars['Float']>;
  current_occupancy_camera?: Maybe<Scalars['Float']>;
  foot_traffic?: Maybe<Scalars['Float']>;
  next_calendar_attendees?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeVarSampOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewDisplaysDiscoverRealtimeVarianceFields = {
  __typename?: 'view_displays_discover_realtime_variance_fields';
  cur_calendar_attendees?: Maybe<Scalars['Float']>;
  current_occupancy_camera?: Maybe<Scalars['Float']>;
  foot_traffic?: Maybe<Scalars['Float']>;
  next_calendar_attendees?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_displays_discover_realtime" */
export type ViewDisplaysDiscoverRealtimeVarianceOrderBy = {
  cur_calendar_attendees?: Maybe<OrderBy>;
  current_occupancy_camera?: Maybe<OrderBy>;
  foot_traffic?: Maybe<OrderBy>;
  next_calendar_attendees?: Maybe<OrderBy>;
};

/** columns and relationships of "view_displays_hosts" */
export type ViewDisplaysHosts = {
  __typename?: 'view_displays_hosts';
  /** An object relationship */
  display?: Maybe<ViewDisplays>;
  display_id?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "view_displays_hosts" */
export type ViewDisplaysHostsAggregate = {
  __typename?: 'view_displays_hosts_aggregate';
  aggregate?: Maybe<ViewDisplaysHostsAggregateFields>;
  nodes: Array<ViewDisplaysHosts>;
};

/** aggregate fields of "view_displays_hosts" */
export type ViewDisplaysHostsAggregateFields = {
  __typename?: 'view_displays_hosts_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysHostsMaxFields>;
  min?: Maybe<ViewDisplaysHostsMinFields>;
};


/** aggregate fields of "view_displays_hosts" */
export type ViewDisplaysHostsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysHostsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_hosts" */
export type ViewDisplaysHostsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysHostsMaxOrderBy>;
  min?: Maybe<ViewDisplaysHostsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_hosts". All fields are combined with a logical 'AND'. */
export type ViewDisplaysHostsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysHostsBoolExp>>>;
  _not?: Maybe<ViewDisplaysHostsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysHostsBoolExp>>>;
  display?: Maybe<ViewDisplaysBoolExp>;
  display_id?: Maybe<StringComparisonExp>;
  host?: Maybe<StringComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplaysHostsMaxFields = {
  __typename?: 'view_displays_hosts_max_fields';
  display_id?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_displays_hosts" */
export type ViewDisplaysHostsMaxOrderBy = {
  display_id?: Maybe<OrderBy>;
  host?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysHostsMinFields = {
  __typename?: 'view_displays_hosts_min_fields';
  display_id?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_displays_hosts" */
export type ViewDisplaysHostsMinOrderBy = {
  display_id?: Maybe<OrderBy>;
  host?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_hosts" */
export type ViewDisplaysHostsOrderBy = {
  display?: Maybe<ViewDisplaysOrderBy>;
  display_id?: Maybe<OrderBy>;
  host?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_hosts" */
export enum ViewDisplaysHostsSelectColumn {
  /** column name */
  DisplayId = 'display_id',
  /** column name */
  Host = 'host',
  /** column name */
  OrgId = 'org_id'
}

/** columns and relationships of "view_displays_locations" */
export type ViewDisplaysLocations = {
  __typename?: 'view_displays_locations';
  /** An object relationship */
  _display?: Maybe<ViewDisplays>;
  /** An object relationship */
  address?: Maybe<OrgAddresses>;
  address_id?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['String']>;
  display_lat?: Maybe<Scalars['float8']>;
  display_long?: Maybe<Scalars['float8']>;
  floor?: Maybe<Scalars['Int']>;
  /** An object relationship */
  floor_count?: Maybe<ViewFloorCount>;
  org?: Maybe<Scalars['String']>;
  precise_lat?: Maybe<Scalars['float8']>;
  precise_location?: Maybe<Scalars['geography']>;
  precise_long?: Maybe<Scalars['float8']>;
  sls_lat?: Maybe<Scalars['float8']>;
  sls_long?: Maybe<Scalars['float8']>;
  user_lat?: Maybe<Scalars['float8']>;
  user_long?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "view_displays_locations" */
export type ViewDisplaysLocationsAggregate = {
  __typename?: 'view_displays_locations_aggregate';
  aggregate?: Maybe<ViewDisplaysLocationsAggregateFields>;
  nodes: Array<ViewDisplaysLocations>;
};

/** aggregate fields of "view_displays_locations" */
export type ViewDisplaysLocationsAggregateFields = {
  __typename?: 'view_displays_locations_aggregate_fields';
  avg?: Maybe<ViewDisplaysLocationsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysLocationsMaxFields>;
  min?: Maybe<ViewDisplaysLocationsMinFields>;
  stddev?: Maybe<ViewDisplaysLocationsStddevFields>;
  stddev_pop?: Maybe<ViewDisplaysLocationsStddevPopFields>;
  stddev_samp?: Maybe<ViewDisplaysLocationsStddevSampFields>;
  sum?: Maybe<ViewDisplaysLocationsSumFields>;
  var_pop?: Maybe<ViewDisplaysLocationsVarPopFields>;
  var_samp?: Maybe<ViewDisplaysLocationsVarSampFields>;
  variance?: Maybe<ViewDisplaysLocationsVarianceFields>;
};


/** aggregate fields of "view_displays_locations" */
export type ViewDisplaysLocationsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysLocationsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_locations" */
export type ViewDisplaysLocationsAggregateOrderBy = {
  avg?: Maybe<ViewDisplaysLocationsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysLocationsMaxOrderBy>;
  min?: Maybe<ViewDisplaysLocationsMinOrderBy>;
  stddev?: Maybe<ViewDisplaysLocationsStddevOrderBy>;
  stddev_pop?: Maybe<ViewDisplaysLocationsStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewDisplaysLocationsStddevSampOrderBy>;
  sum?: Maybe<ViewDisplaysLocationsSumOrderBy>;
  var_pop?: Maybe<ViewDisplaysLocationsVarPopOrderBy>;
  var_samp?: Maybe<ViewDisplaysLocationsVarSampOrderBy>;
  variance?: Maybe<ViewDisplaysLocationsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewDisplaysLocationsAvgFields = {
  __typename?: 'view_displays_locations_avg_fields';
  address_id?: Maybe<Scalars['Float']>;
  display_lat?: Maybe<Scalars['Float']>;
  display_long?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  precise_lat?: Maybe<Scalars['Float']>;
  precise_long?: Maybe<Scalars['Float']>;
  sls_lat?: Maybe<Scalars['Float']>;
  sls_long?: Maybe<Scalars['Float']>;
  user_lat?: Maybe<Scalars['Float']>;
  user_long?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsAvgOrderBy = {
  address_id?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_locations". All fields are combined with a logical 'AND'. */
export type ViewDisplaysLocationsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysLocationsBoolExp>>>;
  _display?: Maybe<ViewDisplaysBoolExp>;
  _not?: Maybe<ViewDisplaysLocationsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysLocationsBoolExp>>>;
  address?: Maybe<OrgAddressesBoolExp>;
  address_id?: Maybe<IntComparisonExp>;
  display?: Maybe<StringComparisonExp>;
  display_lat?: Maybe<Float8ComparisonExp>;
  display_long?: Maybe<Float8ComparisonExp>;
  floor?: Maybe<IntComparisonExp>;
  floor_count?: Maybe<ViewFloorCountBoolExp>;
  org?: Maybe<StringComparisonExp>;
  precise_lat?: Maybe<Float8ComparisonExp>;
  precise_location?: Maybe<GeographyComparisonExp>;
  precise_long?: Maybe<Float8ComparisonExp>;
  sls_lat?: Maybe<Float8ComparisonExp>;
  sls_long?: Maybe<Float8ComparisonExp>;
  user_lat?: Maybe<Float8ComparisonExp>;
  user_long?: Maybe<Float8ComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplaysLocationsMaxFields = {
  __typename?: 'view_displays_locations_max_fields';
  address_id?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['String']>;
  display_lat?: Maybe<Scalars['float8']>;
  display_long?: Maybe<Scalars['float8']>;
  floor?: Maybe<Scalars['Int']>;
  org?: Maybe<Scalars['String']>;
  precise_lat?: Maybe<Scalars['float8']>;
  precise_long?: Maybe<Scalars['float8']>;
  sls_lat?: Maybe<Scalars['float8']>;
  sls_long?: Maybe<Scalars['float8']>;
  user_lat?: Maybe<Scalars['float8']>;
  user_long?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsMaxOrderBy = {
  address_id?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysLocationsMinFields = {
  __typename?: 'view_displays_locations_min_fields';
  address_id?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['String']>;
  display_lat?: Maybe<Scalars['float8']>;
  display_long?: Maybe<Scalars['float8']>;
  floor?: Maybe<Scalars['Int']>;
  org?: Maybe<Scalars['String']>;
  precise_lat?: Maybe<Scalars['float8']>;
  precise_long?: Maybe<Scalars['float8']>;
  sls_lat?: Maybe<Scalars['float8']>;
  sls_long?: Maybe<Scalars['float8']>;
  user_lat?: Maybe<Scalars['float8']>;
  user_long?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsMinOrderBy = {
  address_id?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_locations" */
export type ViewDisplaysLocationsOrderBy = {
  _display?: Maybe<ViewDisplaysOrderBy>;
  address?: Maybe<OrgAddressesOrderBy>;
  address_id?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  floor_count?: Maybe<ViewFloorCountOrderBy>;
  org?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_location?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_locations" */
export enum ViewDisplaysLocationsSelectColumn {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Display = 'display',
  /** column name */
  DisplayLat = 'display_lat',
  /** column name */
  DisplayLong = 'display_long',
  /** column name */
  Floor = 'floor',
  /** column name */
  Org = 'org',
  /** column name */
  PreciseLat = 'precise_lat',
  /** column name */
  PreciseLocation = 'precise_location',
  /** column name */
  PreciseLong = 'precise_long',
  /** column name */
  SlsLat = 'sls_lat',
  /** column name */
  SlsLong = 'sls_long',
  /** column name */
  UserLat = 'user_lat',
  /** column name */
  UserLong = 'user_long'
}

/** aggregate stddev on columns */
export type ViewDisplaysLocationsStddevFields = {
  __typename?: 'view_displays_locations_stddev_fields';
  address_id?: Maybe<Scalars['Float']>;
  display_lat?: Maybe<Scalars['Float']>;
  display_long?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  precise_lat?: Maybe<Scalars['Float']>;
  precise_long?: Maybe<Scalars['Float']>;
  sls_lat?: Maybe<Scalars['Float']>;
  sls_long?: Maybe<Scalars['Float']>;
  user_lat?: Maybe<Scalars['Float']>;
  user_long?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsStddevOrderBy = {
  address_id?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewDisplaysLocationsStddevPopFields = {
  __typename?: 'view_displays_locations_stddev_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  display_lat?: Maybe<Scalars['Float']>;
  display_long?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  precise_lat?: Maybe<Scalars['Float']>;
  precise_long?: Maybe<Scalars['Float']>;
  sls_lat?: Maybe<Scalars['Float']>;
  sls_long?: Maybe<Scalars['Float']>;
  user_lat?: Maybe<Scalars['Float']>;
  user_long?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsStddevPopOrderBy = {
  address_id?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewDisplaysLocationsStddevSampFields = {
  __typename?: 'view_displays_locations_stddev_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  display_lat?: Maybe<Scalars['Float']>;
  display_long?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  precise_lat?: Maybe<Scalars['Float']>;
  precise_long?: Maybe<Scalars['Float']>;
  sls_lat?: Maybe<Scalars['Float']>;
  sls_long?: Maybe<Scalars['Float']>;
  user_lat?: Maybe<Scalars['Float']>;
  user_long?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsStddevSampOrderBy = {
  address_id?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewDisplaysLocationsSumFields = {
  __typename?: 'view_displays_locations_sum_fields';
  address_id?: Maybe<Scalars['Int']>;
  display_lat?: Maybe<Scalars['float8']>;
  display_long?: Maybe<Scalars['float8']>;
  floor?: Maybe<Scalars['Int']>;
  precise_lat?: Maybe<Scalars['float8']>;
  precise_long?: Maybe<Scalars['float8']>;
  sls_lat?: Maybe<Scalars['float8']>;
  sls_long?: Maybe<Scalars['float8']>;
  user_lat?: Maybe<Scalars['float8']>;
  user_long?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsSumOrderBy = {
  address_id?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewDisplaysLocationsVarPopFields = {
  __typename?: 'view_displays_locations_var_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  display_lat?: Maybe<Scalars['Float']>;
  display_long?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  precise_lat?: Maybe<Scalars['Float']>;
  precise_long?: Maybe<Scalars['Float']>;
  sls_lat?: Maybe<Scalars['Float']>;
  sls_long?: Maybe<Scalars['Float']>;
  user_lat?: Maybe<Scalars['Float']>;
  user_long?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsVarPopOrderBy = {
  address_id?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewDisplaysLocationsVarSampFields = {
  __typename?: 'view_displays_locations_var_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  display_lat?: Maybe<Scalars['Float']>;
  display_long?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  precise_lat?: Maybe<Scalars['Float']>;
  precise_long?: Maybe<Scalars['Float']>;
  sls_lat?: Maybe<Scalars['Float']>;
  sls_long?: Maybe<Scalars['Float']>;
  user_lat?: Maybe<Scalars['Float']>;
  user_long?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsVarSampOrderBy = {
  address_id?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewDisplaysLocationsVarianceFields = {
  __typename?: 'view_displays_locations_variance_fields';
  address_id?: Maybe<Scalars['Float']>;
  display_lat?: Maybe<Scalars['Float']>;
  display_long?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  precise_lat?: Maybe<Scalars['Float']>;
  precise_long?: Maybe<Scalars['Float']>;
  sls_lat?: Maybe<Scalars['Float']>;
  sls_long?: Maybe<Scalars['Float']>;
  user_lat?: Maybe<Scalars['Float']>;
  user_long?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_displays_locations" */
export type ViewDisplaysLocationsVarianceOrderBy = {
  address_id?: Maybe<OrderBy>;
  display_lat?: Maybe<OrderBy>;
  display_long?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  sls_lat?: Maybe<OrderBy>;
  sls_long?: Maybe<OrderBy>;
  user_lat?: Maybe<OrderBy>;
  user_long?: Maybe<OrderBy>;
};

/** columns and relationships of "view_displays_management_status" */
export type ViewDisplaysManagementStatus = {
  __typename?: 'view_displays_management_status';
  status?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "view_displays_management_status". All fields are combined with a logical 'AND'. */
export type ViewDisplaysManagementStatusBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysManagementStatusBoolExp>>>;
  _not?: Maybe<ViewDisplaysManagementStatusBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysManagementStatusBoolExp>>>;
  status?: Maybe<StringComparisonExp>;
};

/** ordering options when selecting data from "view_displays_management_status" */
export type ViewDisplaysManagementStatusOrderBy = {
  status?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_management_status" */
export enum ViewDisplaysManagementStatusSelectColumn {
  /** column name */
  Status = 'status'
}

/** aggregate max on columns */
export type ViewDisplaysMaxFields = {
  __typename?: 'view_displays_max_fields';
  deploy_date?: Maybe<Scalars['timestamptz']>;
  flexera_maintenance_end?: Maybe<Scalars['String']>;
  hardware?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ip_addresses_primary?: Maybe<Scalars['String']>;
  maintenance_end?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  onboard_date?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_displays" */
export type ViewDisplaysMaxOrderBy = {
  deploy_date?: Maybe<OrderBy>;
  flexera_maintenance_end?: Maybe<OrderBy>;
  hardware?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip_addresses_primary?: Maybe<OrderBy>;
  maintenance_end?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  onboard_date?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  timezone?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysMinFields = {
  __typename?: 'view_displays_min_fields';
  deploy_date?: Maybe<Scalars['timestamptz']>;
  flexera_maintenance_end?: Maybe<Scalars['String']>;
  hardware?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ip_addresses_primary?: Maybe<Scalars['String']>;
  maintenance_end?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  onboard_date?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_displays" */
export type ViewDisplaysMinOrderBy = {
  deploy_date?: Maybe<OrderBy>;
  flexera_maintenance_end?: Maybe<OrderBy>;
  hardware?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip_addresses_primary?: Maybe<OrderBy>;
  maintenance_end?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  onboard_date?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  timezone?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays" */
export type ViewDisplaysOrderBy = {
  availability?: Maybe<ViewDisplaysAvailabilityOrderBy>;
  catching_up?: Maybe<OrderBy>;
  current_usb_connections_aggregate?: Maybe<ViewDisplaysCurrentUsbConnectionsAggregateOrderBy>;
  deploy_date?: Maybe<OrderBy>;
  deployment?: Maybe<ViewDisplaysDeploymentOrderBy>;
  discover_realtime?: Maybe<ViewDisplaysDiscoverRealtimeOrderBy>;
  flexera_maintenance_end?: Maybe<OrderBy>;
  hardware?: Maybe<OrderBy>;
  has_unconfirmed_templates?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip_addresses_primary?: Maybe<OrderBy>;
  is_in_subscription?: Maybe<OrderBy>;
  is_manageable?: Maybe<OrderBy>;
  is_online?: Maybe<OrderBy>;
  lms_version?: Maybe<OrderBy>;
  location?: Maybe<ViewDisplaysLocationsOrderBy>;
  maintenance_end?: Maybe<OrderBy>;
  management_status?: Maybe<ViewDisplaysManagementStatusOrderBy>;
  meetings_aggregate?: Maybe<ViewMeetingsAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  onboard_date?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  realtime?: Maybe<ViewDisplaysRealtimeOrderBy>;
  subscription?: Maybe<ViewDisplaysSubscriptionsOrderBy>;
  timezone?: Maybe<OrderBy>;
  updateability?: Maybe<ViewDisplaysUpdateabilityOrderBy>;
  uuid?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
  wellness_config?: Maybe<ViewDisplaysWellnessConfigOrderBy>;
  wellness_realtime?: Maybe<ViewDisplaysDiscoverRealtimeOrderBy>;
};

/** columns and relationships of "view_displays_realtime" */
export type ViewDisplaysRealtime = {
  __typename?: 'view_displays_realtime';
  cal_enabled?: Maybe<Scalars['Boolean']>;
  display?: Maybe<Scalars['String']>;
  my_status?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  solstice_users?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "view_displays_realtime" */
export type ViewDisplaysRealtimeAggregate = {
  __typename?: 'view_displays_realtime_aggregate';
  aggregate?: Maybe<ViewDisplaysRealtimeAggregateFields>;
  nodes: Array<ViewDisplaysRealtime>;
};

/** aggregate fields of "view_displays_realtime" */
export type ViewDisplaysRealtimeAggregateFields = {
  __typename?: 'view_displays_realtime_aggregate_fields';
  avg?: Maybe<ViewDisplaysRealtimeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysRealtimeMaxFields>;
  min?: Maybe<ViewDisplaysRealtimeMinFields>;
  stddev?: Maybe<ViewDisplaysRealtimeStddevFields>;
  stddev_pop?: Maybe<ViewDisplaysRealtimeStddevPopFields>;
  stddev_samp?: Maybe<ViewDisplaysRealtimeStddevSampFields>;
  sum?: Maybe<ViewDisplaysRealtimeSumFields>;
  var_pop?: Maybe<ViewDisplaysRealtimeVarPopFields>;
  var_samp?: Maybe<ViewDisplaysRealtimeVarSampFields>;
  variance?: Maybe<ViewDisplaysRealtimeVarianceFields>;
};


/** aggregate fields of "view_displays_realtime" */
export type ViewDisplaysRealtimeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysRealtimeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_realtime" */
export type ViewDisplaysRealtimeAggregateOrderBy = {
  avg?: Maybe<ViewDisplaysRealtimeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysRealtimeMaxOrderBy>;
  min?: Maybe<ViewDisplaysRealtimeMinOrderBy>;
  stddev?: Maybe<ViewDisplaysRealtimeStddevOrderBy>;
  stddev_pop?: Maybe<ViewDisplaysRealtimeStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewDisplaysRealtimeStddevSampOrderBy>;
  sum?: Maybe<ViewDisplaysRealtimeSumOrderBy>;
  var_pop?: Maybe<ViewDisplaysRealtimeVarPopOrderBy>;
  var_samp?: Maybe<ViewDisplaysRealtimeVarSampOrderBy>;
  variance?: Maybe<ViewDisplaysRealtimeVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewDisplaysRealtimeAvgFields = {
  __typename?: 'view_displays_realtime_avg_fields';
  solstice_users?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeAvgOrderBy = {
  solstice_users?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_realtime". All fields are combined with a logical 'AND'. */
export type ViewDisplaysRealtimeBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysRealtimeBoolExp>>>;
  _not?: Maybe<ViewDisplaysRealtimeBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysRealtimeBoolExp>>>;
  cal_enabled?: Maybe<BooleanComparisonExp>;
  display?: Maybe<StringComparisonExp>;
  my_status?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  solstice_users?: Maybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplaysRealtimeMaxFields = {
  __typename?: 'view_displays_realtime_max_fields';
  display?: Maybe<Scalars['String']>;
  my_status?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  solstice_users?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeMaxOrderBy = {
  display?: Maybe<OrderBy>;
  my_status?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  solstice_users?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysRealtimeMinFields = {
  __typename?: 'view_displays_realtime_min_fields';
  display?: Maybe<Scalars['String']>;
  my_status?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  solstice_users?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeMinOrderBy = {
  display?: Maybe<OrderBy>;
  my_status?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  solstice_users?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_realtime" */
export type ViewDisplaysRealtimeOrderBy = {
  cal_enabled?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  my_status?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  solstice_users?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_realtime" */
export enum ViewDisplaysRealtimeSelectColumn {
  /** column name */
  CalEnabled = 'cal_enabled',
  /** column name */
  Display = 'display',
  /** column name */
  MyStatus = 'my_status',
  /** column name */
  Org = 'org',
  /** column name */
  SolsticeUsers = 'solstice_users'
}

/** aggregate stddev on columns */
export type ViewDisplaysRealtimeStddevFields = {
  __typename?: 'view_displays_realtime_stddev_fields';
  solstice_users?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeStddevOrderBy = {
  solstice_users?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewDisplaysRealtimeStddevPopFields = {
  __typename?: 'view_displays_realtime_stddev_pop_fields';
  solstice_users?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeStddevPopOrderBy = {
  solstice_users?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewDisplaysRealtimeStddevSampFields = {
  __typename?: 'view_displays_realtime_stddev_samp_fields';
  solstice_users?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeStddevSampOrderBy = {
  solstice_users?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewDisplaysRealtimeSumFields = {
  __typename?: 'view_displays_realtime_sum_fields';
  solstice_users?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeSumOrderBy = {
  solstice_users?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewDisplaysRealtimeVarPopFields = {
  __typename?: 'view_displays_realtime_var_pop_fields';
  solstice_users?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeVarPopOrderBy = {
  solstice_users?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewDisplaysRealtimeVarSampFields = {
  __typename?: 'view_displays_realtime_var_samp_fields';
  solstice_users?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeVarSampOrderBy = {
  solstice_users?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewDisplaysRealtimeVarianceFields = {
  __typename?: 'view_displays_realtime_variance_fields';
  solstice_users?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_displays_realtime" */
export type ViewDisplaysRealtimeVarianceOrderBy = {
  solstice_users?: Maybe<OrderBy>;
};

/** select columns of table "view_displays" */
export enum ViewDisplaysSelectColumn {
  /** column name */
  CatchingUp = 'catching_up',
  /** column name */
  DeployDate = 'deploy_date',
  /** column name */
  FlexeraMaintenanceEnd = 'flexera_maintenance_end',
  /** column name */
  Hardware = 'hardware',
  /** column name */
  HasUnconfirmedTemplates = 'has_unconfirmed_templates',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddressesPrimary = 'ip_addresses_primary',
  /** column name */
  IsInSubscription = 'is_in_subscription',
  /** column name */
  IsManageable = 'is_manageable',
  /** column name */
  IsOnline = 'is_online',
  /** column name */
  LmsVersion = 'lms_version',
  /** column name */
  MaintenanceEnd = 'maintenance_end',
  /** column name */
  Name = 'name',
  /** column name */
  OnboardDate = 'onboard_date',
  /** column name */
  Org = 'org',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  Version = 'version'
}

/** columns and relationships of "view_displays_subscriptions" */
export type ViewDisplaysSubscriptions = {
  __typename?: 'view_displays_subscriptions';
  display_id?: Maybe<Scalars['String']>;
  flexera_maintenance_end?: Maybe<Scalars['timestamptz']>;
  is_expiring_soon?: Maybe<Scalars['Boolean']>;
  is_in_subscription?: Maybe<Scalars['Boolean']>;
  maintenance_end?: Maybe<Scalars['timestamptz']>;
  org?: Maybe<Scalars['String']>;
  subscription_date?: Maybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "view_displays_subscriptions". All fields are combined with a logical 'AND'. */
export type ViewDisplaysSubscriptionsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysSubscriptionsBoolExp>>>;
  _not?: Maybe<ViewDisplaysSubscriptionsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysSubscriptionsBoolExp>>>;
  display_id?: Maybe<StringComparisonExp>;
  flexera_maintenance_end?: Maybe<TimestamptzComparisonExp>;
  is_expiring_soon?: Maybe<BooleanComparisonExp>;
  is_in_subscription?: Maybe<BooleanComparisonExp>;
  maintenance_end?: Maybe<TimestamptzComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  subscription_date?: Maybe<TimestamptzComparisonExp>;
};

/** ordering options when selecting data from "view_displays_subscriptions" */
export type ViewDisplaysSubscriptionsOrderBy = {
  display_id?: Maybe<OrderBy>;
  flexera_maintenance_end?: Maybe<OrderBy>;
  is_expiring_soon?: Maybe<OrderBy>;
  is_in_subscription?: Maybe<OrderBy>;
  maintenance_end?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  subscription_date?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_subscriptions" */
export enum ViewDisplaysSubscriptionsSelectColumn {
  /** column name */
  DisplayId = 'display_id',
  /** column name */
  FlexeraMaintenanceEnd = 'flexera_maintenance_end',
  /** column name */
  IsExpiringSoon = 'is_expiring_soon',
  /** column name */
  IsInSubscription = 'is_in_subscription',
  /** column name */
  MaintenanceEnd = 'maintenance_end',
  /** column name */
  Org = 'org',
  /** column name */
  SubscriptionDate = 'subscription_date'
}

/** columns and relationships of "view_displays_updateability" */
export type ViewDisplaysUpdateability = {
  __typename?: 'view_displays_updateability';
  current_datetime?: Maybe<Scalars['timestamp']>;
  display_id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updateable?: Maybe<Scalars['Boolean']>;
  updating?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['_int4']>;
};

/** aggregated selection of "view_displays_updateability" */
export type ViewDisplaysUpdateabilityAggregate = {
  __typename?: 'view_displays_updateability_aggregate';
  aggregate?: Maybe<ViewDisplaysUpdateabilityAggregateFields>;
  nodes: Array<ViewDisplaysUpdateability>;
};

/** aggregate fields of "view_displays_updateability" */
export type ViewDisplaysUpdateabilityAggregateFields = {
  __typename?: 'view_displays_updateability_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysUpdateabilityMaxFields>;
  min?: Maybe<ViewDisplaysUpdateabilityMinFields>;
};


/** aggregate fields of "view_displays_updateability" */
export type ViewDisplaysUpdateabilityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysUpdateabilitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_updateability" */
export type ViewDisplaysUpdateabilityAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysUpdateabilityMaxOrderBy>;
  min?: Maybe<ViewDisplaysUpdateabilityMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_updateability". All fields are combined with a logical 'AND'. */
export type ViewDisplaysUpdateabilityBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysUpdateabilityBoolExp>>>;
  _not?: Maybe<ViewDisplaysUpdateabilityBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysUpdateabilityBoolExp>>>;
  current_datetime?: Maybe<TimestampComparisonExp>;
  display_id?: Maybe<StringComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  updateable?: Maybe<BooleanComparisonExp>;
  updating?: Maybe<BooleanComparisonExp>;
  version?: Maybe<Int4ComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplaysUpdateabilityMaxFields = {
  __typename?: 'view_displays_updateability_max_fields';
  current_datetime?: Maybe<Scalars['timestamp']>;
  display_id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_displays_updateability" */
export type ViewDisplaysUpdateabilityMaxOrderBy = {
  current_datetime?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysUpdateabilityMinFields = {
  __typename?: 'view_displays_updateability_min_fields';
  current_datetime?: Maybe<Scalars['timestamp']>;
  display_id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_displays_updateability" */
export type ViewDisplaysUpdateabilityMinOrderBy = {
  current_datetime?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_updateability" */
export type ViewDisplaysUpdateabilityOrderBy = {
  current_datetime?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updateable?: Maybe<OrderBy>;
  updating?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_updateability" */
export enum ViewDisplaysUpdateabilitySelectColumn {
  /** column name */
  CurrentDatetime = 'current_datetime',
  /** column name */
  DisplayId = 'display_id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Status = 'status',
  /** column name */
  Updateable = 'updateable',
  /** column name */
  Updating = 'updating',
  /** column name */
  Version = 'version'
}

/** columns and relationships of "view_displays_wellness_config" */
export type ViewDisplaysWellnessConfig = {
  __typename?: 'view_displays_wellness_config';
  calendar_config_enabled?: Maybe<Scalars['Boolean']>;
  display?: Maybe<Scalars['String']>;
  location_enabled?: Maybe<Scalars['Boolean']>;
  location_set?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  occ_enabled?: Maybe<Scalars['Boolean']>;
  org?: Maybe<Scalars['String']>;
  plugged_in?: Maybe<Scalars['Boolean']>;
  usb_device?: Maybe<Scalars['String']>;
  usb_plug_timestamp?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
  wellness_enabled?: Maybe<Scalars['Boolean']>;
  wellness_ready?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "view_displays_wellness_config" */
export type ViewDisplaysWellnessConfigAggregate = {
  __typename?: 'view_displays_wellness_config_aggregate';
  aggregate?: Maybe<ViewDisplaysWellnessConfigAggregateFields>;
  nodes: Array<ViewDisplaysWellnessConfig>;
};

/** aggregate fields of "view_displays_wellness_config" */
export type ViewDisplaysWellnessConfigAggregateFields = {
  __typename?: 'view_displays_wellness_config_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewDisplaysWellnessConfigMaxFields>;
  min?: Maybe<ViewDisplaysWellnessConfigMinFields>;
};


/** aggregate fields of "view_displays_wellness_config" */
export type ViewDisplaysWellnessConfigAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewDisplaysWellnessConfigSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_displays_wellness_config" */
export type ViewDisplaysWellnessConfigAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewDisplaysWellnessConfigMaxOrderBy>;
  min?: Maybe<ViewDisplaysWellnessConfigMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_displays_wellness_config". All fields are combined with a logical 'AND'. */
export type ViewDisplaysWellnessConfigBoolExp = {
  _and?: Maybe<Array<Maybe<ViewDisplaysWellnessConfigBoolExp>>>;
  _not?: Maybe<ViewDisplaysWellnessConfigBoolExp>;
  _or?: Maybe<Array<Maybe<ViewDisplaysWellnessConfigBoolExp>>>;
  calendar_config_enabled?: Maybe<BooleanComparisonExp>;
  display?: Maybe<StringComparisonExp>;
  location_enabled?: Maybe<BooleanComparisonExp>;
  location_set?: Maybe<BooleanComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  occ_enabled?: Maybe<BooleanComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  plugged_in?: Maybe<BooleanComparisonExp>;
  usb_device?: Maybe<StringComparisonExp>;
  usb_plug_timestamp?: Maybe<TimestamptzComparisonExp>;
  version?: Maybe<StringComparisonExp>;
  wellness_enabled?: Maybe<BooleanComparisonExp>;
  wellness_ready?: Maybe<BooleanComparisonExp>;
};

/** aggregate max on columns */
export type ViewDisplaysWellnessConfigMaxFields = {
  __typename?: 'view_displays_wellness_config_max_fields';
  display?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  usb_device?: Maybe<Scalars['String']>;
  usb_plug_timestamp?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_displays_wellness_config" */
export type ViewDisplaysWellnessConfigMaxOrderBy = {
  display?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  usb_device?: Maybe<OrderBy>;
  usb_plug_timestamp?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewDisplaysWellnessConfigMinFields = {
  __typename?: 'view_displays_wellness_config_min_fields';
  display?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  usb_device?: Maybe<Scalars['String']>;
  usb_plug_timestamp?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_displays_wellness_config" */
export type ViewDisplaysWellnessConfigMinOrderBy = {
  display?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  usb_device?: Maybe<OrderBy>;
  usb_plug_timestamp?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_displays_wellness_config" */
export type ViewDisplaysWellnessConfigOrderBy = {
  calendar_config_enabled?: Maybe<OrderBy>;
  display?: Maybe<OrderBy>;
  location_enabled?: Maybe<OrderBy>;
  location_set?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  occ_enabled?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  plugged_in?: Maybe<OrderBy>;
  usb_device?: Maybe<OrderBy>;
  usb_plug_timestamp?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
  wellness_enabled?: Maybe<OrderBy>;
  wellness_ready?: Maybe<OrderBy>;
};

/** select columns of table "view_displays_wellness_config" */
export enum ViewDisplaysWellnessConfigSelectColumn {
  /** column name */
  CalendarConfigEnabled = 'calendar_config_enabled',
  /** column name */
  Display = 'display',
  /** column name */
  LocationEnabled = 'location_enabled',
  /** column name */
  LocationSet = 'location_set',
  /** column name */
  Name = 'name',
  /** column name */
  OccEnabled = 'occ_enabled',
  /** column name */
  Org = 'org',
  /** column name */
  PluggedIn = 'plugged_in',
  /** column name */
  UsbDevice = 'usb_device',
  /** column name */
  UsbPlugTimestamp = 'usb_plug_timestamp',
  /** column name */
  Version = 'version',
  /** column name */
  WellnessEnabled = 'wellness_enabled',
  /** column name */
  WellnessReady = 'wellness_ready'
}

/** columns and relationships of "view_floor_count" */
export type ViewFloorCount = {
  __typename?: 'view_floor_count';
  address_id?: Maybe<Scalars['Int']>;
  count_per_floor?: Maybe<Scalars['bigint']>;
  floor?: Maybe<Scalars['Int']>;
  org?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "view_floor_count". All fields are combined with a logical 'AND'. */
export type ViewFloorCountBoolExp = {
  _and?: Maybe<Array<Maybe<ViewFloorCountBoolExp>>>;
  _not?: Maybe<ViewFloorCountBoolExp>;
  _or?: Maybe<Array<Maybe<ViewFloorCountBoolExp>>>;
  address_id?: Maybe<IntComparisonExp>;
  count_per_floor?: Maybe<BigintComparisonExp>;
  floor?: Maybe<IntComparisonExp>;
  org?: Maybe<StringComparisonExp>;
};

/** ordering options when selecting data from "view_floor_count" */
export type ViewFloorCountOrderBy = {
  address_id?: Maybe<OrderBy>;
  count_per_floor?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
};

/** select columns of table "view_floor_count" */
export enum ViewFloorCountSelectColumn {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CountPerFloor = 'count_per_floor',
  /** column name */
  Floor = 'floor',
  /** column name */
  Org = 'org'
}

/** columns and relationships of "view_home_page_map" */
export type ViewHomePageMap = {
  __typename?: 'view_home_page_map';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  out_of_template_count?: Maybe<Scalars['bigint']>;
  precise_lat?: Maybe<Scalars['float8']>;
  precise_long?: Maybe<Scalars['float8']>;
  restart_count?: Maybe<Scalars['bigint']>;
  total_alerts?: Maybe<Scalars['bigint']>;
  unreachable_count?: Maybe<Scalars['bigint']>;
  usage_hours?: Maybe<Scalars['float8']>;
  usb_added_event_count?: Maybe<Scalars['bigint']>;
  usb_removed_event_count?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "view_home_page_map". All fields are combined with a logical 'AND'. */
export type ViewHomePageMapBoolExp = {
  _and?: Maybe<Array<Maybe<ViewHomePageMapBoolExp>>>;
  _not?: Maybe<ViewHomePageMapBoolExp>;
  _or?: Maybe<Array<Maybe<ViewHomePageMapBoolExp>>>;
  id?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  out_of_template_count?: Maybe<BigintComparisonExp>;
  precise_lat?: Maybe<Float8ComparisonExp>;
  precise_long?: Maybe<Float8ComparisonExp>;
  restart_count?: Maybe<BigintComparisonExp>;
  total_alerts?: Maybe<BigintComparisonExp>;
  unreachable_count?: Maybe<BigintComparisonExp>;
  usage_hours?: Maybe<Float8ComparisonExp>;
  usb_added_event_count?: Maybe<BigintComparisonExp>;
  usb_removed_event_count?: Maybe<BigintComparisonExp>;
};

/** ordering options when selecting data from "view_home_page_map" */
export type ViewHomePageMapOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  out_of_template_count?: Maybe<OrderBy>;
  precise_lat?: Maybe<OrderBy>;
  precise_long?: Maybe<OrderBy>;
  restart_count?: Maybe<OrderBy>;
  total_alerts?: Maybe<OrderBy>;
  unreachable_count?: Maybe<OrderBy>;
  usage_hours?: Maybe<OrderBy>;
  usb_added_event_count?: Maybe<OrderBy>;
  usb_removed_event_count?: Maybe<OrderBy>;
};

/** select columns of table "view_home_page_map" */
export enum ViewHomePageMapSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OutOfTemplateCount = 'out_of_template_count',
  /** column name */
  PreciseLat = 'precise_lat',
  /** column name */
  PreciseLong = 'precise_long',
  /** column name */
  RestartCount = 'restart_count',
  /** column name */
  TotalAlerts = 'total_alerts',
  /** column name */
  UnreachableCount = 'unreachable_count',
  /** column name */
  UsageHours = 'usage_hours',
  /** column name */
  UsbAddedEventCount = 'usb_added_event_count',
  /** column name */
  UsbRemovedEventCount = 'usb_removed_event_count'
}

/** columns and relationships of "view_hybrid_meeting_aggregation_result" */
export type ViewHybridMeetingAggregationResult = {
  __typename?: 'view_hybrid_meeting_aggregation_result';
  date?: Maybe<Scalars['timestamp']>;
  org_id?: Maybe<Scalars['String']>;
  total_proximate_meetings?: Maybe<Scalars['bigint']>;
  total_remote_meetings?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "view_hybrid_meeting_aggregation_result". All fields are combined with a logical 'AND'. */
export type ViewHybridMeetingAggregationResultBoolExp = {
  _and?: Maybe<Array<Maybe<ViewHybridMeetingAggregationResultBoolExp>>>;
  _not?: Maybe<ViewHybridMeetingAggregationResultBoolExp>;
  _or?: Maybe<Array<Maybe<ViewHybridMeetingAggregationResultBoolExp>>>;
  date?: Maybe<TimestampComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
  total_proximate_meetings?: Maybe<BigintComparisonExp>;
  total_remote_meetings?: Maybe<BigintComparisonExp>;
};

/** ordering options when selecting data from "view_hybrid_meeting_aggregation_result" */
export type ViewHybridMeetingAggregationResultOrderBy = {
  date?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  total_proximate_meetings?: Maybe<OrderBy>;
  total_remote_meetings?: Maybe<OrderBy>;
};

/** select columns of table "view_hybrid_meeting_aggregation_result" */
export enum ViewHybridMeetingAggregationResultSelectColumn {
  /** column name */
  Date = 'date',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  TotalProximateMeetings = 'total_proximate_meetings',
  /** column name */
  TotalRemoteMeetings = 'total_remote_meetings'
}

/** columns and relationships of "view_meetings" */
export type ViewMeetings = {
  __typename?: 'view_meetings';
  display?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  meeting_length_in_hours?: Maybe<Scalars['float8']>;
  org?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "view_meetings" */
export type ViewMeetingsAggregate = {
  __typename?: 'view_meetings_aggregate';
  aggregate?: Maybe<ViewMeetingsAggregateFields>;
  nodes: Array<ViewMeetings>;
};

/** aggregate fields of "view_meetings" */
export type ViewMeetingsAggregateFields = {
  __typename?: 'view_meetings_aggregate_fields';
  avg?: Maybe<ViewMeetingsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewMeetingsMaxFields>;
  min?: Maybe<ViewMeetingsMinFields>;
  stddev?: Maybe<ViewMeetingsStddevFields>;
  stddev_pop?: Maybe<ViewMeetingsStddevPopFields>;
  stddev_samp?: Maybe<ViewMeetingsStddevSampFields>;
  sum?: Maybe<ViewMeetingsSumFields>;
  var_pop?: Maybe<ViewMeetingsVarPopFields>;
  var_samp?: Maybe<ViewMeetingsVarSampFields>;
  variance?: Maybe<ViewMeetingsVarianceFields>;
};


/** aggregate fields of "view_meetings" */
export type ViewMeetingsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewMeetingsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_meetings" */
export type ViewMeetingsAggregateOrderBy = {
  avg?: Maybe<ViewMeetingsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewMeetingsMaxOrderBy>;
  min?: Maybe<ViewMeetingsMinOrderBy>;
  stddev?: Maybe<ViewMeetingsStddevOrderBy>;
  stddev_pop?: Maybe<ViewMeetingsStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewMeetingsStddevSampOrderBy>;
  sum?: Maybe<ViewMeetingsSumOrderBy>;
  var_pop?: Maybe<ViewMeetingsVarPopOrderBy>;
  var_samp?: Maybe<ViewMeetingsVarSampOrderBy>;
  variance?: Maybe<ViewMeetingsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewMeetingsAvgFields = {
  __typename?: 'view_meetings_avg_fields';
  meeting_length_in_hours?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_meetings" */
export type ViewMeetingsAvgOrderBy = {
  meeting_length_in_hours?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_meetings". All fields are combined with a logical 'AND'. */
export type ViewMeetingsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewMeetingsBoolExp>>>;
  _not?: Maybe<ViewMeetingsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewMeetingsBoolExp>>>;
  display?: Maybe<StringComparisonExp>;
  end?: Maybe<TimestamptzComparisonExp>;
  meeting_length_in_hours?: Maybe<Float8ComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  start?: Maybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type ViewMeetingsMaxFields = {
  __typename?: 'view_meetings_max_fields';
  display?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  meeting_length_in_hours?: Maybe<Scalars['float8']>;
  org?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "view_meetings" */
export type ViewMeetingsMaxOrderBy = {
  display?: Maybe<OrderBy>;
  end?: Maybe<OrderBy>;
  meeting_length_in_hours?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  start?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewMeetingsMinFields = {
  __typename?: 'view_meetings_min_fields';
  display?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  meeting_length_in_hours?: Maybe<Scalars['float8']>;
  org?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "view_meetings" */
export type ViewMeetingsMinOrderBy = {
  display?: Maybe<OrderBy>;
  end?: Maybe<OrderBy>;
  meeting_length_in_hours?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  start?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_meetings" */
export type ViewMeetingsOrderBy = {
  display?: Maybe<OrderBy>;
  end?: Maybe<OrderBy>;
  meeting_length_in_hours?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  start?: Maybe<OrderBy>;
};

/** select columns of table "view_meetings" */
export enum ViewMeetingsSelectColumn {
  /** column name */
  Display = 'display',
  /** column name */
  End = 'end',
  /** column name */
  MeetingLengthInHours = 'meeting_length_in_hours',
  /** column name */
  Org = 'org',
  /** column name */
  Start = 'start'
}

/** aggregate stddev on columns */
export type ViewMeetingsStddevFields = {
  __typename?: 'view_meetings_stddev_fields';
  meeting_length_in_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_meetings" */
export type ViewMeetingsStddevOrderBy = {
  meeting_length_in_hours?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewMeetingsStddevPopFields = {
  __typename?: 'view_meetings_stddev_pop_fields';
  meeting_length_in_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_meetings" */
export type ViewMeetingsStddevPopOrderBy = {
  meeting_length_in_hours?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewMeetingsStddevSampFields = {
  __typename?: 'view_meetings_stddev_samp_fields';
  meeting_length_in_hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_meetings" */
export type ViewMeetingsStddevSampOrderBy = {
  meeting_length_in_hours?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewMeetingsSumFields = {
  __typename?: 'view_meetings_sum_fields';
  meeting_length_in_hours?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "view_meetings" */
export type ViewMeetingsSumOrderBy = {
  meeting_length_in_hours?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewMeetingsVarPopFields = {
  __typename?: 'view_meetings_var_pop_fields';
  meeting_length_in_hours?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_meetings" */
export type ViewMeetingsVarPopOrderBy = {
  meeting_length_in_hours?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewMeetingsVarSampFields = {
  __typename?: 'view_meetings_var_samp_fields';
  meeting_length_in_hours?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_meetings" */
export type ViewMeetingsVarSampOrderBy = {
  meeting_length_in_hours?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewMeetingsVarianceFields = {
  __typename?: 'view_meetings_variance_fields';
  meeting_length_in_hours?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_meetings" */
export type ViewMeetingsVarianceOrderBy = {
  meeting_length_in_hours?: Maybe<OrderBy>;
};

/** columns and relationships of "view_most_collaborative_spaces_result" */
export type ViewMostCollaborativeSpacesResult = {
  __typename?: 'view_most_collaborative_spaces_result';
  address?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Scalars['numeric']>;
  display_id?: Maybe<Scalars['String']>;
  location_latitude?: Maybe<Scalars['float8']>;
  location_longitude?: Maybe<Scalars['float8']>;
  mtngs?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  plot_score?: Maybe<Scalars['numeric']>;
  room_type?: Maybe<Scalars['String']>;
  unq_users_avg?: Maybe<Scalars['numeric']>;
};

/** Boolean expression to filter rows from the table "view_most_collaborative_spaces_result". All fields are combined with a logical 'AND'. */
export type ViewMostCollaborativeSpacesResultBoolExp = {
  _and?: Maybe<Array<Maybe<ViewMostCollaborativeSpacesResultBoolExp>>>;
  _not?: Maybe<ViewMostCollaborativeSpacesResultBoolExp>;
  _or?: Maybe<Array<Maybe<ViewMostCollaborativeSpacesResultBoolExp>>>;
  address?: Maybe<StringComparisonExp>;
  building?: Maybe<StringComparisonExp>;
  collaborators?: Maybe<NumericComparisonExp>;
  display_id?: Maybe<StringComparisonExp>;
  location_latitude?: Maybe<Float8ComparisonExp>;
  location_longitude?: Maybe<Float8ComparisonExp>;
  mtngs?: Maybe<BigintComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  nickname?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  plot_score?: Maybe<NumericComparisonExp>;
  room_type?: Maybe<StringComparisonExp>;
  unq_users_avg?: Maybe<NumericComparisonExp>;
};

/** ordering options when selecting data from "view_most_collaborative_spaces_result" */
export type ViewMostCollaborativeSpacesResultOrderBy = {
  address?: Maybe<OrderBy>;
  building?: Maybe<OrderBy>;
  collaborators?: Maybe<OrderBy>;
  display_id?: Maybe<OrderBy>;
  location_latitude?: Maybe<OrderBy>;
  location_longitude?: Maybe<OrderBy>;
  mtngs?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  nickname?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  plot_score?: Maybe<OrderBy>;
  room_type?: Maybe<OrderBy>;
  unq_users_avg?: Maybe<OrderBy>;
};

/** select columns of table "view_most_collaborative_spaces_result" */
export enum ViewMostCollaborativeSpacesResultSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  Building = 'building',
  /** column name */
  Collaborators = 'collaborators',
  /** column name */
  DisplayId = 'display_id',
  /** column name */
  LocationLatitude = 'location_latitude',
  /** column name */
  LocationLongitude = 'location_longitude',
  /** column name */
  Mtngs = 'mtngs',
  /** column name */
  Name = 'name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Org = 'org',
  /** column name */
  PlotScore = 'plot_score',
  /** column name */
  RoomType = 'room_type',
  /** column name */
  UnqUsersAvg = 'unq_users_avg'
}

/** columns and relationships of "view_options" */
export type ViewOptions = {
  __typename?: 'view_options';
  /** An array relationship */
  assignedDisplays: Array<ViewDisplaysAssignedOptions>;
  /** An aggregated array relationship */
  assignedDisplays_aggregate: ViewDisplaysAssignedOptionsAggregate;
  /** An object relationship */
  category?: Maybe<ViewCategories>;
  category_id?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "view_options" */
export type ViewOptionsAssignedDisplaysArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAssignedOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAssignedOptionsOrderBy>>;
  where?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
};


/** columns and relationships of "view_options" */
export type ViewOptionsAssignedDisplaysAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysAssignedOptionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysAssignedOptionsOrderBy>>;
  where?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
};

/** aggregated selection of "view_options" */
export type ViewOptionsAggregate = {
  __typename?: 'view_options_aggregate';
  aggregate?: Maybe<ViewOptionsAggregateFields>;
  nodes: Array<ViewOptions>;
};

/** aggregate fields of "view_options" */
export type ViewOptionsAggregateFields = {
  __typename?: 'view_options_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewOptionsMaxFields>;
  min?: Maybe<ViewOptionsMinFields>;
};


/** aggregate fields of "view_options" */
export type ViewOptionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewOptionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_options" */
export type ViewOptionsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewOptionsMaxOrderBy>;
  min?: Maybe<ViewOptionsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_options". All fields are combined with a logical 'AND'. */
export type ViewOptionsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewOptionsBoolExp>>>;
  _not?: Maybe<ViewOptionsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewOptionsBoolExp>>>;
  assignedDisplays?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
  category?: Maybe<ViewCategoriesBoolExp>;
  category_id?: Maybe<StringComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  hidden?: Maybe<BooleanComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewOptionsMaxFields = {
  __typename?: 'view_options_max_fields';
  category_id?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_options" */
export type ViewOptionsMaxOrderBy = {
  category_id?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewOptionsMinFields = {
  __typename?: 'view_options_min_fields';
  category_id?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_options" */
export type ViewOptionsMinOrderBy = {
  category_id?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_options" */
export type ViewOptionsOrderBy = {
  assignedDisplays_aggregate?: Maybe<ViewDisplaysAssignedOptionsAggregateOrderBy>;
  category?: Maybe<ViewCategoriesOrderBy>;
  category_id?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  hidden?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** select columns of table "view_options" */
export enum ViewOptionsSelectColumn {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'org_id'
}

/** columns and relationships of "view_sds" */
export type ViewSds = {
  __typename?: 'view_sds';
  /** An array relationship */
  displays: Array<ViewDisplaysHosts>;
  /** An aggregated array relationship */
  displays_aggregate: ViewDisplaysHostsAggregate;
  host?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "view_sds" */
export type ViewSdsDisplaysArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysHostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysHostsOrderBy>>;
  where?: Maybe<ViewDisplaysHostsBoolExp>;
};


/** columns and relationships of "view_sds" */
export type ViewSdsDisplaysAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysHostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysHostsOrderBy>>;
  where?: Maybe<ViewDisplaysHostsBoolExp>;
};

/** aggregated selection of "view_sds" */
export type ViewSdsAggregate = {
  __typename?: 'view_sds_aggregate';
  aggregate?: Maybe<ViewSdsAggregateFields>;
  nodes: Array<ViewSds>;
};

/** aggregate fields of "view_sds" */
export type ViewSdsAggregateFields = {
  __typename?: 'view_sds_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewSdsMaxFields>;
  min?: Maybe<ViewSdsMinFields>;
};


/** aggregate fields of "view_sds" */
export type ViewSdsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewSdsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_sds" */
export type ViewSdsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewSdsMaxOrderBy>;
  min?: Maybe<ViewSdsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_sds". All fields are combined with a logical 'AND'. */
export type ViewSdsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewSdsBoolExp>>>;
  _not?: Maybe<ViewSdsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewSdsBoolExp>>>;
  displays?: Maybe<ViewDisplaysHostsBoolExp>;
  host?: Maybe<StringComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewSdsMaxFields = {
  __typename?: 'view_sds_max_fields';
  host?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_sds" */
export type ViewSdsMaxOrderBy = {
  host?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewSdsMinFields = {
  __typename?: 'view_sds_min_fields';
  host?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_sds" */
export type ViewSdsMinOrderBy = {
  host?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_sds" */
export type ViewSdsOrderBy = {
  displays_aggregate?: Maybe<ViewDisplaysHostsAggregateOrderBy>;
  host?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
};

/** select columns of table "view_sds" */
export enum ViewSdsSelectColumn {
  /** column name */
  Host = 'host',
  /** column name */
  OrgId = 'org_id'
}

/** columns and relationships of "view_single_result" */
export type ViewSingleResult = {
  __typename?: 'view_single_result';
  org?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['float8']>;
};

/** Boolean expression to filter rows from the table "view_single_result". All fields are combined with a logical 'AND'. */
export type ViewSingleResultBoolExp = {
  _and?: Maybe<Array<Maybe<ViewSingleResultBoolExp>>>;
  _not?: Maybe<ViewSingleResultBoolExp>;
  _or?: Maybe<Array<Maybe<ViewSingleResultBoolExp>>>;
  org?: Maybe<StringComparisonExp>;
  value?: Maybe<Float8ComparisonExp>;
};

/** ordering options when selecting data from "view_single_result" */
export type ViewSingleResultOrderBy = {
  org?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** select columns of table "view_single_result" */
export enum ViewSingleResultSelectColumn {
  /** column name */
  Org = 'org',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "view_solstice_versions" */
export type ViewSolsticeVersions = {
  __typename?: 'view_solstice_versions';
  /** An array relationship */
  displays: Array<ViewDisplays>;
  /** An aggregated array relationship */
  displays_aggregate: ViewDisplaysAggregate;
  org_id?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['_int4']>;
};


/** columns and relationships of "view_solstice_versions" */
export type ViewSolsticeVersionsDisplaysArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};


/** columns and relationships of "view_solstice_versions" */
export type ViewSolsticeVersionsDisplaysAggregateArgs = {
  distinct_on?: Maybe<Array<ViewDisplaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewDisplaysOrderBy>>;
  where?: Maybe<ViewDisplaysBoolExp>;
};

/** aggregated selection of "view_solstice_versions" */
export type ViewSolsticeVersionsAggregate = {
  __typename?: 'view_solstice_versions_aggregate';
  aggregate?: Maybe<ViewSolsticeVersionsAggregateFields>;
  nodes: Array<ViewSolsticeVersions>;
};

/** aggregate fields of "view_solstice_versions" */
export type ViewSolsticeVersionsAggregateFields = {
  __typename?: 'view_solstice_versions_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewSolsticeVersionsMaxFields>;
  min?: Maybe<ViewSolsticeVersionsMinFields>;
};


/** aggregate fields of "view_solstice_versions" */
export type ViewSolsticeVersionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewSolsticeVersionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_solstice_versions" */
export type ViewSolsticeVersionsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewSolsticeVersionsMaxOrderBy>;
  min?: Maybe<ViewSolsticeVersionsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "view_solstice_versions". All fields are combined with a logical 'AND'. */
export type ViewSolsticeVersionsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewSolsticeVersionsBoolExp>>>;
  _not?: Maybe<ViewSolsticeVersionsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewSolsticeVersionsBoolExp>>>;
  displays?: Maybe<ViewDisplaysBoolExp>;
  org_id?: Maybe<StringComparisonExp>;
  version?: Maybe<Int4ComparisonExp>;
};

/** aggregate max on columns */
export type ViewSolsticeVersionsMaxFields = {
  __typename?: 'view_solstice_versions_max_fields';
  org_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_solstice_versions" */
export type ViewSolsticeVersionsMaxOrderBy = {
  org_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewSolsticeVersionsMinFields = {
  __typename?: 'view_solstice_versions_min_fields';
  org_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_solstice_versions" */
export type ViewSolsticeVersionsMinOrderBy = {
  org_id?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_solstice_versions" */
export type ViewSolsticeVersionsOrderBy = {
  displays_aggregate?: Maybe<ViewDisplaysAggregateOrderBy>;
  org_id?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** select columns of table "view_solstice_versions" */
export enum ViewSolsticeVersionsSelectColumn {
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Version = 'version'
}

/** columns and relationships of "view_usage_by_room_type" */
export type ViewUsageByRoomType = {
  __typename?: 'view_usage_by_room_type';
  id?: Maybe<Scalars['String']>;
  room_type?: Maybe<Scalars['String']>;
  total_meeting_length?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "view_usage_by_room_type" */
export type ViewUsageByRoomTypeAggregate = {
  __typename?: 'view_usage_by_room_type_aggregate';
  aggregate?: Maybe<ViewUsageByRoomTypeAggregateFields>;
  nodes: Array<ViewUsageByRoomType>;
};

/** aggregate fields of "view_usage_by_room_type" */
export type ViewUsageByRoomTypeAggregateFields = {
  __typename?: 'view_usage_by_room_type_aggregate_fields';
  avg?: Maybe<ViewUsageByRoomTypeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewUsageByRoomTypeMaxFields>;
  min?: Maybe<ViewUsageByRoomTypeMinFields>;
  stddev?: Maybe<ViewUsageByRoomTypeStddevFields>;
  stddev_pop?: Maybe<ViewUsageByRoomTypeStddevPopFields>;
  stddev_samp?: Maybe<ViewUsageByRoomTypeStddevSampFields>;
  sum?: Maybe<ViewUsageByRoomTypeSumFields>;
  var_pop?: Maybe<ViewUsageByRoomTypeVarPopFields>;
  var_samp?: Maybe<ViewUsageByRoomTypeVarSampFields>;
  variance?: Maybe<ViewUsageByRoomTypeVarianceFields>;
};


/** aggregate fields of "view_usage_by_room_type" */
export type ViewUsageByRoomTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewUsageByRoomTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeAggregateOrderBy = {
  avg?: Maybe<ViewUsageByRoomTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewUsageByRoomTypeMaxOrderBy>;
  min?: Maybe<ViewUsageByRoomTypeMinOrderBy>;
  stddev?: Maybe<ViewUsageByRoomTypeStddevOrderBy>;
  stddev_pop?: Maybe<ViewUsageByRoomTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewUsageByRoomTypeStddevSampOrderBy>;
  sum?: Maybe<ViewUsageByRoomTypeSumOrderBy>;
  var_pop?: Maybe<ViewUsageByRoomTypeVarPopOrderBy>;
  var_samp?: Maybe<ViewUsageByRoomTypeVarSampOrderBy>;
  variance?: Maybe<ViewUsageByRoomTypeVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewUsageByRoomTypeAvgFields = {
  __typename?: 'view_usage_by_room_type_avg_fields';
  total_meeting_length?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeAvgOrderBy = {
  total_meeting_length?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_usage_by_room_type". All fields are combined with a logical 'AND'. */
export type ViewUsageByRoomTypeBoolExp = {
  _and?: Maybe<Array<Maybe<ViewUsageByRoomTypeBoolExp>>>;
  _not?: Maybe<ViewUsageByRoomTypeBoolExp>;
  _or?: Maybe<Array<Maybe<ViewUsageByRoomTypeBoolExp>>>;
  id?: Maybe<StringComparisonExp>;
  room_type?: Maybe<StringComparisonExp>;
  total_meeting_length?: Maybe<Float8ComparisonExp>;
};

/** aggregate max on columns */
export type ViewUsageByRoomTypeMaxFields = {
  __typename?: 'view_usage_by_room_type_max_fields';
  id?: Maybe<Scalars['String']>;
  room_type?: Maybe<Scalars['String']>;
  total_meeting_length?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeMaxOrderBy = {
  id?: Maybe<OrderBy>;
  room_type?: Maybe<OrderBy>;
  total_meeting_length?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewUsageByRoomTypeMinFields = {
  __typename?: 'view_usage_by_room_type_min_fields';
  id?: Maybe<Scalars['String']>;
  room_type?: Maybe<Scalars['String']>;
  total_meeting_length?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeMinOrderBy = {
  id?: Maybe<OrderBy>;
  room_type?: Maybe<OrderBy>;
  total_meeting_length?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_usage_by_room_type" */
export type ViewUsageByRoomTypeOrderBy = {
  id?: Maybe<OrderBy>;
  room_type?: Maybe<OrderBy>;
  total_meeting_length?: Maybe<OrderBy>;
};

/** select columns of table "view_usage_by_room_type" */
export enum ViewUsageByRoomTypeSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  RoomType = 'room_type',
  /** column name */
  TotalMeetingLength = 'total_meeting_length'
}

/** aggregate stddev on columns */
export type ViewUsageByRoomTypeStddevFields = {
  __typename?: 'view_usage_by_room_type_stddev_fields';
  total_meeting_length?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeStddevOrderBy = {
  total_meeting_length?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewUsageByRoomTypeStddevPopFields = {
  __typename?: 'view_usage_by_room_type_stddev_pop_fields';
  total_meeting_length?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeStddevPopOrderBy = {
  total_meeting_length?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewUsageByRoomTypeStddevSampFields = {
  __typename?: 'view_usage_by_room_type_stddev_samp_fields';
  total_meeting_length?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeStddevSampOrderBy = {
  total_meeting_length?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewUsageByRoomTypeSumFields = {
  __typename?: 'view_usage_by_room_type_sum_fields';
  total_meeting_length?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeSumOrderBy = {
  total_meeting_length?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewUsageByRoomTypeVarPopFields = {
  __typename?: 'view_usage_by_room_type_var_pop_fields';
  total_meeting_length?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeVarPopOrderBy = {
  total_meeting_length?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewUsageByRoomTypeVarSampFields = {
  __typename?: 'view_usage_by_room_type_var_samp_fields';
  total_meeting_length?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeVarSampOrderBy = {
  total_meeting_length?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewUsageByRoomTypeVarianceFields = {
  __typename?: 'view_usage_by_room_type_variance_fields';
  total_meeting_length?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_usage_by_room_type" */
export type ViewUsageByRoomTypeVarianceOrderBy = {
  total_meeting_length?: Maybe<OrderBy>;
};

/** columns and relationships of "view_usb_product_history" */
export type ViewUsbProductHistory = {
  __typename?: 'view_usb_product_history';
  dt?: Maybe<Scalars['timestamptz']>;
  plug_count?: Maybe<Scalars['bigint']>;
  unplug_count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "view_usb_product_history" */
export type ViewUsbProductHistoryAggregate = {
  __typename?: 'view_usb_product_history_aggregate';
  aggregate?: Maybe<ViewUsbProductHistoryAggregateFields>;
  nodes: Array<ViewUsbProductHistory>;
};

/** aggregate fields of "view_usb_product_history" */
export type ViewUsbProductHistoryAggregateFields = {
  __typename?: 'view_usb_product_history_aggregate_fields';
  avg?: Maybe<ViewUsbProductHistoryAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewUsbProductHistoryMaxFields>;
  min?: Maybe<ViewUsbProductHistoryMinFields>;
  stddev?: Maybe<ViewUsbProductHistoryStddevFields>;
  stddev_pop?: Maybe<ViewUsbProductHistoryStddevPopFields>;
  stddev_samp?: Maybe<ViewUsbProductHistoryStddevSampFields>;
  sum?: Maybe<ViewUsbProductHistorySumFields>;
  var_pop?: Maybe<ViewUsbProductHistoryVarPopFields>;
  var_samp?: Maybe<ViewUsbProductHistoryVarSampFields>;
  variance?: Maybe<ViewUsbProductHistoryVarianceFields>;
};


/** aggregate fields of "view_usb_product_history" */
export type ViewUsbProductHistoryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewUsbProductHistorySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_usb_product_history" */
export type ViewUsbProductHistoryAggregateOrderBy = {
  avg?: Maybe<ViewUsbProductHistoryAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewUsbProductHistoryMaxOrderBy>;
  min?: Maybe<ViewUsbProductHistoryMinOrderBy>;
  stddev?: Maybe<ViewUsbProductHistoryStddevOrderBy>;
  stddev_pop?: Maybe<ViewUsbProductHistoryStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewUsbProductHistoryStddevSampOrderBy>;
  sum?: Maybe<ViewUsbProductHistorySumOrderBy>;
  var_pop?: Maybe<ViewUsbProductHistoryVarPopOrderBy>;
  var_samp?: Maybe<ViewUsbProductHistoryVarSampOrderBy>;
  variance?: Maybe<ViewUsbProductHistoryVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewUsbProductHistoryAvgFields = {
  __typename?: 'view_usb_product_history_avg_fields';
  plug_count?: Maybe<Scalars['Float']>;
  unplug_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistoryAvgOrderBy = {
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_usb_product_history". All fields are combined with a logical 'AND'. */
export type ViewUsbProductHistoryBoolExp = {
  _and?: Maybe<Array<Maybe<ViewUsbProductHistoryBoolExp>>>;
  _not?: Maybe<ViewUsbProductHistoryBoolExp>;
  _or?: Maybe<Array<Maybe<ViewUsbProductHistoryBoolExp>>>;
  dt?: Maybe<TimestamptzComparisonExp>;
  plug_count?: Maybe<BigintComparisonExp>;
  unplug_count?: Maybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type ViewUsbProductHistoryMaxFields = {
  __typename?: 'view_usb_product_history_max_fields';
  dt?: Maybe<Scalars['timestamptz']>;
  plug_count?: Maybe<Scalars['bigint']>;
  unplug_count?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistoryMaxOrderBy = {
  dt?: Maybe<OrderBy>;
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewUsbProductHistoryMinFields = {
  __typename?: 'view_usb_product_history_min_fields';
  dt?: Maybe<Scalars['timestamptz']>;
  plug_count?: Maybe<Scalars['bigint']>;
  unplug_count?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistoryMinOrderBy = {
  dt?: Maybe<OrderBy>;
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_usb_product_history" */
export type ViewUsbProductHistoryOrderBy = {
  dt?: Maybe<OrderBy>;
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** select columns of table "view_usb_product_history" */
export enum ViewUsbProductHistorySelectColumn {
  /** column name */
  Dt = 'dt',
  /** column name */
  PlugCount = 'plug_count',
  /** column name */
  UnplugCount = 'unplug_count'
}

/** aggregate stddev on columns */
export type ViewUsbProductHistoryStddevFields = {
  __typename?: 'view_usb_product_history_stddev_fields';
  plug_count?: Maybe<Scalars['Float']>;
  unplug_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistoryStddevOrderBy = {
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewUsbProductHistoryStddevPopFields = {
  __typename?: 'view_usb_product_history_stddev_pop_fields';
  plug_count?: Maybe<Scalars['Float']>;
  unplug_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistoryStddevPopOrderBy = {
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewUsbProductHistoryStddevSampFields = {
  __typename?: 'view_usb_product_history_stddev_samp_fields';
  plug_count?: Maybe<Scalars['Float']>;
  unplug_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistoryStddevSampOrderBy = {
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewUsbProductHistorySumFields = {
  __typename?: 'view_usb_product_history_sum_fields';
  plug_count?: Maybe<Scalars['bigint']>;
  unplug_count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistorySumOrderBy = {
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewUsbProductHistoryVarPopFields = {
  __typename?: 'view_usb_product_history_var_pop_fields';
  plug_count?: Maybe<Scalars['Float']>;
  unplug_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistoryVarPopOrderBy = {
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewUsbProductHistoryVarSampFields = {
  __typename?: 'view_usb_product_history_var_samp_fields';
  plug_count?: Maybe<Scalars['Float']>;
  unplug_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistoryVarSampOrderBy = {
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewUsbProductHistoryVarianceFields = {
  __typename?: 'view_usb_product_history_variance_fields';
  plug_count?: Maybe<Scalars['Float']>;
  unplug_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_usb_product_history" */
export type ViewUsbProductHistoryVarianceOrderBy = {
  plug_count?: Maybe<OrderBy>;
  unplug_count?: Maybe<OrderBy>;
};

/** columns and relationships of "view_user_collaboration_results" */
export type ViewUserCollaborationResults = {
  __typename?: 'view_user_collaboration_results';
  group?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "view_user_collaboration_results". All fields are combined with a logical 'AND'. */
export type ViewUserCollaborationResultsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewUserCollaborationResultsBoolExp>>>;
  _not?: Maybe<ViewUserCollaborationResultsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewUserCollaborationResultsBoolExp>>>;
  group?: Maybe<StringComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
  total?: Maybe<BigintComparisonExp>;
};

/** ordering options when selecting data from "view_user_collaboration_results" */
export type ViewUserCollaborationResultsOrderBy = {
  group?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** select columns of table "view_user_collaboration_results" */
export enum ViewUserCollaborationResultsSelectColumn {
  /** column name */
  Group = 'group',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Total = 'total'
}

/** columns and relationships of "view_user_devices" */
export type ViewUserDevices = {
  __typename?: 'view_user_devices';
  client_platform?: Maybe<Scalars['String']>;
  org?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['float8']>;
};

/** Boolean expression to filter rows from the table "view_user_devices". All fields are combined with a logical 'AND'. */
export type ViewUserDevicesBoolExp = {
  _and?: Maybe<Array<Maybe<ViewUserDevicesBoolExp>>>;
  _not?: Maybe<ViewUserDevicesBoolExp>;
  _or?: Maybe<Array<Maybe<ViewUserDevicesBoolExp>>>;
  client_platform?: Maybe<StringComparisonExp>;
  org?: Maybe<StringComparisonExp>;
  total?: Maybe<Float8ComparisonExp>;
};

/** ordering options when selecting data from "view_user_devices" */
export type ViewUserDevicesOrderBy = {
  client_platform?: Maybe<OrderBy>;
  org?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
};

/** select columns of table "view_user_devices" */
export enum ViewUserDevicesSelectColumn {
  /** column name */
  ClientPlatform = 'client_platform',
  /** column name */
  Org = 'org',
  /** column name */
  Total = 'total'
}

/** columns and relationships of "view_users" */
export type ViewUsers = {
  __typename?: 'view_users';
  email?: Maybe<Scalars['String']>;
  has_password?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  permissions: Array<ViewUsersPermissions>;
  role?: Maybe<Scalars['Int']>;
  sso_only?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};


/** columns and relationships of "view_users" */
export type ViewUsersPermissionsArgs = {
  distinct_on?: Maybe<Array<ViewUsersPermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ViewUsersPermissionsOrderBy>>;
  where?: Maybe<ViewUsersPermissionsBoolExp>;
};

/** aggregated selection of "view_users" */
export type ViewUsersAggregate = {
  __typename?: 'view_users_aggregate';
  aggregate?: Maybe<ViewUsersAggregateFields>;
  nodes: Array<ViewUsers>;
};

/** aggregate fields of "view_users" */
export type ViewUsersAggregateFields = {
  __typename?: 'view_users_aggregate_fields';
  avg?: Maybe<ViewUsersAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ViewUsersMaxFields>;
  min?: Maybe<ViewUsersMinFields>;
  stddev?: Maybe<ViewUsersStddevFields>;
  stddev_pop?: Maybe<ViewUsersStddevPopFields>;
  stddev_samp?: Maybe<ViewUsersStddevSampFields>;
  sum?: Maybe<ViewUsersSumFields>;
  var_pop?: Maybe<ViewUsersVarPopFields>;
  var_samp?: Maybe<ViewUsersVarSampFields>;
  variance?: Maybe<ViewUsersVarianceFields>;
};


/** aggregate fields of "view_users" */
export type ViewUsersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ViewUsersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "view_users" */
export type ViewUsersAggregateOrderBy = {
  avg?: Maybe<ViewUsersAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ViewUsersMaxOrderBy>;
  min?: Maybe<ViewUsersMinOrderBy>;
  stddev?: Maybe<ViewUsersStddevOrderBy>;
  stddev_pop?: Maybe<ViewUsersStddevPopOrderBy>;
  stddev_samp?: Maybe<ViewUsersStddevSampOrderBy>;
  sum?: Maybe<ViewUsersSumOrderBy>;
  var_pop?: Maybe<ViewUsersVarPopOrderBy>;
  var_samp?: Maybe<ViewUsersVarSampOrderBy>;
  variance?: Maybe<ViewUsersVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ViewUsersAvgFields = {
  __typename?: 'view_users_avg_fields';
  role?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "view_users" */
export type ViewUsersAvgOrderBy = {
  role?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "view_users". All fields are combined with a logical 'AND'. */
export type ViewUsersBoolExp = {
  _and?: Maybe<Array<Maybe<ViewUsersBoolExp>>>;
  _not?: Maybe<ViewUsersBoolExp>;
  _or?: Maybe<Array<Maybe<ViewUsersBoolExp>>>;
  email?: Maybe<StringComparisonExp>;
  has_password?: Maybe<BooleanComparisonExp>;
  permissions?: Maybe<ViewUsersPermissionsBoolExp>;
  role?: Maybe<IntComparisonExp>;
  sso_only?: Maybe<BooleanComparisonExp>;
  status?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ViewUsersMaxFields = {
  __typename?: 'view_users_max_fields';
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "view_users" */
export type ViewUsersMaxOrderBy = {
  email?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ViewUsersMinFields = {
  __typename?: 'view_users_min_fields';
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "view_users" */
export type ViewUsersMinOrderBy = {
  email?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** ordering options when selecting data from "view_users" */
export type ViewUsersOrderBy = {
  email?: Maybe<OrderBy>;
  has_password?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
  sso_only?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** columns and relationships of "view_users_permissions" */
export type ViewUsersPermissions = {
  __typename?: 'view_users_permissions';
  email?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "view_users_permissions" */
export type ViewUsersPermissionsPermissionArgs = {
  path?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "view_users_permissions". All fields are combined with a logical 'AND'. */
export type ViewUsersPermissionsBoolExp = {
  _and?: Maybe<Array<Maybe<ViewUsersPermissionsBoolExp>>>;
  _not?: Maybe<ViewUsersPermissionsBoolExp>;
  _or?: Maybe<Array<Maybe<ViewUsersPermissionsBoolExp>>>;
  email?: Maybe<StringComparisonExp>;
  org_id?: Maybe<StringComparisonExp>;
  permission?: Maybe<JsonbComparisonExp>;
};

/** ordering options when selecting data from "view_users_permissions" */
export type ViewUsersPermissionsOrderBy = {
  email?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  permission?: Maybe<OrderBy>;
};

/** select columns of table "view_users_permissions" */
export enum ViewUsersPermissionsSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  Permission = 'permission'
}

/** select columns of table "view_users" */
export enum ViewUsersSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  HasPassword = 'has_password',
  /** column name */
  Role = 'role',
  /** column name */
  SsoOnly = 'sso_only',
  /** column name */
  Status = 'status'
}

/** aggregate stddev on columns */
export type ViewUsersStddevFields = {
  __typename?: 'view_users_stddev_fields';
  role?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "view_users" */
export type ViewUsersStddevOrderBy = {
  role?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ViewUsersStddevPopFields = {
  __typename?: 'view_users_stddev_pop_fields';
  role?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "view_users" */
export type ViewUsersStddevPopOrderBy = {
  role?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ViewUsersStddevSampFields = {
  __typename?: 'view_users_stddev_samp_fields';
  role?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "view_users" */
export type ViewUsersStddevSampOrderBy = {
  role?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ViewUsersSumFields = {
  __typename?: 'view_users_sum_fields';
  role?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "view_users" */
export type ViewUsersSumOrderBy = {
  role?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ViewUsersVarPopFields = {
  __typename?: 'view_users_var_pop_fields';
  role?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "view_users" */
export type ViewUsersVarPopOrderBy = {
  role?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ViewUsersVarSampFields = {
  __typename?: 'view_users_var_samp_fields';
  role?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "view_users" */
export type ViewUsersVarSampOrderBy = {
  role?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ViewUsersVarianceFields = {
  __typename?: 'view_users_variance_fields';
  role?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "view_users" */
export type ViewUsersVarianceOrderBy = {
  role?: Maybe<OrderBy>;
};

export type DisplayDeploymentPropsFragment = (
  { __typename?: 'view_displays' }
  & Pick<ViewDisplays, 'name' | 'org' | 'version' | 'hardware' | 'id' | 'onboard_date' | 'deploy_date'>
  & { online: ViewDisplays['is_online'], pending: ViewDisplays['has_unconfirmed_templates'], inMx: ViewDisplays['is_in_subscription'], catchingUp: ViewDisplays['catching_up'] }
  & { subscription?: Maybe<(
    { __typename?: 'view_displays_subscriptions' }
    & Pick<ViewDisplaysSubscriptions, 'maintenance_end' | 'flexera_maintenance_end'>
  )>, deployment?: Maybe<(
    { __typename?: 'view_displays_deployment' }
    & Pick<ViewDisplaysDeployment, 'display_id' | 'ip_primary' | 'ip_wifi' | 'license' | 'mac_primary' | 'mac_wifi' | 'sds_enabled' | 'sds_host_1' | 'sds_host_2'>
    & { tags: Array<(
      { __typename?: 'orgs_displays_tags' }
      & Pick<OrgsDisplaysTags, 'color' | 'tag'>
    )> }
  )> }
);

export type AddNewAddressMutationVariables = Exact<{
  object: OrgAddressesInsertInput;
}>;


export type AddNewAddressMutation = (
  { __typename?: 'mutation_root' }
  & { insert_org_addresses_one?: Maybe<(
    { __typename?: 'org_addresses' }
    & Pick<OrgAddresses, 'id' | 'address_full' | 'lat' | 'long'>
  )> }
);

export type DeleteRebootedAlertFiltersMutationVariables = Exact<{
  categoryInternalName?: Maybe<Scalars['String']>;
  optionInternalName?: Maybe<Scalars['String']>;
}>;


export type DeleteRebootedAlertFiltersMutation = (
  { __typename?: 'mutation_root' }
  & { delete_orgs_users_rebooted_filters?: Maybe<(
    { __typename?: 'orgs_users_rebooted_filters_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_users_rebooted_filters' }
      & { id: OrgsUsersRebootedFilters['rebooted_filter'] }
    )> }
  )> }
);

export type DeleteTemplateAlertFiltersMutationVariables = Exact<{
  categoryInternalName?: Maybe<Scalars['String']>;
  optionInternalName?: Maybe<Scalars['String']>;
}>;


export type DeleteTemplateAlertFiltersMutation = (
  { __typename?: 'mutation_root' }
  & { delete_orgs_users_template_filters?: Maybe<(
    { __typename?: 'orgs_users_template_filters_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_users_template_filters' }
      & { id: OrgsUsersTemplateFilters['template_filter'] }
    )> }
  )> }
);

export type DeleteUnreachableAlertFiltersMutationVariables = Exact<{
  categoryInternalName?: Maybe<Scalars['String']>;
  optionInternalName?: Maybe<Scalars['String']>;
}>;


export type DeleteUnreachableAlertFiltersMutation = (
  { __typename?: 'mutation_root' }
  & { delete_orgs_users_unreachable_filters?: Maybe<(
    { __typename?: 'orgs_users_unreachable_filters_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_users_unreachable_filters' }
      & { id: OrgsUsersUnreachableFilters['unreachable_filter'] }
    )> }
  )> }
);

export type DeleteUsbPlugAlertFiltersMutationVariables = Exact<{
  categoryInternalName?: Maybe<Scalars['String']>;
  optionInternalName?: Maybe<Scalars['String']>;
}>;


export type DeleteUsbPlugAlertFiltersMutation = (
  { __typename?: 'mutation_root' }
  & { delete_orgs_users_usb_plug_filters?: Maybe<(
    { __typename?: 'orgs_users_usb_plug_filters_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_users_usb_plug_filters' }
      & { id: OrgsUsersUsbPlugFilters['usb_plug_filter'] }
    )> }
  )> }
);

export type InsertRebootedAlertFilterMutationVariables = Exact<{
  id: Scalars['String'];
  categoryInternalName: Scalars['String'];
  optionInternalName: Scalars['String'];
}>;


export type InsertRebootedAlertFilterMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_users_rebooted_filters_one?: Maybe<(
    { __typename?: 'orgs_users_rebooted_filters' }
    & { id: OrgsUsersRebootedFilters['rebooted_filter'] }
  )> }
);

export type InsertTemplateAlertFilterMutationVariables = Exact<{
  id: Scalars['String'];
  categoryInternalName: Scalars['String'];
  optionInternalName: Scalars['String'];
}>;


export type InsertTemplateAlertFilterMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_users_template_filters_one?: Maybe<(
    { __typename?: 'orgs_users_template_filters' }
    & { id: OrgsUsersTemplateFilters['template_filter'] }
  )> }
);

export type InsertUnreachableAlertFilterMutationVariables = Exact<{
  id: Scalars['String'];
  categoryInternalName: Scalars['String'];
  optionInternalName: Scalars['String'];
}>;


export type InsertUnreachableAlertFilterMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_users_unreachable_filters_one?: Maybe<(
    { __typename?: 'orgs_users_unreachable_filters' }
    & { id: OrgsUsersUnreachableFilters['unreachable_filter'] }
  )> }
);

export type InsertUsbPlugAlertFilterMutationVariables = Exact<{
  id: Scalars['String'];
  categoryInternalName: Scalars['String'];
  optionInternalName: Scalars['String'];
}>;


export type InsertUsbPlugAlertFilterMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_users_usb_plug_filters_one?: Maybe<(
    { __typename?: 'orgs_users_usb_plug_filters' }
    & { id: OrgsUsersUsbPlugFilters['usb_plug_filter'] }
  )> }
);

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { delete_orgs_categories?: Maybe<(
    { __typename?: 'orgs_categories_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_categories' }
      & Pick<OrgsCategories, 'category'>
    )> }
  )>, delete_orgs_categories_options?: Maybe<(
    { __typename?: 'orgs_categories_options_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_categories_options' }
      & Pick<OrgsCategoriesOptions, 'category' | 'option'>
    )> }
  )> }
);

export type DeleteCategoryOptionMutationVariables = Exact<{
  categoryId: Scalars['String'];
  optionId: Scalars['String'];
}>;


export type DeleteCategoryOptionMutation = (
  { __typename?: 'mutation_root' }
  & { delete_orgs_categories_options?: Maybe<(
    { __typename?: 'orgs_categories_options_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_categories_options' }
      & Pick<OrgsCategoriesOptions, 'category' | 'option'>
    )> }
  )> }
);

export type DeleteDisplayCategoriesMutationVariables = Exact<{
  displayIds: Array<Scalars['String']> | Scalars['String'];
  categoryId: Scalars['String'];
}>;


export type DeleteDisplayCategoriesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_orgs_displays_categories?: Maybe<(
    { __typename?: 'orgs_displays_categories_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_displays_categories' }
      & Pick<OrgsDisplaysCategories, 'display'>
    )> }
  )> }
);

export type FinishJoyrideMutationVariables = Exact<{
  joyride: Scalars['String'];
}>;


export type FinishJoyrideMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_users_joyrides_one?: Maybe<(
    { __typename?: 'orgs_users_joyrides' }
    & Pick<OrgsUsersJoyrides, 'joyride' | 'finished_on'>
  )> }
);

export type InsertCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  displayName: Scalars['String'];
  options: Array<OrgsCategoriesOptionsInsertInput> | OrgsCategoriesOptionsInsertInput;
}>;


export type InsertCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_categories_one?: Maybe<(
    { __typename?: 'orgs_categories' }
    & Pick<OrgsCategories, 'category' | 'display_name' | 'internal_name'>
  )>, insert_orgs_categories_options?: Maybe<(
    { __typename?: 'orgs_categories_options_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_categories_options' }
      & Pick<OrgsCategoriesOptions, 'option' | 'internal_name' | 'display_name'>
    )> }
  )> }
);

export type InsertNewDisplayMutationVariables = Exact<{
  display?: Maybe<Scalars['String']>;
  address_id?: Maybe<Scalars['Int']>;
}>;


export type InsertNewDisplayMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_displays_locations_one?: Maybe<(
    { __typename?: 'orgs_displays_locations' }
    & Pick<OrgsDisplaysLocations, 'address_id'>
  )> }
);

export type ResetJoyrideMutationVariables = Exact<{
  joyride: Scalars['String'];
}>;


export type ResetJoyrideMutation = (
  { __typename?: 'mutation_root' }
  & { delete_orgs_users_joyrides?: Maybe<(
    { __typename?: 'orgs_users_joyrides_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_users_joyrides' }
      & Pick<OrgsUsersJoyrides, 'joyride' | 'finished_on'>
    )> }
  )> }
);

export type ToggleOrgWorkspaceMutationVariables = Exact<{
  wellness_enabled: Scalars['Boolean'];
}>;


export type ToggleOrgWorkspaceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_flags_one?: Maybe<(
    { __typename?: 'orgs_flags' }
    & Pick<OrgsFlags, 'org_id' | 'wellness_enabled'>
  )> }
);

export type UpdateAddressNicknameByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
}>;


export type UpdateAddressNicknameByIdMutation = (
  { __typename?: 'mutation_root' }
  & { update_org_addresses_by_pk?: Maybe<(
    { __typename?: 'org_addresses' }
    & Pick<OrgAddresses, 'id' | 'nickname'>
  )> }
);

export type UpdateAlertConfigMutationVariables = Exact<{
  config: OrgsUsersSetInput;
}>;


export type UpdateAlertConfigMutation = (
  { __typename?: 'mutation_root' }
  & { update_orgs_users?: Maybe<(
    { __typename?: 'orgs_users_mutation_response' }
    & Pick<OrgsUsersMutationResponse, 'affected_rows'>
  )> }
);

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  displayName: Scalars['String'];
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { update_orgs_categories?: Maybe<(
    { __typename?: 'orgs_categories_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_categories' }
      & Pick<OrgsCategories, 'category' | 'display_name' | 'internal_name'>
    )> }
  )> }
);

export type UpdateFloorMutationVariables = Exact<{
  where: OrgsDisplaysLocationsBoolExp;
  newFloor?: Maybe<Scalars['Int']>;
}>;


export type UpdateFloorMutation = (
  { __typename?: 'mutation_root' }
  & { update_orgs_displays_locations?: Maybe<(
    { __typename?: 'orgs_displays_locations_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_displays_locations' }
      & Pick<OrgsDisplaysLocations, 'display'>
      & { address?: Maybe<(
        { __typename?: 'org_addresses' }
        & { floors: Array<(
          { __typename?: 'view_floor_count' }
          & Pick<ViewFloorCount, 'address_id' | 'count_per_floor' | 'floor'>
        )> }
      )> }
    )> }
  )> }
);

export type UpsertActiveLearningFlagsMutationVariables = Exact<{
  screen_key_enabled: Scalars['Boolean'];
}>;


export type UpsertActiveLearningFlagsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_active_learning_flags_one?: Maybe<(
    { __typename?: 'active_learning_flags' }
    & Pick<ActiveLearningFlags, 'org_id' | 'screen_key_enabled'>
  )> }
);

export type UpsertCategoryOptionMutationVariables = Exact<{
  categoryId: Scalars['String'];
  optionId: Scalars['String'];
  displayName: Scalars['String'];
}>;


export type UpsertCategoryOptionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_categories_options_one?: Maybe<(
    { __typename?: 'orgs_categories_options' }
    & Pick<OrgsCategoriesOptions, 'category' | 'option' | 'display_name' | 'internal_name'>
  )> }
);

export type UpsertDisplayCategoriesMutationVariables = Exact<{
  items: Array<OrgsDisplaysCategoriesInsertInput> | OrgsDisplaysCategoriesInsertInput;
}>;


export type UpsertDisplayCategoriesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_displays_categories?: Maybe<(
    { __typename?: 'orgs_displays_categories_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_displays_categories' }
      & Pick<OrgsDisplaysCategories, 'display' | 'category' | 'config_applied_categories'>
    )> }
  )> }
);

export type UpsertDisplayLocationsMutationVariables = Exact<{
  items: Array<OrgsDisplaysLocationsInsertInput> | OrgsDisplaysLocationsInsertInput;
}>;


export type UpsertDisplayLocationsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_displays_locations?: Maybe<(
    { __typename?: 'orgs_displays_locations_mutation_response' }
    & { returning: Array<(
      { __typename?: 'orgs_displays_locations' }
      & Pick<OrgsDisplaysLocations, 'display' | 'user_lat' | 'user_long' | 'address_id'>
      & { address?: Maybe<(
        { __typename?: 'org_addresses' }
        & Pick<OrgAddresses, 'id' | 'city' | 'country' | 'building' | 'nickname' | 'lat' | 'long' | 'address_full' | 'state_province'>
        & { assignedDisplays_aggregate: (
          { __typename?: 'view_displays_locations_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'view_displays_locations_aggregate_fields' }
            & Pick<ViewDisplaysLocationsAggregateFields, 'count'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type UpsertDisplayOneLocationMutationVariables = Exact<{
  address_id?: Maybe<Scalars['Int']>;
  user_lat?: Maybe<Scalars['float8']>;
  user_long?: Maybe<Scalars['float8']>;
  user_accuracy_h?: Maybe<Scalars['float8']>;
  display: Scalars['String'];
  display_uuid?: Maybe<Scalars['String']>;
}>;


export type UpsertDisplayOneLocationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_displays_locations_one?: Maybe<(
    { __typename?: 'orgs_displays_locations' }
    & Pick<OrgsDisplaysLocations, 'display' | 'address_id'>
  )> }
);

export type SetDisplayPositionInNewBuildingMutationVariables = Exact<{
  object: OrgsDisplaysLocationsInsertInput;
}>;


export type SetDisplayPositionInNewBuildingMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orgs_displays_locations_one?: Maybe<(
    { __typename?: 'orgs_displays_locations' }
    & Pick<OrgsDisplaysLocations, 'address_id'>
  )> }
);

export type ActiveLearningUserCountsQueryVariables = Exact<{
  designerRole: Scalars['Int'];
  facilitatorRole: Scalars['Int'];
}>;


export type ActiveLearningUserCountsQuery = (
  { __typename?: 'query_root' }
  & { designers: (
    { __typename?: 'view_users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_users_aggregate_fields' }
      & Pick<ViewUsersAggregateFields, 'count'>
    )> }
  ), facilitators: (
    { __typename?: 'view_users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_users_aggregate_fields' }
      & Pick<ViewUsersAggregateFields, 'count'>
    )> }
  ) }
);

export type ActivityUserMobilityQueryVariables = Exact<{
  args: SearchActivityUserMobilityArgs;
}>;


export type ActivityUserMobilityQuery = (
  { __typename?: 'query_root' }
  & { activities: Array<(
    { __typename?: 'view_activity_user_mobility_result' }
    & Pick<ViewActivityUserMobilityResult, 'org' | 'group' | 'count' | 'sessions'>
  )> }
);

export type SearchAddressesQueryVariables = Exact<{
  where: OrgAddressesBoolExp;
}>;


export type SearchAddressesQuery = (
  { __typename?: 'query_root' }
  & { _addresses: Array<(
    { __typename?: 'org_addresses' }
    & Pick<OrgAddresses, 'id' | 'address_full' | 'building' | 'city' | 'country' | 'lat' | 'long' | 'nickname' | 'state_province'>
    & { floors: Array<(
      { __typename?: 'view_floor_count' }
      & Pick<ViewFloorCount, 'floor' | 'count_per_floor'>
    )>, assignedDisplays_aggregate: (
      { __typename?: 'view_displays_locations_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'view_displays_locations_aggregate_fields' }
        & Pick<ViewDisplaysLocationsAggregateFields, 'count'>
      )> }
    ) }
  )> }
);

export type AlertConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type AlertConfigQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'orgs_users' }
    & { displayOutOfTemplateEnabled: OrgsUsers['config_alerts_display_out_of_template'], displayRebootedEnabled: OrgsUsers['config_alerts_display_rebooted_enabled'], displayUnreachableEnabled: OrgsUsers['config_alerts_display_unreachable_enabled'], displayUnreachableDelay: OrgsUsers['config_alerts_display_unreachable_delay'], usbPlugEventEnabled: OrgsUsers['config_alerts_usb_plug_event_enabled'], usbPlugEventType: OrgsUsers['config_alerts_usb_plug_event_type'] }
  )>, unreachableFilters: Array<(
    { __typename?: 'orgs_users_unreachable_filters' }
    & { id: OrgsUsersUnreachableFilters['unreachable_filter'], optionInternalName: OrgsUsersUnreachableFilters['option_internal_name'], categoryInternalName: OrgsUsersUnreachableFilters['category_internal_name'] }
  )>, rebootedFilters: Array<(
    { __typename?: 'orgs_users_rebooted_filters' }
    & { id: OrgsUsersRebootedFilters['rebooted_filter'], optionInternalName: OrgsUsersRebootedFilters['option_internal_name'], categoryInternalName: OrgsUsersRebootedFilters['category_internal_name'] }
  )>, templateFilters: Array<(
    { __typename?: 'orgs_users_template_filters' }
    & { id: OrgsUsersTemplateFilters['template_filter'], optionInternalName: OrgsUsersTemplateFilters['option_internal_name'], categoryInternalName: OrgsUsersTemplateFilters['category_internal_name'] }
  )>, usbFilters: Array<(
    { __typename?: 'orgs_users_usb_plug_filters' }
    & { id: OrgsUsersUsbPlugFilters['usb_plug_filter'], optionInternalName: OrgsUsersUsbPlugFilters['option_internal_name'], categoryInternalName: OrgsUsersUsbPlugFilters['category_internal_name'] }
  )> }
);

export type DisplaysAlertsTableCountQueryVariables = Exact<{
  where?: Maybe<ViewDisplayAlertsBoolExp>;
}>;


export type DisplaysAlertsTableCountQuery = (
  { __typename?: 'query_root' }
  & { view_display_alerts_aggregate: (
    { __typename?: 'view_display_alerts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_display_alerts_aggregate_fields' }
      & Pick<ViewDisplayAlertsAggregateFields, 'count'>
    )> }
  ) }
);

export type DisplayAlertsTableQQueryVariables = Exact<{
  where: ViewDisplayAlertsBoolExp;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: Maybe<Array<ViewDisplayAlertsOrderBy> | ViewDisplayAlertsOrderBy>;
}>;


export type DisplayAlertsTableQQuery = (
  { __typename?: 'query_root' }
  & { _alerts: Array<(
    { __typename?: 'view_display_alerts' }
    & Pick<ViewDisplayAlerts, 'time' | 'description' | 'alert_type'>
    & { display?: Maybe<(
      { __typename?: 'view_displays' }
      & Pick<ViewDisplays, 'id' | 'name'>
    )> }
  )> }
);

export type AvgHoursSpentPerUserQueryVariables = Exact<{
  args: AvgHoursSpentPerUserArgs;
}>;


export type AvgHoursSpentPerUserQuery = (
  { __typename?: 'query_root' }
  & { hoursPerUser: Array<(
    { __typename?: 'view_single_result' }
    & Pick<ViewSingleResult, 'org' | 'value'>
  )> }
);

export type AvgRoomsVisitedQueryVariables = Exact<{
  args: AvgRoomsVisitedArgs;
}>;


export type AvgRoomsVisitedQuery = (
  { __typename?: 'query_root' }
  & { roomsVisited: Array<(
    { __typename?: 'view_single_result' }
    & Pick<ViewSingleResult, 'org' | 'value'>
  )> }
);

export type CategoriesQueryVariables = Exact<{
  where?: Maybe<ViewCategoriesBoolExp>;
  hidden?: Maybe<Scalars['Boolean']>;
}>;


export type CategoriesQuery = (
  { __typename?: 'query_root' }
  & { _categories: Array<(
    { __typename?: 'view_categories' }
    & Pick<ViewCategories, 'id' | 'category_type' | 'is_auto_generated'>
    & { displayName: ViewCategories['display_name'] }
    & { options: Array<(
      { __typename?: 'view_options' }
      & Pick<ViewOptions, 'id' | 'category_id' | 'hidden'>
      & { displayName: ViewOptions['display_name'] }
      & { assignedDisplays_aggregate: (
        { __typename?: 'view_displays_assigned_options_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'view_displays_assigned_options_aggregate_fields' }
          & Pick<ViewDisplaysAssignedOptionsAggregateFields, 'count'>
        )> }
      ) }
    )> }
  )>, _displaysAggregate: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type ScreenResolutionsQueryVariables = Exact<{
  args: SearchClientScreenResolutionsArgs;
  order_by?: Maybe<Array<ViewClientScreenResolutionsOrderBy> | ViewClientScreenResolutionsOrderBy>;
}>;


export type ScreenResolutionsQuery = (
  { __typename?: 'query_root' }
  & { screenResolutions: (
    { __typename?: 'view_client_screen_resolutions_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_client_screen_resolutions_aggregate_fields' }
      & { sum?: Maybe<(
        { __typename?: 'view_client_screen_resolutions_sum_fields' }
        & Pick<ViewClientScreenResolutionsSumFields, 'total'>
      )> }
    )>, nodes: Array<(
      { __typename?: 'view_client_screen_resolutions' }
      & Pick<ViewClientScreenResolutions, 'total'>
      & { screenHeight: ViewClientScreenResolutions['screen_height'], screenWidth: ViewClientScreenResolutions['screen_width'] }
    )> }
  ) }
);

export type ClientVersionsQueryVariables = Exact<{
  args: SearchClientVersionsArgs;
  order_by?: Maybe<Array<ViewClientVersionsOrderBy> | ViewClientVersionsOrderBy>;
}>;


export type ClientVersionsQuery = (
  { __typename?: 'query_root' }
  & { clientVersions: (
    { __typename?: 'view_client_versions_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_client_versions_aggregate_fields' }
      & { sum?: Maybe<(
        { __typename?: 'view_client_versions_sum_fields' }
        & Pick<ViewClientVersionsSumFields, 'total'>
      )> }
    )>, nodes: Array<(
      { __typename?: 'view_client_versions' }
      & Pick<ViewClientVersions, 'total'>
      & { clientVersion: ViewClientVersions['client_version'] }
    )> }
  ) }
);

export type ConferenceVendorsQueryVariables = Exact<{
  args: ConferenceVendorGraphArgs;
}>;


export type ConferenceVendorsQuery = (
  { __typename?: 'query_root' }
  & { conferenceVendors: Array<(
    { __typename?: 'view_conference_vendor_result' }
    & Pick<ViewConferenceVendorResult, 'org_id' | 'date' | 'vendor' | 'num_meetings'>
  )> }
);

export type ContentSharingAppsGroupsQueryVariables = Exact<{
  args: SearchContentSharingAppGroupsArgs;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type ContentSharingAppsGroupsQuery = (
  { __typename?: 'query_root' }
  & { data: Array<(
    { __typename?: 'view_content_sharing_app_groups' }
    & Pick<ViewContentSharingAppGroups, 'total'>
    & { name: ViewContentSharingAppGroups['app_group'] }
  )>, count: (
    { __typename?: 'view_content_sharing_app_groups_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_content_sharing_app_groups_aggregate_fields' }
      & { total: ViewContentSharingAppGroupsAggregateFields['count'] }
    )> }
  ) }
);

export type ContentSharingAppNamesQueryVariables = Exact<{
  args: SearchContentSharingAppNamesArgs;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type ContentSharingAppNamesQuery = (
  { __typename?: 'query_root' }
  & { data: Array<(
    { __typename?: 'view_content_sharing_apps_by_name' }
    & Pick<ViewContentSharingAppsByName, 'total'>
    & { name: ViewContentSharingAppsByName['app_name'] }
  )>, count: (
    { __typename?: 'view_content_sharing_apps_by_name_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_content_sharing_apps_by_name_aggregate_fields' }
      & { total: ViewContentSharingAppsByNameAggregateFields['count'] }
    )> }
  ) }
);

export type ContentSharingTypeQueryVariables = Exact<{
  args: SearchContentSharingTypesArgs;
}>;


export type ContentSharingTypeQuery = (
  { __typename?: 'query_root' }
  & { sharingTypes: Array<(
    { __typename?: 'view_content_sharing_types' }
    & { numPosts: ViewContentSharingTypes['num_posts'], shareType: ViewContentSharingTypes['share_type'] }
  )> }
);

export type CurrentFloorInformationQueryVariables = Exact<{
  displayId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Int']>;
}>;


export type CurrentFloorInformationQuery = (
  { __typename?: 'query_root' }
  & { _display_locations: Array<(
    { __typename?: 'view_displays_locations' }
    & { address?: Maybe<(
      { __typename?: 'org_addresses' }
      & { floors: Array<(
        { __typename?: 'view_floor_count' }
        & Pick<ViewFloorCount, 'floor' | 'count_per_floor'>
      )> }
    )>, floor_count?: Maybe<(
      { __typename?: 'view_floor_count' }
      & Pick<ViewFloorCount, 'floor' | 'count_per_floor'>
    )> }
  )> }
);

export type DailyActiveUsersQueryVariables = Exact<{
  args: DailyActiveUsersGraphArgs;
}>;


export type DailyActiveUsersQuery = (
  { __typename?: 'query_root' }
  & { dailyActiveUsers: Array<(
    { __typename?: 'view_date_aggregation_result' }
    & Pick<ViewDateAggregationResult, 'org_id' | 'date' | 'total'>
  )> }
);

export type DisplayAlertsQueryVariables = Exact<{
  where: ViewDisplayAlertsBoolExp;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: Maybe<Array<ViewDisplayAlertsOrderBy> | ViewDisplayAlertsOrderBy>;
}>;


export type DisplayAlertsQuery = (
  { __typename?: 'query_root' }
  & { _alerts: Array<(
    { __typename?: 'view_display_alerts' }
    & Pick<ViewDisplayAlerts, 'time' | 'description'>
    & { display?: Maybe<(
      { __typename?: 'view_displays' }
      & Pick<ViewDisplays, 'id' | 'name'>
      & { categories: Array<(
        { __typename?: 'view_display_assigned_categories' }
        & Pick<ViewDisplayAssignedCategories, 'category_id' | 'option_id' | 'is_assigned'>
      )> }
    )> }
  )>, view_display_alerts_aggregate: (
    { __typename?: 'view_display_alerts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_display_alerts_aggregate_fields' }
      & Pick<ViewDisplayAlertsAggregateFields, 'count'>
    )> }
  ) }
);

export type DisplayCountQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type DisplayCountQuery = (
  { __typename?: 'query_root' }
  & { totalDisplays: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type DisplaysQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type DisplaysQuery = (
  { __typename?: 'query_root' }
  & { displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'uuid' | 'name' | 'timezone'>
  )> }
);

export type DisplaysActionsEligibilityQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type DisplaysActionsEligibilityQuery = (
  { __typename?: 'query_root' }
  & { offline: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ), offlinePod: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'name'>
  )>, unsupportedFirmware: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ), failedOnboarding: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  )}
);

export type DisplaysAvailabilityQQueryVariables = Exact<{
  where: ViewDisplaysBoolExp;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type DisplaysAvailabilityQQuery = (
  { __typename?: 'query_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'name' | 'org' | 'version' | 'is_in_subscription'>
    & { catchingUp: ViewDisplays['catching_up'] }
    & { availability?: Maybe<(
      { __typename?: 'view_displays_availability' }
      & Pick<ViewDisplaysAvailability, 'is_online' | 'last_online' | 'status'>
    )> }
  )> }
);

export type DisplaysByGeoQueryVariables = Exact<{
  args: DisplaysByGeoArgs;
}>;


export type DisplaysByGeoQuery = (
  { __typename?: 'query_root' }
  & { _displays_by_geo: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'name'>
    & { location?: Maybe<(
      { __typename?: 'view_displays_locations' }
      & Pick<ViewDisplaysLocations, 'precise_lat' | 'precise_long'>
    )> }
  )> }
);

export type DisplaysCategoriesQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type DisplaysCategoriesQuery = (
  { __typename?: 'query_root' }
  & { displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'name'>
    & { categories: Array<(
      { __typename?: 'view_display_assigned_categories' }
      & Pick<ViewDisplayAssignedCategories, 'category_id' | 'display_id'>
      & { option?: Maybe<(
        { __typename?: 'view_options' }
        & Pick<ViewOptions, 'id'>
        & { displayName: ViewOptions['display_name'] }
      )> }
    )> }
  )> }
);

export type DisplaysDeploymentCsvQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type DisplaysDeploymentCsvQuery = (
  { __typename?: 'query_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & DisplayDeploymentPropsFragment
  )> }
);

export type ExpiredDisplaysQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ExpiredDisplaysQuery = (
  { __typename?: 'query_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'hardware' | 'is_manageable' | 'name' | 'id' | 'is_in_subscription'>
    & { subscription?: Maybe<(
      { __typename?: 'view_displays_subscriptions' }
      & Pick<ViewDisplaysSubscriptions, 'is_in_subscription' | 'maintenance_end' | 'flexera_maintenance_end'>
    )>, deployment?: Maybe<(
      { __typename?: 'view_displays_deployment' }
      & Pick<ViewDisplaysDeployment, 'license' | 'edition'>
    )> }
  )> }
);

export type AnalyticCountsQueryVariables = Exact<{
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
}>;


export type AnalyticCountsQuery = (
  { __typename?: 'query_root' }
  & { _busiest_hour: Array<(
    { __typename?: 'view_single_result' }
    & Pick<ViewSingleResult, 'value'>
  )>, _median_meeting_duration: Array<(
    { __typename?: 'view_single_result' }
    & Pick<ViewSingleResult, 'value'>
  )>, _unique_users_count: Array<(
    { __typename?: 'view_single_result' }
    & Pick<ViewSingleResult, 'value'>
  )> }
);

export type HybridMeetingsQueryVariables = Exact<{
  args: HybridMeetingsGraphArgs;
}>;


export type HybridMeetingsQuery = (
  { __typename?: 'query_root' }
  & { hybridMeetings: Array<(
    { __typename?: 'view_hybrid_meeting_aggregation_result' }
    & Pick<ViewHybridMeetingAggregationResult, 'org_id' | 'date' | 'total_proximate_meetings' | 'total_remote_meetings'>
  )> }
);

export type JoyridesQueryVariables = Exact<{ [key: string]: never; }>;


export type JoyridesQuery = (
  { __typename?: 'query_root' }
  & { joyrides: Array<(
    { __typename?: 'orgs_users_joyrides' }
    & Pick<OrgsUsersJoyrides, 'joyride' | 'finished_on'>
  )> }
);

export type BuildingLocationsQueryVariables = Exact<{
  where?: Maybe<OrgAddressesBoolExp>;
  whereDisplay?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<OrgAddressesOrderBy> | OrgAddressesOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type BuildingLocationsQuery = (
  { __typename?: 'query_root' }
  & { buildings: Array<(
    { __typename?: 'org_addresses' }
    & Pick<OrgAddresses, 'id' | 'city' | 'country'>
    & { building: OrgAddresses['nickname'], address: OrgAddresses['building'] }
    & { totalPods: (
      { __typename?: 'view_displays_locations_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'view_displays_locations_aggregate_fields' }
        & Pick<ViewDisplaysLocationsAggregateFields, 'count'>
      )> }
    ) }
  )>, totalBuildings: (
    { __typename?: 'org_addresses_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'org_addresses_aggregate_fields' }
      & Pick<OrgAddressesAggregateFields, 'count'>
    )> }
  ) }
);

export type DisplayAddressesCountQueryVariables = Exact<{
  where: ViewDisplaysLocationsBoolExp;
}>;


export type DisplayAddressesCountQuery = (
  { __typename?: 'query_root' }
  & { address: Array<(
    { __typename?: 'org_addresses' }
    & Pick<OrgAddresses, 'id'>
  )>, total: (
    { __typename?: 'org_addresses_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'org_addresses_aggregate_fields' }
      & Pick<OrgAddressesAggregateFields, 'count'>
    )> }
  ), noAddresses: Array<(
    { __typename?: 'view_displays_locations' }
    & Pick<ViewDisplaysLocations, 'address_id'>
  )> }
);

export type FetchAffectedDisplayIdsQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type FetchAffectedDisplayIdsQuery = (
  { __typename?: 'query_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'uuid'>
    & { location?: Maybe<(
      { __typename?: 'view_displays_locations' }
      & Pick<ViewDisplaysLocations, 'precise_lat' | 'precise_long'>
      & { address?: Maybe<(
        { __typename?: 'org_addresses' }
        & Pick<OrgAddresses, 'city' | 'state_province' | 'country'>
      )> }
    )> }
  )> }
);

export type HomePageMapQueryVariables = Exact<{ [key: string]: never; }>;


export type HomePageMapQuery = (
  { __typename?: 'query_root' }
  & { displayCount: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ), displayLocations: Array<(
    { __typename?: 'view_displays_locations' }
    & Pick<ViewDisplaysLocations, 'precise_lat' | 'precise_long'>
    & { _display?: Maybe<(
      { __typename?: 'view_displays' }
      & Pick<ViewDisplays, 'id' | 'name'>
    )> }
  )> }
);

export type HomePageMapNewQueryVariables = Exact<{
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
}>;


export type HomePageMapNewQuery = (
  { __typename?: 'query_root' }
  & { mapData: Array<(
    { __typename?: 'view_home_page_map' }
    & Pick<ViewHomePageMap, 'id' | 'name' | 'out_of_template_count' | 'precise_lat' | 'precise_long' | 'restart_count' | 'total_alerts' | 'unreachable_count' | 'usage_hours' | 'usb_added_event_count' | 'usb_removed_event_count'>
  )> }
);

export type LocationsPageCardsQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
  category: Scalars['String'];
}>;


export type LocationsPageCardsQuery = (
  { __typename?: 'query_root' }
  & { _categories: Array<(
    { __typename?: 'view_categories' }
    & Pick<ViewCategories, 'id' | 'display_name'>
    & { options: Array<(
      { __typename?: 'view_options' }
      & Pick<ViewOptions, 'id' | 'display_name'>
      & { assignedDisplays_aggregate: (
        { __typename?: 'view_displays_assigned_options_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'view_displays_assigned_options_aggregate_fields' }
          & Pick<ViewDisplaysAssignedOptionsAggregateFields, 'count'>
        )> }
      ) }
    )> }
  )> }
);

export type LocationFloorListQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type LocationFloorListQuery = (
  { __typename?: 'query_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & { location?: Maybe<(
      { __typename?: 'view_displays_locations' }
      & Pick<ViewDisplaysLocations, 'address_id'>
      & { floor_count?: Maybe<(
        { __typename?: 'view_floor_count' }
        & Pick<ViewFloorCount, 'address_id' | 'floor' | 'count_per_floor'>
      )> }
    )> }
  )> }
);

export type LocationsPageQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  whereTotal?: Maybe<ViewDisplaysBoolExp>;
}>;


export type LocationsPageQuery = (
  { __typename?: 'query_root' }
  & { totalDisplayCount: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ), filteredDisplayCount: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type LocationsPageMapQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type LocationsPageMapQuery = (
  { __typename?: 'query_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'name'>
    & { location?: Maybe<(
      { __typename?: 'view_displays_locations' }
      & Pick<ViewDisplaysLocations, 'precise_lat' | 'precise_long' | 'address_id' | 'floor'>
      & { address?: Maybe<(
        { __typename?: 'org_addresses' }
        & Pick<OrgAddresses, 'id' | 'address_full' | 'building' | 'city' | 'country' | 'state_province' | 'nickname' | 'lat' | 'long'>
        & { assignedDisplays_aggregate: (
          { __typename?: 'view_displays_locations_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'view_displays_locations_aggregate_fields' }
            & Pick<ViewDisplaysLocationsAggregateFields, 'count'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type LocationsPageOverviewCardCountQueryVariables = Exact<{
  displaywhere?: Maybe<ViewDisplaysBoolExp>;
}>;


export type LocationsPageOverviewCardCountQuery = (
  { __typename?: 'query_root' }
  & { country: (
    { __typename?: 'view_displays_assigned_options_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_assigned_options_aggregate_fields' }
      & Pick<ViewDisplaysAssignedOptionsAggregateFields, 'count'>
    )> }
  ), city: (
    { __typename?: 'view_displays_assigned_options_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_assigned_options_aggregate_fields' }
      & Pick<ViewDisplaysAssignedOptionsAggregateFields, 'count'>
    )> }
  ), building: (
    { __typename?: 'view_displays_assigned_options_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_assigned_options_aggregate_fields' }
      & Pick<ViewDisplaysAssignedOptionsAggregateFields, 'count'>
    )> }
  ), unassignedBuilding: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ), pods: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type LocationsPageTableQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type LocationsPageTableQuery = (
  { __typename?: 'query_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'name' | 'version' | 'hardware' | 'ip_addresses_primary'>
    & { availability?: Maybe<(
      { __typename?: 'view_displays_availability' }
      & Pick<ViewDisplaysAvailability, 'is_online'>
    )>, location?: Maybe<(
      { __typename?: 'view_displays_locations' }
      & { floor_count?: Maybe<(
        { __typename?: 'view_floor_count' }
        & Pick<ViewFloorCount, 'floor' | 'count_per_floor'>
      )>, address?: Maybe<(
        { __typename?: 'org_addresses' }
        & Pick<OrgAddresses, 'id' | 'nickname' | 'building' | 'city' | 'country' | 'address_full'>
      )> }
    )> }
  )> }
);

export type MostCollaborativeSpacesQueryVariables = Exact<{
  args: SearchMostCollaborativeSpacesArgs;
}>;


export type MostCollaborativeSpacesQuery = (
  { __typename?: 'query_root' }
  & { _search_most_collaborative_spaces: Array<(
    { __typename?: 'view_most_collaborative_spaces_result' }
    & Pick<ViewMostCollaborativeSpacesResult, 'collaborators' | 'display_id' | 'location_latitude' | 'location_longitude' | 'mtngs' | 'name' | 'org' | 'plot_score' | 'unq_users_avg' | 'room_type' | 'nickname' | 'building' | 'address'>
  )> }
);

export type OptionsWithDisplayCountQueryVariables = Exact<{
  category_id: Scalars['String'];
  where?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
  order_by?: Maybe<Array<ViewOptionsOrderBy> | ViewOptionsOrderBy>;
}>;


export type OptionsWithDisplayCountQuery = (
  { __typename?: 'query_root' }
  & { _options: Array<(
    { __typename?: 'view_options' }
    & Pick<ViewOptions, 'id' | 'category_id' | 'display_name'>
    & { assignedDisplays_aggregate: (
      { __typename?: 'view_displays_assigned_options_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'view_displays_assigned_options_aggregate_fields' }
        & Pick<ViewDisplaysAssignedOptionsAggregateFields, 'count'>
      )> }
    ) }
  )> }
);

export type RealtimeQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type RealtimeQuery = (
  { __typename?: 'query_root' }
  & { realtimePods: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'org'>
    & { display: ViewDisplays['id'], room: ViewDisplays['name'] }
    & { location?: Maybe<(
      { __typename?: 'view_displays_locations' }
      & Pick<ViewDisplaysLocations, 'precise_lat' | 'precise_long'>
      & { address?: Maybe<(
        { __typename?: 'org_addresses' }
        & Pick<OrgAddresses, 'nickname' | 'building'>
      )> }
    )>, discover_realtime?: Maybe<(
      { __typename?: 'view_displays_discover_realtime' }
      & Pick<ViewDisplaysDiscoverRealtime, 'foot_traffic' | 'myfoottraffic' | 'cur_calendar_start' | 'cur_calendar_end' | 'cur_calendar_attendees' | 'next_calendar_start' | 'next_calendar_end' | 'next_calendar_attendees' | 'current_occupancy_camera'>
    )>, realtime?: Maybe<(
      { __typename?: 'view_displays_realtime' }
      & { realtime_status: ViewDisplaysRealtime['my_status'], user_count: ViewDisplaysRealtime['solstice_users'], calendar_config_enabled: ViewDisplaysRealtime['cal_enabled'] }
    )> }
  )> }
);

export type UserDevicesQueryVariables = Exact<{
  args: SearchUserDevicesArgs;
  order_by?: Maybe<Array<ViewUserDevicesOrderBy> | ViewUserDevicesOrderBy>;
}>;


export type UserDevicesQuery = (
  { __typename?: 'query_root' }
  & { userDevices: Array<(
    { __typename?: 'view_user_devices' }
    & Pick<ViewUserDevices, 'total'>
    & { clientPlatform: ViewUserDevices['client_platform'] }
  )> }
);

export type SessionsPerUserQueryVariables = Exact<{
  args: SessionsPerUserArgs;
}>;


export type SessionsPerUserQuery = (
  { __typename?: 'query_root' }
  & { sessionsPerUser: Array<(
    { __typename?: 'view_single_result' }
    & Pick<ViewSingleResult, 'org' | 'value'>
  )> }
);

export type SolsticeVersionsQueryVariables = Exact<{
  whereDisplays?: Maybe<ViewDisplaysBoolExp>;
}>;


export type SolsticeVersionsQuery = (
  { __typename?: 'query_root' }
  & { versions: Array<(
    { __typename?: 'view_solstice_versions' }
    & Pick<ViewSolsticeVersions, 'version'>
    & { displays: (
      { __typename?: 'view_displays_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'view_displays_aggregate_fields' }
        & Pick<ViewDisplaysAggregateFields, 'count'>
      )> }
    ) }
  )>, totalDisplays: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type TableFilterCategoriesQueryVariables = Exact<{
  where?: Maybe<ViewCategoriesBoolExp>;
}>;


export type TableFilterCategoriesQuery = (
  { __typename?: 'query_root' }
  & { _categories: Array<(
    { __typename?: 'view_categories' }
    & Pick<ViewCategories, 'id' | 'category_type' | 'display_name' | 'is_auto_generated'>
  )>, _displaysAggregate: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type UsbDeviceAvailabilityQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
}>;


export type UsbDeviceAvailabilityQuery = (
  { __typename?: 'query_root' }
  & { offline: (
    { __typename?: 'view_displays_current_usb_connections_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
      & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
    )> }
  ), online: (
    { __typename?: 'view_displays_current_usb_connections_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
      & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
    )> }
  ) }
);

export type UsbDeviceHistoryQueryVariables = Exact<{
  days: Scalars['Int'];
  startDate: Scalars['timestamptz'];
}>;


export type UsbDeviceHistoryQuery = (
  { __typename?: 'query_root' }
  & { history: Array<(
    { __typename?: 'view_usb_product_history' }
    & Pick<ViewUsbProductHistory, 'dt'>
    & { onlineCount: ViewUsbProductHistory['plug_count'], offlineCount: ViewUsbProductHistory['unplug_count'] }
  )>, total: (
    { __typename?: 'view_usb_product_history_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_usb_product_history_aggregate_fields' }
      & { counts?: Maybe<(
        { __typename?: 'view_usb_product_history_sum_fields' }
        & { online: ViewUsbProductHistorySumFields['plug_count'], offline: ViewUsbProductHistorySumFields['unplug_count'] }
      )> }
    )> }
  ) }
);

export type UsbDeviceProductsQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
}>;


export type UsbDeviceProductsQuery = (
  { __typename?: 'query_root' }
  & { products: Array<(
    { __typename?: 'view_current_usb_products' }
    & { productId: ViewCurrentUsbProducts['product_id'], productName: ViewCurrentUsbProducts['product_name'] }
    & { connections: (
      { __typename?: 'view_displays_current_usb_connections_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
        & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
      )> }
    ) }
  )>, unknownDevices: (
    { __typename?: 'view_displays_current_usb_connections_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
      & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
    )> }
  ), total: (
    { __typename?: 'view_displays_current_usb_connections_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
      & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
    )> }
  ) }
);

export type UsbDevicesQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysCurrentUsbConnectionsOrderBy> | ViewDisplaysCurrentUsbConnectionsOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type UsbDevicesQuery = (
  { __typename?: 'query_root' }
  & { connections: Array<(
    { __typename?: 'view_displays_current_usb_connections' }
    & Pick<ViewDisplaysCurrentUsbConnections, 'vendor_name' | 'device_name' | 'usb_plug_timestamp' | 'full_product_name' | 'version' | 'relay_enabled' | 'occupancy_counting_enabled' | 'is_conference_ready' | 'is_occupancy_ready' | 'is_online' | 'av_device'>
    & { org: ViewDisplaysCurrentUsbConnections['org_id'] }
    & { display?: Maybe<(
      { __typename?: 'view_displays' }
      & Pick<ViewDisplays, 'id' | 'version' | 'name' | 'org' | 'hardware' | 'is_in_subscription'>
    )> }
  )>, totalConnections: (
    { __typename?: 'view_displays_current_usb_connections_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
      & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
    )> }
  ) }
);

export type UsbDevicesCountQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysCurrentUsbConnectionsBoolExp>;
}>;


export type UsbDevicesCountQuery = (
  { __typename?: 'query_root' }
  & { totalPods: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ), podsWithDevices: (
    { __typename?: 'view_displays_current_usb_connections_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
      & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
    )> }
  ), totalDevices: (
    { __typename?: 'view_displays_current_usb_connections_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
      & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
    )> }
  ), totalAvDevices: (
    { __typename?: 'view_displays_current_usb_connections_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
      & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
    )> }
  ), totalOtherDevices: (
    { __typename?: 'view_displays_current_usb_connections_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_current_usb_connections_aggregate_fields' }
      & Pick<ViewDisplaysCurrentUsbConnectionsAggregateFields, 'count'>
    )> }
  ) }
);

export type UniqueUsersCountQueryVariables = Exact<{
  args: UniqueUsersCountArgs;
}>;


export type UniqueUsersCountQuery = (
  { __typename?: 'query_root' }
  & { usersCount: Array<(
    { __typename?: 'view_single_result' }
    & Pick<ViewSingleResult, 'org' | 'value'>
  )> }
);

export type UpdateablePodsQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type UpdateablePodsQuery = (
  { __typename?: 'query_root' }
  & { displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'name'>
    & { catchingUp: ViewDisplays['catching_up'] }
    & { updateability?: Maybe<(
      { __typename?: 'view_displays_updateability' }
      & Pick<ViewDisplaysUpdateability, 'version' | 'updating' | 'updateable' | 'status'>
    )>, subscription?: Maybe<(
      { __typename?: 'view_displays_subscriptions' }
      & Pick<ViewDisplaysSubscriptions, 'subscription_date'>
    )> }
  )>, totalDisplays: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ), totalSelectableDisplays: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type UpdateablePodsCountQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysUpdateabilityBoolExp>;
}>;


export type UpdateablePodsCountQuery = (
  { __typename?: 'query_root' }
  & { totalDisplays: (
    { __typename?: 'view_displays_updateability_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_updateability_aggregate_fields' }
      & Pick<ViewDisplaysUpdateabilityAggregateFields, 'count'>
    )> }
  ) }
);

export type UpdateablePodsSlimQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysUpdateabilityBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysUpdateabilityOrderBy> | ViewDisplaysUpdateabilityOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type UpdateablePodsSlimQuery = (
  { __typename?: 'query_root' }
  & { displays: Array<(
    { __typename?: 'view_displays_updateability' }
    & Pick<ViewDisplaysUpdateability, 'version' | 'updating' | 'updateable' | 'status'>
  )> }
);

export type UsageByRoomTypeQueryVariables = Exact<{
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
}>;


export type UsageByRoomTypeQuery = (
  { __typename?: 'query_root' }
  & { roomTypes: Array<(
    { __typename?: 'view_usage_by_room_type' }
    & Pick<ViewUsageByRoomType, 'id'>
    & { name: ViewUsageByRoomType['room_type'], totalMeetingLength: ViewUsageByRoomType['total_meeting_length'] }
  )> }
);

export type UserCollaborationQueryVariables = Exact<{
  args: UserCollaborationArgs;
}>;


export type UserCollaborationQuery = (
  { __typename?: 'query_root' }
  & { _user_collaboration: Array<(
    { __typename?: 'view_user_collaboration_results' }
    & Pick<ViewUserCollaborationResults, 'group' | 'org_id' | 'total'>
  )> }
);

export type UsersQueryVariables = Exact<{
  where?: Maybe<ViewUsersBoolExp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ViewUsersOrderBy> | ViewUsersOrderBy>;
}>;


export type UsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'view_users' }
    & Pick<ViewUsers, 'email' | 'role' | 'status' | 'sso_only' | 'has_password'>
  )>, totalUsers: (
    { __typename?: 'view_users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_users_aggregate_fields' }
      & Pick<ViewUsersAggregateFields, 'count'>
    )> }
  ) }
);

export type WellnessConfigQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type WellnessConfigQuery = (
  { __typename?: 'query_root' }
  & { displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'is_online'>
    & { subscription?: Maybe<(
      { __typename?: 'view_displays_subscriptions' }
      & Pick<ViewDisplaysSubscriptions, 'is_in_subscription'>
    )>, wellness_config?: Maybe<(
      { __typename?: 'view_displays_wellness_config' }
      & Pick<ViewDisplaysWellnessConfig, 'name' | 'wellness_ready' | 'wellness_enabled' | 'plugged_in' | 'occ_enabled' | 'calendar_config_enabled' | 'location_set'>
    )>, location?: Maybe<(
      { __typename?: 'view_displays_locations' }
      & { address?: Maybe<(
        { __typename?: 'org_addresses' }
        & Pick<OrgAddresses, 'building' | 'nickname'>
      )> }
    )> }
  )> }
);

export type WellnessRealtimeQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type WellnessRealtimeQuery = (
  { __typename?: 'query_root' }
  & { displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'is_online'>
    & { subscription?: Maybe<(
      { __typename?: 'view_displays_subscriptions' }
      & Pick<ViewDisplaysSubscriptions, 'is_in_subscription'>
    )>, discover_realtime?: Maybe<(
      { __typename?: 'view_displays_discover_realtime' }
      & Pick<ViewDisplaysDiscoverRealtime, 'name' | 'current_occupancy_camera' | 'myfoottraffic' | 'foot_traffic' | 'cur_calendar_start' | 'cur_calendar_end' | 'cur_calendar_attendees' | 'next_calendar_start' | 'next_calendar_end' | 'next_calendar_attendees'>
    )>, wellness_config?: Maybe<(
      { __typename?: 'view_displays_wellness_config' }
      & Pick<ViewDisplaysWellnessConfig, 'wellness_ready'>
    )>, location?: Maybe<(
      { __typename?: 'view_displays_locations' }
      & { address?: Maybe<(
        { __typename?: 'org_addresses' }
        & Pick<OrgAddresses, 'building' | 'nickname'>
      )> }
    )> }
  )> }
);

export type WorkspaceStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkspaceStatusQuery = (
  { __typename?: 'query_root' }
  & { orgs_flags: Array<(
    { __typename?: 'orgs_flags' }
    & Pick<OrgsFlags, 'wellness_enabled'>
  )> }
);

export type ActiveLearningFlagsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ActiveLearningFlagsSubscription = (
  { __typename?: 'subscription_root' }
  & { active_learning_flags: Array<(
    { __typename?: 'active_learning_flags' }
    & Pick<ActiveLearningFlags, 'screen_key_enabled'>
  )> }
);

export type ActiveMeetingCountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ActiveMeetingCountSubscription = (
  { __typename?: 'subscription_root' }
  & { _active_meeting_count: Array<(
    { __typename?: 'view_single_result' }
    & Pick<ViewSingleResult, 'value'>
  )> }
);

export type AlertsChartSubscriptionVariables = Exact<{
  args: AlertsChartArgs;
}>;


export type AlertsChartSubscription = (
  { __typename?: 'subscription_root' }
  & { _alerts_chart: Array<(
    { __typename?: 'view_alerts_chart_result' }
    & Pick<ViewAlertsChartResult, 'alert_type' | 'alerts' | 'dt' | 'org_id'>
  )> }
);

export type DisplayAlertsTableSubscriptionVariables = Exact<{
  where: ViewDisplayAlertsBoolExp;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: Maybe<Array<ViewDisplayAlertsOrderBy> | ViewDisplayAlertsOrderBy>;
}>;


export type DisplayAlertsTableSubscription = (
  { __typename?: 'subscription_root' }
  & { _alerts: Array<(
    { __typename?: 'view_display_alerts' }
    & Pick<ViewDisplayAlerts, 'time' | 'description' | 'alert_type'>
    & { display?: Maybe<(
      { __typename?: 'view_displays' }
      & Pick<ViewDisplays, 'id' | 'name'>
    )> }
  )> }
);

export type AvailabilityDisplayCountSubscriptionVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type AvailabilityDisplayCountSubscription = (
  { __typename?: 'subscription_root' }
  & { totalDisplays: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type AvailabilityOnlineDisplayCountSubscriptionVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type AvailabilityOnlineDisplayCountSubscription = (
  { __typename?: 'subscription_root' }
  & { totalOnline: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type AvailabilityUptimeDisplayCountSubscriptionVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type AvailabilityUptimeDisplayCountSubscription = (
  { __typename?: 'subscription_root' }
  & { totalUpTimeDisplays: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type DeploymentSdsQueryVariables = Exact<{
  where: ViewSdsBoolExp;
  whereAggregate: ViewDisplaysHostsBoolExp;
}>;


export type DeploymentSdsQuery = (
  { __typename?: 'query_root' }
  & { view_sds: Array<(
    { __typename?: 'view_sds' }
    & Pick<ViewSds, 'host'>
    & { displays_aggregate: (
      { __typename?: 'view_displays_hosts_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'view_displays_hosts_aggregate_fields' }
        & Pick<ViewDisplaysHostsAggregateFields, 'count'>
      )> }
    ) }
  )> }
);

export type DisplayCountSubSubscriptionVariables = Exact<{
  where: ViewDisplaysBoolExp;
}>;


export type DisplayCountSubSubscription = (
  { __typename?: 'subscription_root' }
  & { totalDisplays: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type DisplaysDeploymentSubscriptionVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type DisplaysDeploymentSubscription = (
  { __typename?: 'subscription_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & DisplayDeploymentPropsFragment
  )> }
);

export type DeploymentDisplayCountSubscriptionVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type DeploymentDisplayCountSubscription = (
  { __typename?: 'subscription_root' }
  & { totalDisplays: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type DisplayLocationSubscriptionVariables = Exact<{
  id: Scalars['String'];
}>;


export type DisplayLocationSubscription = (
  { __typename?: 'subscription_root' }
  & { _display_locations: Array<(
    { __typename?: 'view_displays_locations' }
    & Pick<ViewDisplaysLocations, 'precise_lat' | 'precise_long' | 'address_id'>
    & { address?: Maybe<(
      { __typename?: 'org_addresses' }
      & Pick<OrgAddresses, 'id' | 'address_full' | 'building' | 'nickname' | 'zip_code' | 'city' | 'state_province' | 'country' | 'lat' | 'long'>
    )> }
  )> }
);

export type DisplaysAvailabilitySubscriptionVariables = Exact<{
  where: ViewDisplaysBoolExp;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type DisplaysAvailabilitySubscription = (
  { __typename?: 'subscription_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'name' | 'org' | 'version' | 'is_in_subscription'>
    & { availability?: Maybe<(
      { __typename?: 'view_displays_availability' }
      & Pick<ViewDisplaysAvailability, 'is_online' | 'last_online' | 'status'>
    )> }
  )> }
);

export type DisplaysCategoriesSubSubscriptionVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type DisplaysCategoriesSubSubscription = (
  { __typename?: 'subscription_root' }
  & { displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'name'>
    & { categories: Array<(
      { __typename?: 'view_display_assigned_categories' }
      & Pick<ViewDisplayAssignedCategories, 'category_id' | 'display_id'>
      & { option?: Maybe<(
        { __typename?: 'view_options' }
        & Pick<ViewOptions, 'id'>
        & { displayName: ViewOptions['display_name'] }
      )> }
    )> }
  )> }
);

export type FilteredDisplayCountSubscriptionVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
}>;


export type FilteredDisplayCountSubscription = (
  { __typename?: 'subscription_root' }
  & { _displaysAggregate: (
    { __typename?: 'view_displays_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_aggregate_fields' }
      & Pick<ViewDisplaysAggregateFields, 'count'>
    )> }
  ) }
);

export type LocationsPageCardsCategoryCountSubscriptionVariables = Exact<{
  where?: Maybe<ViewDisplaysAssignedOptionsBoolExp>;
}>;


export type LocationsPageCardsCategoryCountSubscription = (
  { __typename?: 'subscription_root' }
  & { _displayAssignedOptionsAggregate: (
    { __typename?: 'view_displays_assigned_options_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'view_displays_assigned_options_aggregate_fields' }
      & Pick<ViewDisplaysAssignedOptionsAggregateFields, 'count'>
    )> }
  ) }
);

export type LocationsPageTableSQueryVariables = Exact<{
  where?: Maybe<ViewDisplaysBoolExp>;
  orderBy?: Maybe<Array<ViewDisplaysOrderBy> | ViewDisplaysOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type LocationsPageTableSQuery = (
  { __typename?: 'query_root' }
  & { _displays: Array<(
    { __typename?: 'view_displays' }
    & Pick<ViewDisplays, 'id' | 'name' | 'version' | 'hardware' | 'ip_addresses_primary'>
    & { availability?: Maybe<(
      { __typename?: 'view_displays_availability' }
      & Pick<ViewDisplaysAvailability, 'is_online'>
    )>, location?: Maybe<(
      { __typename?: 'view_displays_locations' }
      & { floor_count?: Maybe<(
        { __typename?: 'view_floor_count' }
        & Pick<ViewFloorCount, 'floor' | 'count_per_floor'>
      )>, address?: Maybe<(
        { __typename?: 'org_addresses' }
        & Pick<OrgAddresses, 'id' | 'nickname' | 'building' | 'city' | 'country' | 'address_full'>
      )> }
    )> }
  )> }
);

export const DisplayDeploymentPropsFragmentDoc = gql`
    fragment DisplayDeploymentProps on view_displays {
  name
  org
  version
  hardware
  id
  subscription {
    maintenance_end
    flexera_maintenance_end
  }
  version
  deployment {
    display_id
    ip_primary
    ip_wifi
    license
    mac_primary
    mac_wifi
    sds_enabled
    sds_host_1
    sds_host_2
    tags {
      color
      tag
    }
  }
  online: is_online
  pending: has_unconfirmed_templates
  inMx: is_in_subscription
  onboard_date
  deploy_date
  catchingUp: catching_up
}
    `;
export const AddNewAddressDocument = gql`
    mutation _AddNewAddress($object: org_addresses_insert_input!) {
  insert_org_addresses_one(object: $object) {
    id
    address_full
    lat
    long
  }
}
    `;
export type AddNewAddressMutationFn = Apollo.MutationFunction<AddNewAddressMutation, AddNewAddressMutationVariables>;

/**
 * __useAddNewAddressMutation__
 *
 * To run a mutation, you first call `useAddNewAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewAddressMutation, { data, loading, error }] = useAddNewAddressMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddNewAddressMutation(baseOptions?: Apollo.MutationHookOptions<AddNewAddressMutation, AddNewAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewAddressMutation, AddNewAddressMutationVariables>(AddNewAddressDocument, options);
      }
export type AddNewAddressMutationHookResult = ReturnType<typeof useAddNewAddressMutation>;
export type AddNewAddressMutationResult = Apollo.MutationResult<AddNewAddressMutation>;
export type AddNewAddressMutationOptions = Apollo.BaseMutationOptions<AddNewAddressMutation, AddNewAddressMutationVariables>;
export const DeleteRebootedAlertFiltersDocument = gql`
    mutation _DeleteRebootedAlertFilters($categoryInternalName: String, $optionInternalName: String) {
  delete_orgs_users_rebooted_filters(
    where: {category_internal_name: {_eq: $categoryInternalName}, option_internal_name: {_eq: $optionInternalName}}
  ) {
    returning {
      id: rebooted_filter
    }
  }
}
    `;
export type DeleteRebootedAlertFiltersMutationFn = Apollo.MutationFunction<DeleteRebootedAlertFiltersMutation, DeleteRebootedAlertFiltersMutationVariables>;

/**
 * __useDeleteRebootedAlertFiltersMutation__
 *
 * To run a mutation, you first call `useDeleteRebootedAlertFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRebootedAlertFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRebootedAlertFiltersMutation, { data, loading, error }] = useDeleteRebootedAlertFiltersMutation({
 *   variables: {
 *      categoryInternalName: // value for 'categoryInternalName'
 *      optionInternalName: // value for 'optionInternalName'
 *   },
 * });
 */
export function useDeleteRebootedAlertFiltersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRebootedAlertFiltersMutation, DeleteRebootedAlertFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRebootedAlertFiltersMutation, DeleteRebootedAlertFiltersMutationVariables>(DeleteRebootedAlertFiltersDocument, options);
      }
export type DeleteRebootedAlertFiltersMutationHookResult = ReturnType<typeof useDeleteRebootedAlertFiltersMutation>;
export type DeleteRebootedAlertFiltersMutationResult = Apollo.MutationResult<DeleteRebootedAlertFiltersMutation>;
export type DeleteRebootedAlertFiltersMutationOptions = Apollo.BaseMutationOptions<DeleteRebootedAlertFiltersMutation, DeleteRebootedAlertFiltersMutationVariables>;
export const DeleteTemplateAlertFiltersDocument = gql`
    mutation _DeleteTemplateAlertFilters($categoryInternalName: String, $optionInternalName: String) {
  delete_orgs_users_template_filters(
    where: {category_internal_name: {_eq: $categoryInternalName}, option_internal_name: {_eq: $optionInternalName}}
  ) {
    returning {
      id: template_filter
    }
  }
}
    `;
export type DeleteTemplateAlertFiltersMutationFn = Apollo.MutationFunction<DeleteTemplateAlertFiltersMutation, DeleteTemplateAlertFiltersMutationVariables>;

/**
 * __useDeleteTemplateAlertFiltersMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateAlertFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateAlertFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateAlertFiltersMutation, { data, loading, error }] = useDeleteTemplateAlertFiltersMutation({
 *   variables: {
 *      categoryInternalName: // value for 'categoryInternalName'
 *      optionInternalName: // value for 'optionInternalName'
 *   },
 * });
 */
export function useDeleteTemplateAlertFiltersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateAlertFiltersMutation, DeleteTemplateAlertFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateAlertFiltersMutation, DeleteTemplateAlertFiltersMutationVariables>(DeleteTemplateAlertFiltersDocument, options);
      }
export type DeleteTemplateAlertFiltersMutationHookResult = ReturnType<typeof useDeleteTemplateAlertFiltersMutation>;
export type DeleteTemplateAlertFiltersMutationResult = Apollo.MutationResult<DeleteTemplateAlertFiltersMutation>;
export type DeleteTemplateAlertFiltersMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateAlertFiltersMutation, DeleteTemplateAlertFiltersMutationVariables>;
export const DeleteUnreachableAlertFiltersDocument = gql`
    mutation _DeleteUnreachableAlertFilters($categoryInternalName: String, $optionInternalName: String) {
  delete_orgs_users_unreachable_filters(
    where: {category_internal_name: {_eq: $categoryInternalName}, option_internal_name: {_eq: $optionInternalName}}
  ) {
    returning {
      id: unreachable_filter
    }
  }
}
    `;
export type DeleteUnreachableAlertFiltersMutationFn = Apollo.MutationFunction<DeleteUnreachableAlertFiltersMutation, DeleteUnreachableAlertFiltersMutationVariables>;

/**
 * __useDeleteUnreachableAlertFiltersMutation__
 *
 * To run a mutation, you first call `useDeleteUnreachableAlertFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnreachableAlertFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnreachableAlertFiltersMutation, { data, loading, error }] = useDeleteUnreachableAlertFiltersMutation({
 *   variables: {
 *      categoryInternalName: // value for 'categoryInternalName'
 *      optionInternalName: // value for 'optionInternalName'
 *   },
 * });
 */
export function useDeleteUnreachableAlertFiltersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnreachableAlertFiltersMutation, DeleteUnreachableAlertFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnreachableAlertFiltersMutation, DeleteUnreachableAlertFiltersMutationVariables>(DeleteUnreachableAlertFiltersDocument, options);
      }
export type DeleteUnreachableAlertFiltersMutationHookResult = ReturnType<typeof useDeleteUnreachableAlertFiltersMutation>;
export type DeleteUnreachableAlertFiltersMutationResult = Apollo.MutationResult<DeleteUnreachableAlertFiltersMutation>;
export type DeleteUnreachableAlertFiltersMutationOptions = Apollo.BaseMutationOptions<DeleteUnreachableAlertFiltersMutation, DeleteUnreachableAlertFiltersMutationVariables>;
export const DeleteUsbPlugAlertFiltersDocument = gql`
    mutation _DeleteUsbPlugAlertFilters($categoryInternalName: String, $optionInternalName: String) {
  delete_orgs_users_usb_plug_filters(
    where: {category_internal_name: {_eq: $categoryInternalName}, option_internal_name: {_eq: $optionInternalName}}
  ) {
    returning {
      id: usb_plug_filter
    }
  }
}
    `;
export type DeleteUsbPlugAlertFiltersMutationFn = Apollo.MutationFunction<DeleteUsbPlugAlertFiltersMutation, DeleteUsbPlugAlertFiltersMutationVariables>;

/**
 * __useDeleteUsbPlugAlertFiltersMutation__
 *
 * To run a mutation, you first call `useDeleteUsbPlugAlertFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsbPlugAlertFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsbPlugAlertFiltersMutation, { data, loading, error }] = useDeleteUsbPlugAlertFiltersMutation({
 *   variables: {
 *      categoryInternalName: // value for 'categoryInternalName'
 *      optionInternalName: // value for 'optionInternalName'
 *   },
 * });
 */
export function useDeleteUsbPlugAlertFiltersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsbPlugAlertFiltersMutation, DeleteUsbPlugAlertFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsbPlugAlertFiltersMutation, DeleteUsbPlugAlertFiltersMutationVariables>(DeleteUsbPlugAlertFiltersDocument, options);
      }
export type DeleteUsbPlugAlertFiltersMutationHookResult = ReturnType<typeof useDeleteUsbPlugAlertFiltersMutation>;
export type DeleteUsbPlugAlertFiltersMutationResult = Apollo.MutationResult<DeleteUsbPlugAlertFiltersMutation>;
export type DeleteUsbPlugAlertFiltersMutationOptions = Apollo.BaseMutationOptions<DeleteUsbPlugAlertFiltersMutation, DeleteUsbPlugAlertFiltersMutationVariables>;
export const InsertRebootedAlertFilterDocument = gql`
    mutation _InsertRebootedAlertFilter($id: String!, $categoryInternalName: String!, $optionInternalName: String!) {
  insert_orgs_users_rebooted_filters_one(
    object: {rebooted_filter: $id, category_internal_name: $categoryInternalName, option_internal_name: $optionInternalName}
  ) {
    id: rebooted_filter
  }
}
    `;
export type InsertRebootedAlertFilterMutationFn = Apollo.MutationFunction<InsertRebootedAlertFilterMutation, InsertRebootedAlertFilterMutationVariables>;

/**
 * __useInsertRebootedAlertFilterMutation__
 *
 * To run a mutation, you first call `useInsertRebootedAlertFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRebootedAlertFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRebootedAlertFilterMutation, { data, loading, error }] = useInsertRebootedAlertFilterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryInternalName: // value for 'categoryInternalName'
 *      optionInternalName: // value for 'optionInternalName'
 *   },
 * });
 */
export function useInsertRebootedAlertFilterMutation(baseOptions?: Apollo.MutationHookOptions<InsertRebootedAlertFilterMutation, InsertRebootedAlertFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRebootedAlertFilterMutation, InsertRebootedAlertFilterMutationVariables>(InsertRebootedAlertFilterDocument, options);
      }
export type InsertRebootedAlertFilterMutationHookResult = ReturnType<typeof useInsertRebootedAlertFilterMutation>;
export type InsertRebootedAlertFilterMutationResult = Apollo.MutationResult<InsertRebootedAlertFilterMutation>;
export type InsertRebootedAlertFilterMutationOptions = Apollo.BaseMutationOptions<InsertRebootedAlertFilterMutation, InsertRebootedAlertFilterMutationVariables>;
export const InsertTemplateAlertFilterDocument = gql`
    mutation _InsertTemplateAlertFilter($id: String!, $categoryInternalName: String!, $optionInternalName: String!) {
  insert_orgs_users_template_filters_one(
    object: {template_filter: $id, category_internal_name: $categoryInternalName, option_internal_name: $optionInternalName}
  ) {
    id: template_filter
  }
}
    `;
export type InsertTemplateAlertFilterMutationFn = Apollo.MutationFunction<InsertTemplateAlertFilterMutation, InsertTemplateAlertFilterMutationVariables>;

/**
 * __useInsertTemplateAlertFilterMutation__
 *
 * To run a mutation, you first call `useInsertTemplateAlertFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTemplateAlertFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTemplateAlertFilterMutation, { data, loading, error }] = useInsertTemplateAlertFilterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryInternalName: // value for 'categoryInternalName'
 *      optionInternalName: // value for 'optionInternalName'
 *   },
 * });
 */
export function useInsertTemplateAlertFilterMutation(baseOptions?: Apollo.MutationHookOptions<InsertTemplateAlertFilterMutation, InsertTemplateAlertFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTemplateAlertFilterMutation, InsertTemplateAlertFilterMutationVariables>(InsertTemplateAlertFilterDocument, options);
      }
export type InsertTemplateAlertFilterMutationHookResult = ReturnType<typeof useInsertTemplateAlertFilterMutation>;
export type InsertTemplateAlertFilterMutationResult = Apollo.MutationResult<InsertTemplateAlertFilterMutation>;
export type InsertTemplateAlertFilterMutationOptions = Apollo.BaseMutationOptions<InsertTemplateAlertFilterMutation, InsertTemplateAlertFilterMutationVariables>;
export const InsertUnreachableAlertFilterDocument = gql`
    mutation _InsertUnreachableAlertFilter($id: String!, $categoryInternalName: String!, $optionInternalName: String!) {
  insert_orgs_users_unreachable_filters_one(
    object: {unreachable_filter: $id, category_internal_name: $categoryInternalName, option_internal_name: $optionInternalName}
  ) {
    id: unreachable_filter
  }
}
    `;
export type InsertUnreachableAlertFilterMutationFn = Apollo.MutationFunction<InsertUnreachableAlertFilterMutation, InsertUnreachableAlertFilterMutationVariables>;

/**
 * __useInsertUnreachableAlertFilterMutation__
 *
 * To run a mutation, you first call `useInsertUnreachableAlertFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUnreachableAlertFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUnreachableAlertFilterMutation, { data, loading, error }] = useInsertUnreachableAlertFilterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryInternalName: // value for 'categoryInternalName'
 *      optionInternalName: // value for 'optionInternalName'
 *   },
 * });
 */
export function useInsertUnreachableAlertFilterMutation(baseOptions?: Apollo.MutationHookOptions<InsertUnreachableAlertFilterMutation, InsertUnreachableAlertFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUnreachableAlertFilterMutation, InsertUnreachableAlertFilterMutationVariables>(InsertUnreachableAlertFilterDocument, options);
      }
export type InsertUnreachableAlertFilterMutationHookResult = ReturnType<typeof useInsertUnreachableAlertFilterMutation>;
export type InsertUnreachableAlertFilterMutationResult = Apollo.MutationResult<InsertUnreachableAlertFilterMutation>;
export type InsertUnreachableAlertFilterMutationOptions = Apollo.BaseMutationOptions<InsertUnreachableAlertFilterMutation, InsertUnreachableAlertFilterMutationVariables>;
export const InsertUsbPlugAlertFilterDocument = gql`
    mutation _InsertUsbPlugAlertFilter($id: String!, $categoryInternalName: String!, $optionInternalName: String!) {
  insert_orgs_users_usb_plug_filters_one(
    object: {usb_plug_filter: $id, category_internal_name: $categoryInternalName, option_internal_name: $optionInternalName}
  ) {
    id: usb_plug_filter
  }
}
    `;
export type InsertUsbPlugAlertFilterMutationFn = Apollo.MutationFunction<InsertUsbPlugAlertFilterMutation, InsertUsbPlugAlertFilterMutationVariables>;

/**
 * __useInsertUsbPlugAlertFilterMutation__
 *
 * To run a mutation, you first call `useInsertUsbPlugAlertFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUsbPlugAlertFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUsbPlugAlertFilterMutation, { data, loading, error }] = useInsertUsbPlugAlertFilterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      categoryInternalName: // value for 'categoryInternalName'
 *      optionInternalName: // value for 'optionInternalName'
 *   },
 * });
 */
export function useInsertUsbPlugAlertFilterMutation(baseOptions?: Apollo.MutationHookOptions<InsertUsbPlugAlertFilterMutation, InsertUsbPlugAlertFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUsbPlugAlertFilterMutation, InsertUsbPlugAlertFilterMutationVariables>(InsertUsbPlugAlertFilterDocument, options);
      }
export type InsertUsbPlugAlertFilterMutationHookResult = ReturnType<typeof useInsertUsbPlugAlertFilterMutation>;
export type InsertUsbPlugAlertFilterMutationResult = Apollo.MutationResult<InsertUsbPlugAlertFilterMutation>;
export type InsertUsbPlugAlertFilterMutationOptions = Apollo.BaseMutationOptions<InsertUsbPlugAlertFilterMutation, InsertUsbPlugAlertFilterMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation _DeleteCategory($id: String!) {
  delete_orgs_categories(where: {category: {_eq: $id}}) {
    returning {
      category
    }
  }
  delete_orgs_categories_options(where: {category: {_eq: $id}}) {
    returning {
      category
      option
    }
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const DeleteCategoryOptionDocument = gql`
    mutation _DeleteCategoryOption($categoryId: String!, $optionId: String!) {
  delete_orgs_categories_options(
    where: {category: {_eq: $categoryId}, option: {_eq: $optionId}}
  ) {
    returning {
      category
      option
    }
  }
}
    `;
export type DeleteCategoryOptionMutationFn = Apollo.MutationFunction<DeleteCategoryOptionMutation, DeleteCategoryOptionMutationVariables>;

/**
 * __useDeleteCategoryOptionMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryOptionMutation, { data, loading, error }] = useDeleteCategoryOptionMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useDeleteCategoryOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryOptionMutation, DeleteCategoryOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryOptionMutation, DeleteCategoryOptionMutationVariables>(DeleteCategoryOptionDocument, options);
      }
export type DeleteCategoryOptionMutationHookResult = ReturnType<typeof useDeleteCategoryOptionMutation>;
export type DeleteCategoryOptionMutationResult = Apollo.MutationResult<DeleteCategoryOptionMutation>;
export type DeleteCategoryOptionMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryOptionMutation, DeleteCategoryOptionMutationVariables>;
export const DeleteDisplayCategoriesDocument = gql`
    mutation _DeleteDisplayCategories($displayIds: [String!]!, $categoryId: String!) {
  delete_orgs_displays_categories(
    where: {display: {_in: $displayIds}, category: {_eq: $categoryId}}
  ) {
    returning {
      display
    }
  }
}
    `;
export type DeleteDisplayCategoriesMutationFn = Apollo.MutationFunction<DeleteDisplayCategoriesMutation, DeleteDisplayCategoriesMutationVariables>;

/**
 * __useDeleteDisplayCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteDisplayCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDisplayCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDisplayCategoriesMutation, { data, loading, error }] = useDeleteDisplayCategoriesMutation({
 *   variables: {
 *      displayIds: // value for 'displayIds'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteDisplayCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDisplayCategoriesMutation, DeleteDisplayCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDisplayCategoriesMutation, DeleteDisplayCategoriesMutationVariables>(DeleteDisplayCategoriesDocument, options);
      }
export type DeleteDisplayCategoriesMutationHookResult = ReturnType<typeof useDeleteDisplayCategoriesMutation>;
export type DeleteDisplayCategoriesMutationResult = Apollo.MutationResult<DeleteDisplayCategoriesMutation>;
export type DeleteDisplayCategoriesMutationOptions = Apollo.BaseMutationOptions<DeleteDisplayCategoriesMutation, DeleteDisplayCategoriesMutationVariables>;
export const FinishJoyrideDocument = gql`
    mutation _FinishJoyride($joyride: String!) {
  insert_orgs_users_joyrides_one(object: {joyride: $joyride}) {
    joyride
    finished_on
  }
}
    `;
export type FinishJoyrideMutationFn = Apollo.MutationFunction<FinishJoyrideMutation, FinishJoyrideMutationVariables>;

/**
 * __useFinishJoyrideMutation__
 *
 * To run a mutation, you first call `useFinishJoyrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishJoyrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishJoyrideMutation, { data, loading, error }] = useFinishJoyrideMutation({
 *   variables: {
 *      joyride: // value for 'joyride'
 *   },
 * });
 */
export function useFinishJoyrideMutation(baseOptions?: Apollo.MutationHookOptions<FinishJoyrideMutation, FinishJoyrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishJoyrideMutation, FinishJoyrideMutationVariables>(FinishJoyrideDocument, options);
      }
export type FinishJoyrideMutationHookResult = ReturnType<typeof useFinishJoyrideMutation>;
export type FinishJoyrideMutationResult = Apollo.MutationResult<FinishJoyrideMutation>;
export type FinishJoyrideMutationOptions = Apollo.BaseMutationOptions<FinishJoyrideMutation, FinishJoyrideMutationVariables>;
export const InsertCategoryDocument = gql`
    mutation _InsertCategory($id: String!, $displayName: String!, $options: [orgs_categories_options_insert_input!]!) {
  insert_orgs_categories_one(
    object: {category: $id, display_name: $displayName, internal_name: $id}
  ) {
    category
    display_name
    internal_name
  }
  insert_orgs_categories_options(objects: $options) {
    returning {
      option
      internal_name
      display_name
    }
  }
}
    `;
export type InsertCategoryMutationFn = Apollo.MutationFunction<InsertCategoryMutation, InsertCategoryMutationVariables>;

/**
 * __useInsertCategoryMutation__
 *
 * To run a mutation, you first call `useInsertCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCategoryMutation, { data, loading, error }] = useInsertCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayName: // value for 'displayName'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useInsertCategoryMutation(baseOptions?: Apollo.MutationHookOptions<InsertCategoryMutation, InsertCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCategoryMutation, InsertCategoryMutationVariables>(InsertCategoryDocument, options);
      }
export type InsertCategoryMutationHookResult = ReturnType<typeof useInsertCategoryMutation>;
export type InsertCategoryMutationResult = Apollo.MutationResult<InsertCategoryMutation>;
export type InsertCategoryMutationOptions = Apollo.BaseMutationOptions<InsertCategoryMutation, InsertCategoryMutationVariables>;
export const InsertNewDisplayDocument = gql`
    mutation _InsertNewDisplay($display: String, $address_id: Int) {
  insert_orgs_displays_locations_one(
    object: {display: $display, address_id: $address_id}
  ) {
    address_id
  }
}
    `;
export type InsertNewDisplayMutationFn = Apollo.MutationFunction<InsertNewDisplayMutation, InsertNewDisplayMutationVariables>;

/**
 * __useInsertNewDisplayMutation__
 *
 * To run a mutation, you first call `useInsertNewDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNewDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNewDisplayMutation, { data, loading, error }] = useInsertNewDisplayMutation({
 *   variables: {
 *      display: // value for 'display'
 *      address_id: // value for 'address_id'
 *   },
 * });
 */
export function useInsertNewDisplayMutation(baseOptions?: Apollo.MutationHookOptions<InsertNewDisplayMutation, InsertNewDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertNewDisplayMutation, InsertNewDisplayMutationVariables>(InsertNewDisplayDocument, options);
      }
export type InsertNewDisplayMutationHookResult = ReturnType<typeof useInsertNewDisplayMutation>;
export type InsertNewDisplayMutationResult = Apollo.MutationResult<InsertNewDisplayMutation>;
export type InsertNewDisplayMutationOptions = Apollo.BaseMutationOptions<InsertNewDisplayMutation, InsertNewDisplayMutationVariables>;
export const ResetJoyrideDocument = gql`
    mutation _ResetJoyride($joyride: String!) {
  delete_orgs_users_joyrides(where: {joyride: {_eq: $joyride}}) {
    returning {
      joyride
      finished_on
    }
  }
}
    `;
export type ResetJoyrideMutationFn = Apollo.MutationFunction<ResetJoyrideMutation, ResetJoyrideMutationVariables>;

/**
 * __useResetJoyrideMutation__
 *
 * To run a mutation, you first call `useResetJoyrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetJoyrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetJoyrideMutation, { data, loading, error }] = useResetJoyrideMutation({
 *   variables: {
 *      joyride: // value for 'joyride'
 *   },
 * });
 */
export function useResetJoyrideMutation(baseOptions?: Apollo.MutationHookOptions<ResetJoyrideMutation, ResetJoyrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetJoyrideMutation, ResetJoyrideMutationVariables>(ResetJoyrideDocument, options);
      }
export type ResetJoyrideMutationHookResult = ReturnType<typeof useResetJoyrideMutation>;
export type ResetJoyrideMutationResult = Apollo.MutationResult<ResetJoyrideMutation>;
export type ResetJoyrideMutationOptions = Apollo.BaseMutationOptions<ResetJoyrideMutation, ResetJoyrideMutationVariables>;
export const ToggleOrgWorkspaceDocument = gql`
    mutation _ToggleOrgWorkspace($wellness_enabled: Boolean!) {
  insert_orgs_flags_one(
    object: {wellness_enabled: $wellness_enabled}
    on_conflict: {constraint: orgs_flags_org_id_key, update_columns: wellness_enabled}
  ) {
    org_id
    wellness_enabled
  }
}
    `;
export type ToggleOrgWorkspaceMutationFn = Apollo.MutationFunction<ToggleOrgWorkspaceMutation, ToggleOrgWorkspaceMutationVariables>;

/**
 * __useToggleOrgWorkspaceMutation__
 *
 * To run a mutation, you first call `useToggleOrgWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleOrgWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleOrgWorkspaceMutation, { data, loading, error }] = useToggleOrgWorkspaceMutation({
 *   variables: {
 *      wellness_enabled: // value for 'wellness_enabled'
 *   },
 * });
 */
export function useToggleOrgWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<ToggleOrgWorkspaceMutation, ToggleOrgWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleOrgWorkspaceMutation, ToggleOrgWorkspaceMutationVariables>(ToggleOrgWorkspaceDocument, options);
      }
export type ToggleOrgWorkspaceMutationHookResult = ReturnType<typeof useToggleOrgWorkspaceMutation>;
export type ToggleOrgWorkspaceMutationResult = Apollo.MutationResult<ToggleOrgWorkspaceMutation>;
export type ToggleOrgWorkspaceMutationOptions = Apollo.BaseMutationOptions<ToggleOrgWorkspaceMutation, ToggleOrgWorkspaceMutationVariables>;
export const UpdateAddressNicknameByIdDocument = gql`
    mutation _UpdateAddressNicknameById($id: Int!, $nickname: String) {
  update_org_addresses_by_pk(pk_columns: {id: $id}, _set: {nickname: $nickname}) {
    id
    nickname
  }
}
    `;
export type UpdateAddressNicknameByIdMutationFn = Apollo.MutationFunction<UpdateAddressNicknameByIdMutation, UpdateAddressNicknameByIdMutationVariables>;

/**
 * __useUpdateAddressNicknameByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAddressNicknameByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressNicknameByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressNicknameByIdMutation, { data, loading, error }] = useUpdateAddressNicknameByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useUpdateAddressNicknameByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressNicknameByIdMutation, UpdateAddressNicknameByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressNicknameByIdMutation, UpdateAddressNicknameByIdMutationVariables>(UpdateAddressNicknameByIdDocument, options);
      }
export type UpdateAddressNicknameByIdMutationHookResult = ReturnType<typeof useUpdateAddressNicknameByIdMutation>;
export type UpdateAddressNicknameByIdMutationResult = Apollo.MutationResult<UpdateAddressNicknameByIdMutation>;
export type UpdateAddressNicknameByIdMutationOptions = Apollo.BaseMutationOptions<UpdateAddressNicknameByIdMutation, UpdateAddressNicknameByIdMutationVariables>;
export const UpdateAlertConfigDocument = gql`
    mutation _UpdateAlertConfig($config: orgs_users_set_input!) {
  update_orgs_users(where: {}, _set: $config) {
    affected_rows
  }
}
    `;
export type UpdateAlertConfigMutationFn = Apollo.MutationFunction<UpdateAlertConfigMutation, UpdateAlertConfigMutationVariables>;

/**
 * __useUpdateAlertConfigMutation__
 *
 * To run a mutation, you first call `useUpdateAlertConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertConfigMutation, { data, loading, error }] = useUpdateAlertConfigMutation({
 *   variables: {
 *      config: // value for 'config'
 *   },
 * });
 */
export function useUpdateAlertConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAlertConfigMutation, UpdateAlertConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAlertConfigMutation, UpdateAlertConfigMutationVariables>(UpdateAlertConfigDocument, options);
      }
export type UpdateAlertConfigMutationHookResult = ReturnType<typeof useUpdateAlertConfigMutation>;
export type UpdateAlertConfigMutationResult = Apollo.MutationResult<UpdateAlertConfigMutation>;
export type UpdateAlertConfigMutationOptions = Apollo.BaseMutationOptions<UpdateAlertConfigMutation, UpdateAlertConfigMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation _UpdateCategory($id: String!, $displayName: String!) {
  update_orgs_categories(
    where: {category: {_eq: $id}}
    _set: {display_name: $displayName}
  ) {
    returning {
      category
      display_name
      internal_name
    }
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const UpdateFloorDocument = gql`
    mutation _UpdateFloor($where: orgs_displays_locations_bool_exp!, $newFloor: Int) {
  update_orgs_displays_locations(where: $where, _set: {floor: $newFloor}) {
    returning {
      display
      address {
        floors {
          address_id
          count_per_floor
          floor
        }
      }
    }
  }
}
    `;
export type UpdateFloorMutationFn = Apollo.MutationFunction<UpdateFloorMutation, UpdateFloorMutationVariables>;

/**
 * __useUpdateFloorMutation__
 *
 * To run a mutation, you first call `useUpdateFloorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFloorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFloorMutation, { data, loading, error }] = useUpdateFloorMutation({
 *   variables: {
 *      where: // value for 'where'
 *      newFloor: // value for 'newFloor'
 *   },
 * });
 */
export function useUpdateFloorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFloorMutation, UpdateFloorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFloorMutation, UpdateFloorMutationVariables>(UpdateFloorDocument, options);
      }
export type UpdateFloorMutationHookResult = ReturnType<typeof useUpdateFloorMutation>;
export type UpdateFloorMutationResult = Apollo.MutationResult<UpdateFloorMutation>;
export type UpdateFloorMutationOptions = Apollo.BaseMutationOptions<UpdateFloorMutation, UpdateFloorMutationVariables>;
export const UpsertActiveLearningFlagsDocument = gql`
    mutation _UpsertActiveLearningFlags($screen_key_enabled: Boolean!) {
  insert_active_learning_flags_one(
    object: {screen_key_enabled: $screen_key_enabled}
    on_conflict: {constraint: active_learning_flags_pkey, update_columns: [screen_key_enabled]}
  ) {
    org_id
    screen_key_enabled
  }
}
    `;
export type UpsertActiveLearningFlagsMutationFn = Apollo.MutationFunction<UpsertActiveLearningFlagsMutation, UpsertActiveLearningFlagsMutationVariables>;

/**
 * __useUpsertActiveLearningFlagsMutation__
 *
 * To run a mutation, you first call `useUpsertActiveLearningFlagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertActiveLearningFlagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertActiveLearningFlagsMutation, { data, loading, error }] = useUpsertActiveLearningFlagsMutation({
 *   variables: {
 *      screen_key_enabled: // value for 'screen_key_enabled'
 *   },
 * });
 */
export function useUpsertActiveLearningFlagsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertActiveLearningFlagsMutation, UpsertActiveLearningFlagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertActiveLearningFlagsMutation, UpsertActiveLearningFlagsMutationVariables>(UpsertActiveLearningFlagsDocument, options);
      }
export type UpsertActiveLearningFlagsMutationHookResult = ReturnType<typeof useUpsertActiveLearningFlagsMutation>;
export type UpsertActiveLearningFlagsMutationResult = Apollo.MutationResult<UpsertActiveLearningFlagsMutation>;
export type UpsertActiveLearningFlagsMutationOptions = Apollo.BaseMutationOptions<UpsertActiveLearningFlagsMutation, UpsertActiveLearningFlagsMutationVariables>;
export const UpsertCategoryOptionDocument = gql`
    mutation _UpsertCategoryOption($categoryId: String!, $optionId: String!, $displayName: String!) {
  insert_orgs_categories_options_one(
    object: {category: $categoryId, option: $optionId, display_name: $displayName, internal_name: $optionId}
    on_conflict: {constraint: orgs_categories_options_pkey, update_columns: [display_name], where: {category: {_eq: $categoryId}, option: {_eq: $optionId}}}
  ) {
    category
    option
    display_name
    internal_name
  }
}
    `;
export type UpsertCategoryOptionMutationFn = Apollo.MutationFunction<UpsertCategoryOptionMutation, UpsertCategoryOptionMutationVariables>;

/**
 * __useUpsertCategoryOptionMutation__
 *
 * To run a mutation, you first call `useUpsertCategoryOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCategoryOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCategoryOptionMutation, { data, loading, error }] = useUpsertCategoryOptionMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      optionId: // value for 'optionId'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUpsertCategoryOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCategoryOptionMutation, UpsertCategoryOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCategoryOptionMutation, UpsertCategoryOptionMutationVariables>(UpsertCategoryOptionDocument, options);
      }
export type UpsertCategoryOptionMutationHookResult = ReturnType<typeof useUpsertCategoryOptionMutation>;
export type UpsertCategoryOptionMutationResult = Apollo.MutationResult<UpsertCategoryOptionMutation>;
export type UpsertCategoryOptionMutationOptions = Apollo.BaseMutationOptions<UpsertCategoryOptionMutation, UpsertCategoryOptionMutationVariables>;
export const UpsertDisplayCategoriesDocument = gql`
    mutation _UpsertDisplayCategories($items: [orgs_displays_categories_insert_input!]!) {
  insert_orgs_displays_categories(
    objects: $items
    on_conflict: {update_columns: [config_applied_categories], constraint: orgs_displays_categories_pkey}
  ) {
    returning {
      display
      category
      config_applied_categories
    }
  }
}
    `;
export type UpsertDisplayCategoriesMutationFn = Apollo.MutationFunction<UpsertDisplayCategoriesMutation, UpsertDisplayCategoriesMutationVariables>;

/**
 * __useUpsertDisplayCategoriesMutation__
 *
 * To run a mutation, you first call `useUpsertDisplayCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDisplayCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDisplayCategoriesMutation, { data, loading, error }] = useUpsertDisplayCategoriesMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpsertDisplayCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDisplayCategoriesMutation, UpsertDisplayCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDisplayCategoriesMutation, UpsertDisplayCategoriesMutationVariables>(UpsertDisplayCategoriesDocument, options);
      }
export type UpsertDisplayCategoriesMutationHookResult = ReturnType<typeof useUpsertDisplayCategoriesMutation>;
export type UpsertDisplayCategoriesMutationResult = Apollo.MutationResult<UpsertDisplayCategoriesMutation>;
export type UpsertDisplayCategoriesMutationOptions = Apollo.BaseMutationOptions<UpsertDisplayCategoriesMutation, UpsertDisplayCategoriesMutationVariables>;
export const UpsertDisplayLocationsDocument = gql`
    mutation _UpsertDisplayLocations($items: [orgs_displays_locations_insert_input!]!) {
  insert_orgs_displays_locations(
    objects: $items
    on_conflict: {update_columns: [address_id, user_lat, user_long, user_accuracy_h, display_uuid], constraint: orgs_displays_locations_pkey}
  ) {
    returning {
      display
      user_lat
      user_long
      address_id
      address {
        id
        city
        country
        building
        nickname
        lat
        long
        address_full
        state_province
        assignedDisplays_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;
export type UpsertDisplayLocationsMutationFn = Apollo.MutationFunction<UpsertDisplayLocationsMutation, UpsertDisplayLocationsMutationVariables>;

/**
 * __useUpsertDisplayLocationsMutation__
 *
 * To run a mutation, you first call `useUpsertDisplayLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDisplayLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDisplayLocationsMutation, { data, loading, error }] = useUpsertDisplayLocationsMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpsertDisplayLocationsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDisplayLocationsMutation, UpsertDisplayLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDisplayLocationsMutation, UpsertDisplayLocationsMutationVariables>(UpsertDisplayLocationsDocument, options);
      }
export type UpsertDisplayLocationsMutationHookResult = ReturnType<typeof useUpsertDisplayLocationsMutation>;
export type UpsertDisplayLocationsMutationResult = Apollo.MutationResult<UpsertDisplayLocationsMutation>;
export type UpsertDisplayLocationsMutationOptions = Apollo.BaseMutationOptions<UpsertDisplayLocationsMutation, UpsertDisplayLocationsMutationVariables>;
export const UpsertDisplayOneLocationDocument = gql`
    mutation _UpsertDisplayOneLocation($address_id: Int, $user_lat: float8, $user_long: float8, $user_accuracy_h: float8, $display: String!, $display_uuid: String) {
  insert_orgs_displays_locations_one(
    object: {address_id: $address_id, display: $display, user_lat: $user_lat, user_long: $user_long, user_accuracy_h: $user_accuracy_h, display_uuid: $display_uuid}
    on_conflict: {constraint: orgs_displays_locations_pkey, update_columns: [address_id, user_lat, user_long, user_accuracy_h, display_uuid], where: {display: {_eq: $display}}}
  ) {
    display
    address_id
  }
}
    `;
export type UpsertDisplayOneLocationMutationFn = Apollo.MutationFunction<UpsertDisplayOneLocationMutation, UpsertDisplayOneLocationMutationVariables>;

/**
 * __useUpsertDisplayOneLocationMutation__
 *
 * To run a mutation, you first call `useUpsertDisplayOneLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDisplayOneLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDisplayOneLocationMutation, { data, loading, error }] = useUpsertDisplayOneLocationMutation({
 *   variables: {
 *      address_id: // value for 'address_id'
 *      user_lat: // value for 'user_lat'
 *      user_long: // value for 'user_long'
 *      user_accuracy_h: // value for 'user_accuracy_h'
 *      display: // value for 'display'
 *      display_uuid: // value for 'display_uuid'
 *   },
 * });
 */
export function useUpsertDisplayOneLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDisplayOneLocationMutation, UpsertDisplayOneLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDisplayOneLocationMutation, UpsertDisplayOneLocationMutationVariables>(UpsertDisplayOneLocationDocument, options);
      }
export type UpsertDisplayOneLocationMutationHookResult = ReturnType<typeof useUpsertDisplayOneLocationMutation>;
export type UpsertDisplayOneLocationMutationResult = Apollo.MutationResult<UpsertDisplayOneLocationMutation>;
export type UpsertDisplayOneLocationMutationOptions = Apollo.BaseMutationOptions<UpsertDisplayOneLocationMutation, UpsertDisplayOneLocationMutationVariables>;
export const SetDisplayPositionInNewBuildingDocument = gql`
    mutation _SetDisplayPositionInNewBuilding($object: orgs_displays_locations_insert_input!) {
  insert_orgs_displays_locations_one(object: $object) {
    address_id
  }
}
    `;
export type SetDisplayPositionInNewBuildingMutationFn = Apollo.MutationFunction<SetDisplayPositionInNewBuildingMutation, SetDisplayPositionInNewBuildingMutationVariables>;

/**
 * __useSetDisplayPositionInNewBuildingMutation__
 *
 * To run a mutation, you first call `useSetDisplayPositionInNewBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDisplayPositionInNewBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDisplayPositionInNewBuildingMutation, { data, loading, error }] = useSetDisplayPositionInNewBuildingMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useSetDisplayPositionInNewBuildingMutation(baseOptions?: Apollo.MutationHookOptions<SetDisplayPositionInNewBuildingMutation, SetDisplayPositionInNewBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDisplayPositionInNewBuildingMutation, SetDisplayPositionInNewBuildingMutationVariables>(SetDisplayPositionInNewBuildingDocument, options);
      }
export type SetDisplayPositionInNewBuildingMutationHookResult = ReturnType<typeof useSetDisplayPositionInNewBuildingMutation>;
export type SetDisplayPositionInNewBuildingMutationResult = Apollo.MutationResult<SetDisplayPositionInNewBuildingMutation>;
export type SetDisplayPositionInNewBuildingMutationOptions = Apollo.BaseMutationOptions<SetDisplayPositionInNewBuildingMutation, SetDisplayPositionInNewBuildingMutationVariables>;
export const ActiveLearningUserCountsDocument = gql`
    query _ActiveLearningUserCounts($designerRole: Int!, $facilitatorRole: Int!) {
  designers: view_users_aggregate(where: {role: {_eq: $designerRole}}) {
    aggregate {
      count
    }
  }
  facilitators: view_users_aggregate(where: {role: {_eq: $facilitatorRole}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useActiveLearningUserCountsQuery__
 *
 * To run a query within a React component, call `useActiveLearningUserCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLearningUserCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLearningUserCountsQuery({
 *   variables: {
 *      designerRole: // value for 'designerRole'
 *      facilitatorRole: // value for 'facilitatorRole'
 *   },
 * });
 */
export function useActiveLearningUserCountsQuery(baseOptions: Apollo.QueryHookOptions<ActiveLearningUserCountsQuery, ActiveLearningUserCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveLearningUserCountsQuery, ActiveLearningUserCountsQueryVariables>(ActiveLearningUserCountsDocument, options);
      }
export function useActiveLearningUserCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveLearningUserCountsQuery, ActiveLearningUserCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveLearningUserCountsQuery, ActiveLearningUserCountsQueryVariables>(ActiveLearningUserCountsDocument, options);
        }
export type ActiveLearningUserCountsQueryHookResult = ReturnType<typeof useActiveLearningUserCountsQuery>;
export type ActiveLearningUserCountsLazyQueryHookResult = ReturnType<typeof useActiveLearningUserCountsLazyQuery>;
export type ActiveLearningUserCountsQueryResult = Apollo.QueryResult<ActiveLearningUserCountsQuery, ActiveLearningUserCountsQueryVariables>;
export const ActivityUserMobilityDocument = gql`
    query _ActivityUserMobility($args: _search_activity_user_mobility_args!) {
  activities: _search_activity_user_mobility(args: $args) {
    org
    group
    count
    sessions
  }
}
    `;

/**
 * __useActivityUserMobilityQuery__
 *
 * To run a query within a React component, call `useActivityUserMobilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityUserMobilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityUserMobilityQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useActivityUserMobilityQuery(baseOptions: Apollo.QueryHookOptions<ActivityUserMobilityQuery, ActivityUserMobilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityUserMobilityQuery, ActivityUserMobilityQueryVariables>(ActivityUserMobilityDocument, options);
      }
export function useActivityUserMobilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityUserMobilityQuery, ActivityUserMobilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityUserMobilityQuery, ActivityUserMobilityQueryVariables>(ActivityUserMobilityDocument, options);
        }
export type ActivityUserMobilityQueryHookResult = ReturnType<typeof useActivityUserMobilityQuery>;
export type ActivityUserMobilityLazyQueryHookResult = ReturnType<typeof useActivityUserMobilityLazyQuery>;
export type ActivityUserMobilityQueryResult = Apollo.QueryResult<ActivityUserMobilityQuery, ActivityUserMobilityQueryVariables>;
export const SearchAddressesDocument = gql`
    query _SearchAddresses($where: org_addresses_bool_exp!) {
  _addresses(where: $where, limit: 10, order_by: [{nickname: asc, building: asc}]) {
    id
    address_full
    building
    city
    country
    lat
    long
    nickname
    external_id
    state_province
    floors {
      floor
      count_per_floor
    }
    assignedDisplays_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useSearchAddressesQuery__
 *
 * To run a query within a React component, call `useSearchAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAddressesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchAddressesQuery(baseOptions: Apollo.QueryHookOptions<SearchAddressesQuery, SearchAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAddressesQuery, SearchAddressesQueryVariables>(SearchAddressesDocument, options);
      }
export function useSearchAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAddressesQuery, SearchAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAddressesQuery, SearchAddressesQueryVariables>(SearchAddressesDocument, options);
        }
export type SearchAddressesQueryHookResult = ReturnType<typeof useSearchAddressesQuery>;
export type SearchAddressesLazyQueryHookResult = ReturnType<typeof useSearchAddressesLazyQuery>;
export type SearchAddressesQueryResult = Apollo.QueryResult<SearchAddressesQuery, SearchAddressesQueryVariables>;
export const AlertConfigDocument = gql`
    query _AlertConfig {
  users: orgs_users {
    displayOutOfTemplateEnabled: config_alerts_display_out_of_template
    displayRebootedEnabled: config_alerts_display_rebooted_enabled
    displayUnreachableEnabled: config_alerts_display_unreachable_enabled
    displayUnreachableDelay: config_alerts_display_unreachable_delay
    usbPlugEventEnabled: config_alerts_usb_plug_event_enabled
    usbPlugEventType: config_alerts_usb_plug_event_type
  }
  unreachableFilters: orgs_users_unreachable_filters {
    id: unreachable_filter
    optionInternalName: option_internal_name
    categoryInternalName: category_internal_name
  }
  rebootedFilters: orgs_users_rebooted_filters {
    id: rebooted_filter
    optionInternalName: option_internal_name
    categoryInternalName: category_internal_name
  }
  templateFilters: orgs_users_template_filters {
    id: template_filter
    optionInternalName: option_internal_name
    categoryInternalName: category_internal_name
  }
  usbFilters: orgs_users_usb_plug_filters {
    id: usb_plug_filter
    optionInternalName: option_internal_name
    categoryInternalName: category_internal_name
  }
}
    `;

/**
 * __useAlertConfigQuery__
 *
 * To run a query within a React component, call `useAlertConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAlertConfigQuery(baseOptions?: Apollo.QueryHookOptions<AlertConfigQuery, AlertConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AlertConfigQuery, AlertConfigQueryVariables>(AlertConfigDocument, options);
      }
export function useAlertConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlertConfigQuery, AlertConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AlertConfigQuery, AlertConfigQueryVariables>(AlertConfigDocument, options);
        }
export type AlertConfigQueryHookResult = ReturnType<typeof useAlertConfigQuery>;
export type AlertConfigLazyQueryHookResult = ReturnType<typeof useAlertConfigLazyQuery>;
export type AlertConfigQueryResult = Apollo.QueryResult<AlertConfigQuery, AlertConfigQueryVariables>;
export const DisplaysAlertsTableCountDocument = gql`
    query _DisplaysAlertsTableCount($where: view_display_alerts_bool_exp) {
  view_display_alerts_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDisplaysAlertsTableCountQuery__
 *
 * To run a query within a React component, call `useDisplaysAlertsTableCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysAlertsTableCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysAlertsTableCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDisplaysAlertsTableCountQuery(baseOptions?: Apollo.QueryHookOptions<DisplaysAlertsTableCountQuery, DisplaysAlertsTableCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplaysAlertsTableCountQuery, DisplaysAlertsTableCountQueryVariables>(DisplaysAlertsTableCountDocument, options);
      }
export function useDisplaysAlertsTableCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplaysAlertsTableCountQuery, DisplaysAlertsTableCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplaysAlertsTableCountQuery, DisplaysAlertsTableCountQueryVariables>(DisplaysAlertsTableCountDocument, options);
        }
export type DisplaysAlertsTableCountQueryHookResult = ReturnType<typeof useDisplaysAlertsTableCountQuery>;
export type DisplaysAlertsTableCountLazyQueryHookResult = ReturnType<typeof useDisplaysAlertsTableCountLazyQuery>;
export type DisplaysAlertsTableCountQueryResult = Apollo.QueryResult<DisplaysAlertsTableCountQuery, DisplaysAlertsTableCountQueryVariables>;
export const DisplayAlertsTableQDocument = gql`
    query _DisplayAlertsTableQ($where: view_display_alerts_bool_exp!, $limit: Int!, $offset: Int!, $orderBy: [view_display_alerts_order_by!]) {
  _alerts(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    time
    description
    alert_type
    display {
      id
      name
    }
  }
}
    `;

/**
 * __useDisplayAlertsTableQQuery__
 *
 * To run a query within a React component, call `useDisplayAlertsTableQQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayAlertsTableQQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayAlertsTableQQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDisplayAlertsTableQQuery(baseOptions: Apollo.QueryHookOptions<DisplayAlertsTableQQuery, DisplayAlertsTableQQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplayAlertsTableQQuery, DisplayAlertsTableQQueryVariables>(DisplayAlertsTableQDocument, options);
      }
export function useDisplayAlertsTableQLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplayAlertsTableQQuery, DisplayAlertsTableQQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplayAlertsTableQQuery, DisplayAlertsTableQQueryVariables>(DisplayAlertsTableQDocument, options);
        }
export type DisplayAlertsTableQQueryHookResult = ReturnType<typeof useDisplayAlertsTableQQuery>;
export type DisplayAlertsTableQLazyQueryHookResult = ReturnType<typeof useDisplayAlertsTableQLazyQuery>;
export type DisplayAlertsTableQQueryResult = Apollo.QueryResult<DisplayAlertsTableQQuery, DisplayAlertsTableQQueryVariables>;
export const AvgHoursSpentPerUserDocument = gql`
    query _AvgHoursSpentPerUser($args: _avg_hours_spent_per_user_args!) {
  hoursPerUser: _avg_hours_spent_per_user(args: $args) {
    org
    value
  }
}
    `;

/**
 * __useAvgHoursSpentPerUserQuery__
 *
 * To run a query within a React component, call `useAvgHoursSpentPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvgHoursSpentPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvgHoursSpentPerUserQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAvgHoursSpentPerUserQuery(baseOptions: Apollo.QueryHookOptions<AvgHoursSpentPerUserQuery, AvgHoursSpentPerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvgHoursSpentPerUserQuery, AvgHoursSpentPerUserQueryVariables>(AvgHoursSpentPerUserDocument, options);
      }
export function useAvgHoursSpentPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvgHoursSpentPerUserQuery, AvgHoursSpentPerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvgHoursSpentPerUserQuery, AvgHoursSpentPerUserQueryVariables>(AvgHoursSpentPerUserDocument, options);
        }
export type AvgHoursSpentPerUserQueryHookResult = ReturnType<typeof useAvgHoursSpentPerUserQuery>;
export type AvgHoursSpentPerUserLazyQueryHookResult = ReturnType<typeof useAvgHoursSpentPerUserLazyQuery>;
export type AvgHoursSpentPerUserQueryResult = Apollo.QueryResult<AvgHoursSpentPerUserQuery, AvgHoursSpentPerUserQueryVariables>;
export const AvgRoomsVisitedDocument = gql`
    query _AvgRoomsVisited($args: _avg_rooms_visited_args!) {
  roomsVisited: _avg_rooms_visited(args: $args) {
    org
    value
  }
}
    `;

/**
 * __useAvgRoomsVisitedQuery__
 *
 * To run a query within a React component, call `useAvgRoomsVisitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvgRoomsVisitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvgRoomsVisitedQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAvgRoomsVisitedQuery(baseOptions: Apollo.QueryHookOptions<AvgRoomsVisitedQuery, AvgRoomsVisitedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvgRoomsVisitedQuery, AvgRoomsVisitedQueryVariables>(AvgRoomsVisitedDocument, options);
      }
export function useAvgRoomsVisitedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvgRoomsVisitedQuery, AvgRoomsVisitedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvgRoomsVisitedQuery, AvgRoomsVisitedQueryVariables>(AvgRoomsVisitedDocument, options);
        }
export type AvgRoomsVisitedQueryHookResult = ReturnType<typeof useAvgRoomsVisitedQuery>;
export type AvgRoomsVisitedLazyQueryHookResult = ReturnType<typeof useAvgRoomsVisitedLazyQuery>;
export type AvgRoomsVisitedQueryResult = Apollo.QueryResult<AvgRoomsVisitedQuery, AvgRoomsVisitedQueryVariables>;
export const CategoriesDocument = gql`
    query _Categories($where: view_categories_bool_exp, $hidden: Boolean = false) {
  _categories(where: $where, order_by: {category_type: asc, display_name: asc}) {
    id
    category_type
    displayName: display_name
    is_auto_generated
    options(order_by: {display_name: asc}, where: {hidden: {_eq: $hidden}}) {
      id
      category_id
      displayName: display_name
      assignedDisplays_aggregate(where: {display: {id: {_is_null: false}}}) {
        aggregate {
          count
        }
      }
      hidden
    }
  }
  _displaysAggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      hidden: // value for 'hidden'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const ScreenResolutionsDocument = gql`
    query _ScreenResolutions($args: _search_client_screen_resolutions_args!, $order_by: [view_client_screen_resolutions_order_by!]) {
  screenResolutions: _search_client_screen_resolutions_aggregate(
    args: $args
    order_by: $order_by
  ) {
    aggregate {
      sum {
        total
      }
    }
    nodes {
      screenHeight: screen_height
      screenWidth: screen_width
      total
    }
  }
}
    `;

/**
 * __useScreenResolutionsQuery__
 *
 * To run a query within a React component, call `useScreenResolutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenResolutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenResolutionsQuery({
 *   variables: {
 *      args: // value for 'args'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useScreenResolutionsQuery(baseOptions: Apollo.QueryHookOptions<ScreenResolutionsQuery, ScreenResolutionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScreenResolutionsQuery, ScreenResolutionsQueryVariables>(ScreenResolutionsDocument, options);
      }
export function useScreenResolutionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScreenResolutionsQuery, ScreenResolutionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScreenResolutionsQuery, ScreenResolutionsQueryVariables>(ScreenResolutionsDocument, options);
        }
export type ScreenResolutionsQueryHookResult = ReturnType<typeof useScreenResolutionsQuery>;
export type ScreenResolutionsLazyQueryHookResult = ReturnType<typeof useScreenResolutionsLazyQuery>;
export type ScreenResolutionsQueryResult = Apollo.QueryResult<ScreenResolutionsQuery, ScreenResolutionsQueryVariables>;
export const ClientVersionsDocument = gql`
    query _ClientVersions($args: _search_client_versions_args!, $order_by: [view_client_versions_order_by!]) {
  clientVersions: _search_client_versions_aggregate(
    args: $args
    order_by: $order_by
  ) {
    aggregate {
      sum {
        total
      }
    }
    nodes {
      total
      clientVersion: client_version
    }
  }
}
    `;

/**
 * __useClientVersionsQuery__
 *
 * To run a query within a React component, call `useClientVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientVersionsQuery({
 *   variables: {
 *      args: // value for 'args'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useClientVersionsQuery(baseOptions: Apollo.QueryHookOptions<ClientVersionsQuery, ClientVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientVersionsQuery, ClientVersionsQueryVariables>(ClientVersionsDocument, options);
      }
export function useClientVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientVersionsQuery, ClientVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientVersionsQuery, ClientVersionsQueryVariables>(ClientVersionsDocument, options);
        }
export type ClientVersionsQueryHookResult = ReturnType<typeof useClientVersionsQuery>;
export type ClientVersionsLazyQueryHookResult = ReturnType<typeof useClientVersionsLazyQuery>;
export type ClientVersionsQueryResult = Apollo.QueryResult<ClientVersionsQuery, ClientVersionsQueryVariables>;
export const ConferenceVendorsDocument = gql`
    query _ConferenceVendors($args: _conference_vendor_graph_args!) {
  conferenceVendors: _conference_vendor_graph(args: $args) {
    org_id
    date
    vendor
    num_meetings
  }
}
    `;

/**
 * __useConferenceVendorsQuery__
 *
 * To run a query within a React component, call `useConferenceVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConferenceVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConferenceVendorsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useConferenceVendorsQuery(baseOptions: Apollo.QueryHookOptions<ConferenceVendorsQuery, ConferenceVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConferenceVendorsQuery, ConferenceVendorsQueryVariables>(ConferenceVendorsDocument, options);
      }
export function useConferenceVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConferenceVendorsQuery, ConferenceVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConferenceVendorsQuery, ConferenceVendorsQueryVariables>(ConferenceVendorsDocument, options);
        }
export type ConferenceVendorsQueryHookResult = ReturnType<typeof useConferenceVendorsQuery>;
export type ConferenceVendorsLazyQueryHookResult = ReturnType<typeof useConferenceVendorsLazyQuery>;
export type ConferenceVendorsQueryResult = Apollo.QueryResult<ConferenceVendorsQuery, ConferenceVendorsQueryVariables>;
export const ContentSharingAppsGroupsDocument = gql`
    query _ContentSharingAppsGroups($args: _search_content_sharing_app_groups_args!, $limit: Int!, $offset: Int!) {
  data: _search_content_sharing_app_groups(
    args: $args
    limit: $limit
    offset: $offset
  ) {
    total
    name: app_group
  }
  count: _search_content_sharing_app_groups_aggregate(args: $args) {
    aggregate {
      total: count
    }
  }
}
    `;

/**
 * __useContentSharingAppsGroupsQuery__
 *
 * To run a query within a React component, call `useContentSharingAppsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentSharingAppsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentSharingAppsGroupsQuery({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContentSharingAppsGroupsQuery(baseOptions: Apollo.QueryHookOptions<ContentSharingAppsGroupsQuery, ContentSharingAppsGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentSharingAppsGroupsQuery, ContentSharingAppsGroupsQueryVariables>(ContentSharingAppsGroupsDocument, options);
      }
export function useContentSharingAppsGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentSharingAppsGroupsQuery, ContentSharingAppsGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentSharingAppsGroupsQuery, ContentSharingAppsGroupsQueryVariables>(ContentSharingAppsGroupsDocument, options);
        }
export type ContentSharingAppsGroupsQueryHookResult = ReturnType<typeof useContentSharingAppsGroupsQuery>;
export type ContentSharingAppsGroupsLazyQueryHookResult = ReturnType<typeof useContentSharingAppsGroupsLazyQuery>;
export type ContentSharingAppsGroupsQueryResult = Apollo.QueryResult<ContentSharingAppsGroupsQuery, ContentSharingAppsGroupsQueryVariables>;
export const ContentSharingAppNamesDocument = gql`
    query _ContentSharingAppNames($args: _search_content_sharing_app_names_args!, $limit: Int!, $offset: Int!) {
  data: _search_content_sharing_app_names(
    args: $args
    limit: $limit
    offset: $offset
  ) {
    total
    name: app_name
  }
  count: _search_content_sharing_app_names_aggregate(args: $args) {
    aggregate {
      total: count
    }
  }
}
    `;

/**
 * __useContentSharingAppNamesQuery__
 *
 * To run a query within a React component, call `useContentSharingAppNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentSharingAppNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentSharingAppNamesQuery({
 *   variables: {
 *      args: // value for 'args'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContentSharingAppNamesQuery(baseOptions: Apollo.QueryHookOptions<ContentSharingAppNamesQuery, ContentSharingAppNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentSharingAppNamesQuery, ContentSharingAppNamesQueryVariables>(ContentSharingAppNamesDocument, options);
      }
export function useContentSharingAppNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentSharingAppNamesQuery, ContentSharingAppNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentSharingAppNamesQuery, ContentSharingAppNamesQueryVariables>(ContentSharingAppNamesDocument, options);
        }
export type ContentSharingAppNamesQueryHookResult = ReturnType<typeof useContentSharingAppNamesQuery>;
export type ContentSharingAppNamesLazyQueryHookResult = ReturnType<typeof useContentSharingAppNamesLazyQuery>;
export type ContentSharingAppNamesQueryResult = Apollo.QueryResult<ContentSharingAppNamesQuery, ContentSharingAppNamesQueryVariables>;
export const ContentSharingTypeDocument = gql`
    query _ContentSharingType($args: _search_content_sharing_types_args!) {
  sharingTypes: _search_content_sharing_types(args: $args) {
    numPosts: num_posts
    shareType: share_type
  }
}
    `;

/**
 * __useContentSharingTypeQuery__
 *
 * To run a query within a React component, call `useContentSharingTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentSharingTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentSharingTypeQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useContentSharingTypeQuery(baseOptions: Apollo.QueryHookOptions<ContentSharingTypeQuery, ContentSharingTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentSharingTypeQuery, ContentSharingTypeQueryVariables>(ContentSharingTypeDocument, options);
      }
export function useContentSharingTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentSharingTypeQuery, ContentSharingTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentSharingTypeQuery, ContentSharingTypeQueryVariables>(ContentSharingTypeDocument, options);
        }
export type ContentSharingTypeQueryHookResult = ReturnType<typeof useContentSharingTypeQuery>;
export type ContentSharingTypeLazyQueryHookResult = ReturnType<typeof useContentSharingTypeLazyQuery>;
export type ContentSharingTypeQueryResult = Apollo.QueryResult<ContentSharingTypeQuery, ContentSharingTypeQueryVariables>;
export const CurrentFloorInformationDocument = gql`
    query _CurrentFloorInformation($displayId: String, $addressId: Int) {
  _display_locations(
    where: {_display: {id: {_eq: $displayId}}, _and: {address_id: {_eq: $addressId}}}
  ) {
    address {
      floors {
        floor
        count_per_floor
      }
    }
    floor_count {
      floor
      count_per_floor
    }
  }
}
    `;

/**
 * __useCurrentFloorInformationQuery__
 *
 * To run a query within a React component, call `useCurrentFloorInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentFloorInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentFloorInformationQuery({
 *   variables: {
 *      displayId: // value for 'displayId'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useCurrentFloorInformationQuery(baseOptions?: Apollo.QueryHookOptions<CurrentFloorInformationQuery, CurrentFloorInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentFloorInformationQuery, CurrentFloorInformationQueryVariables>(CurrentFloorInformationDocument, options);
      }
export function useCurrentFloorInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentFloorInformationQuery, CurrentFloorInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentFloorInformationQuery, CurrentFloorInformationQueryVariables>(CurrentFloorInformationDocument, options);
        }
export type CurrentFloorInformationQueryHookResult = ReturnType<typeof useCurrentFloorInformationQuery>;
export type CurrentFloorInformationLazyQueryHookResult = ReturnType<typeof useCurrentFloorInformationLazyQuery>;
export type CurrentFloorInformationQueryResult = Apollo.QueryResult<CurrentFloorInformationQuery, CurrentFloorInformationQueryVariables>;
export const DailyActiveUsersDocument = gql`
    query _DailyActiveUsers($args: _daily_active_users_graph_args!) {
  dailyActiveUsers: _daily_active_users_graph(args: $args) {
    org_id
    date
    total
  }
}
    `;

/**
 * __useDailyActiveUsersQuery__
 *
 * To run a query within a React component, call `useDailyActiveUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyActiveUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyActiveUsersQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDailyActiveUsersQuery(baseOptions: Apollo.QueryHookOptions<DailyActiveUsersQuery, DailyActiveUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyActiveUsersQuery, DailyActiveUsersQueryVariables>(DailyActiveUsersDocument, options);
      }
export function useDailyActiveUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyActiveUsersQuery, DailyActiveUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyActiveUsersQuery, DailyActiveUsersQueryVariables>(DailyActiveUsersDocument, options);
        }
export type DailyActiveUsersQueryHookResult = ReturnType<typeof useDailyActiveUsersQuery>;
export type DailyActiveUsersLazyQueryHookResult = ReturnType<typeof useDailyActiveUsersLazyQuery>;
export type DailyActiveUsersQueryResult = Apollo.QueryResult<DailyActiveUsersQuery, DailyActiveUsersQueryVariables>;
export const DisplayAlertsDocument = gql`
    query _DisplayAlerts($where: view_display_alerts_bool_exp!, $limit: Int!, $offset: Int!, $orderBy: [view_display_alerts_order_by!]) {
  _alerts(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    time
    description
    display {
      id
      name
      categories {
        category_id
        option_id
        is_assigned
      }
    }
  }
  view_display_alerts_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDisplayAlertsQuery__
 *
 * To run a query within a React component, call `useDisplayAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayAlertsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDisplayAlertsQuery(baseOptions: Apollo.QueryHookOptions<DisplayAlertsQuery, DisplayAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplayAlertsQuery, DisplayAlertsQueryVariables>(DisplayAlertsDocument, options);
      }
export function useDisplayAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplayAlertsQuery, DisplayAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplayAlertsQuery, DisplayAlertsQueryVariables>(DisplayAlertsDocument, options);
        }
export type DisplayAlertsQueryHookResult = ReturnType<typeof useDisplayAlertsQuery>;
export type DisplayAlertsLazyQueryHookResult = ReturnType<typeof useDisplayAlertsLazyQuery>;
export type DisplayAlertsQueryResult = Apollo.QueryResult<DisplayAlertsQuery, DisplayAlertsQueryVariables>;
export const DisplayCountDocument = gql`
    query _DisplayCount($where: view_displays_bool_exp) {
  totalDisplays: _displaysAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDisplayCountQuery__
 *
 * To run a query within a React component, call `useDisplayCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDisplayCountQuery(baseOptions?: Apollo.QueryHookOptions<DisplayCountQuery, DisplayCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplayCountQuery, DisplayCountQueryVariables>(DisplayCountDocument, options);
      }
export function useDisplayCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplayCountQuery, DisplayCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplayCountQuery, DisplayCountQueryVariables>(DisplayCountDocument, options);
        }
export type DisplayCountQueryHookResult = ReturnType<typeof useDisplayCountQuery>;
export type DisplayCountLazyQueryHookResult = ReturnType<typeof useDisplayCountLazyQuery>;
export type DisplayCountQueryResult = Apollo.QueryResult<DisplayCountQuery, DisplayCountQueryVariables>;
export const DisplaysDocument = gql`
    query _Displays($where: view_displays_bool_exp, $limit: Int, $offset: Int) {
  displays: _displays(where: $where, limit: $limit, offset: $offset) {
    id
    uuid
    name
    timezone
  }
}
    `;

/**
 * __useDisplaysQuery__
 *
 * To run a query within a React component, call `useDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDisplaysQuery(baseOptions?: Apollo.QueryHookOptions<DisplaysQuery, DisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplaysQuery, DisplaysQueryVariables>(DisplaysDocument, options);
      }
export function useDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplaysQuery, DisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplaysQuery, DisplaysQueryVariables>(DisplaysDocument, options);
        }
export type DisplaysQueryHookResult = ReturnType<typeof useDisplaysQuery>;
export type DisplaysLazyQueryHookResult = ReturnType<typeof useDisplaysLazyQuery>;
export type DisplaysQueryResult = Apollo.QueryResult<DisplaysQuery, DisplaysQueryVariables>;
export const DisplaysActionsEligibilityDocument = gql`
    query _DisplaysActionsEligibility($where: view_displays_bool_exp) {
  offline: _displaysAggregate(
    where: {_and: [$where, {is_manageable: {_eq: true}, is_online: {_eq: false}}]}
  ) {
    aggregate {
      count
    }
  }
  offlinePod: _displays(
    where: {_and: [$where, {is_manageable: {_eq: true}, is_online: {_eq: false}}]}
    limit: 1
  ) {
    name
  }
  unsupportedFirmware: _displaysAggregate(
    where: {_and: [$where, {is_manageable: {_eq: true}, lms_version: {_lt: "{1,8,0}"}}]}
  ) {
    aggregate {
      count
    }
  }
  failedOnboarding: _displaysAggregate(
      where: {
      _and: [
        $where
        {is_manageable: {_eq: false}}
      ]
    }
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDisplaysActionsEligibilityQuery__
 *
 * To run a query within a React component, call `useDisplaysActionsEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysActionsEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysActionsEligibilityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDisplaysActionsEligibilityQuery(baseOptions?: Apollo.QueryHookOptions<DisplaysActionsEligibilityQuery, DisplaysActionsEligibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplaysActionsEligibilityQuery, DisplaysActionsEligibilityQueryVariables>(DisplaysActionsEligibilityDocument, options);
      }
export function useDisplaysActionsEligibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplaysActionsEligibilityQuery, DisplaysActionsEligibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplaysActionsEligibilityQuery, DisplaysActionsEligibilityQueryVariables>(DisplaysActionsEligibilityDocument, options);
        }
export type DisplaysActionsEligibilityQueryHookResult = ReturnType<typeof useDisplaysActionsEligibilityQuery>;
export type DisplaysActionsEligibilityLazyQueryHookResult = ReturnType<typeof useDisplaysActionsEligibilityLazyQuery>;
export type DisplaysActionsEligibilityQueryResult = Apollo.QueryResult<DisplaysActionsEligibilityQuery, DisplaysActionsEligibilityQueryVariables>;
export const DisplaysAvailabilityQDocument = gql`
    query _DisplaysAvailabilityQ($where: view_displays_bool_exp!, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  _displays(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    availability {
      is_online
      last_online
      status
    }
    name
    org
    version
    is_in_subscription
    catchingUp: catching_up
  }
}
    `;

/**
 * __useDisplaysAvailabilityQQuery__
 *
 * To run a query within a React component, call `useDisplaysAvailabilityQQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysAvailabilityQQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysAvailabilityQQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDisplaysAvailabilityQQuery(baseOptions: Apollo.QueryHookOptions<DisplaysAvailabilityQQuery, DisplaysAvailabilityQQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplaysAvailabilityQQuery, DisplaysAvailabilityQQueryVariables>(DisplaysAvailabilityQDocument, options);
      }
export function useDisplaysAvailabilityQLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplaysAvailabilityQQuery, DisplaysAvailabilityQQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplaysAvailabilityQQuery, DisplaysAvailabilityQQueryVariables>(DisplaysAvailabilityQDocument, options);
        }
export type DisplaysAvailabilityQQueryHookResult = ReturnType<typeof useDisplaysAvailabilityQQuery>;
export type DisplaysAvailabilityQLazyQueryHookResult = ReturnType<typeof useDisplaysAvailabilityQLazyQuery>;
export type DisplaysAvailabilityQQueryResult = Apollo.QueryResult<DisplaysAvailabilityQQuery, DisplaysAvailabilityQQueryVariables>;
export const DisplaysByGeoDocument = gql`
    query _DisplaysByGeo($args: _displays_by_geo_args!) {
  _displays_by_geo(
    args: $args
    where: {location: {precise_location: {_is_null: false}}}
  ) {
    id
    name
    location {
      precise_lat
      precise_long
    }
  }
}
    `;

/**
 * __useDisplaysByGeoQuery__
 *
 * To run a query within a React component, call `useDisplaysByGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysByGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysByGeoQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDisplaysByGeoQuery(baseOptions: Apollo.QueryHookOptions<DisplaysByGeoQuery, DisplaysByGeoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplaysByGeoQuery, DisplaysByGeoQueryVariables>(DisplaysByGeoDocument, options);
      }
export function useDisplaysByGeoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplaysByGeoQuery, DisplaysByGeoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplaysByGeoQuery, DisplaysByGeoQueryVariables>(DisplaysByGeoDocument, options);
        }
export type DisplaysByGeoQueryHookResult = ReturnType<typeof useDisplaysByGeoQuery>;
export type DisplaysByGeoLazyQueryHookResult = ReturnType<typeof useDisplaysByGeoLazyQuery>;
export type DisplaysByGeoQueryResult = Apollo.QueryResult<DisplaysByGeoQuery, DisplaysByGeoQueryVariables>;
export const DisplaysCategoriesDocument = gql`
    query _DisplaysCategories($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  displays: _displays(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    id
    name
    categories(where: {is_assigned: {_eq: true}}) {
      category_id
      display_id
      option {
        id
        displayName: display_name
      }
    }
  }
}
    `;

/**
 * __useDisplaysCategoriesQuery__
 *
 * To run a query within a React component, call `useDisplaysCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDisplaysCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<DisplaysCategoriesQuery, DisplaysCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplaysCategoriesQuery, DisplaysCategoriesQueryVariables>(DisplaysCategoriesDocument, options);
      }
export function useDisplaysCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplaysCategoriesQuery, DisplaysCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplaysCategoriesQuery, DisplaysCategoriesQueryVariables>(DisplaysCategoriesDocument, options);
        }
export type DisplaysCategoriesQueryHookResult = ReturnType<typeof useDisplaysCategoriesQuery>;
export type DisplaysCategoriesLazyQueryHookResult = ReturnType<typeof useDisplaysCategoriesLazyQuery>;
export type DisplaysCategoriesQueryResult = Apollo.QueryResult<DisplaysCategoriesQuery, DisplaysCategoriesQueryVariables>;
export const DisplaysDeploymentCsvDocument = gql`
    query _DisplaysDeploymentCSV($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  _displays(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    ...DisplayDeploymentProps
  }
}
    ${DisplayDeploymentPropsFragmentDoc}`;

/**
 * __useDisplaysDeploymentCsvQuery__
 *
 * To run a query within a React component, call `useDisplaysDeploymentCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysDeploymentCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysDeploymentCsvQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDisplaysDeploymentCsvQuery(baseOptions?: Apollo.QueryHookOptions<DisplaysDeploymentCsvQuery, DisplaysDeploymentCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplaysDeploymentCsvQuery, DisplaysDeploymentCsvQueryVariables>(DisplaysDeploymentCsvDocument, options);
      }
export function useDisplaysDeploymentCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplaysDeploymentCsvQuery, DisplaysDeploymentCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplaysDeploymentCsvQuery, DisplaysDeploymentCsvQueryVariables>(DisplaysDeploymentCsvDocument, options);
        }
export type DisplaysDeploymentCsvQueryHookResult = ReturnType<typeof useDisplaysDeploymentCsvQuery>;
export type DisplaysDeploymentCsvLazyQueryHookResult = ReturnType<typeof useDisplaysDeploymentCsvLazyQuery>;
export type DisplaysDeploymentCsvQueryResult = Apollo.QueryResult<DisplaysDeploymentCsvQuery, DisplaysDeploymentCsvQueryVariables>;
export const ExpiredDisplaysDocument = gql`
    query _ExpiredDisplays($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  _displays(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    subscription {
      is_in_subscription
      maintenance_end
      flexera_maintenance_end
    }
    deployment {
      license
      edition
    }
    hardware
    is_manageable
    name
    id
    is_in_subscription
  }
}
    `;

/**
 * __useExpiredDisplaysQuery__
 *
 * To run a query within a React component, call `useExpiredDisplaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpiredDisplaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpiredDisplaysQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useExpiredDisplaysQuery(baseOptions?: Apollo.QueryHookOptions<ExpiredDisplaysQuery, ExpiredDisplaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpiredDisplaysQuery, ExpiredDisplaysQueryVariables>(ExpiredDisplaysDocument, options);
      }
export function useExpiredDisplaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpiredDisplaysQuery, ExpiredDisplaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpiredDisplaysQuery, ExpiredDisplaysQueryVariables>(ExpiredDisplaysDocument, options);
        }
export type ExpiredDisplaysQueryHookResult = ReturnType<typeof useExpiredDisplaysQuery>;
export type ExpiredDisplaysLazyQueryHookResult = ReturnType<typeof useExpiredDisplaysLazyQuery>;
export type ExpiredDisplaysQueryResult = Apollo.QueryResult<ExpiredDisplaysQuery, ExpiredDisplaysQueryVariables>;
export const AnalyticCountsDocument = gql`
    query _AnalyticCounts($fromDate: date!, $toDate: date!) {
  _busiest_hour(args: {from_date: $fromDate, to_date: $toDate}) {
    value
  }
  _median_meeting_duration(args: {from_date: $fromDate, to_date: $toDate}) {
    value
  }
  _unique_users_count(args: {from_date: $fromDate, to_date: $toDate}) {
    value
  }
}
    `;

/**
 * __useAnalyticCountsQuery__
 *
 * To run a query within a React component, call `useAnalyticCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticCountsQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useAnalyticCountsQuery(baseOptions: Apollo.QueryHookOptions<AnalyticCountsQuery, AnalyticCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticCountsQuery, AnalyticCountsQueryVariables>(AnalyticCountsDocument, options);
      }
export function useAnalyticCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticCountsQuery, AnalyticCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticCountsQuery, AnalyticCountsQueryVariables>(AnalyticCountsDocument, options);
        }
export type AnalyticCountsQueryHookResult = ReturnType<typeof useAnalyticCountsQuery>;
export type AnalyticCountsLazyQueryHookResult = ReturnType<typeof useAnalyticCountsLazyQuery>;
export type AnalyticCountsQueryResult = Apollo.QueryResult<AnalyticCountsQuery, AnalyticCountsQueryVariables>;
export const HybridMeetingsDocument = gql`
    query _HybridMeetings($args: _hybrid_meetings_graph_args!) {
  hybridMeetings: _hybrid_meetings_graph(args: $args) {
    org_id
    date
    total_proximate_meetings
    total_remote_meetings
  }
}
    `;

/**
 * __useHybridMeetingsQuery__
 *
 * To run a query within a React component, call `useHybridMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHybridMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHybridMeetingsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useHybridMeetingsQuery(baseOptions: Apollo.QueryHookOptions<HybridMeetingsQuery, HybridMeetingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HybridMeetingsQuery, HybridMeetingsQueryVariables>(HybridMeetingsDocument, options);
      }
export function useHybridMeetingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HybridMeetingsQuery, HybridMeetingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HybridMeetingsQuery, HybridMeetingsQueryVariables>(HybridMeetingsDocument, options);
        }
export type HybridMeetingsQueryHookResult = ReturnType<typeof useHybridMeetingsQuery>;
export type HybridMeetingsLazyQueryHookResult = ReturnType<typeof useHybridMeetingsLazyQuery>;
export type HybridMeetingsQueryResult = Apollo.QueryResult<HybridMeetingsQuery, HybridMeetingsQueryVariables>;
export const JoyridesDocument = gql`
    query _Joyrides {
  joyrides: orgs_users_joyrides {
    joyride
    finished_on
  }
}
    `;

/**
 * __useJoyridesQuery__
 *
 * To run a query within a React component, call `useJoyridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoyridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoyridesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJoyridesQuery(baseOptions?: Apollo.QueryHookOptions<JoyridesQuery, JoyridesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JoyridesQuery, JoyridesQueryVariables>(JoyridesDocument, options);
      }
export function useJoyridesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JoyridesQuery, JoyridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JoyridesQuery, JoyridesQueryVariables>(JoyridesDocument, options);
        }
export type JoyridesQueryHookResult = ReturnType<typeof useJoyridesQuery>;
export type JoyridesLazyQueryHookResult = ReturnType<typeof useJoyridesLazyQuery>;
export type JoyridesQueryResult = Apollo.QueryResult<JoyridesQuery, JoyridesQueryVariables>;
export const BuildingLocationsDocument = gql`
    query _BuildingLocations($where: org_addresses_bool_exp, $whereDisplay: view_displays_bool_exp, $orderBy: [org_addresses_order_by!], $limit: Int, $offset: Int) {
  buildings: _addresses(
    where: {_and: [$where, {assignedDisplays: {_display: $whereDisplay}}]}
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    id
    building: nickname
    address: building
    totalPods: assignedDisplays_aggregate(where: {_display: $whereDisplay}) {
      aggregate {
        count
      }
    }
    city
    country
  }
  totalBuildings: org_addresses_aggregate(
    where: {_and: [$where, {assignedDisplays: {_display: $whereDisplay}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useBuildingLocationsQuery__
 *
 * To run a query within a React component, call `useBuildingLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingLocationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      whereDisplay: // value for 'whereDisplay'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useBuildingLocationsQuery(baseOptions?: Apollo.QueryHookOptions<BuildingLocationsQuery, BuildingLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingLocationsQuery, BuildingLocationsQueryVariables>(BuildingLocationsDocument, options);
      }
export function useBuildingLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingLocationsQuery, BuildingLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingLocationsQuery, BuildingLocationsQueryVariables>(BuildingLocationsDocument, options);
        }
export type BuildingLocationsQueryHookResult = ReturnType<typeof useBuildingLocationsQuery>;
export type BuildingLocationsLazyQueryHookResult = ReturnType<typeof useBuildingLocationsLazyQuery>;
export type BuildingLocationsQueryResult = Apollo.QueryResult<BuildingLocationsQuery, BuildingLocationsQueryVariables>;
export const DisplayAddressesCountDocument = gql`
    query _DisplayAddressesCount($where: view_displays_locations_bool_exp!) {
  address: _addresses(where: {assignedDisplays: $where}, limit: 1) {
    id
  }
  total: org_addresses_aggregate(where: {assignedDisplays: $where}) {
    aggregate {
      count
    }
  }
  noAddresses: _display_locations(
    where: {_and: [$where, {address_id: {_is_null: true}}]}
    limit: 1
  ) {
    address_id
  }
}
    `;

/**
 * __useDisplayAddressesCountQuery__
 *
 * To run a query within a React component, call `useDisplayAddressesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayAddressesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayAddressesCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDisplayAddressesCountQuery(baseOptions: Apollo.QueryHookOptions<DisplayAddressesCountQuery, DisplayAddressesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplayAddressesCountQuery, DisplayAddressesCountQueryVariables>(DisplayAddressesCountDocument, options);
      }
export function useDisplayAddressesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplayAddressesCountQuery, DisplayAddressesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplayAddressesCountQuery, DisplayAddressesCountQueryVariables>(DisplayAddressesCountDocument, options);
        }
export type DisplayAddressesCountQueryHookResult = ReturnType<typeof useDisplayAddressesCountQuery>;
export type DisplayAddressesCountLazyQueryHookResult = ReturnType<typeof useDisplayAddressesCountLazyQuery>;
export type DisplayAddressesCountQueryResult = Apollo.QueryResult<DisplayAddressesCountQuery, DisplayAddressesCountQueryVariables>;
export const FetchAffectedDisplayIdsDocument = gql`
    query _FetchAffectedDisplayIds($where: view_displays_bool_exp) {
  _displays(where: $where) {
    id
    uuid
    location {
      precise_lat
      precise_long
      address {
        city
        state_province
        country
      }
    }
  }
}
    `;

/**
 * __useFetchAffectedDisplayIdsQuery__
 *
 * To run a query within a React component, call `useFetchAffectedDisplayIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAffectedDisplayIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAffectedDisplayIdsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFetchAffectedDisplayIdsQuery(baseOptions?: Apollo.QueryHookOptions<FetchAffectedDisplayIdsQuery, FetchAffectedDisplayIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAffectedDisplayIdsQuery, FetchAffectedDisplayIdsQueryVariables>(FetchAffectedDisplayIdsDocument, options);
      }
export function useFetchAffectedDisplayIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAffectedDisplayIdsQuery, FetchAffectedDisplayIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAffectedDisplayIdsQuery, FetchAffectedDisplayIdsQueryVariables>(FetchAffectedDisplayIdsDocument, options);
        }
export type FetchAffectedDisplayIdsQueryHookResult = ReturnType<typeof useFetchAffectedDisplayIdsQuery>;
export type FetchAffectedDisplayIdsLazyQueryHookResult = ReturnType<typeof useFetchAffectedDisplayIdsLazyQuery>;
export type FetchAffectedDisplayIdsQueryResult = Apollo.QueryResult<FetchAffectedDisplayIdsQuery, FetchAffectedDisplayIdsQueryVariables>;
export const HomePageMapDocument = gql`
    query _HomePageMap {
  displayCount: _displaysAggregate {
    aggregate {
      count
    }
  }
  displayLocations: _display_locations(
    where: {precise_location: {_is_null: false}}
  ) {
    precise_lat
    precise_long
    _display {
      id
      name
    }
  }
}
    `;

/**
 * __useHomePageMapQuery__
 *
 * To run a query within a React component, call `useHomePageMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageMapQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageMapQuery(baseOptions?: Apollo.QueryHookOptions<HomePageMapQuery, HomePageMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomePageMapQuery, HomePageMapQueryVariables>(HomePageMapDocument, options);
      }
export function useHomePageMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageMapQuery, HomePageMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomePageMapQuery, HomePageMapQueryVariables>(HomePageMapDocument, options);
        }
export type HomePageMapQueryHookResult = ReturnType<typeof useHomePageMapQuery>;
export type HomePageMapLazyQueryHookResult = ReturnType<typeof useHomePageMapLazyQuery>;
export type HomePageMapQueryResult = Apollo.QueryResult<HomePageMapQuery, HomePageMapQueryVariables>;
export const HomePageMapNewDocument = gql`
    query _HomePageMapNew($fromDate: date!, $toDate: date!) {
  mapData: _home_page_map_by_display(
    args: {from_date: $fromDate, to_date: $toDate}
  ) {
    id
    name
    out_of_template_count
    precise_lat
    precise_long
    restart_count
    total_alerts
    unreachable_count
    usage_hours
    usb_added_event_count
    usb_removed_event_count
  }
}
    `;

/**
 * __useHomePageMapNewQuery__
 *
 * To run a query within a React component, call `useHomePageMapNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageMapNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageMapNewQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useHomePageMapNewQuery(baseOptions: Apollo.QueryHookOptions<HomePageMapNewQuery, HomePageMapNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomePageMapNewQuery, HomePageMapNewQueryVariables>(HomePageMapNewDocument, options);
      }
export function useHomePageMapNewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageMapNewQuery, HomePageMapNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomePageMapNewQuery, HomePageMapNewQueryVariables>(HomePageMapNewDocument, options);
        }
export type HomePageMapNewQueryHookResult = ReturnType<typeof useHomePageMapNewQuery>;
export type HomePageMapNewLazyQueryHookResult = ReturnType<typeof useHomePageMapNewLazyQuery>;
export type HomePageMapNewQueryResult = Apollo.QueryResult<HomePageMapNewQuery, HomePageMapNewQueryVariables>;
export const LocationsPageCardsDocument = gql`
    query _LocationsPageCards($where: view_displays_assigned_options_bool_exp, $category: String!) {
  _categories(where: {options: {category_id: {_eq: $category}}}) {
    id
    display_name
    options(where: {hidden: {_eq: false}}) {
      id
      display_name
      assignedDisplays_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useLocationsPageCardsQuery__
 *
 * To run a query within a React component, call `useLocationsPageCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsPageCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsPageCardsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useLocationsPageCardsQuery(baseOptions: Apollo.QueryHookOptions<LocationsPageCardsQuery, LocationsPageCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsPageCardsQuery, LocationsPageCardsQueryVariables>(LocationsPageCardsDocument, options);
      }
export function useLocationsPageCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsPageCardsQuery, LocationsPageCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsPageCardsQuery, LocationsPageCardsQueryVariables>(LocationsPageCardsDocument, options);
        }
export type LocationsPageCardsQueryHookResult = ReturnType<typeof useLocationsPageCardsQuery>;
export type LocationsPageCardsLazyQueryHookResult = ReturnType<typeof useLocationsPageCardsLazyQuery>;
export type LocationsPageCardsQueryResult = Apollo.QueryResult<LocationsPageCardsQuery, LocationsPageCardsQueryVariables>;
export const LocationFloorListDocument = gql`
    query _LocationFloorList($where: view_displays_bool_exp) {
  _displays(where: $where) {
    location {
      address_id
      floor_count {
        address_id
        floor
        count_per_floor
      }
    }
  }
}
    `;

/**
 * __useLocationFloorListQuery__
 *
 * To run a query within a React component, call `useLocationFloorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationFloorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationFloorListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationFloorListQuery(baseOptions?: Apollo.QueryHookOptions<LocationFloorListQuery, LocationFloorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationFloorListQuery, LocationFloorListQueryVariables>(LocationFloorListDocument, options);
      }
export function useLocationFloorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationFloorListQuery, LocationFloorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationFloorListQuery, LocationFloorListQueryVariables>(LocationFloorListDocument, options);
        }
export type LocationFloorListQueryHookResult = ReturnType<typeof useLocationFloorListQuery>;
export type LocationFloorListLazyQueryHookResult = ReturnType<typeof useLocationFloorListLazyQuery>;
export type LocationFloorListQueryResult = Apollo.QueryResult<LocationFloorListQuery, LocationFloorListQueryVariables>;
export const LocationsPageDocument = gql`
    query _LocationsPage($where: view_displays_bool_exp, $whereTotal: view_displays_bool_exp) {
  totalDisplayCount: _displaysAggregate(where: $whereTotal) {
    aggregate {
      count
    }
  }
  filteredDisplayCount: _displaysAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useLocationsPageQuery__
 *
 * To run a query within a React component, call `useLocationsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsPageQuery({
 *   variables: {
 *      where: // value for 'where'
 *      whereTotal: // value for 'whereTotal'
 *   },
 * });
 */
export function useLocationsPageQuery(baseOptions?: Apollo.QueryHookOptions<LocationsPageQuery, LocationsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsPageQuery, LocationsPageQueryVariables>(LocationsPageDocument, options);
      }
export function useLocationsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsPageQuery, LocationsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsPageQuery, LocationsPageQueryVariables>(LocationsPageDocument, options);
        }
export type LocationsPageQueryHookResult = ReturnType<typeof useLocationsPageQuery>;
export type LocationsPageLazyQueryHookResult = ReturnType<typeof useLocationsPageLazyQuery>;
export type LocationsPageQueryResult = Apollo.QueryResult<LocationsPageQuery, LocationsPageQueryVariables>;
export const LocationsPageMapDocument = gql`
    query _LocationsPageMap($where: view_displays_bool_exp) {
  _displays(where: $where) {
    id
    name
    location {
      precise_lat
      precise_long
      address_id
      floor
      address {
        id
        address_full
        building
        city
        country
        state_province
        nickname
        lat
        long
        assignedDisplays_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLocationsPageMapQuery__
 *
 * To run a query within a React component, call `useLocationsPageMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsPageMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsPageMapQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationsPageMapQuery(baseOptions?: Apollo.QueryHookOptions<LocationsPageMapQuery, LocationsPageMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsPageMapQuery, LocationsPageMapQueryVariables>(LocationsPageMapDocument, options);
      }
export function useLocationsPageMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsPageMapQuery, LocationsPageMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsPageMapQuery, LocationsPageMapQueryVariables>(LocationsPageMapDocument, options);
        }
export type LocationsPageMapQueryHookResult = ReturnType<typeof useLocationsPageMapQuery>;
export type LocationsPageMapLazyQueryHookResult = ReturnType<typeof useLocationsPageMapLazyQuery>;
export type LocationsPageMapQueryResult = Apollo.QueryResult<LocationsPageMapQuery, LocationsPageMapQueryVariables>;
export const LocationsPageOverviewCardCountDocument = gql`
    query _LocationsPageOverviewCardCount($displaywhere: view_displays_bool_exp) {
  country: _displayAssignedOptionsAggregate(
    where: {display: $displaywhere, category_id: {_in: ["country"]}}
    distinct_on: option_id
  ) {
    aggregate {
      count
    }
  }
  city: _displayAssignedOptionsAggregate(
    where: {display: $displaywhere, category_id: {_in: ["city"]}}
    distinct_on: option_id
  ) {
    aggregate {
      count
    }
  }
  building: _displayAssignedOptionsAggregate(
    where: {display: $displaywhere, category_id: {_in: ["building"]}}
    distinct_on: option_id
  ) {
    aggregate {
      count
    }
  }
  unassignedBuilding: _displaysAggregate(
    where: {categories: {category_id: {_eq: "building"}, is_assigned: {_eq: false}}}
  ) {
    aggregate {
      count
    }
  }
  pods: _displaysAggregate(where: $displaywhere) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useLocationsPageOverviewCardCountQuery__
 *
 * To run a query within a React component, call `useLocationsPageOverviewCardCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsPageOverviewCardCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsPageOverviewCardCountQuery({
 *   variables: {
 *      displaywhere: // value for 'displaywhere'
 *   },
 * });
 */
export function useLocationsPageOverviewCardCountQuery(baseOptions?: Apollo.QueryHookOptions<LocationsPageOverviewCardCountQuery, LocationsPageOverviewCardCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsPageOverviewCardCountQuery, LocationsPageOverviewCardCountQueryVariables>(LocationsPageOverviewCardCountDocument, options);
      }
export function useLocationsPageOverviewCardCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsPageOverviewCardCountQuery, LocationsPageOverviewCardCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsPageOverviewCardCountQuery, LocationsPageOverviewCardCountQueryVariables>(LocationsPageOverviewCardCountDocument, options);
        }
export type LocationsPageOverviewCardCountQueryHookResult = ReturnType<typeof useLocationsPageOverviewCardCountQuery>;
export type LocationsPageOverviewCardCountLazyQueryHookResult = ReturnType<typeof useLocationsPageOverviewCardCountLazyQuery>;
export type LocationsPageOverviewCardCountQueryResult = Apollo.QueryResult<LocationsPageOverviewCardCountQuery, LocationsPageOverviewCardCountQueryVariables>;
export const LocationsPageTableDocument = gql`
    query _LocationsPageTable($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  _displays(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    id
    name
    version
    hardware
    ip_addresses_primary
    availability {
      is_online
    }
    location {
      floor_count {
        floor
        count_per_floor
      }
      address {
        id
        nickname
        building
        city
        country
        address_full
        external_id
      }
    }
  }
}
    `;

export const LocationsDisplaysInfoDocument = gql`
    query _LocationsDisplaysInfo($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  _displays(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    location {
      address {
        id
        nickname
      }
    }
  }
}
    `;

/**
 * __useLocationsPageTableQuery__
 *
 * To run a query within a React component, call `useLocationsPageTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsPageTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsPageTableQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useLocationsDisplaysInfoQuery(baseOptions?: Apollo.QueryHookOptions<LocationsPageTableQuery, LocationsPageTableQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<LocationsPageTableQuery, LocationsPageTableQueryVariables>(LocationsDisplaysInfoDocument, options);
}
export function useLocationsDisplaysInfoLazyQuery(baseOptions?: Apollo.QueryHookOptions<LocationsPageTableQuery, LocationsPageTableQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<LocationsPageTableQuery, LocationsPageTableQueryVariables>(LocationsDisplaysInfoDocument, options);
}
export function useLocationsPageTableQuery(baseOptions?: Apollo.QueryHookOptions<LocationsPageTableQuery, LocationsPageTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsPageTableQuery, LocationsPageTableQueryVariables>(LocationsPageTableDocument, options);
      }
export function useLocationsPageTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsPageTableQuery, LocationsPageTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsPageTableQuery, LocationsPageTableQueryVariables>(LocationsPageTableDocument, options);
        }
export type LocationsPageTableQueryHookResult = ReturnType<typeof useLocationsPageTableQuery>;
export type LocationsPageTableLazyQueryHookResult = ReturnType<typeof useLocationsPageTableLazyQuery>;
export type LocationsPageTableQueryResult = Apollo.QueryResult<LocationsPageTableQuery, LocationsPageTableQueryVariables>;
export const MostCollaborativeSpacesDocument = gql`
    query _MostCollaborativeSpaces($args: _search_most_collaborative_spaces_args!) {
  _search_most_collaborative_spaces(args: $args) {
    collaborators
    display_id
    location_latitude
    location_longitude
    mtngs
    name
    org
    plot_score
    unq_users_avg
    room_type
    nickname
    building
    address
  }
}
    `;

/**
 * __useMostCollaborativeSpacesQuery__
 *
 * To run a query within a React component, call `useMostCollaborativeSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostCollaborativeSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostCollaborativeSpacesQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useMostCollaborativeSpacesQuery(baseOptions: Apollo.QueryHookOptions<MostCollaborativeSpacesQuery, MostCollaborativeSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MostCollaborativeSpacesQuery, MostCollaborativeSpacesQueryVariables>(MostCollaborativeSpacesDocument, options);
      }
export function useMostCollaborativeSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MostCollaborativeSpacesQuery, MostCollaborativeSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MostCollaborativeSpacesQuery, MostCollaborativeSpacesQueryVariables>(MostCollaborativeSpacesDocument, options);
        }
export type MostCollaborativeSpacesQueryHookResult = ReturnType<typeof useMostCollaborativeSpacesQuery>;
export type MostCollaborativeSpacesLazyQueryHookResult = ReturnType<typeof useMostCollaborativeSpacesLazyQuery>;
export type MostCollaborativeSpacesQueryResult = Apollo.QueryResult<MostCollaborativeSpacesQuery, MostCollaborativeSpacesQueryVariables>;
export const OptionsWithDisplayCountDocument = gql`
    query _OptionsWithDisplayCount($category_id: String!, $where: view_displays_assigned_options_bool_exp, $order_by: [view_options_order_by!]) {
  _options(
    order_by: $order_by
    where: {category_id: {_eq: $category_id}, hidden: {_eq: false}}
  ) {
    id
    category_id
    display_name
    assignedDisplays_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useOptionsWithDisplayCountQuery__
 *
 * To run a query within a React component, call `useOptionsWithDisplayCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionsWithDisplayCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionsWithDisplayCountQuery({
 *   variables: {
 *      category_id: // value for 'category_id'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useOptionsWithDisplayCountQuery(baseOptions: Apollo.QueryHookOptions<OptionsWithDisplayCountQuery, OptionsWithDisplayCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptionsWithDisplayCountQuery, OptionsWithDisplayCountQueryVariables>(OptionsWithDisplayCountDocument, options);
      }
export function useOptionsWithDisplayCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptionsWithDisplayCountQuery, OptionsWithDisplayCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptionsWithDisplayCountQuery, OptionsWithDisplayCountQueryVariables>(OptionsWithDisplayCountDocument, options);
        }
export type OptionsWithDisplayCountQueryHookResult = ReturnType<typeof useOptionsWithDisplayCountQuery>;
export type OptionsWithDisplayCountLazyQueryHookResult = ReturnType<typeof useOptionsWithDisplayCountLazyQuery>;
export type OptionsWithDisplayCountQueryResult = Apollo.QueryResult<OptionsWithDisplayCountQuery, OptionsWithDisplayCountQueryVariables>;
export const RealtimeDocument = gql`
    query _Realtime($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  realtimePods: _displays(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    location {
      precise_lat
      precise_long
      address {
        nickname
        building
      }
    }
    discover_realtime {
      foot_traffic
      myfoottraffic
      cur_calendar_start
      cur_calendar_end
      cur_calendar_attendees
      next_calendar_start
      next_calendar_end
      next_calendar_attendees
      current_occupancy_camera
    }
    realtime {
      realtime_status: my_status
      user_count: solstice_users
      calendar_config_enabled: cal_enabled
    }
    org
    display: id
    room: name
  }
}
    `;

/**
 * __useRealtimeQuery__
 *
 * To run a query within a React component, call `useRealtimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealtimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealtimeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useRealtimeQuery(baseOptions?: Apollo.QueryHookOptions<RealtimeQuery, RealtimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RealtimeQuery, RealtimeQueryVariables>(RealtimeDocument, options);
      }
export function useRealtimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RealtimeQuery, RealtimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RealtimeQuery, RealtimeQueryVariables>(RealtimeDocument, options);
        }
export type RealtimeQueryHookResult = ReturnType<typeof useRealtimeQuery>;
export type RealtimeLazyQueryHookResult = ReturnType<typeof useRealtimeLazyQuery>;
export type RealtimeQueryResult = Apollo.QueryResult<RealtimeQuery, RealtimeQueryVariables>;
export const UserDevicesDocument = gql`
    query _UserDevices($args: _search_user_devices_args!, $order_by: [view_user_devices_order_by!]) {
  userDevices: _search_user_devices(args: $args, order_by: $order_by) {
    total
    clientPlatform: client_platform
  }
}
    `;

/**
 * __useUserDevicesQuery__
 *
 * To run a query within a React component, call `useUserDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDevicesQuery({
 *   variables: {
 *      args: // value for 'args'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useUserDevicesQuery(baseOptions: Apollo.QueryHookOptions<UserDevicesQuery, UserDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDevicesQuery, UserDevicesQueryVariables>(UserDevicesDocument, options);
      }
export function useUserDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDevicesQuery, UserDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDevicesQuery, UserDevicesQueryVariables>(UserDevicesDocument, options);
        }
export type UserDevicesQueryHookResult = ReturnType<typeof useUserDevicesQuery>;
export type UserDevicesLazyQueryHookResult = ReturnType<typeof useUserDevicesLazyQuery>;
export type UserDevicesQueryResult = Apollo.QueryResult<UserDevicesQuery, UserDevicesQueryVariables>;
export const SessionsPerUserDocument = gql`
    query _SessionsPerUser($args: _sessions_per_user_args!) {
  sessionsPerUser: _sessions_per_user(args: $args) {
    org
    value
  }
}
    `;

/**
 * __useSessionsPerUserQuery__
 *
 * To run a query within a React component, call `useSessionsPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsPerUserQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useSessionsPerUserQuery(baseOptions: Apollo.QueryHookOptions<SessionsPerUserQuery, SessionsPerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionsPerUserQuery, SessionsPerUserQueryVariables>(SessionsPerUserDocument, options);
      }
export function useSessionsPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsPerUserQuery, SessionsPerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionsPerUserQuery, SessionsPerUserQueryVariables>(SessionsPerUserDocument, options);
        }
export type SessionsPerUserQueryHookResult = ReturnType<typeof useSessionsPerUserQuery>;
export type SessionsPerUserLazyQueryHookResult = ReturnType<typeof useSessionsPerUserLazyQuery>;
export type SessionsPerUserQueryResult = Apollo.QueryResult<SessionsPerUserQuery, SessionsPerUserQueryVariables>;
export const SolsticeVersionsDocument = gql`
    query _SolsticeVersions($whereDisplays: view_displays_bool_exp) {
  versions: _solstice_versions(order_by: {version: desc}) {
    version
    displays: displays_aggregate(where: $whereDisplays) {
      aggregate {
        count
      }
    }
  }
  totalDisplays: _displaysAggregate(where: $whereDisplays) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSolsticeVersionsQuery__
 *
 * To run a query within a React component, call `useSolsticeVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolsticeVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolsticeVersionsQuery({
 *   variables: {
 *      whereDisplays: // value for 'whereDisplays'
 *   },
 * });
 */
export function useSolsticeVersionsQuery(baseOptions?: Apollo.QueryHookOptions<SolsticeVersionsQuery, SolsticeVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SolsticeVersionsQuery, SolsticeVersionsQueryVariables>(SolsticeVersionsDocument, options);
      }
export function useSolsticeVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SolsticeVersionsQuery, SolsticeVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SolsticeVersionsQuery, SolsticeVersionsQueryVariables>(SolsticeVersionsDocument, options);
        }
export type SolsticeVersionsQueryHookResult = ReturnType<typeof useSolsticeVersionsQuery>;
export type SolsticeVersionsLazyQueryHookResult = ReturnType<typeof useSolsticeVersionsLazyQuery>;
export type SolsticeVersionsQueryResult = Apollo.QueryResult<SolsticeVersionsQuery, SolsticeVersionsQueryVariables>;
export const TableFilterCategoriesDocument = gql`
    query _TableFilterCategories($where: view_categories_bool_exp) {
  _categories(where: $where, order_by: {category_type: asc, display_name: asc}) {
    id
    category_type
    display_name
    is_auto_generated
  }
  _displaysAggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useTableFilterCategoriesQuery__
 *
 * To run a query within a React component, call `useTableFilterCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableFilterCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableFilterCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTableFilterCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<TableFilterCategoriesQuery, TableFilterCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableFilterCategoriesQuery, TableFilterCategoriesQueryVariables>(TableFilterCategoriesDocument, options);
      }
export function useTableFilterCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableFilterCategoriesQuery, TableFilterCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableFilterCategoriesQuery, TableFilterCategoriesQueryVariables>(TableFilterCategoriesDocument, options);
        }
export type TableFilterCategoriesQueryHookResult = ReturnType<typeof useTableFilterCategoriesQuery>;
export type TableFilterCategoriesLazyQueryHookResult = ReturnType<typeof useTableFilterCategoriesLazyQuery>;
export type TableFilterCategoriesQueryResult = Apollo.QueryResult<TableFilterCategoriesQuery, TableFilterCategoriesQueryVariables>;
export const UsbDeviceAvailabilityDocument = gql`
    query _USBDeviceAvailability($where: view_displays_current_usb_connections_bool_exp) {
  offline: view_displays_current_usb_connections_aggregate(
    where: {_and: [$where, {is_online: {_eq: false}}]}
  ) {
    aggregate {
      count
    }
  }
  online: view_displays_current_usb_connections_aggregate(
    where: {_and: [$where, {is_online: {_eq: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsbDeviceAvailabilityQuery__
 *
 * To run a query within a React component, call `useUsbDeviceAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsbDeviceAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsbDeviceAvailabilityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsbDeviceAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<UsbDeviceAvailabilityQuery, UsbDeviceAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsbDeviceAvailabilityQuery, UsbDeviceAvailabilityQueryVariables>(UsbDeviceAvailabilityDocument, options);
      }
export function useUsbDeviceAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsbDeviceAvailabilityQuery, UsbDeviceAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsbDeviceAvailabilityQuery, UsbDeviceAvailabilityQueryVariables>(UsbDeviceAvailabilityDocument, options);
        }
export type UsbDeviceAvailabilityQueryHookResult = ReturnType<typeof useUsbDeviceAvailabilityQuery>;
export type UsbDeviceAvailabilityLazyQueryHookResult = ReturnType<typeof useUsbDeviceAvailabilityLazyQuery>;
export type UsbDeviceAvailabilityQueryResult = Apollo.QueryResult<UsbDeviceAvailabilityQuery, UsbDeviceAvailabilityQueryVariables>;
export const UsbDeviceHistoryDocument = gql`
    query _USBDeviceHistory($days: Int!, $startDate: timestamptz!) {
  history: _usb_product_history(args: {days: $days}) {
    dt
    onlineCount: plug_count
    offlineCount: unplug_count
  }
  total: view_usb_product_history_aggregate(where: {dt: {_gt: $startDate}}) {
    aggregate {
      counts: sum {
        online: plug_count
        offline: unplug_count
      }
    }
  }
}
    `;

/**
 * __useUsbDeviceHistoryQuery__
 *
 * To run a query within a React component, call `useUsbDeviceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsbDeviceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsbDeviceHistoryQuery({
 *   variables: {
 *      days: // value for 'days'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useUsbDeviceHistoryQuery(baseOptions: Apollo.QueryHookOptions<UsbDeviceHistoryQuery, UsbDeviceHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsbDeviceHistoryQuery, UsbDeviceHistoryQueryVariables>(UsbDeviceHistoryDocument, options);
      }
export function useUsbDeviceHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsbDeviceHistoryQuery, UsbDeviceHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsbDeviceHistoryQuery, UsbDeviceHistoryQueryVariables>(UsbDeviceHistoryDocument, options);
        }
export type UsbDeviceHistoryQueryHookResult = ReturnType<typeof useUsbDeviceHistoryQuery>;
export type UsbDeviceHistoryLazyQueryHookResult = ReturnType<typeof useUsbDeviceHistoryLazyQuery>;
export type UsbDeviceHistoryQueryResult = Apollo.QueryResult<UsbDeviceHistoryQuery, UsbDeviceHistoryQueryVariables>;
export const UsbDeviceProductsDocument = gql`
    query _USBDeviceProducts($where: view_displays_current_usb_connections_bool_exp) {
  products: view_current_usb_products {
    productId: product_id
    productName: product_name
    connections: connections_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  unknownDevices: view_displays_current_usb_connections_aggregate(
    where: {_and: [$where, {product_id: {_is_null: true}}]}
  ) {
    aggregate {
      count
    }
  }
  total: view_displays_current_usb_connections_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsbDeviceProductsQuery__
 *
 * To run a query within a React component, call `useUsbDeviceProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsbDeviceProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsbDeviceProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsbDeviceProductsQuery(baseOptions?: Apollo.QueryHookOptions<UsbDeviceProductsQuery, UsbDeviceProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsbDeviceProductsQuery, UsbDeviceProductsQueryVariables>(UsbDeviceProductsDocument, options);
      }
export function useUsbDeviceProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsbDeviceProductsQuery, UsbDeviceProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsbDeviceProductsQuery, UsbDeviceProductsQueryVariables>(UsbDeviceProductsDocument, options);
        }
export type UsbDeviceProductsQueryHookResult = ReturnType<typeof useUsbDeviceProductsQuery>;
export type UsbDeviceProductsLazyQueryHookResult = ReturnType<typeof useUsbDeviceProductsLazyQuery>;
export type UsbDeviceProductsQueryResult = Apollo.QueryResult<UsbDeviceProductsQuery, UsbDeviceProductsQueryVariables>;
export const UsbDevicesDocument = gql`
    query _USBDevices($where: view_displays_current_usb_connections_bool_exp, $orderBy: [view_displays_current_usb_connections_order_by!], $limit: Int, $offset: Int) {
  connections: view_displays_current_usb_connections(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    vendor_name
    display {
      id
      version
      name
      org
      hardware
      is_in_subscription
    }
    device_name
    usb_plug_timestamp
    full_product_name
    org: org_id
    version
    relay_enabled
    occupancy_counting_enabled
    is_conference_ready
    is_occupancy_ready
    is_online
    av_device
  }
  totalConnections: view_displays_current_usb_connections_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsbDevicesQuery__
 *
 * To run a query within a React component, call `useUsbDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsbDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsbDevicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useUsbDevicesQuery(baseOptions?: Apollo.QueryHookOptions<UsbDevicesQuery, UsbDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsbDevicesQuery, UsbDevicesQueryVariables>(UsbDevicesDocument, options);
      }
export function useUsbDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsbDevicesQuery, UsbDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsbDevicesQuery, UsbDevicesQueryVariables>(UsbDevicesDocument, options);
        }
export type UsbDevicesQueryHookResult = ReturnType<typeof useUsbDevicesQuery>;
export type UsbDevicesLazyQueryHookResult = ReturnType<typeof useUsbDevicesLazyQuery>;
export type UsbDevicesQueryResult = Apollo.QueryResult<UsbDevicesQuery, UsbDevicesQueryVariables>;
export const UsbDevicesCountDocument = gql`
    query _USBDevicesCount($where: view_displays_current_usb_connections_bool_exp) {
  totalPods: _displaysAggregate(where: {is_online: {_eq: true}}) {
    aggregate {
      count
    }
  }
  podsWithDevices: view_displays_current_usb_connections_aggregate(
    where: {_and: [$where, {is_online: {_eq: true}}]}
    distinct_on: [display_id]
  ) {
    aggregate {
      count
    }
  }
  totalDevices: view_displays_current_usb_connections_aggregate(
    where: {_and: [$where, {is_online: {_eq: true}}]}
  ) {
    aggregate {
      count
    }
  }
  totalAvDevices: view_displays_current_usb_connections_aggregate(
    where: {_and: [$where, {av_device: {_eq: true}}]}
  ) {
    aggregate {
      count
    }
  }
  totalOtherDevices: view_displays_current_usb_connections_aggregate(
    where: {_and: [$where, {av_device: {_eq: false}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsbDevicesCountQuery__
 *
 * To run a query within a React component, call `useUsbDevicesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsbDevicesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsbDevicesCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsbDevicesCountQuery(baseOptions?: Apollo.QueryHookOptions<UsbDevicesCountQuery, UsbDevicesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsbDevicesCountQuery, UsbDevicesCountQueryVariables>(UsbDevicesCountDocument, options);
      }
export function useUsbDevicesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsbDevicesCountQuery, UsbDevicesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsbDevicesCountQuery, UsbDevicesCountQueryVariables>(UsbDevicesCountDocument, options);
        }
export type UsbDevicesCountQueryHookResult = ReturnType<typeof useUsbDevicesCountQuery>;
export type UsbDevicesCountLazyQueryHookResult = ReturnType<typeof useUsbDevicesCountLazyQuery>;
export type UsbDevicesCountQueryResult = Apollo.QueryResult<UsbDevicesCountQuery, UsbDevicesCountQueryVariables>;
export const UniqueUsersCountDocument = gql`
    query _UniqueUsersCount($args: _unique_users_count_args!) {
  usersCount: _unique_users_count(args: $args) {
    org
    value
  }
}
    `;

/**
 * __useUniqueUsersCountQuery__
 *
 * To run a query within a React component, call `useUniqueUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueUsersCountQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUniqueUsersCountQuery(baseOptions: Apollo.QueryHookOptions<UniqueUsersCountQuery, UniqueUsersCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniqueUsersCountQuery, UniqueUsersCountQueryVariables>(UniqueUsersCountDocument, options);
      }
export function useUniqueUsersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniqueUsersCountQuery, UniqueUsersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniqueUsersCountQuery, UniqueUsersCountQueryVariables>(UniqueUsersCountDocument, options);
        }
export type UniqueUsersCountQueryHookResult = ReturnType<typeof useUniqueUsersCountQuery>;
export type UniqueUsersCountLazyQueryHookResult = ReturnType<typeof useUniqueUsersCountLazyQuery>;
export type UniqueUsersCountQueryResult = Apollo.QueryResult<UniqueUsersCountQuery, UniqueUsersCountQueryVariables>;
export const UpdateablePodsDocument = gql`
    query _UpdateablePods($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int = 0, $offset: Int) {
  displays: _displays(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    id
    name
    updateability {
      version
      updating
      updateable
      status
    }
    subscription {
      subscription_date
    }
    catchingUp: catching_up
  }
  totalDisplays: _displaysAggregate(where: $where) {
    aggregate {
      count
    }
  }
  totalSelectableDisplays: _displaysAggregate(
    where: {_and: [$where, {updateability: {updateable: {_eq: true}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUpdateablePodsQuery__
 *
 * To run a query within a React component, call `useUpdateablePodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateablePodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateablePodsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useUpdateablePodsQuery(baseOptions?: Apollo.QueryHookOptions<UpdateablePodsQuery, UpdateablePodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateablePodsQuery, UpdateablePodsQueryVariables>(UpdateablePodsDocument, options);
      }
export function useUpdateablePodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateablePodsQuery, UpdateablePodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateablePodsQuery, UpdateablePodsQueryVariables>(UpdateablePodsDocument, options);
        }
export type UpdateablePodsQueryHookResult = ReturnType<typeof useUpdateablePodsQuery>;
export type UpdateablePodsLazyQueryHookResult = ReturnType<typeof useUpdateablePodsLazyQuery>;
export type UpdateablePodsQueryResult = Apollo.QueryResult<UpdateablePodsQuery, UpdateablePodsQueryVariables>;
export const UpdateablePodsCountDocument = gql`
    query _UpdateablePodsCount($where: view_displays_updateability_bool_exp) {
  totalDisplays: view_displays_updateability_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUpdateablePodsCountQuery__
 *
 * To run a query within a React component, call `useUpdateablePodsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateablePodsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateablePodsCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateablePodsCountQuery(baseOptions?: Apollo.QueryHookOptions<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>(UpdateablePodsCountDocument, options);
      }
export function useUpdateablePodsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>(UpdateablePodsCountDocument, options);
        }
export type UpdateablePodsCountQueryHookResult = ReturnType<typeof useUpdateablePodsCountQuery>;
export type UpdateablePodsCountLazyQueryHookResult = ReturnType<typeof useUpdateablePodsCountLazyQuery>;
export type UpdateablePodsCountQueryResult = Apollo.QueryResult<UpdateablePodsCountQuery, UpdateablePodsCountQueryVariables>;
export const UpdateablePodsSlimDocument = gql`
    query _UpdateablePodsSlim($where: view_displays_updateability_bool_exp, $orderBy: [view_displays_updateability_order_by!], $limit: Int = 0, $offset: Int) {
  displays: view_displays_updateability(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    version
    updating
    updateable
    status
  }
}
    `;

/**
 * __useUpdateablePodsSlimQuery__
 *
 * To run a query within a React component, call `useUpdateablePodsSlimQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateablePodsSlimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateablePodsSlimQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useUpdateablePodsSlimQuery(baseOptions?: Apollo.QueryHookOptions<UpdateablePodsSlimQuery, UpdateablePodsSlimQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateablePodsSlimQuery, UpdateablePodsSlimQueryVariables>(UpdateablePodsSlimDocument, options);
      }
export function useUpdateablePodsSlimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateablePodsSlimQuery, UpdateablePodsSlimQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateablePodsSlimQuery, UpdateablePodsSlimQueryVariables>(UpdateablePodsSlimDocument, options);
        }
export type UpdateablePodsSlimQueryHookResult = ReturnType<typeof useUpdateablePodsSlimQuery>;
export type UpdateablePodsSlimLazyQueryHookResult = ReturnType<typeof useUpdateablePodsSlimLazyQuery>;
export type UpdateablePodsSlimQueryResult = Apollo.QueryResult<UpdateablePodsSlimQuery, UpdateablePodsSlimQueryVariables>;
export const UsageByRoomTypeDocument = gql`
    query _UsageByRoomType($fromDate: date!, $toDate: date!) {
  roomTypes: _usage_by_room_type(args: {from_date: $fromDate, to_date: $toDate}) {
    id
    name: room_type
    totalMeetingLength: total_meeting_length
  }
}
    `;

/**
 * __useUsageByRoomTypeQuery__
 *
 * To run a query within a React component, call `useUsageByRoomTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsageByRoomTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsageByRoomTypeQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useUsageByRoomTypeQuery(baseOptions: Apollo.QueryHookOptions<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>(UsageByRoomTypeDocument, options);
      }
export function useUsageByRoomTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>(UsageByRoomTypeDocument, options);
        }
export type UsageByRoomTypeQueryHookResult = ReturnType<typeof useUsageByRoomTypeQuery>;
export type UsageByRoomTypeLazyQueryHookResult = ReturnType<typeof useUsageByRoomTypeLazyQuery>;
export type UsageByRoomTypeQueryResult = Apollo.QueryResult<UsageByRoomTypeQuery, UsageByRoomTypeQueryVariables>;
export const UserCollaborationDocument = gql`
    query _UserCollaboration($args: _user_collaboration_args!) {
  _user_collaboration(args: $args) {
    group
    org_id
    total
  }
}
    `;

/**
 * __useUserCollaborationQuery__
 *
 * To run a query within a React component, call `useUserCollaborationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCollaborationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCollaborationQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUserCollaborationQuery(baseOptions: Apollo.QueryHookOptions<UserCollaborationQuery, UserCollaborationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCollaborationQuery, UserCollaborationQueryVariables>(UserCollaborationDocument, options);
      }
export function useUserCollaborationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCollaborationQuery, UserCollaborationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCollaborationQuery, UserCollaborationQueryVariables>(UserCollaborationDocument, options);
        }
export type UserCollaborationQueryHookResult = ReturnType<typeof useUserCollaborationQuery>;
export type UserCollaborationLazyQueryHookResult = ReturnType<typeof useUserCollaborationLazyQuery>;
export type UserCollaborationQueryResult = Apollo.QueryResult<UserCollaborationQuery, UserCollaborationQueryVariables>;
export const UsersDocument = gql`
    query _Users($where: view_users_bool_exp, $limit: Int, $offset: Int, $orderBy: [view_users_order_by!]) {
  users: view_users(
    where: {_and: [$where, {status: {_neq: "deleted"}}]}
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    email
    role
    status
    sso_only
    has_password
  }
  totalUsers: view_users_aggregate(
    where: {_and: [$where, {status: {_neq: "deleted"}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const WellnessConfigDocument = gql`
    query _WellnessConfig($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  displays: _displays(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    id
    is_online
    subscription {
      is_in_subscription
    }
    wellness_config {
      name
      wellness_ready
      wellness_enabled
      plugged_in
      occ_enabled
      calendar_config_enabled
      location_set
    }
    location {
      address {
        building
        nickname
      }
    }
  }
}
    `;

/**
 * __useWellnessConfigQuery__
 *
 * To run a query within a React component, call `useWellnessConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellnessConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellnessConfigQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWellnessConfigQuery(baseOptions?: Apollo.QueryHookOptions<WellnessConfigQuery, WellnessConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WellnessConfigQuery, WellnessConfigQueryVariables>(WellnessConfigDocument, options);
      }
export function useWellnessConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WellnessConfigQuery, WellnessConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WellnessConfigQuery, WellnessConfigQueryVariables>(WellnessConfigDocument, options);
        }
export type WellnessConfigQueryHookResult = ReturnType<typeof useWellnessConfigQuery>;
export type WellnessConfigLazyQueryHookResult = ReturnType<typeof useWellnessConfigLazyQuery>;
export type WellnessConfigQueryResult = Apollo.QueryResult<WellnessConfigQuery, WellnessConfigQueryVariables>;
export const WellnessRealtimeDocument = gql`
    query _WellnessRealtime($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  displays: _displays(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    id
    is_online
    subscription {
      is_in_subscription
    }
    discover_realtime {
      name
      current_occupancy_camera
      myfoottraffic
      foot_traffic
      cur_calendar_start
      cur_calendar_end
      cur_calendar_attendees
      next_calendar_start
      next_calendar_end
      next_calendar_attendees
    }
    wellness_config {
      wellness_ready
    }
    location {
      address {
        building
        nickname
      }
    }
  }
}
    `;

/**
 * __useWellnessRealtimeQuery__
 *
 * To run a query within a React component, call `useWellnessRealtimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellnessRealtimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellnessRealtimeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWellnessRealtimeQuery(baseOptions?: Apollo.QueryHookOptions<WellnessRealtimeQuery, WellnessRealtimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WellnessRealtimeQuery, WellnessRealtimeQueryVariables>(WellnessRealtimeDocument, options);
      }
export function useWellnessRealtimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WellnessRealtimeQuery, WellnessRealtimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WellnessRealtimeQuery, WellnessRealtimeQueryVariables>(WellnessRealtimeDocument, options);
        }
export type WellnessRealtimeQueryHookResult = ReturnType<typeof useWellnessRealtimeQuery>;
export type WellnessRealtimeLazyQueryHookResult = ReturnType<typeof useWellnessRealtimeLazyQuery>;
export type WellnessRealtimeQueryResult = Apollo.QueryResult<WellnessRealtimeQuery, WellnessRealtimeQueryVariables>;
export const WorkspaceStatusDocument = gql`
    query _WorkspaceStatus {
  orgs_flags {
    wellness_enabled
  }
}
    `;

/**
 * __useWorkspaceStatusQuery__
 *
 * To run a query within a React component, call `useWorkspaceStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceStatusQuery(baseOptions?: Apollo.QueryHookOptions<WorkspaceStatusQuery, WorkspaceStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceStatusQuery, WorkspaceStatusQueryVariables>(WorkspaceStatusDocument, options);
      }
export function useWorkspaceStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceStatusQuery, WorkspaceStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceStatusQuery, WorkspaceStatusQueryVariables>(WorkspaceStatusDocument, options);
        }
export type WorkspaceStatusQueryHookResult = ReturnType<typeof useWorkspaceStatusQuery>;
export type WorkspaceStatusLazyQueryHookResult = ReturnType<typeof useWorkspaceStatusLazyQuery>;
export type WorkspaceStatusQueryResult = Apollo.QueryResult<WorkspaceStatusQuery, WorkspaceStatusQueryVariables>;
export const ActiveLearningFlagsDocument = gql`
    subscription _ActiveLearningFlags {
  active_learning_flags {
    screen_key_enabled
  }
}
    `;

/**
 * __useActiveLearningFlagsSubscription__
 *
 * To run a query within a React component, call `useActiveLearningFlagsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActiveLearningFlagsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLearningFlagsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useActiveLearningFlagsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ActiveLearningFlagsSubscription, ActiveLearningFlagsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ActiveLearningFlagsSubscription, ActiveLearningFlagsSubscriptionVariables>(ActiveLearningFlagsDocument, options);
      }
export type ActiveLearningFlagsSubscriptionHookResult = ReturnType<typeof useActiveLearningFlagsSubscription>;
export type ActiveLearningFlagsSubscriptionResult = Apollo.SubscriptionResult<ActiveLearningFlagsSubscription>;
export const ActiveMeetingCountDocument = gql`
    subscription _ActiveMeetingCount {
  _active_meeting_count {
    value
  }
}
    `;

/**
 * __useActiveMeetingCountSubscription__
 *
 * To run a query within a React component, call `useActiveMeetingCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActiveMeetingCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveMeetingCountSubscription({
 *   variables: {
 *   },
 * });
 */
export function useActiveMeetingCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ActiveMeetingCountSubscription, ActiveMeetingCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ActiveMeetingCountSubscription, ActiveMeetingCountSubscriptionVariables>(ActiveMeetingCountDocument, options);
      }
export type ActiveMeetingCountSubscriptionHookResult = ReturnType<typeof useActiveMeetingCountSubscription>;
export type ActiveMeetingCountSubscriptionResult = Apollo.SubscriptionResult<ActiveMeetingCountSubscription>;
export const AlertsChartDocument = gql`
    subscription _AlertsChart($args: _alerts_chart_args!) {
  _alerts_chart(args: $args) {
    alert_type
    alerts
    dt
    org_id
  }
}
    `;

/**
 * __useAlertsChartSubscription__
 *
 * To run a query within a React component, call `useAlertsChartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAlertsChartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertsChartSubscription({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAlertsChartSubscription(baseOptions: Apollo.SubscriptionHookOptions<AlertsChartSubscription, AlertsChartSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AlertsChartSubscription, AlertsChartSubscriptionVariables>(AlertsChartDocument, options);
      }
export type AlertsChartSubscriptionHookResult = ReturnType<typeof useAlertsChartSubscription>;
export type AlertsChartSubscriptionResult = Apollo.SubscriptionResult<AlertsChartSubscription>;
export const DisplayAlertsTableDocument = gql`
    subscription _DisplayAlertsTable($where: view_display_alerts_bool_exp!, $limit: Int!, $offset: Int!, $orderBy: [view_display_alerts_order_by!]) {
  _alerts(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    time
    description
    alert_type
    display {
      id
      name
    }
  }
}
    `;

/**
 * __useDisplayAlertsTableSubscription__
 *
 * To run a query within a React component, call `useDisplayAlertsTableSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDisplayAlertsTableSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayAlertsTableSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDisplayAlertsTableSubscription(baseOptions: Apollo.SubscriptionHookOptions<DisplayAlertsTableSubscription, DisplayAlertsTableSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DisplayAlertsTableSubscription, DisplayAlertsTableSubscriptionVariables>(DisplayAlertsTableDocument, options);
      }
export type DisplayAlertsTableSubscriptionHookResult = ReturnType<typeof useDisplayAlertsTableSubscription>;
export type DisplayAlertsTableSubscriptionResult = Apollo.SubscriptionResult<DisplayAlertsTableSubscription>;
export const AvailabilityDisplayCountDocument = gql`
    subscription _AvailabilityDisplayCount($where: view_displays_bool_exp) {
  totalDisplays: _displaysAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAvailabilityDisplayCountSubscription__
 *
 * To run a query within a React component, call `useAvailabilityDisplayCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityDisplayCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityDisplayCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAvailabilityDisplayCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AvailabilityDisplayCountSubscription, AvailabilityDisplayCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AvailabilityDisplayCountSubscription, AvailabilityDisplayCountSubscriptionVariables>(AvailabilityDisplayCountDocument, options);
      }
export type AvailabilityDisplayCountSubscriptionHookResult = ReturnType<typeof useAvailabilityDisplayCountSubscription>;
export type AvailabilityDisplayCountSubscriptionResult = Apollo.SubscriptionResult<AvailabilityDisplayCountSubscription>;
export const AvailabilityOnlineDisplayCountDocument = gql`
    subscription _AvailabilityOnlineDisplayCount($where: view_displays_bool_exp) {
  totalOnline: _displaysAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAvailabilityOnlineDisplayCountSubscription__
 *
 * To run a query within a React component, call `useAvailabilityOnlineDisplayCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityOnlineDisplayCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityOnlineDisplayCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAvailabilityOnlineDisplayCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AvailabilityOnlineDisplayCountSubscription, AvailabilityOnlineDisplayCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AvailabilityOnlineDisplayCountSubscription, AvailabilityOnlineDisplayCountSubscriptionVariables>(AvailabilityOnlineDisplayCountDocument, options);
      }
export type AvailabilityOnlineDisplayCountSubscriptionHookResult = ReturnType<typeof useAvailabilityOnlineDisplayCountSubscription>;
export type AvailabilityOnlineDisplayCountSubscriptionResult = Apollo.SubscriptionResult<AvailabilityOnlineDisplayCountSubscription>;
export const AvailabilityUptimeDisplayCountDocument = gql`
    subscription _AvailabilityUptimeDisplayCount($where: view_displays_bool_exp) {
  totalUpTimeDisplays: _displaysAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAvailabilityUptimeDisplayCountSubscription__
 *
 * To run a query within a React component, call `useAvailabilityUptimeDisplayCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityUptimeDisplayCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityUptimeDisplayCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAvailabilityUptimeDisplayCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AvailabilityUptimeDisplayCountSubscription, AvailabilityUptimeDisplayCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AvailabilityUptimeDisplayCountSubscription, AvailabilityUptimeDisplayCountSubscriptionVariables>(AvailabilityUptimeDisplayCountDocument, options);
      }
export type AvailabilityUptimeDisplayCountSubscriptionHookResult = ReturnType<typeof useAvailabilityUptimeDisplayCountSubscription>;
export type AvailabilityUptimeDisplayCountSubscriptionResult = Apollo.SubscriptionResult<AvailabilityUptimeDisplayCountSubscription>;
export const DeploymentSdsDocument = gql`
    query _DeploymentSds($where: view_sds_bool_exp!, $whereAggregate: view_displays_hosts_bool_exp!) {
  view_sds(where: $where) {
    displays_aggregate(where: $whereAggregate) {
      aggregate {
        count
      }
    }
    host
  }
}
    `;

/**
 * __useDeploymentSdsQuery__
 *
 * To run a query within a React component, call `useDeploymentSdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentSdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentSdsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      whereAggregate: // value for 'whereAggregate'
 *   },
 * });
 */
export function useDeploymentSdsQuery(baseOptions: Apollo.QueryHookOptions<DeploymentSdsQuery, DeploymentSdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentSdsQuery, DeploymentSdsQueryVariables>(DeploymentSdsDocument, options);
      }
export function useDeploymentSdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentSdsQuery, DeploymentSdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentSdsQuery, DeploymentSdsQueryVariables>(DeploymentSdsDocument, options);
        }
export type DeploymentSdsQueryHookResult = ReturnType<typeof useDeploymentSdsQuery>;
export type DeploymentSdsLazyQueryHookResult = ReturnType<typeof useDeploymentSdsLazyQuery>;
export type DeploymentSdsQueryResult = Apollo.QueryResult<DeploymentSdsQuery, DeploymentSdsQueryVariables>;
export const DisplaysDeploymentDocument = gql`
    subscription _DisplaysDeployment($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  _displays(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    ...DisplayDeploymentProps
  }
}
    ${DisplayDeploymentPropsFragmentDoc}`;

/**
 * __useDisplaysDeploymentSubscription__
 *
 * To run a query within a React component, call `useDisplaysDeploymentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysDeploymentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysDeploymentSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDisplaysDeploymentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DisplaysDeploymentSubscription, DisplaysDeploymentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DisplaysDeploymentSubscription, DisplaysDeploymentSubscriptionVariables>(DisplaysDeploymentDocument, options);
      }
export type DisplaysDeploymentSubscriptionHookResult = ReturnType<typeof useDisplaysDeploymentSubscription>;
export type DisplaysDeploymentSubscriptionResult = Apollo.SubscriptionResult<DisplaysDeploymentSubscription>;
export const DeploymentDisplayCountDocument = gql`
    subscription _DeploymentDisplayCount($where: view_displays_bool_exp) {
  totalDisplays: _displaysAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDeploymentDisplayCountSubscription__
 *
 * To run a query within a React component, call `useDeploymentDisplayCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentDisplayCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentDisplayCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeploymentDisplayCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeploymentDisplayCountSubscription, DeploymentDisplayCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeploymentDisplayCountSubscription, DeploymentDisplayCountSubscriptionVariables>(DeploymentDisplayCountDocument, options);
      }
export type DeploymentDisplayCountSubscriptionHookResult = ReturnType<typeof useDeploymentDisplayCountSubscription>;
export type DeploymentDisplayCountSubscriptionResult = Apollo.SubscriptionResult<DeploymentDisplayCountSubscription>;
export const DisplayLocationDocument = gql`
    subscription _DisplayLocation($id: String!) {
  _display_locations(where: {_display: {id: {_eq: $id}}}) {
    precise_lat
    precise_long
    address_id
    address {
      id
      address_full
      building
      nickname
      zip_code
      city
      state_province
      country
      lat
      long
      external_id
    }
  }
}
    `;

/**
 * __useDisplayLocationSubscription__
 *
 * To run a query within a React component, call `useDisplayLocationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDisplayLocationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayLocationSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisplayLocationSubscription(baseOptions: Apollo.SubscriptionHookOptions<DisplayLocationSubscription, DisplayLocationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DisplayLocationSubscription, DisplayLocationSubscriptionVariables>(DisplayLocationDocument, options);
      }
export type DisplayLocationSubscriptionHookResult = ReturnType<typeof useDisplayLocationSubscription>;
export type DisplayLocationSubscriptionResult = Apollo.SubscriptionResult<DisplayLocationSubscription>;
export const DisplaysAvailabilityDocument = gql`
    subscription _DisplaysAvailability($where: view_displays_bool_exp!, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  _displays(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    availability {
      is_online
      last_online
      status
    }
    name
    org
    version
    is_in_subscription
  }
}
    `;

/**
 * __useDisplaysAvailabilitySubscription__
 *
 * To run a query within a React component, call `useDisplaysAvailabilitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysAvailabilitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysAvailabilitySubscription({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDisplaysAvailabilitySubscription(baseOptions: Apollo.SubscriptionHookOptions<DisplaysAvailabilitySubscription, DisplaysAvailabilitySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DisplaysAvailabilitySubscription, DisplaysAvailabilitySubscriptionVariables>(DisplaysAvailabilityDocument, options);
      }
export type DisplaysAvailabilitySubscriptionHookResult = ReturnType<typeof useDisplaysAvailabilitySubscription>;
export type DisplaysAvailabilitySubscriptionResult = Apollo.SubscriptionResult<DisplaysAvailabilitySubscription>;
export const DisplaysCategoriesSubDocument = gql`
    subscription _DisplaysCategoriesSub($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  displays: _displays(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    id
    name
    categories(where: {is_assigned: {_eq: true}}) {
      category_id
      display_id
      option {
        id
        displayName: display_name
      }
    }
  }
}
    `;

/**
 * __useDisplaysCategoriesSubSubscription__
 *
 * To run a query within a React component, call `useDisplaysCategoriesSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDisplaysCategoriesSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplaysCategoriesSubSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDisplaysCategoriesSubSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DisplaysCategoriesSubSubscription, DisplaysCategoriesSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DisplaysCategoriesSubSubscription, DisplaysCategoriesSubSubscriptionVariables>(DisplaysCategoriesSubDocument, options);
      }
export type DisplaysCategoriesSubSubscriptionHookResult = ReturnType<typeof useDisplaysCategoriesSubSubscription>;
export type DisplaysCategoriesSubSubscriptionResult = Apollo.SubscriptionResult<DisplaysCategoriesSubSubscription>;
export const FilteredDisplayCountDocument = gql`
    subscription _FilteredDisplayCount($where: view_displays_bool_exp) {
  _displaysAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useFilteredDisplayCountSubscription__
 *
 * To run a query within a React component, call `useFilteredDisplayCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFilteredDisplayCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredDisplayCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFilteredDisplayCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<FilteredDisplayCountSubscription, FilteredDisplayCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FilteredDisplayCountSubscription, FilteredDisplayCountSubscriptionVariables>(FilteredDisplayCountDocument, options);
      }
export type FilteredDisplayCountSubscriptionHookResult = ReturnType<typeof useFilteredDisplayCountSubscription>;
export type FilteredDisplayCountSubscriptionResult = Apollo.SubscriptionResult<FilteredDisplayCountSubscription>;
export const LocationsPageCardsCategoryCountDocument = gql`
    subscription _LocationsPageCardsCategoryCount($where: view_displays_assigned_options_bool_exp) {
  _displayAssignedOptionsAggregate(where: $where, distinct_on: option_id) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useLocationsPageCardsCategoryCountSubscription__
 *
 * To run a query within a React component, call `useLocationsPageCardsCategoryCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLocationsPageCardsCategoryCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsPageCardsCategoryCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationsPageCardsCategoryCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<LocationsPageCardsCategoryCountSubscription, LocationsPageCardsCategoryCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LocationsPageCardsCategoryCountSubscription, LocationsPageCardsCategoryCountSubscriptionVariables>(LocationsPageCardsCategoryCountDocument, options);
      }
export type LocationsPageCardsCategoryCountSubscriptionHookResult = ReturnType<typeof useLocationsPageCardsCategoryCountSubscription>;
export type LocationsPageCardsCategoryCountSubscriptionResult = Apollo.SubscriptionResult<LocationsPageCardsCategoryCountSubscription>;
export const LocationsPageTableSDocument = gql`
    query _LocationsPageTableS($where: view_displays_bool_exp, $orderBy: [view_displays_order_by!], $limit: Int, $offset: Int) {
  _displays(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    id
    name
    version
    hardware
    ip_addresses_primary
    availability {
      is_online
    }
    location {
      floor_count {
        floor
        count_per_floor
      }
      address {
        id
        nickname
        building
        city
        country
        address_full
      }
    }
  }
}
    `;

/**
 * __useLocationsPageTableSQuery__
 *
 * To run a query within a React component, call `useLocationsPageTableSQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsPageTableSQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsPageTableSQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useLocationsPageTableSQuery(baseOptions?: Apollo.QueryHookOptions<LocationsPageTableSQuery, LocationsPageTableSQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsPageTableSQuery, LocationsPageTableSQueryVariables>(LocationsPageTableSDocument, options);
      }
export function useLocationsPageTableSLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsPageTableSQuery, LocationsPageTableSQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsPageTableSQuery, LocationsPageTableSQueryVariables>(LocationsPageTableSDocument, options);
        }
export type LocationsPageTableSQueryHookResult = ReturnType<typeof useLocationsPageTableSQuery>;
export type LocationsPageTableSLazyQueryHookResult = ReturnType<typeof useLocationsPageTableSLazyQuery>;
export type LocationsPageTableSQueryResult = Apollo.QueryResult<LocationsPageTableSQuery, LocationsPageTableSQueryVariables>;
